<template>
        <div>
        
        <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="500"
                    >
                    <v-card>
                        <v-card-title class="text-h5">
                        Extraction du contrat
                        </v-card-title>
                        <v-card-text>
                            <v-alert v-if="error==true && loading==false" type="error">
                                {{messageDialoge}}
                            </v-alert>
                            <v-alert v-if="error==false && loading==false" type="success">
                                {{messageDialoge}}
                            </v-alert>
                            <v-flex  class="text-center">
                                <v-progress-circular v-if="loading==true"
                                    :size="150"
                                    :width="7"
                                    color="primary"
                                    indeterminate
                                    >
                                    Patientez  ...
                                </v-progress-circular>
                            </v-flex>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-if="loading==false"
                            color="green darken-1"
                            text
                            @click="dialog = false"
                        >
                            FERMER
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            </v-row>

            <!-- form add plus data -->
        <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="addData==true">
                <v-dialog
                    v-model="addData"
                    max-width="850"
                    persistent
                    >
                    <v-card>
                        <v-card-title class="text-h5">
                            Informations complémentaires
                            
                        </v-card-title>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-form ref="form"  lazy-validation class="justify-left">
                            <v-row  class="justify-left">
                                
                                <v-col cols="10" md="10" sm="11" lg="11" xl="11" class="justify-left">
                                    <span>Type de contrat</span>
                                    <v-radio-group
                                        class="justify-left"
                                        v-model="etatCompteur"
                                        row
                                        >
                                        <v-radio
                                            label="Changement de fournisseur"
                                            value="changer"
                                        ></v-radio>
                                        <v-radio
                                            label="Emménagement sur site"
                                            value="couper"
                                        ></v-radio>
                                        <v-radio
                                            label="Mise en service sur site"
                                            value="mise"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="10" md="10" sm="10" lg="10" xl="10" class="justify-left" v-if="etatCompteur=='couper'">
                                    <span>Électricité coupée</span>
                                    <v-radio-group
                                        class="justify-left"
                                        v-model="elecouper"
                                        row
                                        >
                                        <v-radio
                                            label="oui"
                                            value="oui"
                                        ></v-radio>
                                        <v-radio
                                            label="non"
                                            value="non"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="10" md="10" sm="10" lg="10" xl="10" class="justify-left" v-if="etatCompteur=='mise'">
                                    <span>Consuel obtenu</span>
                                    <v-radio-group
                                        class="justify-left"
                                        v-model="Consuel"
                                        row
                                        >
                                        <v-radio
                                            label="oui"
                                            value="oui"
                                        ></v-radio>
                                        <v-radio
                                            label="non"
                                            value="non"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                
                                <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                                    
                                    <v-btn v-if="loading==false"
                                        @click="createContrat"
                                        depressed class="ma-auto text-capitalize" 
                                        color="primary">
                                        Confirmer
                                    </v-btn>
                                    <v-btn v-if="loading==false"
                                        color="error"
                                        text
                                        @click="addData=false"
                                    >
                                        Annuler
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col>
                <v-btn
                    @click="replierForm"
                    depressed class="ma-auto text-capitalize" color="primary">
                    Éditer contrat
                </v-btn>
            </v-col>
        </v-row>
            <!--<v-col>

                <v-btn
                    @click="generatePdfForm"
                    depressed class="ma-auto text-capitalize" color="primary">
                    Souscrire
                </v-btn>
            </v-col>
        </v-row>-->
        </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText, setFillingRgbColor, setFontAndSize  } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import axios from 'axios'
    export default {
        name: 'edsContrat',
        props: {
            donner: null,
            numCompteur: null,
            societe: null,
            donnerEnedis: null,
            optionTarifaire: null,
        },
        data: () => ({
            etatCompteur: "changer",
            elecouper: 'oui',
            Consuel: 'oui',
            addData: false,
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "",
             energieVert: true,
             percent: '25',
             generatedPdf: null,
             type: '',
             positionPage1: 1,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             
             data:{
                 dateAjourdhui: new Date().getDate()+"/"+new Date().getMonth()+1+"/"+new Date().getFullYear(),
                 dateDebut: "",
                 dateFin: "",
                 pdl: "",
                 siret: "", 
                 codeNaf: "", 
                 adresseSociete: "", 
                 adresseCompteur: "",
                 codePostaleCompteur: '',
                 voieCompteur: '',
                 communeCompteur: '',
                 codePostaleSociete: '',
                 voieSociete: '',
                 communeSociete: '',
                 codePostale: '',
                 nom: "",
                 prenom: "", 
                 email: "", 
                 numTele: "", 
                 consommationAnuuelle: "",
                 typeCompteur: "",
                 kva: "",
                 raison: "",
                 nbrMonth: '',
                 cabase: '',
                 cahp: '',
                 cahc: '',
             },
        }),
        
        created() {
            //this.verifyApplicationDataSecurity()
        },
        methods: {
            async replierForm(){
                this.addData = true
            },
            async createContrat(){
                this.$refs.form.validate()
                if(this.$refs.form.validate()){
                this.addData = false
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialoge= ""
                var autreData = {
                    etatCompteur: this.etatCompteur,
                    elecouper: this.elecouper,
                    Consuel: this.Consuel,
                }
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                    autreData: autreData,
                }
                await axios({
                    url: 'https://editioncontrat.volto.fr/edsContrat',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'_EDS_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
                }
            },
            async getAdresseCompteur(){
                this.addData = false
                this.data.dateAjourdhui= new Date().getDate()+"/"+new Date().getMonth()+1+"/"+new Date().getFullYear()
                if(this.donnerEnedis.donneTechniqueInfo[0].result == 'valide'){
                    this.data.adresseCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie+' '+
                            this.donnerEnedis.donneTechniqueInfo[0].data.codePostal+' '+this.donnerEnedis.donneTechniqueInfo[0].data.commune
                    
                    this.data.codePostaleCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.codePostal
                    this.data.voieCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie
                    this.data.communeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.commune

                }else{
                    
                    this.data.codePostaleCompteur = ""
                    this.data.voieCompteur = ""
                    this.data.communeCompteur = ""
                    this.data.adresseCompteu = ""
                }
                this.data.dateDebut = this.donner.dateDebut+""
                this.data.dateFin = this.donner.dateFin+""
                this.data.pdl = this.numCompteur+""
                this.data.siret = this.societe.siret+''
                this.data.adresseSociete = this.societe.AdressePostal+""
                this.data.nom = this.societe.nom+""
                this.data.prenom = this.societe.prenom+""
                this.data.email = this.societe.AdresseEmail+""
                this.data.numTele = this.societe.numTele+""
                this.data.consommationAnuuelle = this.donner.consomationGlobale+""
                this.data.kva = this.donner.kva+""
                this.data.raison = this.societe.Raison+""
                this.data.nbrMonth = this.donner.nbrMonth+""
                this.data.typeCompteur = this.optionTarifaire
                this.data.codeNaf = this.societe.codeNaf               
                this.data.codePostaleSociete = this.societe.codePostal
                this.data.voieSociete = this.societe.Voie
                this.data.communeSociete = this.societe.commune
                this.data.abo = this.donner.mensuel
                this.data.prixBase = this.donner.unitaire
                this.data.prixHp = this.donner.unitairehp
                this.data.prixHc = this.donner.unitairehc
                this.data.CAHP = this.donner.CAHP
                this.data.CAHC = this.donner.CAHC
                this.data.CABASE = this.donner.CABASE

                this.data.propHp = this.donner.propHp
                this.data.propHc = this.donner.propHc
                this.data.propBase = this.donner.propBase
                this.data.achHp = this.donner.achHp
                this.data.achHc = this.donner.achHc
                this.data.achBase = this.donner.achBase
                this.data.duree = this.donner.duree
                this.data.type = this.donner.type
                this.data.parametreCompteur = this.donner.parametreCompteur

                this.data.scoreCreditSafe = this.donner.scoreCreditSafe
            },
            async generatePdfForm(){
                 await this.getAdresseCompteur();
                /////////////// page 1 ///////////////////////////////////////
                var url0 = '/pdf/eds/EDSF-1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                const form2 = pageZeroDoc.getForm()
                const dateDebut = form2.getTextField('dateDebut')
                dateDebut.setText(this.data.dateDebut)
                dateDebut.setFontSize(6.5)

                const dateFin = form2.getTextField('dateFin')
                dateFin.setText(this.data.dateFin)
                dateFin.setFontSize(6.5)

                const nbrMonth = form2.getTextField('nbrMonth')
                nbrMonth.setText(this.data.nbrMonth+" mois")
                nbrMonth.setFontSize(6.5)

                const pdl = form2.getTextField('pdl')
                pdl.setText(this.data.pdl)
                pdl.setFontSize(6.5)
                const consommation = form2.getTextField('consommation')
                consommation.setText(this.data.consommationAnuuelle*1000+" KWh")
                consommation.setFontSize(6.5)
                const adresseCons = form2.getTextField('adresseCons')
                adresseCons.setText(this.data.adresseCompteur)
                adresseCons.setFontSize(6.5)

                const codePostalCons = form2.getTextField('codePostalCons')
                codePostalCons.setText(this.data.codePostaleCompteur)
                codePostalCons.setFontSize(6.5)

                const communCons = form2.getTextField('communCons')
                communCons.setText(this.data.communeCompteur)
                communCons.setFontSize(6.5)

                const raison = form2.getTextField('raison')
                raison.setText(this.data.raison)
                raison.setFontSize(6.5)

                const nomClient = form2.getTextField('nomClient')
                nomClient.setText(this.data.nom+"")
                nomClient.setFontSize(6.5)

                const prenomClient = form2.getTextField('prenomClient')
                prenomClient.setText(this.data.prenom+"")
                prenomClient.setFontSize(6.5)

                const nomClient1 = form2.getTextField('nom1')
                nomClient1.setText(this.data.nom+"")
                nomClient1.setFontSize(6.5)

                const prenomClient1 = form2.getTextField('prenom1')
                prenomClient1.setText("  "+this.data.prenom+"")
                prenomClient1.setFontSize(6.5)

                /*const lieux1 = form2.getTextField('lieux1')
                lieux1.setText("  "+this.data.communeSociete+"")
                lieux1.setFontSize(6.5)*/

                const siret = form2.getTextField('siret')
                siret.setText(this.data.siret)
                siret.setFontSize(6.5)
                const adresse = form2.getTextField('adresse')
                adresse.setText(this.data.adresseSociete)
                adresse.setFontSize(6.5)

                const codepostal = form2.getTextField('codepostal')
                codepostal.setText(this.data.codePostaleSociete)
                codepostal.setFontSize(6.5)

                const commun = form2.getTextField('commun')
                commun.setText(this.data.communeSociete)
                commun.setFontSize(6.5)
                const numTele = form2.getTextField('numTele')
                numTele.setText(this.data.numTele)
                numTele.setFontSize(6.5)

                const email = form2.getTextField('email')
                email.setText(this.data.email)
                email.setFontSize(6.5)
                const kva1 = form2.getRadioGroup('kva1')
                const kva2 = form2.getRadioGroup('kva2')
                const unite1 = form2.getTextField('unite1')
                const unite2 = form2.getTextField('unite2')
                const prix1 = form2.getTextField('prix1')
                const prix2 = form2.getTextField('prix2')
                var prixdepo = 0;
                if(this.data.parametreCompteur == "C5BASE"){
                    if(this.etatCompteur == "couper"){
                        const CABASEFIELD = form2.getTextField('CABASE')
                        CABASEFIELD.setText(this.data.CABASE+' MWh(BASE)')
                        CABASEFIELD.setFontSize(6.5)
                    }
                    kva2.clear()
                    kva1.select(this.data.kva)
                    unite1.setText("BASE: ")
                    unite1.setFontSize(6.5)
                    
                    prix1.setText(this.data.prixBase+" €/MWh")
                    prix1.setFontSize(6.5)
                    const parsed = parseInt(this.data.scoreCreditSafe, 10);
                    if (isNaN(parsed)) { 
                        this.data.scoreCreditSafe = 0
                    }
                    if(this.data.scoreCreditSafe<25){
                        prixdepo = (this.data.prixBase * this.data.CABASE)/6
                    }
                }else{
                    kva2.select(this.data.kva)
                    kva1.clear()
                    unite1.setText("HP: ")
                    unite1.setFontSize(6.5)
                    unite2.setText("HC: ")
                    unite2.setFontSize(6.5)
                    
                    prix1.setText(this.data.prixHp+" €/MWh")
                    prix1.setFontSize(6.5)
                    
                    prix2.setText(this.data.prixHc+" €/MWh")
                    prix2.setFontSize(6.5)
                    const parsed = parseInt(this.data.scoreCreditSafe, 10);
                    if (isNaN(parsed)) { 
                        this.data.scoreCreditSafe = 0
                    }
                    if(this.data.scoreCreditSafe<25){
                        var hp = 2/3*this.data.prixHp
                        var hc = 2/3*this.data.prixHc
                        var d = ((hp+hc)*this.data.consommationAnuuelle)/6
                        prixdepo = d
                    }
                    if(this.etatCompteur == "couper"){
                        const CAHPFIELD = form2.getTextField('CAHP')
                        CAHPFIELD.setText(this.data.CAHP+' MWh(HP)')
                        CAHPFIELD.setFontSize(6.5)

                        const CAHCFIELD = form2.getTextField('CAHC')
                        CAHCFIELD.setText(this.data.CAHC+' MWh(HP)')
                        CAHCFIELD.setFontSize(6.5)
                    }
                }
                const abo = form2.getTextField('abo')
                abo.setText(this.data.abo+' €/mois')
                abo.setFontSize(6.5)
                var t= new Date();
                var dateFinMonth = new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59);
                dateFinMonth = dateFinMonth.getDate()+"/"+(dateFinMonth.getMonth()+1)+"/"+dateFinMonth.getFullYear();

                const dateValide = form2.getTextField('dateValide')
                dateValide.setText(dateFinMonth)
                dateValide.setFontSize(6.5)

                const depo = form2.getTextField('depo')
                depo.setText(prixdepo.toFixed(2)+" €")
                depo.setFontSize(6.5)


                const resiliation = form2.getTextField('resiliation')
                resiliation.setText(((this.data.consommationAnuuelle*1000/12)/10).toFixed(2)+" €/mois restant")
                resiliation.setFontSize(6.5)

                const consoMoyenne = form2.getTextField('consoMoyenne')
                consoMoyenne.setText((this.data.consommationAnuuelle*1000/12+"").split('.')[0]+" kWh/mois) (hors cessation d'activité)")
                consoMoyenne.setFontSize(6.5)

                const status = form2.getRadioGroup('status')
                status.select(this.etatCompteur)

                if(this.etatCompteur == "couper"){
                    const status = form2.getRadioGroup('elecCouper')
                    status.select(this.elecouper)
                }else if(this.etatCompteur == "mise"){
                    const status = form2.getRadioGroup('consuelObtenu')
                    status.select(this.Consuel)
                }
                const typePayement = form2.getRadioGroup('typePayement')
                typePayement.select("PA")
                const fieldss = form2.getFields()
                fieldss.forEach(field => {
                    field.enableReadOnly()
                })
                form2.updateFieldAppearances()

                /////////////// page 3 ///////////////////////////////////////
                var url0 = '/pdf/eds/EDSF-2.pdf'
                const pageTwo = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                const pageTwoDoc = await PDFDocument.load(pageTwo)

                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]
                const form3 = pageTwoDoc.getForm()
                const fields = form3.getFields()
                fields.forEach(field => {
                    const type = field.constructor.name
                    const name = field.getName()
                })
                //get champs
                
                const title1 = form3.getTextField('title1')
                if(this.data.type == 'Prix variable'){
                    if(this.data.duree == "36" || this.data.duree == 36){
                        title1.setText("VAR - PRIX VARIABLE JUSQU’À 48 MOIS")
                    }else if(this.data.duree == "24" || this.data.duree == 24){
                        title1.setText("VAR - PRIX VARIABLE JUSQU’À 35 MOIS")
                    }else{
                        title1.setText("VAR - PRIX VARIABLE JUSQU’À "+this.data.duree+" MOIS")
                    }
                }else{
                    if(this.data.duree == "36" || this.data.duree == 36){
                        title1.setText("FC0 - PRIX FIXE JUSQU’À 48 MOIS")
                    }else if(this.data.duree == "24" || this.data.duree == 24){
                        title1.setText("FC0 - PRIX FIXE JUSQU’À 35 MOIS")
                    }else{
                        title1.setText("FC0 - PRIX FIXE JUSQU’À "+this.data.duree+" MOIS")
                    }
                }
                const title2 = form3.getTextField('title2')
                if(this.data.type == 'Prix variable'){
                    title2.setText(" HT Fixe - PRO")
                }else{
                    title2.setText(" HT Fixe - PRO")
                }
                const title3 = form3.getTextField('title3')
                if(this.data.type == 'Prix variable'){
                    title3.setText("HT Variable - PRO HPHC")
                }else{
                    title3.setText("HT Fixe - PRO HPHC")
                }

                const prixBase1 = form3.getTextField('prixBase1')
                const prixBase2 = form3.getTextField('prixBase2')
                const prixBase3 = form3.getTextField('prixBase3')
                const prixBase4 = form3.getTextField('prixBase4')
                const prixBase5 = form3.getTextField('prixBase5')
                const prixBase6 = form3.getTextField('prixBase6')
                const prixBase7 = form3.getTextField('prixBase7')
                const prixBase8 = form3.getTextField('prixBase8')
                const prixBase9 = form3.getTextField('prixBase9')

                const propBase1 = form3.getTextField('propBase1')
                const propBase2 = form3.getTextField('propBase2')
                const propBase3 = form3.getTextField('propBase3')
                const propBase4 = form3.getTextField('propBase4')
                const propBase5 = form3.getTextField('propBase5')
                const propBase6 = form3.getTextField('propBase6')
                const propBase7 = form3.getTextField('propBase7')
                const propBase8 = form3.getTextField('propBase8')
                const propBase9 = form3.getTextField('propBase9')

                const achBase1 = form3.getTextField('achBase1')
                const achBase2 = form3.getTextField('achBase2')
                const achBase3 = form3.getTextField('achBase3')
                const achBase4 = form3.getTextField('achBase4')
                const achBase5 = form3.getTextField('achBase5')
                const achBase6 = form3.getTextField('achBase6')
                const achBase7 = form3.getTextField('achBase7')
                const achBase8 = form3.getTextField('achBase8')
                const achBase9 = form3.getTextField('achBase9')

                const prixHp1 = form3.getTextField('prixHp1')
                const prixHp2 = form3.getTextField('prixHp2')
                const prixHp3 = form3.getTextField('prixHp3')
                const prixHp4 = form3.getTextField('prixHp4')
                const prixHp5 = form3.getTextField('prixHp5')
                const prixHp6 = form3.getTextField('prixHp6')
                const prixHp7 = form3.getTextField('prixHp7')
                const prixHp8 = form3.getTextField('prixHp8')

                const propHp1 = form3.getTextField('propHp1')
                const propHp2 = form3.getTextField('propHp2')
                const propHp3 = form3.getTextField('propHp3')
                const propHp4 = form3.getTextField('propHp4')
                const propHp5 = form3.getTextField('propHp5')
                const propHp6 = form3.getTextField('propHp6')
                const propHp7 = form3.getTextField('propHp7')
                const propHp8 = form3.getTextField('propHp8')

                const achHp1 = form3.getTextField('achHp1')
                const achHp2 = form3.getTextField('achHp2')
                const achHp3 = form3.getTextField('achHp3')
                const achHp4 = form3.getTextField('achHp4')
                const achHp5 = form3.getTextField('achHp5')
                const achHp6 = form3.getTextField('achHp6')
                const achHp7 = form3.getTextField('achHp7')
                const achHp8 = form3.getTextField('achHp8')

                const prixHc1 = form3.getTextField('prixHc1')
                const prixHc2 = form3.getTextField('prixHc2')
                const prixHc3 = form3.getTextField('prixHc3')
                const prixHc4 = form3.getTextField('prixHc4')
                const prixHc5 = form3.getTextField('prixHc5')
                const prixHc6 = form3.getTextField('prixHc6')
                const prixHc7 = form3.getTextField('prixHc7')
                const prixHc8 = form3.getTextField('prixHc8')

                const propHc1 = form3.getTextField('propHc1')
                const propHc2 = form3.getTextField('propHc2')
                const propHc3 = form3.getTextField('propHc3')
                const propHc4 = form3.getTextField('propHc4')
                const propHc5 = form3.getTextField('propHc5')
                const propHc6 = form3.getTextField('propHc6')
                const propHc7 = form3.getTextField('propHc7')
                const propHc8 = form3.getTextField('propHc8')

                const achHc1 = form3.getTextField('achHc1')
                const achHc2 = form3.getTextField('achHc2')
                const achHc3 = form3.getTextField('achHc3')
                const achHc4 = form3.getTextField('achHc4')
                const achHc5 = form3.getTextField('achHc5')
                const achHc6 = form3.getTextField('achHc6')
                const achHc7 = form3.getTextField('achHc7')
                const achHc8 = form3.getTextField('achHc8')
                //remplire donner

                prixBase1.setText((this.data.propBase + this.data.achBase)+"") 
                prixBase2.setText((this.data.propBase + this.data.achBase)+"") 
                prixBase3.setText((this.data.propBase + this.data.achBase)+"")
                prixBase4.setText((this.data.propBase + this.data.achBase)+"")
                prixBase5.setText((this.data.propBase + this.data.achBase)+"")
                prixBase6.setText((this.data.propBase + this.data.achBase)+"")
                prixBase7.setText((this.data.propBase + this.data.achBase)+"")
                prixBase8.setText((this.data.propBase + this.data.achBase)+"")
                prixBase9.setText((this.data.propBase + this.data.achBase)+"")

                propBase1.setText(this.data.propBase+"")
                propBase2.setText(this.data.propBase+"")
                propBase3.setText(this.data.propBase+"")
                propBase4.setText(this.data.propBase+"")
                propBase5.setText(this.data.propBase+"")
                propBase6.setText(this.data.propBase+"")
                propBase7.setText(this.data.propBase+"")
                propBase8.setText(this.data.propBase+"")
                propBase9.setText(this.data.propBase+"")

                achBase1.setText(this.data.achBase+"")
                achBase2.setText(this.data.achBase+"")
                achBase3.setText(this.data.achBase+"")
                achBase4.setText(this.data.achBase+"")
                achBase5.setText(this.data.achBase+"")
                achBase6.setText(this.data.achBase+"")
                achBase7.setText(this.data.achBase+"")
                achBase8.setText(this.data.achBase+"")
                achBase9.setText(this.data.achBase+"")

                prixHp1.setText((this.data.propHp + this.data.achHp)+"")
                prixHp2.setText((this.data.propHp + this.data.achHp)+"")
                prixHp3.setText((this.data.propHp + this.data.achHp)+"")
                prixHp4.setText((this.data.propHp + this.data.achHp)+"")
                prixHp5.setText((this.data.propHp + this.data.achHp)+"")
                prixHp6.setText((this.data.propHp + this.data.achHp)+"")
                prixHp7.setText((this.data.propHp + this.data.achHp)+"")
                prixHp8.setText((this.data.propHp + this.data.achHp)+"")

                propHp1.setText(this.data.propHp+"")
                propHp2.setText(this.data.propHp+"")
                propHp3.setText(this.data.propHp+"")
                propHp4.setText(this.data.propHp+"")
                propHp5.setText(this.data.propHp+"")
                propHp6.setText(this.data.propHp+"")
                propHp7.setText(this.data.propHp+"")
                propHp8.setText(this.data.propHp+"")

                achHp1.setText(this.data.achHp+"")
                achHp2.setText(this.data.achHp+"")
                achHp3.setText(this.data.achHp+"")
                achHp4.setText(this.data.achHp+"")
                achHp5.setText(this.data.achHp+"")
                achHp6.setText(this.data.achHp+"")
                achHp7.setText(this.data.achHp+"")
                achHp8.setText(this.data.achHp+"")

                prixHc1.setText((this.data.propHc + this.data.achHc)+"")
                prixHc2.setText((this.data.propHc + this.data.achHc)+"")
                prixHc3.setText((this.data.propHc + this.data.achHc)+"")
                prixHc4.setText((this.data.propHc + this.data.achHc)+"")
                prixHc5.setText((this.data.propHc + this.data.achHc)+"")
                prixHc6.setText((this.data.propHc + this.data.achHc)+"")
                prixHc7.setText((this.data.propHc + this.data.achHc)+"")
                prixHc8.setText((this.data.propHc + this.data.achHc)+"")

                propHc1.setText(this.data.propHc+"")
                propHc2.setText(this.data.propHc+"")
                propHc3.setText(this.data.propHc+"")
                propHc4.setText(this.data.propHc+"")
                propHc5.setText(this.data.propHc+"")
                propHc6.setText(this.data.propHc+"")
                propHc7.setText(this.data.propHc+"")
                propHc8.setText(this.data.propHc+"")
                
                achHc1.setText(this.data.achHc+"")
                achHc2.setText(this.data.achHc+"")
                achHc3.setText(this.data.achHc+"")
                achHc4.setText(this.data.achHc+"")
                achHc5.setText(this.data.achHc+"")
                achHc6.setText(this.data.achHc+"")
                achHc7.setText(this.data.achHc+"")
                achHc8.setText(this.data.achHc+"")

                

                
                prixBase1.setFontSize(6.5)
                prixBase2.setFontSize(6.5)
                prixBase3.setFontSize(6.5)
                prixBase4.setFontSize(6.5)
                prixBase5.setFontSize(6.5)
                prixBase6.setFontSize(6.5)
                prixBase7.setFontSize(6.5)
                prixBase8.setFontSize(6.5)
                prixBase9.setFontSize(6.5)
                prixHp1.setFontSize(6.5)
                prixHp2.setFontSize(6.5)
                prixHp3.setFontSize(6.5)
                prixHp4.setFontSize(6.5)
                prixHp5.setFontSize(6.5)
                prixHp6.setFontSize(6.5)
                prixHp7.setFontSize(6.5)
                prixHp8.setFontSize(6.5)
                prixHc1.setFontSize(6.5)
                prixHc2.setFontSize(6.5)
                prixHc3.setFontSize(6.5)
                prixHc4.setFontSize(6.5)
                prixHc5.setFontSize(6.5)
                prixHc6.setFontSize(6.5)
                prixHc7.setFontSize(6.5)
                prixHc8.setFontSize(6.5)

                propBase1.setFontSize(6.5)
                propBase2.setFontSize(6.5)
                propBase3.setFontSize(6.5)
                propBase4.setFontSize(6.5)
                propBase5.setFontSize(6.5)
                propBase6.setFontSize(6.5)
                propBase7.setFontSize(6.5)
                propBase8.setFontSize(6.5)
                propBase9.setFontSize(6.5)
                propHp1.setFontSize(4.5)
                propHp2.setFontSize(4.5)
                propHp3.setFontSize(4.5)
                propHp4.setFontSize(4.5)
                propHp5.setFontSize(4.5)
                propHp6.setFontSize(4.5)
                propHp7.setFontSize(4.5)
                propHp8.setFontSize(4.5)
                propHc1.setFontSize(4.5)
                propHc2.setFontSize(4.5)
                propHc3.setFontSize(4.5)
                propHc4.setFontSize(4.5)
                propHc5.setFontSize(4.5)
                propHc6.setFontSize(4.5)
                propHc7.setFontSize(4.5)
                propHc8.setFontSize(4.5)

                achBase1.setFontSize(6.5)
                achBase2.setFontSize(6.5)
                achBase3.setFontSize(6.5)
                achBase4.setFontSize(6.5)
                achBase5.setFontSize(6.5)
                achBase6.setFontSize(6.5)
                achBase7.setFontSize(6.5)
                achBase8.setFontSize(6.5)
                achBase9.setFontSize(6.5)
                achHp1.setFontSize(4.5)
                achHp2.setFontSize(4.5)
                achHp3.setFontSize(4.5)
                achHp4.setFontSize(4.5)
                achHp5.setFontSize(4.5)
                achHp6.setFontSize(4.5)
                achHp7.setFontSize(4.5)
                achHp8.setFontSize(4.5)
                achHc1.setFontSize(4.5)
                achHc2.setFontSize(4.5)
                achHc3.setFontSize(4.5)
                achHc4.setFontSize(4.5)
                achHc5.setFontSize(4.5)
                achHc6.setFontSize(4.5)
                achHc7.setFontSize(4.5)
                achHc8.setFontSize(4.5)

                
                title1.setFontSize(4.5)
                title1.setFontSize(4.5)
                title1.setFontSize(4.5)
                

                propBase1.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString())
                propBase2.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propBase3.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propBase4.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propBase5.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propBase6.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propBase7.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propBase8.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propBase9.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHp1.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHp2.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHp3.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHp4.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHp5.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHp6.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHp7.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHp8.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHc1.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHc2.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHc3.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHc4.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHc5.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHc6.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHc7.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());
                propHc8.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.44, 0.74, 0.56).toString());


                achBase1.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                
                
                achBase2.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achBase3.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achBase4.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achBase5.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achBase6.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achBase7.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achBase8.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achBase9.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHp1.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHp2.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHp3.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHp4.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHp5.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHp6.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHp7.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHp8.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHc1.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHc2.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHc3.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHc4.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHc5.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHc6.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHc7.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());
                achHc8.acroField.setDefaultAppearance(setFontAndSize('Courier', 6).toString()+setFillingRgbColor(0.13, 0.30, 0.47).toString());

                title1.acroField.setDefaultAppearance(setFontAndSize('Courier', 10).toString()+setFillingRgbColor(0,0,0).toString());
                title2.acroField.setDefaultAppearance(setFontAndSize('Courier', 9).toString()+setFillingRgbColor(1,1,1).toString());
                title3.acroField.setDefaultAppearance(setFontAndSize('Courier', 9).toString()+setFillingRgbColor(1,1,1).toString());


                const fields3 = form3.getFields()
                fields3.forEach(field => {
                    field.enableReadOnly()
                })
                form3.updateFieldAppearances()
                /////////////// page 3 ///////////////////////////////////////
                var url0 = '/pdf/eds/EDSF-3.pdf'
                const pageThree = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                const form4 = pageThreeDoc.getForm()

                const pdlFin = form4.getTextField('pdlFin')
                pdlFin.setText(this.data.pdl)
                pdlFin.setFontSize(6.5)
                const nomPrenomFin = form4.getTextField('nomPrenomFin')
                nomPrenomFin.setText(this.data.raison)
                nomPrenomFin.setFontSize(6.5)
                const adresseClientFin = form4.getTextField('adresseClientFin')
                adresseClientFin.setText(this.data.adresseSociete)
                adresseClientFin.setFontSize(6.5)
                const villeFin = form4.getTextField('villeFin')
                villeFin.setText(this.data.communeSociete)
                villeFin.setFontSize(6.5)
                var codePostal = ""
                for (var i=0; i<this.data.codePostaleSociete.length; i++){
                    codePostal = codePostal + this.data.codePostaleSociete[i]+" "
                }
                const codePostalFin = form4.getTextField('codePostalFin')
                codePostalFin.setText(codePostal)
                codePostalFin.setFontSize(8.7)
                
                const fieldsFin = form4.getFields()
                fieldsFin.forEach(field => {
                    field.enableReadOnly()
                })
                form4.updateFieldAppearances()
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)

                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_EDS_'+this.data.raison+'_.pdf', 'application/pdf')
            },
             async generatePdf(){
                 await this.getAdresseCompteur();
                /////////////// page 1 ///////////////////////////////////////
                var url0 = '/pdf/eds/EDS-1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]

                /////////////// page 2 ///////////////////////////////////////
                var url0 = '/pdf/eds/EDS-2.pdf'
                const pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.dateDebut, {
                    x: 130,
                    y: 725,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 280,
                    y: 725,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.nbrMonth, {
                    x: 410,
                    y: 725,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 120,
                    y: 700,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.consommationAnuuelle*1000+"", {
                    x: 345,
                    y: 700,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.adresseCompteur, {
                    x: 100,
                    y: 660,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.codePostaleCompteur, {
                    x: 120,
                    y: 645,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.communeCompteur, {
                    x: 250,
                    y: 645,
                    size: 9,
                    color: rgb(0, 0, 0),
                })

                firstPage.drawText(this.data.raison, {
                    x: 95,
                    y: 616,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.nom + " "+ this.data.prenom, {
                    x: 115,
                    y: 600,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.siret, {
                    x: 90,
                    y: 587,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 400,
                    y: 587,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.adresseSociete, {
                    x: 95,
                    y: 573,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.codePostaleSociete, {
                    x: 120,
                    y: 560,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                 firstPage.drawText(this.data.communeSociete, {
                    x: 200,
                    y: 560,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                
                 firstPage.drawText(this.data.numTele, {
                    x: 85,
                    y: 547,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.email, {
                    x: 240,
                    y: 547,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                
                var ypos = 407
                if(this.data.typeCompteur == "BTINFMUDT" || this.data.typeCompteur == "BTINFMU4"){
                    ypos = 395
                }else{
                    ypos = 407
                }
                var xpos = 290;
                if(this.data.kva == '3'){
                    xpos = 290;
                }else if(this.data.kva == '6'){
                    xpos = 323;
                }else if(this.data.kva == '9'){
                    xpos = 353;
                }else if(this.data.kva == '12'){
                    xpos = 384;
                }else if(this.data.kva == '15'){
                    xpos = 416;
                }else if(this.data.kva == '18'){
                    xpos = 446;
                }else if(this.data.kva == '24'){
                    xpos = 476;
                }else if(this.data.kva == '30'){
                    xpos = 510;
                }else if(this.data.kva == '36'){
                    xpos = 540;
                }
                
                firstPage.drawText("X", {
                    x: xpos,
                    y: ypos,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText("X", {
                    x: 50,
                    y: 336,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.nbrMonth, {
                    x: 270,
                    y: 242,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText("X", {
                    x: 130,
                    y: 208,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                /////////////// page 3 ///////////////////////////////////////
                var url0 = '/pdf/eds/EDS-3.pdf'
                const pageTwo = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]

                 /////////////// page 4 ///////////////////////////////////////
                var url0 = '/pdf/eds/EDS-4.pdf'
                const pageThree = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]

                 /////////////// page 5 ///////////////////////////////////////
                var url0 = '/pdf/eds/EDS-5.pdf'
                const pageFour = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]


                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_EDS_'+this.data.raison+'_.pdf', 'application/pdf')

            },
            async addPagesToPdf(mergedPdf, donorPdf) {
                const copiedPages = await mergedPdf.copyPages(
                    donorPdf,
                    donorPdf.getPageIndices()
                )
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page)
                })
                return mergedPdf
            },
            verifyApplicationDataSecurity() {
                let self = this
                var formData = new FormData()
                formData.append('type', localStorage.getItem('typeUser'))
                formData.append('vueAfficher', 'pro')
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'verifyApplicationDataSecurity.php',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login/')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login/')
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            },
        },
    }
</script>
<style scoped>
::v-deep .v-input--selection-controls__input + .v-label{
   margin-bottom:0px !important;
}
</style>