<template>
    <v-container>
        <v-card class="mt-6 pa-4" elevation="10">
        <template>
            <!--<v-toolbar dark color="primary" fixed>
                <v-toolbar-title class="white--text">Mes Demandes</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
            </v-toolbar>-->
            <v-tabs
                v-model="tab"
                background-color="primary"
                dark
                icons-and-text
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows
                >
                <v-tabs-slider></v-tabs-slider>
        
                <v-tab href="#tab-2">
                    Demande De Cotation
                    <v-icon>drive_folder_upload</v-icon>
                </v-tab>
                <v-tab href="#tab-1">
                    Autorisaton De Collection De Donnees
                    <v-icon>task</v-icon>
                </v-tab>    
            </v-tabs>
            
            <v-tabs-items style="border-radius:5px;" v-model="tab">
            
                    <v-text-field class="ml-6" color="#000000" width="150" v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                <v-tab-item :value="'tab-1'" style="background: #ffffff;">
                    <v-card flat>
                        <v-layout column style="padding-top:0px">
                            <v-data-iterator
                                :search="search"
                                :items="mesDemandeACD"
                                item-key="id"
                                :items-per-page="10"
                                :single-expand="singleExpand"
                                
                                >
                                <template v-slot:default="{ items, isExpanded, expand }">
                                
                                <v-row>
                                    <v-col
                                    v-for="item in items"
                                    :key="item.id"
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    >
                                    <v-card>
                                        <v-switch
                                        :input-value="isExpanded(item)"
                                        :label="isExpanded(item) ? '' : ''"
                                        class="pl-4 mt-0"
                                        @change="(v) => expand(item, v)"
                                        ></v-switch>
                                        <v-card-title>
                                        <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                        <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                                                            <div>
                                                                <p class="text-body-1 grey--text  mb-2">Type:</p>
                                                                <p class="font-weight-regular">{{item.typeDemande}}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="5" md="3" lg="3" xl="3">
                                                            <div>
                                                            <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                            <p class="font-weight-regular">{{societeACD[item.Societe].siret}}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="5" md="4" lg="3" xl="3">
                                                            <div>
                                                            <p class="text-body-1 grey--text  mb-2">Raison:</p>
                                                            <p class="font-weight-regular">{{societeACD[item.Societe].Raison}}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="5" md="4" lg="4" xl="4" class="d-flex flex-column">
                                                            <div style="max-height:90px; overflow: auto;"  class="ml-5">
                                                            <p class="text-body-1 grey--text  mb-2">Les compteurs</p>
                                                            <p class="font-weight-regular " >
                                                                <span v-for="(compteur,i) in item.numCompteur">{{compteur}} | </span>
                                                            </p>
                                                            
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                        </v-card-title>
                                        
                                        <v-divider></v-divider>
                                        <v-list
                                        v-if="isExpanded(item)"
                                        dense
                                        >
                                        <v-list-item>
                                            <v-list-item-content>
                                                    <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                    <v-col cols="12" sm="4" md="3" lg="3" xl="3"  justify="start" align="start" align-content="start">
                                                        <div>
                                                        <p class="font-weight-regular ">
                                                            <span class="text-body-1 grey--text  mb-2">Nom:</span>
                                                            {{societeACD[item.Societe].nom}}
                                                        </p>
                                                        <p class="font-weight-regular ">
                                                            <span class="text-body-1 grey--text  mb-2">Prenom:</span>
                                                            {{societeACD[item.Societe].prenom}}
                                                        </p>
                                                        <p class="font-weight-regular ">
                                                            <span class="text-body-1 grey--text  mb-2">AdresseEmail:</span>
                                                            {{societeACD[item.Societe].AdresseEmail}}
                                                        </p>
                                                        <p class="font-weight-regular ">
                                                            <span class="text-body-1 grey--text  mb-2">numTele:</span>
                                                            {{societeACD[item.Societe].numTele}}
                                                        </p>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="4" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                        <div>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">AdressePostale:</span>
                                                                {{societeACD[item.Societe].AdressePostal}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">codeNaf:</span>
                                                                {{societeACD[item.Societe].codeNaf}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">codeInsee:</span>
                                                                {{societeACD[item.Societe].codeInsee}}
                                                            </p>
                                                        </div>
                                                    </v-col>
                                                    <!-- Budget -->
                                                    <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="" justify="start" align="start" align-content="start">
                                                        <div>
                                                            <div style="max-width: 290px; max-height:90px; overflow: auto;">
                                                                <p class="text-body-1 grey--text  mb-2">Compteur ELEC:</p>
                                                                <p class="font-weight-regular " >
                                                                    <span v-for="(compteur,i) in item.numCompteur">{{compteur}} | </span>
                                                                </p>
                                                            </div>
                                                            <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                            <p class="font-weight-regular">{{societeACD[item.Societe].siret}}</p>
                                                            <!--<p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">Etat:</span>
                                                                signed
                                                            </p>-->
                                                        </div>
                                                    </v-col>

                                                    <!-- score -->
                                                    <v-col class="d-flex flex-column" cols="12" sm="12" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                        
                                                        <v-card outlined>
                                                            <v-card-title class="justify-start">
                                                                <v-avatar class="mr-2" size="36" rounded>
                                                                <img
                                                                    alt="credit safe score"
                                                                    src="../assets/images/logos/logo-credit-safe.png"
                                                                />
                                                                </v-avatar>
                                                                creditsafe
                                                            </v-card-title>

                                                            <v-card-text class=" text-left text-body-1  pr-6"
                                                                >Score:
                                                                <span class="ml-2 font-weight-black text-h6">{{
                                                                societeACD[item.Societe].scoreCreditSafe
                                                                }}</span>
                                                                /100
                                                            </v-card-text>
                                                        </v-card>
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/logoElliPro.png"
                                                                        />
                                                                        </v-avatar>
                                                                        Elli Pro
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1 pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">
                                                                        {{societeACD[item.Societe].scoreEllipro}}
                                                                        </span>
                                                                        /10
                                                                    </v-card-text>
                                                                </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-list>
                                    </v-card>
                                    </v-col>
                                </v-row>
                                </template>
                            </v-data-iterator>
                        </v-layout>
                            
                    </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-2'" style="background: #ffffff;">
                    <v-card flat>
                        <v-layout column style="padding-top:0px">
                            <v-data-iterator
                                :search="search"
                                :items="mesDemandeDeCotation"
                                item-key="id"
                                :items-per-page="10"
                                :single-expand="singleExpand"
                                
                                >
                                <template v-slot:default="{ items, isExpanded, expand }">
                                
                                <v-row>
                                    <v-col
                                    v-for="item in items"
                                    :key="item.id"
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    >
                                    <v-card>
                                        <v-switch
                                        :input-value="isExpanded(item)"
                                        :label="isExpanded(item) ? '' : ''"
                                        class="pl-4 mt-0"
                                        @change="(v) => expand(item, v)"
                                        ></v-switch>
                                        <v-card-title>
                                        <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                        <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                                                            <div>
                                                                <p class="text-body-1 grey--text  mb-2">Type:</p>
                                                                <p class="font-weight-regular">{{item.typeDemande}}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="5" md="3" lg="3" xl="3">
                                                            <div>
                                                            <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                            <p class="font-weight-regular">{{societeDemandeCota[item.Societe].siret}}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="5" md="4" lg="3" xl="3">
                                                            <div>
                                                            <p class="text-body-1 grey--text  mb-2">Raison:</p>
                                                            <p class="font-weight-regular">{{societeDemandeCota[item.Societe].Raison}}</p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="5" md="4" lg="4" xl="4" class="d-flex flex-column">
                                                            <div style="max-height:90px; overflow: auto;"  class="ml-5">
                                                            <p class="text-body-1 grey--text  mb-2">Les compteurs</p>
                                                            <p class="font-weight-regular " >
                                                                <span v-for="(compteur,i) in item.numCompteur">{{compteur}} | </span>
                                                            </p>
                                                            
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                        </v-card-title>
                                        
                                        <v-divider></v-divider>
                                        <v-list
                                        v-if="isExpanded(item)"
                                        dense
                                        >
                                        <v-list-item>
                                            <v-list-item-content>
                                                    <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                    <v-col cols="12" sm="4" md="3" lg="3" xl="3"  justify="start" align="start" align-content="start">
                                                        <div>
                                                        <p class="font-weight-regular ">
                                                            <span class="text-body-1 grey--text  mb-2">Nom:</span>
                                                            {{societeDemandeCota[item.Societe].nom}}
                                                        </p>
                                                        <p class="font-weight-regular ">
                                                            <span class="text-body-1 grey--text  mb-2">Prenom:</span>
                                                            {{societeDemandeCota[item.Societe].prenom}}
                                                        </p>
                                                        <p class="font-weight-regular ">
                                                            <span class="text-body-1 grey--text  mb-2">AdresseEmail:</span>
                                                            {{societeDemandeCota[item.Societe].AdresseEmail}}
                                                        </p>
                                                        <p class="font-weight-regular ">
                                                            <span class="text-body-1 grey--text  mb-2">numTele:</span>
                                                            {{societeDemandeCota[item.Societe].numTele}}
                                                        </p>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="4" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                        <div>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">AdressePostale:</span>
                                                                {{societeDemandeCota[item.Societe].AdressePostal}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">codeNaf:</span>
                                                                {{societeDemandeCota[item.Societe].codeNaf}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">codeInsee:</span>
                                                                {{societeDemandeCota[item.Societe].codeInsee}}
                                                            </p>
                                                        </div>
                                                    </v-col>
                                                    <!-- Budget -->
                                                    <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="" justify="start" align="start" align-content="start">
                                                        <div>
                                                            <div style="max-width: 290px; max-height:90px; overflow: auto;">
                                                                <p class="text-body-1 grey--text  mb-2">Compteur ELEC:</p>
                                                                <p class="font-weight-regular " >
                                                                    <span v-for="(compteur,i) in item.numCompteur">{{compteur}} | </span>
                                                                </p>
                                                            </div>
                                                            <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                            <p class="font-weight-regular">{{societeDemandeCota[item.Societe].siret}}</p>
                                                            <!--<p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">Etat:</span>
                                                                signed
                                                            </p>-->
                                                        </div>
                                                    </v-col>

                                                    <!-- score -->
                                                    <v-col class="d-flex flex-column" cols="12" sm="12" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                        
                                                        <v-card outlined>
                                                            <v-card-title class="justify-start">
                                                                <v-avatar class="mr-2" size="36" rounded>
                                                                <img
                                                                    alt="credit safe score"
                                                                    src="../assets/images/logos/logo-credit-safe.png"
                                                                />
                                                                </v-avatar>
                                                                creditsafe
                                                            </v-card-title>

                                                            <v-card-text class=" text-left text-body-1  pr-6"
                                                                >Score:
                                                                <span class="ml-2 font-weight-black text-h6">{{
                                                                societeDemandeCota[item.Societe].scoreCreditSafe
                                                                }}</span>
                                                                /100
                                                            </v-card-text>
                                                        </v-card>
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/logoElliPro.png"
                                                                        />
                                                                        </v-avatar>
                                                                        Elli Pro
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1 pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">
                                                                        {{societeDemandeCota[item.Societe].scoreEllipro}}
                                                                        </span>
                                                                        /10
                                                                    </v-card-text>
                                                                </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                        </v-list>
                                    </v-card>
                                    </v-col>
                                </v-row>
                                </template>
                            </v-data-iterator>
                        </v-layout>
                            
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
           <!-- <v-data-table
                :search="search"
                :headers="headers"
                :items="mesDemande"
                sort-by="Siret"
                :items-per-page="10"
              >
              
                <template v-slot:top>
                    <v-toolbar flat>

                    </v-toolbar>
                </template>
                <template v-slot:item.scoreCreditSafe="{ item }">
                    <div>
                        <p>{{isNaN(item.scoreCreditSafe)?"info manquante":item.scoreCreditSafe+"/100"}}</p>
                    </div>
                </template>
                <template v-slot:item.scoreInfoLegale="{ item }">
                    <div>
                        <p>{{isNaN(item.scoreInfoLegale)?"info manquante":item.scoreInfoLegale+"/20"}}</p>
                    </div>
                </template>
                <template v-slot:item.numCompteurElec="{ item }">
                    <div style="max-width: 170px; min-width:150px; max-height:80px; overflow: auto;">
                        <p v-for="val in splitedList(item.numCompteurElec)">{{val}}</p>
                    </div>
                </template>
                <template v-slot:item.numCompteurGaz="{ item }">
                    <div style="max-width: 125px; min-width:150px; max-height:80px; overflow: auto;">
                        <p v-for="val in splitedList(item.numCompteurGaz)">{{val}}</p>
                    </div>
                </template>
                
            
            </v-data-table>
            -->
        </template>
        </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'mesDemandeAcd',
        components: {
        },
        data: () => ({
                search: '',
                
                tab: null,
                singleExpand: false,
                mesDemandeDeCotation: [],
                societeDemandeCota: [],
                mesDemandeACD: [],
                societeACD: [],
                

        }),
        created() {
            
            //this.verifyApplicationDataSecurity()
            this.getMesDemandeDeCotation()
            this.getMesDemandeACD()
            
        },
        methods: {
            splitedList(str){
                
                if(str !== '' && str != null){
                    return str.split(',')
                }else{
                    return null
                }
            },
            verifyApplicationDataSecurity() {
                let self = this
                var formData = new FormData()
                formData.append('type', localStorage.getItem('typeUser'))
                formData.append('vueAfficher', "pro")
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'verifyApplicationDataSecurity.php',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            },
            getMesDemandeDeCotation() {
                let self = this
                
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getMesDemande/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        self.mesDemandeDeCotation = res.data.demandeCotation
                        self.societeDemandeCota = res.data.societes

                    }else if(res.data.result == false){
                        self.mesDemandeDeCotation = []
                        self.societeDemandeCota = []
                    }else if(res.data.result == 'permission'){
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
            },
            getMesDemandeACD() {
                let self = this
                
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getMesDemandeACD/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        self.mesDemandeACD = res.data.demandeACD
                        self.societeACD = res.data.societes

                    }else if(res.data.result == false){
                        self.mesDemandeACD = []
                        self.societeACD = []
                    }else if(res.data.result == 'permission'){
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
            },
        
        }
    }
</script>