<template>
    <div>
        
        <v-row>
          <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
                <loadingComponent @closeDialog="dialog = $event" :messageDialogeProps="messageDialogeError" :dialogProps="dialog" :loadingProps="loading" :errorProps="error" />
          </v-col>
        </v-row>
        <v-row v-if="vraiSiret==true">
            <v-col cols="12" sm="12" md="12" lg="12" xl='12'>
                <v-subheader class="ma-0 pa-0">Score d'entreprise:</v-subheader>
                <v-divider class="ma-0 pa-0"></v-divider>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl='4'>
                <div>
                    <v-card-title class="justify-start">
                        <v-avatar class="mr-2" size="30" rounded>
                        <img
                            alt="credit safe score"
                            src="../../assets/images/logos/logo-credit-safe.png"
                        />
                        </v-avatar>
                        creditsafe :
                        <span class="ml-2 font-weight-black">
                            {{isNaN(this.scoreCreditSafe) ? "info manquante": this.scoreCreditSafe+"/100"}}
                        </span>
                    </v-card-title>
                </div>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl='4'>
                <div>
                    <v-card-title class="justify-start">
                        <v-avatar class="mr-2" height="30" width="61" rounded>
                        <img
                            alt="credit safe score"
                            src="../../assets/logoElliPro.png"
                        />
                        </v-avatar>
                        Elli Pro :
                        <span class="ml-2 font-weight-black">
                        {{isNaN(this.scoreEllipro) ? "info manquante": this.scoreEllipro+"/10"}}
                        </span>
                    </v-card-title>
                </div>
            </v-col>
        </v-row>
        <!-- form de siret -->
        <v-form ref="formeSiret">
            <v-subheader>Recherche par SIRET ou Raison sociale :</v-subheader>
            <v-divider class="mb-12"></v-divider>
            <v-row>
                <v-col cols="12" sm="11" md="6" lg="6" xl="6" >
                    <v-combobox
                        :search-input.sync="valueRechercheSiret"
                        @input="getPropositionSiretOrName"
                        v-model="valueRechercheSiret"
                        outlined
                        :rules="[rules.required(`un siret ou une raison sociale valide`)]"
                        :items="listSociete"
                        :return-object="true"
                        label="Siret ou Raison sociale"
                    ></v-combobox>
                </v-col>
                <v-col cols="12" sm="8" md="6" lg="6" xl="6" class="mt-1">
                    <v-row >
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex justify-content-center">
                            <v-btn
                                depressed
                                elevation="1"
                                large
                                @click='getListeCompteurs'
                                :color="$colors[0]"
                                width="75%"
                                class="responsive-margin text-white"
                                >
                                Rechercher
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        
        
        <!--form details compteur -->
        <v-form ref="formGeneral" lazy-validation v-if="vraiSiret==true">
                    <v-row>
                <v-col cols="12" sm="11" md="6" lg="6" xl="6" >
                   <v-select
                        @change="getCompteurData"
                        required
                        outlined
                        v-model="donneDeCotation.numCompteur"
                        :items="listCompteur"
                        label="Numéro de compteur"
                        :rules="[rules.required('un numéro de compteur valide')]"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="8" md="6" lg="6" xl="6" class="mt-1">
                    <v-row >
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex justify-content-center">
                            <v-btn
                                v-if="enedisRecherche"
                                @click="openModalFornisseurActuelle = !openModalFornisseurActuelle"
                                elevation="1"
                                depressed
                                large
                                :color="$colors[0]"
                                class="responsive-margin text-white"                                
                                width="75%"
                                >
                                <v-icon left>{{ openModalFornisseurActuelle ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                                    Fournisseur
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row  v-if="enedisRecherche==true" class="ma-0 pa-0">
                <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0" v-if='errorEnedis == true'>
            
                    <v-alert dense text type="warning">
                    Attention Enedis n'a pas remonté d'information pour ce compteur
                    </v-alert>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                    <v-subheader class="ma-0">Informations de Compteur :</v-subheader>
                    <v-divider class="ma-0 pa-0"></v-divider>
                </v-col>
                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-select
                        required
                        outlined
                        v-model="donneDeCotation.optionTarifaire"
                        :items="optionsTarifaires"
                        label="Option Tarifaire"
                        :disabled="resultatEnedisTechnique && donneDeCotation.optionTarifaire!=null"
                        :rules="[required('l\'Option Tatifaire ')]"
                        @change="changeOptionTarifaire"
                    ></v-select>
                </v-col>
                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field
                        required
                        outlined
                        v-model="donneDeCotation.puissanceSouscrite"
                        label="Puissance souscrite"
                        suffix="kVA"
                        :disabled="resultatEnedisTechnique && donneDeCotation.puissanceSouscrite!=null"
                        :rules="[rules.required('la Puisance souscrite')]"
                    ></v-text-field>
                </v-col>
                <!--<v-col cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field
                        required
                        outlined
                        v-model="donneDeCotation.sommeConsomation"
                        :value="sommeConsommation(donneDeCotation)"
                        label="Consommation Globale"
                        :disabled="resultatEnedisTechnique && resultatEnedisMesure"
                        :rules="[required('Consommation Globale')]"
                    ></v-text-field>
                </v-col>-->
            </v-row>
              
            <v-row  v-if="enedisRecherche==true" class="ma-0 pa-0">
                <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                    <v-subheader class="ma-0">Consommations :</v-subheader>
                    <v-divider class="ma-0 pa-0"></v-divider>
                </v-col>
                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field
                        v-if="parametreCompteur == 'C5BASE'"
                        type="number"
                        step="0.001"
                        required
                        outlined
                        v-model="donneDeCotation.CABASE"
                        label="(CABASE)"
                        :rules="[rules.required('une consommation (CABASE) valide')]"
                    ></v-text-field>
                    <v-text-field
                        v-if="parametreCompteur == 'C3' || parametreCompteur == 'C4'"
                        type="number"
                        step="0.001"
                        required
                        outlined
                        v-model="donneDeCotation.CAHPH"
                        label="(HPH)"
                        :rules="[rules.required('une consommation CONSOMMATION (HPH) valide')]"
                    ></v-text-field>
                    <v-text-field
                        v-if="parametreCompteur == 'C5HP'"
                        type="number"
                        step="0.001"
                        required
                        outlined
                        v-model="donneDeCotation.CAHP"
                        label="(HP)"
                        :rules="[rules.required('une consommation (HP) valide')]"
                    ></v-text-field>
                </v-col>

                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field
                        v-if="parametreCompteur == 'C3' || parametreCompteur == 'C4'"
                        type="number"
                        step="0.001"
                        required
                        outlined
                        v-model="donneDeCotation.CAHCH"
                        label="(HCH)"
                        :rules="[rules.required('une consommation (HCH) valide')]"
                    ></v-text-field>
                    <v-text-field
                        v-if="parametreCompteur == 'C5HP'"
                        type="number"
                        step="0.001"
                        required
                        outlined
                        v-model="donneDeCotation.CAHC"
                        label="(HC)"
                        :rules="[rules.required('une consommation (HC) valide')]"
                    ></v-text-field>
                </v-col>

                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field
                        v-if="parametreCompteur == 'C3' || parametreCompteur == 'C4'"
                        type="number"
                        step="0.001"
                        required
                        outlined
                        v-model="donneDeCotation.CAHPE"
                        label="(HPE)"
                        :rules="[rules.required('une consommation (HPE) valide')]"
                    ></v-text-field>
                </v-col>
                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field
                        v-if="parametreCompteur == 'C3' || parametreCompteur == 'C4'"
                        type="number"
                        step="0.001"
                        required
                        outlined
                        v-model="donneDeCotation.CAHCE"
                        label="(HCE)"
                        :rules="[rules.required('une consommation (HCE) valide')]"
                    ></v-text-field>
                </v-col>
                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field
                        v-if="parametreCompteur == 'C3'"
                        type="number"
                        step="0.001"
                        
                        
                        required
                        outlined
                        v-model="donneDeCotation.CAP"
                        label="(P)"
                        :rules="[rules.required('conso (P)')]"
                    ></v-text-field>
                </v-col>
            </v-row>
        
            <!-- marge et dates -->
            <v-row  v-if="enedisRecherche==true" class="ma-0 pa-0">
                <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                    <v-subheader class="ma-0 pa-0">Détails de l'offre :</v-subheader>
                    <v-divider class="ma-0 pa-0"></v-divider>
                </v-col>
                <!-- marge -->
                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field
                        type="number"
                        step="0.001"
                        max="25"
                        min="2"
                        required
                        outlined
                        v-model="donneDeCotation.marge"
                        label="Marge"
                        :rules="[rules.required('une marge'), rules.margeMaxMin]"
                    ></v-text-field>
                </v-col>
                <!-- La date de début -->
                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field 
                        type="date" 
                        outlined
                        :rules="[rules.required('une date de début du contrat valide'), 
                                isValidDate('DD/MM/YYYY', minDebutContrat, maxDateDebutContrat),isRangeDateValide('Début')]"
                        label="Début de Contrat"
                        v-model="donneDeCotation.dateDebut"
                        :min="minDebutContrat"
                        :max="maxDateDebutContrat"/>
                </v-col>

                <!-- La date de fin -->
                <v-col class="pl-0" cols="11" sm="4" md="3" lg="3" xl='3'>
                    <v-text-field 
                        type="date" 
                        outlined
                        :rules="[rules.required('une date de fin du contrat valide'), 
                                isValidDate('DD/MM/YYYY', minDebutFinContrat, maxDateFinContrat), isRangeDateValide('Fin')]"
                        label="Fin de Contrat"
                        v-model="donneDeCotation.dateFin"
                        :min="minDebutFinContrat"
                        :max="maxDateFinContrat"/>
                </v-col>
            </v-row>
        </v-form>

        <!-- pop up de fornisseur actuelle -->
        <v-dialog
            v-model="openModalFornisseurActuelle"
            persistent
            max-width="80%"
            >
            <v-card>
                <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 pl-0">Informations Fournisseur</span>
                </v-toolbar>
                <v-form ref="formActuelle" class="pa-4">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="11" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <v-subheader class="ma-0">Type de fournisseur :</v-subheader>
                            <v-divider class="ma-0 pa-0"></v-divider>
                            <v-row>
                                <v-col cols='11' sm="5" md="5" lg="5" xl='5'
                                        v-if="fournisseurActuelle == false">
                                    <v-radio-group
                                        v-model="autreFournisseur.typeFournisseur"
                                        row
                                        >
                                        <v-radio
                                            :color="$colors[0]"
                                            label="Fournisseur actuel"
                                            value="Actuelle"
                                            class="v-radio"
                                        ></v-radio>
                                        <v-radio
                                            :color="$colors[0]"
                                            label="Nouveau fournisseur"
                                            value="Fournisseur"
                                            class="v-radio"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="11" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <v-subheader class="ma-0">Informations du fournisseur :</v-subheader>
                                <v-col class="pl-0" cols='11' sm="5" md="5" lg="5" xl='5'>
                                    <v-radio-group
                                        v-model="autreFournisseur.turpeInclus"
                                        row
                                        >
                                        <v-radio
                                            :color="$colors[0]"
                                            label="Turpe Inclus"
                                            value="oui"
                                            class="v-radio"
                                        ></v-radio>
                                        <v-radio
                                            :color="$colors[0]"
                                            label="Turpe non Inclus"
                                            value="non"
                                            class="v-radio"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2'>
                            <v-select
                                required
                                outlined
                                :rules="[rules.required('un fournisseur valide')]"
                                v-model="autreFournisseur.fournisseur"
                                :items="optionsFournisseurs"
                                label="Nom fournisseur"
                            ></v-select>
                        </v-col>
                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2'>
                            <v-select
                                required
                                outlined
                                :rules="[required(`un type d'offre valide`),rules.Length]"
                                v-model="autreFournisseur.typeOffre"
                                :items="['Arenh', 'Fixe']"
                                label="type d'offre"
                            ></v-select>
                        </v-col>
                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2'>
                            <v-text-field
                                type="number"
                                step="1"
                                required
                                outlined
                                suffix="mois"
                                v-model="autreFournisseur.DUREE"
                                label="Durée de contrat"
                                :rules="[required(`une durée de contrat valide`),rules.isPositiveNumb]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <!-- Prix -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <v-subheader class="ma-0">Prix du MWh :</v-subheader>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                v-if="parametreCompteur == 'C5BASE' || donneDeCotation.optionTarifaire == 'BTINFCU4'"
                                type="number"
                                step="0.001"
                                
                                
                                required
                                outlined
                                v-model="autreFournisseur.prixBASE"
                                label="(BASE)"
                                :rules="[rules.required('un PRIX (BASE) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.prixHPH"
                                label="(HPH)"
                                :rules="[rules.required('un PRIX (HPH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire == 'BTINFMU4')"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.prixHP"
                                label="(HP)"
                                :rules="[rules.required('un PRIX (HP) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3' || parametreCompteur == 'C5HP'
                                 || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire != 'BTINFCU4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.prixHCH"
                                label="(HCH)"
                                :rules="[rules.required('un PRIX (HCH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire == 'BTINFMU4')"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.prixHC"
                                label="(HC)"
                                :rules="[rules.required('un PRIX (HC) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3' || parametreCompteur == 'C4'
                                && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.prixHPE"
                                label="(HPE)"
                                :rules="[rules.required('un PRIX (HPE) valide')]"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') 
                                && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.prixHCE"
                                label="(HCE)"
                                :rules="[rules.required('un PRIX (HCE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col  class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3' && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'">
                            <v-text-field
                                v-if="parametreCompteur == 'C3'"
                                type="number"
                                step="0.001"
                                
                                
                                required
                                outlined
                                v-model="autreFournisseur.prixPOINTE"
                                suffix="€"
                                label="(P)"
                                :rules="[rules.required('un PRIX (P) valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                outlined
                                suffix="€"
                                v-model="autreFournisseur.CEE"
                                label="CEE"
                                :rules="[rules.required('un CEE valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                outlined
                                suffix="€"
                                v-model="autreFournisseur.ABO"
                                label="Abonnement mensuel"
                                :rules="[rules.required('une valeur valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <!-- coef capa -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <v-subheader class="ma-0">Informations sur la capacité :</v-subheader>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        
                        <v-col cols='12' sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <v-radio-group v-model="autreFournisseur.typeCapa" row class="ma-0 pa-0">
                                <v-radio
                                    :color="$colors[0]"
                                    label="Coût capacité €/MWh"
                                    value="CAPA"
                                ></v-radio>
                                <v-radio
                                    :color="$colors[0]"
                                    label="Coefficient capacité €/MWh"
                                    value="COEF"
                                ></v-radio>
                                <v-radio
                                    :color="$colors[0]"
                                    label="CAPA Inclus"
                                    value="CAPAINCLUS"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="autreFournisseur.typeCapa != 'CAPAINCLUS'">
                            <v-text-field
                                v-if="parametreCompteur == 'C5BASE' || donneDeCotation.optionTarifaire == 'BTINFCU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.capaBASE"
                                label="(BASE)"
                                :rules="[rules.required('un CAPA (BASE) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.capaHPH"
                                label="(HPH)"
                                :rules="[rules.required('un CAPA (HPH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire == 'BTINFMU4')"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.capaHP"
                                label="(HP)"
                                :rules="[rules.required('un CAPA (HP) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="autreFournisseur.typeCapa != 'CAPAINCLUS' && (parametreCompteur == 'C3' || parametreCompteur == 'C5HP'
                                 || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire != 'BTINFCU4'))">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.capaHCH"
                                label="(HCH)"
                                :rules="[rules.required('un CAPA (HCH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire == 'BTINFMU4')"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.capaHC"
                                label="(HC)"
                                :rules="[rules.required('un CAPA (HC) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="autreFournisseur.typeCapa != 'CAPAINCLUS' && (parametreCompteur == 'C3' || parametreCompteur == 'C4'
                                && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.capaHPE"
                                label="(HPE)"
                                :rules="[rules.required('un CAPA (HPE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="autreFournisseur.typeCapa != 'CAPAINCLUS' && ((parametreCompteur == 'C3' || parametreCompteur == 'C4')
                                && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.capaHCE"
                                label="(HCE)"
                                :rules="[rules.required('un CAPA (HCE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl='2' v-if="autreFournisseur.typeCapa != 'CAPAINCLUS' && parametreCompteur == 'C3'">
                            <v-text-field
                                v-if="parametreCompteur == 'C3'"
                                type="number"
                                step="0.001"
                                
                                suffix="€"
                                required
                                outlined
                                v-model="autreFournisseur.capaPOINTE"
                                label="(P)"
                                :rules="[rules.required('un CAPA (P) valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions>
                    <v-row  class="ma-2" justify="space-between">
                        <div>
                            <v-btn
                                v-if="editFournisseur==false"
                                @click="resetFournisseurActuelle"
                                depressed
                                color="error"
                                style="textTransform: none"
                                class='marginBtn '
                                text
                                >
                                Supprimer et Fermer
                            </v-btn>
                        </div>
                        <div> 
                            <v-btn
                                text
                                color="error"
                                class='marginBtn ml-5'
                                @click="openModalFornisseurActuelle = false;$refs.formActuelle.resetValidation()"
                            >
                                Fermer
                            </v-btn>
                            <v-btn
                                :color="$colors[0]"
                                class='marginBtn ml-5 white--text font-weight-bold'
                                v-if="editFournisseur==true"
                                @click="AjouterFournisseur(indexFournisseurEdited)"
                            >
                                Modifier
                            </v-btn>
                            <v-btn
                                :color="$colors[0]"
                                class='marginBtn ml-5 text-white'
                                v-if="editFournisseur==false"
                                @click="AjouterFournisseur(-1)"
                            >
                                Ajouter
                            </v-btn>
                        </div>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- les deux bottion pricipale -->
        <v-row class="mb-12" v-if='enedisRecherche'>
            <!-- affichage des donner du fornisseur actuelle -->
            
            <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0" v-if='tousLesAutreFournisseur.length>0'>
                <v-subheader class="ma-0 ml-4">Informations des autres Fournisseurs :</v-subheader>
                <v-divider class="ma-4 pa-0"></v-divider>
            </v-col>
            <v-expansion-panels class="ma-3" v-if='tousLesAutreFournisseur.length>0'>
                <v-expansion-panel v-for="(item, index) in tousLesAutreFournisseur" :key="index">
                    <v-expansion-panel-header hide-actions class="responsiveClass">
                        <v-btn 
                            max-width="50"
                            color="error"
                            icon
                            plain
                            @click.stop="supprimerFournisseur(index)">
                            <v-icon center>mdi-delete
                            </v-icon>
                        </v-btn>
                        <v-btn 
                            max-width="50"
                            class="reponsiveBtn"
                            icon
                            plain
                            @click.stop="editFournisseurMethod(index)">
                            <v-icon :color='$colors[0]'>mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-subheader class="ma-0" v-if="item.typeFournisseur=='Actuelle'">Informations du fournisseur actuel : <b class="pl-2">{{item.fournisseur}}</b></v-subheader>
                        <v-subheader class="ma-0" v-else>Informations du nouveau fournisseur : <b class="pl-2">{{ item.fournisseur}}</b></v-subheader>
                        
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="background-color:rgb(245,245,245)" class="pa-4">
                        <v-row justify="space-between">
                                    <v-col class="pa-0">
                                        <h5 class="mb-8">Informations Générales</h5>
                                        <p class="mx-4"><span class="grey--text mb-0">Fournisseur : </span><span style="color:#103A5E;font-weight:bold">{{item.fournisseur}}</span></p>
                                        <p class="mx-4"><span class="grey--text mb-0">TURPE INCLUS : </span><span style="color:#103A5E;font-weight:bold">{{item.turpeInclus}}</span></p>
                                        <p class="mx-4"><span class="grey--text mb-0">Durée de contrat : </span><span style="color:#103A5E;font-weight:bold">{{item.DUREE}} mois</span></p>
                                    </v-col>
                                    <v-col class="pa-0">
                                        <h5 class="mb-8">Coût du MWh</h5>
                                            <v-row  class="pa-0" v-if="parametreCompteur == 'C5BASE' || donneDeCotation.optionTarifaire == 'BTINFCU4'">
                                                <p class="mx-2"><span class="grey--text mb-2">BASE : </span><span style="color:#103A5E;font-weight:bold">{{item.prixBASE}}€</span></p>
                                            </v-row>
                                            <v-row  class="pa-0" v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire == 'BTINFMU4')">
                                                <p class="mx-4"><span class="grey--text mb-2">HP : </span><span style="color:#103A5E;font-weight:bold">{{item.prixHP}}€</span></p>
                                                <p class="mx-4"><span class="grey--text mb-2">HC : </span><span style="color:#103A5E;font-weight:bold">{{item.prixHC}}€</span></p>
                                            </v-row>

                                            <v-row  class="pa-0" v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') &&
                                                     donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'">
                                                <p class="mx-4"><span class="grey--text mb-2">HCH : </span><span style="color:#103A5E;font-weight:bold">{{item.prixHCH}}€</span></p>
                                                <p class="mx-4"><span class="grey--text mb-2">HPH : </span><span style="color:#103A5E;font-weight:bold">{{item.prixHPH}}€</span></p>
                                            </v-row>

                                            <v-row  class="pa-0"  v-if="parametreCompteur == 'C3'">
                                                <p class="mx-4"><span class="grey--text mb-2">POINTE : </span><span style="color:#103A5E;font-weight:bold">{{item.prixPOINTE}}€</span></p>
                                            </v-row>


                                        <v-row  class="pa-0" v-if="parametreCompteur == 'C3' || parametreCompteur == 'C4'&&
                                                     donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'">
                                                <p class="mx-4"><span class="grey--text mb-2">HCE : </span><span style="color:#103A5E;font-weight:bold">{{item.prixHCE}}€</span></p>
                                                <p class="mx-4"><span class="grey--text mb-2">HPE : </span><span style="color:#103A5E;font-weight:bold">{{item.prixHPE}}€</span></p>
                                        </v-row>

                                        <v-row  class="pa-0">
                                                <p class="mx-4"><span class="grey--text mb-2">CEE : </span><span style="color:#103A5E;font-weight:bold">{{item.CEE}}€</span></p>
                                                <p class="mx-4"><span class="grey--text mb-2">Abonnement mensuel : </span><span style="color:#103A5E;font-weight:bold">{{item.ABO}}€</span></p>
                                        </v-row>
                                    </v-col>
                                    
                                    <v-col class="pa-0">
                                        <h5 class="mb-8">Informations sur la Capacité</h5>
                                        <v-row v-if="item.typeCapa!='CAPAINCLUS'">
                                            <div class="mb-0"   v-if="parametreCompteur == 'C5BASE' || donneDeCotation.optionTarifaire == 'BTINFCU4'">
                                                <p class="mx-4"><span class="grey--text mb-2">BASE : </span><span style="color:#103A5E;font-weight:bold">{{item.capaBASE}}€</span></p>
                                            </div>
                                            <div class="mb-0"   v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire == 'BTINFMU4')">
                                                <p class="mx-4"><span class="grey--text mb-2">HP : </span><span style="color:#103A5E;font-weight:bold">{{item.capaHP}}€</span></p>
                                            </div>
                                            <div class="mb-0"   v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && donneDeCotation.optionTarifaire == 'BTINFMU4')">
                                                <p class="mx-4"><span class="grey--text mb-2">HC : </span><span style="color:#103A5E;font-weight:bold">{{item.capaHC}}€</span></p>
                                            </div>
                                            <div class="mb-0"   v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') &&
                                                     donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'">
                                                <p class="mx-4"><span class="grey--text mb-2">HPH : </span><span style="color:#103A5E;font-weight:bold">{{item.capaHPH}}€</span></p>
                                            </div>
                                            <div class="mb-0"   v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') &&
                                                     donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'">
                                                <p class="mx-4"><span class="grey--text mb-2">HCH : </span><span style="color:#103A5E;font-weight:bold">{{item.capaHCH}}€</span></p>
                                            </div>
                                        </v-row>
                                        <v-row   v-if="item.typeCapa!='CAPAINCLUS' && (parametreCompteur == 'C3' || parametreCompteur == 'C4') &&
                                                     donneDeCotation.optionTarifaire != 'BTINFCU4' && donneDeCotation.optionTarifaire != 'BTINFMU4'">
                                            <div>
                                                <p class="mx-4"><span class="grey--text mb-2">HPE : </span><span style="color:#103A5E;font-weight:bold">{{item.capaHPE}}€</span></p>
                                            </div>
                                            <div>
                                                <p class="mx-4"><span class="grey--text mb-2">HCE : </span><span style="color:#103A5E;font-weight:bold">{{item.capaHCE}}€</span></p>
                                            </div>
                                        
                                        </v-row>
                                        <v-row  v-if="item.typeCapa!='CAPAINCLUS' && parametreCompteur == 'C3'">
                                            <div >
                                                <p class="mx-4"><span class="grey--text mb-2">POINTE : </span>{{item.capaPOINTE}}€</p>
                                            </div>
                                        </v-row>
                                        <p class="mt-4">
                                            <span class="grey--text">Type des valeurs de CAPA : </span>
                                            <span style="color:#103A5E;font-weight:bold" v-if="item.typeCapa=='CAPA'">Coût</span>
                                            <span style="color:#103A5E;font-weight:bold" v-if="item.typeCapa=='COEF'">Coefficient</span>
                                            <span style="color:#103A5E;font-weight:bold" v-if="item.typeCapa=='CAPAINCLUS'">CAPA Inclus</span>
                                        </p>
                                    </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            
            <div class="d-flex flex-row mt-4 ml-2">
                <v-btn
                    @click="resetForm"
                    depressed
                    large
                    class="mx-2"
                    elevation="1"
                    width="75%"
                    >
                    Réinitialiser
                </v-btn>
                <v-btn
                    depressed
                    elevation="1"
                    large
                    @click="calculer"
                    :color="$colors[0]"
                    class="text-white mx-2"
                    width="70%">
                    Calculer
                </v-btn>
            </div>
        </v-row>

    </div>
</template>
<script>
    import moment from 'moment'
    import axios from 'axios'

    import dialogeModal from '../ui/dialogeModal.vue'
    import loadingComponent from "../ui/loading-component.vue"
    export default {
        name: 'FourmulaireDeCotation',
        components:{dialogeModal:dialogeModal,loadingComponent},
        
        data() {
            return {
                editFournisseur: false,
                indexFournisseurEdited: -1,
                errorDateDeFin: false,
                nbrMonth: 0,
                dialog: false,
                loading: false,
                error: false,
                messageDialogeSucces:'',
                messageDialogeError: '',
                errorEnedis: false,
                openModalFornisseurActuelle: false,
                vraiSiret: false,
                resultatEnedisMesure: false,
                resultatEnedisTechnique: false,
                enedisRecherche: false,
                siret:'',
                valueRechercheSiret: '',
                listSociete: [{data:'', disable: true}],
                listCompteur: [],
                parametreCompteur: '',
                scoreEllipro:'',
                scoreCreditSafe:'',
                listProposition: [],
                optionsTarifaires: [
                    'BTINFCU4',
                    'BTINFCUST',
                    'BTINFMU4',
                    'BTINFMUDT',
                    'BTINFLU',
                    'BTSUPCU4',
                    'BTSUPLU4',
                    'HTACU5',
                    'HTACUPM5',
                    'HTALU5',
                    'HTALUPM5',
                ],
                donneDeCotation:{
                    resultatEnedis: null,
                    sommeConsomation: '',
                    numCompteur: '',
                    optionTarifaire: '',
                    puissanceSouscrite: '',
                    dateDebut: '',
                    dateFin: '',
                    marge: '',
                    CAHPH: '',
                    CAHCH: '',
                    CAHPE: '',
                    CAHCE: '',
                    CAHP: '',
                    CAHC: '',
                    CABASE: '', 
                    CAP: '',

                    kvaHph: '',
                    kvaHch: '',
                    kvaHpe: '',
                    kvaHce: '',
                    kvaHp: '',
                    kvaHc: '',
                    kvaBase: '', 
                    kvaPte: '',
                },
                tousLesAutreFournisseur: [

                ],
                autreFournisseur:{
                    typeFournisseur: 'Fournisseur',
                    turpeInclus: 'non',
                    fournisseur: null,
                    typeOffre: "",
                    prixHPH: null,
                    prixHCH: null,
                    prixHPE: null,
                    prixHCE: null,
                    prixHP: null,
                    prixHC: null,
                    prixBASE: null, 
                    prixPOINTE: null, 
                    typeCapa: 'CAPA',
                    capaHPH: null,
                    capaHCH: null,
                    capaHPE: null,
                    capaHCE: null,
                    capaHP: null,
                    capaHC: null,
                    capaBASE: null, 
                    capaPOINTE: null, 
                    CEE: null,
                    ABO: null,
                    DUREE: null,            
                },
                fournisseurActuelle: false,
                optionsFournisseurs: [
                    'Alpiq',
                    'LLUM',
                    'Alterna',
                    'Antargaz',
                    'Axpo',
                    'BARRY',
                    'BULB',
                    'BUTAGAZ',
                    'CDISCOUNT',
                    'Dyneff',
                    'Edenkia',
                    'EDF',
                    'EDS',
                    'EDSB',
                    'Ekwateur',
                    'Elecocite',
                    'Electricite_de_provence',
                    'Electricite_de_savoie',
                    'Electricite_de_Strasbourg',
                    'Enalp',
                    'Endesa',
                    'Enercoop',
                    'Energem',
                    'ENERGIE_DICI',
                    'Engie',
                    'ENI',
                    'Enovos',
                    'Gaz_de_Bordeaux',
                    'GazelEnergie',
                    'Gazprom',
                    'GEG',
                    'GREEN_YELLOW',
                    'HAPPE',
                    'IBERDROLA',
                    'ILEK',
                    'LECLERCQ',
                    'LUCIA',
                    'MEGA',
                    'mint-energie',
                    'OHM',
                    'OVO',
                    'Picoty',
                    'PLANETE_OUI',
                    'PLUM',
                    'Primeo',
                    'Proxelia',
                    'Redeéo',
                    'Selia',
                    'SOWEE',
                    'Synelva',
                    'TDE',
                    'Urban_Solar',
                    'Vattenfall',
                    'Wekiwi',
                    'yeli-xs',
                ],
                //debut rules

                 rules: {
                    isPositiveNumb:v=>(/^[0-9]\d*$/).test(v) || "La valeur doit etre un entier strictement supérieur à 0",        
                    required(fieldName){ return value => !!value || `Veuillez saisir ${fieldName}`},
                    siretLength: v => v?.length == 14 || 'Le siret doit comporter 14 chiffres',
                    isMonthCorrect: v => (v >= 1 && v <= 12) || "Le mois doit etre compris entre 1 et 12 ",
                    isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                    compteurElecLength: v => v?.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                    isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                    phoneLength: v => v?.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
                    phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                    margeMaxMin: v => (v<=25 && v>=2) || 'La marge doit être comprise entre 2 et 25',
                    Length: v=> (v?.length>0) || "Veuillez saisir un type d'offre",
                    isBetweenRangeNumber : v => (v>=2 && v<=25) || "La valeur saisie doit etre comprise entre 2 et 25",
                    isRangeDateValide(date){return date == "Début" ? "oui" :"non"},
                    
                    emailRules:v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
                },


                required(fieldName) {
                    return (value) => ((!!value || value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                minDebutContrat: new Date().toISOString().substr(0, 10),
                minDebutFinContrat:new Date().toISOString().substr(0, 10),
                maxDateFinContrat: new Date(new Date().getFullYear() + 5+'-'+12+'-'+31).toISOString().substr(0, 10),
                maxDateDebutContrat: new Date(new Date().getFullYear() + 5+'-'+ 11+'-30').toISOString().substr(0, 10),
            }
        },
        created(){
            try {
                this.valueRechercheSiret = this.$route.query.siret
                this.siret = this.$route.query.siret
                this.listSociete= [this.siret]
            } catch (error) {
                console.log(error)
            }
        },
        methods: {
            renitialiserLaListeDeResultat(){
                this.$emit('sendDataToParent',{
                    donneDeCotation: [],
                    optionTarifaire: '',
                    parametreCompteur: '',
                    Propositions: [],
                    SocieteData: '',
                    enedisData: '',
                    numCompteur: '',
                    turpeData: '',
                    taxeData: '',
                    typeCompteur: '',
                    fournisseurActuelle: '',
                    selected: [],
                    listCompteur:[]}
                )
            },
            resetForm(){
                this.renitialiserLaListeDeResultat()
                this.fournisseurActuelle= false
                //donner generale
                this.openModalFornisseurActuelle = false
                this.vraiSiret = false
                this.resultatEnedisMesure = false
                this.resultatEnedisTechnique = false
                this.enedisRecherche = false
                this.siret = ''
                this.listSociete = []
                this.listCompteur = []
                this.parametreCompteur = ''
                this.scoreEllipro = ''
                this.scoreCreditSafe = ''
                //donner de cotation
                this.donneDeCotation.resultatEnedis = null
                this.donneDeCotation.sommeConsomation = ''
                this.donneDeCotation.numCompteur = ''
                this.donneDeCotation.optionTarifaire = ''
                this.donneDeCotation.puissanceSouscrite = ''
                this.donneDeCotation.dateDebut = ''
                this.donneDeCotation.dateFin = ''
                this.donneDeCotation.marge = ''
                this.donneDeCotation.CAHPH = ''
                this.donneDeCotation.CAHCH = ''
                this.donneDeCotation.CAHPE = ''
                this.donneDeCotation.CAHCE = ''
                this.donneDeCotation.CAHP = ''
                this.donneDeCotation.CAHC = ''
                this.donneDeCotation.CABASE = ''
                this.donneDeCotation.CAP = ''
                this.donneDeCotation.kvaHph = ''
                this.donneDeCotation.kvaHch = ''
                this.donneDeCotation.kvaHpe = ''
                this.donneDeCotation.kvaHce = ''
                this.donneDeCotation.kvaHp = ''
                this.donneDeCotation.kvaHc = ''
                this.donneDeCotation.kvaBase = ''
                this.donneDeCotation.kvaPte = ''
                //fornisseur actuelle
                
                this.openModalFornisseurActuelle = false
                this.autreFournisseur.typeFournisseur = 'Fournisseur'
                this.autreFournisseur.turpeInclus = 'non'
                this.autreFournisseur.fournisseur = null
                this.autreFournisseur.typeOffre = ""
                this.autreFournisseur.prixHPH = null
                this.autreFournisseur.prixHCH = null
                this.autreFournisseur.prixHPE = null
                this.autreFournisseur.prixHCE = null
                this.autreFournisseur.prixHP = null
                this.autreFournisseur.prixHC = null
                this.autreFournisseur.prixBASE = null 
                this.autreFournisseur.prixPOINTE = null 
                this.autreFournisseur.typeCapa = 'CAPA'
                this.autreFournisseur.capaHPH = null
                this.autreFournisseur.capaHCH = null
                this.autreFournisseur.capaHPE = null
                this.autreFournisseur.capaHCE = null
                this.autreFournisseur.capaHP = null
                this.autreFournisseur.capaHC = null
                this.autreFournisseur.capaBASE = null 
                this.autreFournisseur.capaPOINTE = null 
                this.autreFournisseur.CEE = null
                this.autreFournisseur.ABO = null
                this.autreFournisseur.DUREE = null
            },
            resetFournisseurActuelle(){
                this.renitialiserLaListeDeResultat();
                this.$refs.formActuelle.reset();
                this.$refs.formActuelle.resetValidation();
                this.openModalFornisseurActuelle = false
            },
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
            isRangeDateValide(date){
               if(this.donneDeCotation.dateFin != "" && this.donneDeCotation.dateDebut!=""&& this.donneDeCotation.dateFin<this.donneDeCotation.dateDebut){
                switch(date){
                    case "Début":
                        return "la date de début doit etre inférieure à la date de fin";
                        break;
                    case "Fin":
                        return "la date de fin doit etre supérieur à la date de début";
                        break;

                    default:
                        break;
                }
               }
               

            },
            isValidDate(formatDate, minDate, maxDate) {
                return (date) => {
                    var tmpdate = this.dateFormatMinus(date)
                    if (!date) {
                        return 'Veuillez saisir une date.'
                    }
                    if (moment(tmpdate).isBefore(minDate)) {
                        return `La date doit être supérieure  au ${this.dateFormatSlach(minDate)}.`
                    }
                    if (moment(tmpdate).isAfter(maxDate)) {
                        return `La date limite de fin de contrat est: ${this.dateFormatSlach(maxDate)}.`
                    }
                    return true
                }
            },
            changeOptionTarifaire(){
                if(this.resultatEnedisMesure == false && this.resultatEnedisTechnique==false){
                    if(this.donneDeCotation.optionTarifaire == 'BTINFMUDT'){
                        this.parametreCompteur = 'C5HP'
                    }
                    if(this.donneDeCotation.optionTarifaire == 'BTINFLU' || this.donneDeCotation.optionTarifaire == 'BTINFCUST'){
                        this.parametreCompteur = 'C5BASE'
                    }
                    if(['BTINFCU4','BTINFMU4','BTSUPCU4','BTSUPLU4',].includes(this.donneDeCotation.optionTarifaire)){
                        this.parametreCompteur = 'C4'
                    }
                    if(['HTACU5','HTACUPM5','HTALU5','HTALUPM5',].includes(this.donneDeCotation.optionTarifaire)){
                        this.parametreCompteur = 'C3'
                    }
                }
                this.renitialiserLaListeDeResultat()

            },
            getListeCompteurs() {
                this.$refs.formeSiret.validate()
                if(this.$refs.formeSiret.validate()){
                    
                    this.dialog = true
                    this.loading = true
                    this.error = false
                    this.messageDialogeError = ''
                    this.messageDialogeSucces = ''
                    this.siret = this.valueRechercheSiret
                    this.renitialiserLaListeDeResultat()
                    var formData = new FormData()
                    formData.append('value', this.siret)
                    formData.append('token', this.getToken('token'))
                    axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'getListCompteurElecParSiret/',
                        method: 'POST',
                        data: formData,
                    })
                    .then((res) => {
                        const responseData = res.data
                        if (responseData.result === 'permission') {
                            localStorage.setItem('vueAfficher', 'particulier')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }else if (responseData.result == true) {
                            if(responseData.compteur.length<=0){
                                this.loading = false
                                this.messageDialogeError = "Vous n'avez aucun compteur associé à ce siret/raison sociale"
                                this.error = true
                            }
                            this.vraiSiret = true
                            this.scoreEllipro = responseData.scoreEllipro
                            this.scoreCreditSafe = responseData.scoreCreditSafe
                            this.listCompteur = responseData.compteur
                            this.siret = responseData.siret
                            
                            this.dialog = false
                            this.loading = false
                        }else if(responseData.result == false){
                            this.vraiSiret = false
                            this.loading = false
                            this.messageDialogeError = "Vérifiez que votre siret/raison social soit valide et que vous disposez d'une demande de cotation déjà acceptée"
                            this.error = true
                        }
                        
                    })
                    .catch((error) => {
                        this.loading = false
                        this.messageDialogeError = "Il semble qu'une erreur soit survenue ! Veuillez réessayer plus tard "
                        this.error = true
                        console.log(error)
                    })
                }
            },
            getCompteurData() {
                
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialogeError = ''
                this.messageDialogeSucces = ''
                this.tousLesAutreFournisseur = []
                this.renitialiserLaListeDeResultat()
                this.enedisRecherche = false
                var formData = new FormData()
                formData.append('numCompteur', this.donneDeCotation.numCompteur)
                formData.append('token', this.getToken('token'))
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getToutLesDonneesParCompteurEnedis/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if (res.data.result === 'permission') {
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    this.nbrMonth = res.data.mesuresInfo[0].nbrMonth
                    if(res.data.mesuresInfo[0].result=='notValide' && res.data.donneTechniqueInfo[0].result=='notValide' ){
                        this.errorEnedis = true
                    }else{
                        this.errorEnedis = false
                        this.enedisRecherche = true
                    }
                    var data = JSON.parse(JSON.stringify(res.data))
                    this.enedisRecherche = true
                    this.donneDeCotation.resultatEnedis = data
                    if(data.mesuresInfo[0].result==='valide'){
                        this.resultatEnedisMesure = true
                        if("HP" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAHP = data.mesuresInfo[0].donne.HP.somme
                        }else{
                            this.donneDeCotation.CAHP = null
                        }
                        if("HC" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAHC = data.mesuresInfo[0].donne.HC.somme
                        }else{
                            this.donneDeCotation.CAHC = null
                        }
                        if("HPH" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAHPH = data.mesuresInfo[0].donne.HPH.somme
                        }else{
                            this.donneDeCotation.CAHPH = null
                        }
                        if("HCH" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAHCH = data.mesuresInfo[0].donne.HCH.somme
                        }else{
                            this.donneDeCotation.CAHCH = null
                        }
                        if("HPE" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAHPE = data.mesuresInfo[0].donne.HPE.somme
                        }else{
                            this.donneDeCotation.CAHPE = null
                        }
                        if("HCE" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAHCE = data.mesuresInfo[0].donne.HCE.somme
                        }else{
                            this.donneDeCotation.CAHCE = null
                        }
                        if("Pointe" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAP = data.mesuresInfo[0].donne.Pointe.somme
                        }
                        if("POINTE" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAP = data.mesuresInfo[0].donne.POINTE.somme
                        }
                        if("PTE" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CAP = data.mesuresInfo[0].donne.PTE.somme
                        }
                        if(!("Pointe" in data.mesuresInfo[0].donne) && !("POINTE" in data.mesuresInfo[0].donne) && !("PTE" in data.mesuresInfo[0].donne)){
                            this.donneDeCotation.CAP = null
                        }
                        if("BASE" in data.mesuresInfo[0].donne){
                            this.donneDeCotation.CABASE = data.mesuresInfo[0].donne.BASE.somme
                        }else{
                            this.donneDeCotation.CABASE = null
                        }
                    }else{
                        this.resultatEnedisMesure = false
                        this.donneDeCotation.CAHP = null
                        this.donneDeCotation.CAHC = null
                        this.donneDeCotation.CAHPH = null
                        this.donneDeCotation.CAHCH = null
                        this.donneDeCotation.CAHPE = null
                        this.donneDeCotation.CAHCE = null
                        this.donneDeCotation.CAP = null
                        this.donneDeCotation.CAP = null
                        this.donneDeCotation.CABASE = null
                    }
                    if(data.donneTechniqueInfo[0].result==='valide'){
                        this.parametreCompteur = data.parametreDeCompteur
                        if(data.donneTechniqueInfo[0].data.typeCompteur===''){
                            this.resultatEnedisTechnique = false
                        }else{
                            this.resultatEnedisTechnique = true
                        }
                        if(data.donneTechniqueInfo[0].data.typeCompteur != "" && data.donneTechniqueInfo[0].data.kva != "" ){
                            this.donneDeCotation.optionTarifaire = data.donneTechniqueInfo[0].data.typeCompteur
                            this.donneDeCotation.puissanceSouscrite = data.donneTechniqueInfo[0].data.kva
                        }else{
                            this.donneDeCotation.optionTarifaire = null
                            this.donneDeCotation.puissanceSouscrite = null
                        }
                        // get tout les kva
                        
                        if("BASE" in data.donneTechniqueInfo[0].data){
                            this.donneDeCotation.kvaBase = data.donneTechniqueInfo[0].data.BASE
                        }else{
                            this.donneDeCotation.kvaBase = ''
                        }
                        if("HP" in data.donneTechniqueInfo[0].data){
                            this.donneDeCotation.kvaHp = data.donneTechniqueInfo[0].data.HP
                        }else{
                            this.donneDeCotation.kvaHp = ''
                        }
                        if("HC" in data.donneTechniqueInfo[0].data){
                            this.donneDeCotation.kvaHc = data.donneTechniqueInfo[0].data.HC
                        }else{
                            this.donneDeCotation.kvaHc = ''
                        }
                        if("HCE" in data.donneTechniqueInfo[0].data){
                            this.donneDeCotation.kvaHce = data.donneTechniqueInfo[0].data.HCE
                        }else{
                            this.donneDeCotation.kvaHce = ''
                        }
                        if("HPE" in data.donneTechniqueInfo[0].data){
                            this.donneDeCotation.kvaHpe = data.donneTechniqueInfo[0].data.HPE
                        }else{
                            this.donneDeCotation.kvaHpe = ''
                        }
                        if("HCH" in data.donneTechniqueInfo[0].data){
                            this.donneDeCotation.kvaHch = data.donneTechniqueInfo[0].data.HCH
                        }else{
                            this.donneDeCotation.kvaHch = ''
                        }
                        if("HPH" in data.donneTechniqueInfo[0].data){
                            this.donneDeCotation.kvaHph = data.donneTechniqueInfo[0].data.HPH
                        }else{
                            this.donneDeCotation.kvaHph = ''
                        }
                        if("PTE" in data.donneTechniqueInfo[0].data){
                            this.donneDeCotation.kvaPte = data.donneTechniqueInfo[0].data.PTE
                        }else{
                            this.donneDeCotation.kvaPte = ''
                        }
                    }else{
                        this.parametreCompteur =''
                        this.resultatEnedisTechnique = false
                    }
                    if(data.autreData.result == true){
                        this.donneDeCotation.dateDebut = this.dateFormatMinus(data.autreData.dateDebut)
                        this.donneDeCotation.dateFin = this.dateFormatMinus(data.autreData.dateFin)
                    }
                    if(this.parametreCompteur == 'C3'){
                        this.donneDeCotation.sommeConsomation = this.donneDeCotation.CAHCH + this.donneDeCotation.CAHPH + 
                            this.donneDeCotation.CAHPE +this.donneDeCotation.CAHCE +this.donneDeCotation.CAP 
                    }else if(this.parametreCompteur == 'C4'){
                        this.donneDeCotation.sommeConsomation = this.donneDeCotation.CAHCH + this.donneDeCotation.CAHPH 
                            + this.donneDeCotation.CAHPE +this.donneDeCotation.CAHCE 
                    }else if(this.parametreCompteur == 'C5HP'){
                        this.donneDeCotation.sommeConsomation = this.donneDeCotation.CAHC 
                            + this.donneDeCotation.CAHP 

                    }else if(this.parametreCompteur == 'C5BASE'){
                        this.donneDeCotation.sommeConsomation = this.donneDeCotation.CABASE
                    }
                    
                    this.dialog = false
                    this.loading = false
                    
                }).catch((err)=>{
                    this.dialog = false
                    this.loading = false
                    this.enedisRecherche = false
                    this.errorEnedis = false
                    this.parametreCompteur =''
                })
            },
            calculer(){
                if(new Date(this.donneDeCotation.dateDebut).getTime()>= new Date(this.donneDeCotation.dateFin).getTime()){
                    this.errorDateDeFin = true
                }else{
                    
                    this.errorDateDeFin = false
                }
                if(!this.$refs.formGeneral.validate()){
                    return false
                }
                this.errorDateDeFin = false
                this.dialog = true
                this.loading = true
                this.messageDialogeError = ""
                this.messageDialogeSucces = ''
                this.error = false
                this.renitialiserLaListeDeResultat()
                this.$refs.formGeneral.validate()
                if(this.$refs.formGeneral.validate()){
                        axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + 'CalculeCoutProElec/',
                            method: 'POST',
                            data: {'donneDeCotation': this.donneDeCotation,
                                    'token': this.getToken('token'),
                                    'parametreCompteur': this.parametreCompteur,
                                    'siret': this.siret,
                                    'autreFournisseur': this.tousLesAutreFournisseur,
                                },
                        }).then((res) => {
                            if (res.data.result === 'permission') {
                                localStorage.setItem('vueAfficher', 'particulier')
                                localStorage.removeItem('vendeurName')
                                localStorage.removeItem('typeUser')
                                localStorage.removeItem('token')
                                this.$router.push('/login')
                            }
                            var paramAndTypeCompteur = null
                            if(this.errorEnedis == true){
                                res.data.dataEnedis['mesuresInfo'][0]['donne'] = {BASE: {somme: 0}}
                                res.data.dataEnedis['mesuresInfo'][0]['donne']['BASE']['somme'] = this.donneDeCotation.CABASE
                                if(this.donneDeCotation.optionTarifaire=="BTINFCU4"){
                                    paramAndTypeCompteur='C5BASE'
                                }
                                if(this.donneDeCotation.optionTarifaire=="BTINFMU4"){
                                    paramAndTypeCompteur='C5HP'
                                }
                            }else{
                                paramAndTypeCompteur = this.parametreCompteur
                            }
                            this.listProposition = res.data.result
                            this.$emit('sendDataToParent',{
                                donneDeCotation: this.donneDeCotation,
                                optionTarifaire: this.donneDeCotation.optionTarifaire,
                                parametreCompteur: paramAndTypeCompteur,
                                Propositions: res.data.result,
                                SocieteData: res.data.dataSociete,
                                enedisData: res.data.dataEnedis,
                                numCompteur: res.data.numCompteur,
                                turpeData: res.data.dataTurpe,
                                taxeData: res.data.dataTaxe,
                                typeCompteur: res.data.typeCompteur,
                                fournisseurActuelle: res.data.actuelle,
                                selected: [],
                                listCompteur: [...this.listCompteur]})
                                
                            this.dialog = false
                            this.loading = false
                        }).catch((err) => {
                            this.error = true
                            this.loading = false
                            this.messageDialogeError = "une erreur s'est produite lors du calcul du cotation"
                        })
                }
            },
            supprimerFournisseur(index){
                
                this.renitialiserLaListeDeResultat()
                if(this.tousLesAutreFournisseur[index].typeFournisseur == "Actuelle"){
                        this.fournisseurActuelle = false
                }
                this.tousLesAutreFournisseur.splice(index, 1)

            },
            AjouterFournisseur(index=-1){

                this.renitialiserLaListeDeResultat()
                this.$refs.formActuelle.validate()
                if(this.$refs.formActuelle.validate()){
                    if (this.autreFournisseur.typeFournisseur == "Actuelle"){
                        this.fournisseurActuelle = true
                    }
                    
                    if(this.editFournisseur == true){
                        this.tousLesAutreFournisseur[index] = {
                                turpeInclus: this.autreFournisseur.turpeInclus,
                                typeFournisseur: this.autreFournisseur.typeFournisseur,
                                fournisseur: this.autreFournisseur.fournisseur,
                                typeOffre: this.autreFournisseur.typeOffre,
                                prixHPH: this.autreFournisseur.prixHPH,
                                prixHCH: this.autreFournisseur.prixHCH,
                                prixHPE: this.autreFournisseur.prixHPE,
                                prixHCE: this.autreFournisseur.prixHCE,
                                prixHP: this.autreFournisseur.prixHP,
                                prixHC: this.autreFournisseur.prixHC,
                                prixBASE: this.autreFournisseur.prixBASE, 
                                prixPOINTE: this.autreFournisseur.prixPOINTE, 
                                typeCapa: this.autreFournisseur.typeCapa,
                                capaHPH: this.autreFournisseur.capaHPH,
                                capaHCH: this.autreFournisseur.capaHCH,
                                capaHPE: this.autreFournisseur.capaHPE,
                                capaHCE: this.autreFournisseur.capaHCE,
                                capaHP: this.autreFournisseur.capaHP,
                                capaHC: this.autreFournisseur.capaHC,
                                capaBASE: this.autreFournisseur.capaBASE, 
                                capaPOINTE: this.autreFournisseur.capaPOINTE, 
                                CEE: this.autreFournisseur.CEE,
                                ABO: this.autreFournisseur.ABO,
                                DUREE: this.autreFournisseur.DUREE,
                            }
                        if(this.autreFournisseur.typeFournisseur == "Actuelle"){
                            for (var i=0; i<this.tousLesAutreFournisseur.length; i++){
                                if(i!=index){
                                    this.autreFournisseur.typeFournisseur == "Fournisseur" 
                                }
                            }
                        }
                    }else{

                        this.tousLesAutreFournisseur.push({
                                turpeInclus: this.autreFournisseur.turpeInclus,
                                typeFournisseur: this.autreFournisseur.typeFournisseur,
                                fournisseur: this.autreFournisseur.fournisseur,
                                typeOffre: this.autreFournisseur.typeOffre,
                                prixHPH: this.autreFournisseur.prixHPH,
                                prixHCH: this.autreFournisseur.prixHCH,
                                prixHPE: this.autreFournisseur.prixHPE,
                                prixHCE: this.autreFournisseur.prixHCE,
                                prixHP: this.autreFournisseur.prixHP,
                                prixHC: this.autreFournisseur.prixHC,
                                prixBASE: this.autreFournisseur.prixBASE, 
                                prixPOINTE: this.autreFournisseur.prixPOINTE, 
                                typeCapa: this.autreFournisseur.typeCapa,
                                capaHPH: this.autreFournisseur.capaHPH,
                                capaHCH: this.autreFournisseur.capaHCH,
                                capaHPE: this.autreFournisseur.capaHPE,
                                capaHCE: this.autreFournisseur.capaHCE,
                                capaHP: this.autreFournisseur.capaHP,
                                capaHC: this.autreFournisseur.capaHC,
                                capaBASE: this.autreFournisseur.capaBASE, 
                                capaPOINTE: this.autreFournisseur.capaPOINTE, 
                                CEE: this.autreFournisseur.CEE,
                                ABO: this.autreFournisseur.ABO,
                                DUREE: this.autreFournisseur.DUREE,
                            })
                    }
                    this.resetFournisseurActuelle()
                    this.openModalFornisseurActuelle = false
                    this.editFournisseur = false
                }
            },
            editFournisseurMethod(index){
                this.editFournisseur = true
                this.indexFournisseurEdited = index
                this.autreFournisseur = this.tousLesAutreFournisseur[index]
                this.openModalFornisseurActuelle = true
            },

        },
        computed: {
            
            isDisabledMesures() {
                if (this.nbrMonth>=8) {
                    return true
                } else {
                    return false
                }
            },

            getPropositionSiretOrName(){
                this.Propositions=[]
                if(this.Propositions.length==0){
                    this.renitialiserLaListeDeResultat()
                }
                this.listCompteur = []
                this.donneDeCotation.numCompteur=''
                this.enedisRecherche =false
                this.vraiSiret = false
                if(this.valueRechercheSiret != ''){
                    var formData = new FormData()
                    formData.append('siret', this.valueRechercheSiret)
                    formData.append('token', this.getToken('token'))
                    this.isCompteursLoading = true
                    axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'getPropositionSociete/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        this.listSociete = res.data.result
                    })
                }else{
                    this.listSociete = []
                }
                return true
            }
        }
    }

</script>
<style scoped>
.v-subheader{
    font-size:large;
    font-weight:300;
    color:black;
    padding:0px;
}

::v-deep .v-label{
   margin:0px; 
}

@media only screen and (max-width:600px){
    .v-subheader{
        font-size:medium;
        margin-top:10px
    }
}

@media only screen and (max-width:1260px){
    .responsive-margin{
        margin-bottom:20px;
    }
}

@media only screen and (max-width:500px){
    .responsiveClass{

            display:flex;
            flex-direction:column;
            align-items:center;
    }

    .reponsiveBtn{
        margin:0px;
    }
     .responsiveGroupButton{
        display:flex;
        flex-direction:column;
    }
     ::v-deep .marginBtn{
       margin-bottom:10px !important;
       margin-left:0px !important;
    }
}

@media  screen and (max-width:510px){
.v-subheader {
        font-size:1em
    }

.v-dialog > .v-card > .v-card__title {
        font-size:.7em
    }
}

@media  screen and (max-width:300px){
.v-dialog > .v-card > .v-card__title{
        font-size:.7em;
        padding-left:0px;
        padding-right:0px;
    }
}

</style>