<template>
  <div @dragover="dragover" @dragleave="dragleave" @drop="drop">
   <v-alert v-if="maxSizeFile" type="warning" icon="mdi-alert-outline">
          Veuillez supprimer un ou plusieurs fichier(s) pour rajouter d'autres fichiers
        </v-alert>
    <input
      type="file"
      style="display: none"
      name="fields"
      id="assetsFieldHandle"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      @change="onChange()"
      ref="file"
    />
    <h4>{{ name }}</h4>
    <label for="assetsFieldHandle" class="block cursor-pointer">
      <div class="requireddropzone">
        <v-icon x-large>cloud_upload</v-icon>
        <p>
          Déposer votre/vos fichier(s) ici <span><br />(ou)</span>
        </p>
        <p style="background-color: #e2e2e2">Séléctionnez un fichier à importer</p>
        <p>Types de fichier supportés : pdf, eml, msg, png, jpg, jpeg</p>
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: "uploadFile",
  props: ["name","fileProps",'maxNumberFileProps'],
  data() {
    return {
      file: [],
      maxSizeFile: false,
    };
  },
     watch :{
        fileProps:function(newVal,oldVal){
          this.file = this.fileProps
        },
        maxNumberFileProps:function(newValue){
          this.maxSizeFile = this.maxNumberFileProps
        }
    },
  methods: {
    dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-green-300');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        drop(event) {
            event.preventDefault();
            if(event.dataTransfer.files.length>1){
                this.$refs.file.files=null;
            }else{
                
                    this.$refs.file.files = event.dataTransfer.files;
                    this.onChange(); // Trigger the onChange event manually
                    // Clean up
                    event.currentTarget.classList.add('bg-gray-100');
                    event.currentTarget.classList.remove('bg-green-300');
                
            }
        },

    onChange() {
     if(this.file.length < 3){
      this.file.push(this.$refs.file.files[0])
      this.$emit("FileData",this.file)
     }else{
       this.maxSizeFile = true
       this.$emit('maxNumberFile',this.maxSizeFile)
     }
    },
  },
};
</script>
