<template>
<div>
    <v-app-bar height="100" flat app style="z-index:12">
          <div class="d-flex">
            <v-col class="px-0" cols="1" sm="1" md="1" lg="1" xl="1"> 
              <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            </v-col>
            <v-col class="px-4 ml-6" cols="5" sm="5" md="5" lg="5" xl="10"> 
              <div class="ticker-wrap">
                  <div class="ticker" :style="`--duration: `+duration+`s`">
                      <v-alert v-for='( msg, index)  in $store.getters.getMessages' class="ticker__item px-4 mx-1"   :color="colorFunction(msg.color)" :key="index"><v-icon color="white" class="mr-2" large>{{iconFunction(msg.color)}}</v-icon>{{msg.message}}</v-alert>
                  </div>
              </div>
            </v-col>
          </div>
    </v-app-bar>
    <v-navigation-drawer width="280" flat v-model="drawer" app>
      <img
        class="ma-4"
        position="center"
        height="60"
        style="width:80%;"
        src="../assets/logoDune.png"
      ></img>
      <div class="d-flex flex-column align-items-center mx-4">
        <span class="user-info mb-0 mr-4">{{ userInfo }}</span>
        <span class='role mb-0'>{{ typeUser }}</span>
      </div>
      <v-divider></v-divider>
      <v-list flat class="mt-8" dense nav width="100%">
        <!-- pour chaque element de la list items un element de menu est créé -->
        <v-list-item
          v-for="item in filteredByUserTypeItems"
          v-if="!item.subLinks"
          :key="item.title"
          :to="item.to"
          link
        >
          <!-- ajoute l'icone d'un element du menu -->
          <v-list-item-icon >
            <v-icon color="blue darken-4" class="ml-2">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <!-- change l'evenement onClick pour "Point de Livraison" -->
          <v-list-item-content
            v-if="item.title == 'Point De Livraison'"
            @click="openPdl"
          >
            <!-- ajoute le titre d'un element du menu -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>

          <!-- Les autres elements du menu redirigent vers un vue interne -->
          <v-list-item-content v-else>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
            v-else
            :key="item.title"
            no-action
        >
            <template v-slot:activator>
              <v-list-item>
                <!-- ajoute l'icone d'un element du menu -->
                <v-list-item-icon>
                  <v-icon color="blue darken-4">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <!-- Les autres elements du menu redirigent vers un vue interne -->
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item 
                v-for="sublink in item.subLinks.filter((item) => item.userType.includes(typeUser))"
                :to="sublink.to"
                :key="sublink.title">
                <!-- ajoute l'icone d'un element du menu -->
                <v-list-item-icon>
                  <v-icon color="blue darken-4">{{ sublink.icon }}</v-icon>
                </v-list-item-icon>

                <!-- Les autres elements du menu redirigent vers un vue interne -->
                <v-list-item-content>
                  <v-list-item-title>{{ sublink.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
      <div class="mt-10" style="width:100%;background-color:#103A5E">
          <div class="d-flex justify-content-center" app>
              <v-btn plain :color="$colors[0]" class="text-white" elevation='0' @click="logout">
                Déconnexion <v-icon class='mx-2'>mdi-logout-variant</v-icon>
              </v-btn>
          </div>
      </div> 
      </template>
    </v-navigation-drawer>
</div>
</template>
<script>
import axios from "axios";
export default {
  name: 'Menu',
  data: () => ({
    duration: 0,
    drawer: true,
    message:[],
    typeUser: '',
    userInfo: '',
    items: [
     {
        userType: ['superadmin', 'pro', 'pro-part', 'admin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
        title: 'Courbes des prix',
        icon: 'mdi-chart-line',
        to: '/graphiques',
      },
      {
        userType: ['fournisseur'],
        title: 'Mes Offres',
        icon: 'mdi-equalizer',
        to: '/mes-offres-externes',
      },
      {
        userType: ['pro', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
        title: 'Nouvelle opportunité',
        icon: 'mdi-cash-plus',
        to: '',
        subLinks : [
          {
            userType: ['pro', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
            title:  'Collecte de Données',
            icon: 'mdi-file-plus',
            to: '/nouvelle-opportunite/autorisation-collecte-donnees',
          },
          
          {
            userType: ['pro', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
            title: 'Demande de Cotation',
            icon: 'mdi-file-document-plus',
            to: '/nouvelle-opportunite/demande-cotation',
          },
        ]
      },
      {
        userType: ['pro', 'pro-part', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur'],
        title: 'Cotations',
        icon: 'mdi-cash-multiple',
        subLinks : [
            
            {
              userType: [ 'pro', 'pro-part', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur'],
              title: 'Électricité',
              icon: 'mdi-lightning-bolt-circle',
              to: '/demande-cotation-elec-pro',
            },
            {
              userType: ['pro', 'pro-part', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur'],
              title: 'Gaz',
              icon: 'mdi-fire-circle',
              to: '/demande-cotation-gaz-pro',
            },
        ]
      },
      {
        userType: ['pro', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
        title: 'Ajouter une vente ',
        icon: 'mdi-package-variant-plus',
        to: '/ajouterVenteProMulti',
      },
      {
        userType: ['pro', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
        title: 'Portefeuille client',
        icon: 'mdi-wallet-outline',
        to: '',
        subLinks : [
          {
            userType: ['pro', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
            title: 'Demandes de cotation',
            icon: 'mdi-file-document-outline',
            to: '/mes-demandes-cotation',
          },
          
          {
            userType: ['pro', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
            title: 'Ventes professionnelles',
            icon: 'mdi-shopping-outline',
            to: '/mes-ventes-pro',
          },

          {
              userType: ['pro', 'pro-part', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur'],
              title: 'Entreprises',
              icon: 'mdi-store-outline',
              to: '/mes-societes',
          },
        ]
      },
      {
        userType: ['admin', 'superadmin', 'Chef équipe', 'ADV'],
        title: 'Supervision',
        icon: 'mdi-cctv',
        to: '',
        subLinks : [
            {
              userType: ['admin', 'superadmin', 'Chef équipe', 'ADV'],
              title: 'Ventes professionnelles',
              icon: 'mdi-shopping-outline',
              to: '/controle-ventes-pro',
            },
            {
              userType: ['admin','superadmin', 'Chef équipe', 'ADV'],
              title: 'Entreprises',
              icon: 'mdi-store-outline',
              to: '/controle-societes',
            },
            {
              userType: ['admin', 'superadmin', 'Chef équipe', 'ADV'],
              title: 'Validations de cotation',
              icon: 'mdi-file-document-check-outline',
              to: '/validation-cotation',
            },
            /*{
              userType: ['admin','superadmin'],
              title: 'Offres Externes',
              icon: 'mdi-basket',
              to: '/offres-externes-admins',
            },*/
            {
              userType: ['admin', 'superadmin'],
              title: 'Vendeurs',
              icon: 'mdi-badge-account-outline',
              to: '/vendeurs',
            },
            {
              userType: ['ADV'],
              title: 'Vendeurs',
              icon: 'mdi-badge-account-outline',
              to: '/vendeursAvd',
            },
            {
              userType: ['Chef équipe'],
              title: 'Vendeurs',
              icon: 'mdi-badge-account-outline',
              to: '/vendeurschef',
            },
            {
              userType: ['ADV'],
              title: 'Listes des équipes',
              icon: 'mdi-account-group-outline',
              to: '/listeEquipeAdv',
            },
            {
              userType: ['admin', 'superadmin'],
              title: 'Listes des équipes',
              icon: 'mdi-account-group-outline',
              to: '/listeEquipeAdmin',
            },
            /*{
              userType: ['admin', 'superadmin', 'ADV'],
              title: 'Rapport de vente',
              icon: 'mdi-file-sign',
              to: '/rapport-vente',
            },*/
            {
              userType: ['admin', 'superadmin'],
              title: "Contrôle d'alerte",
              icon: 'mdi-comment-alert-outline',
              to: '/controle-alerte',
            },
        ]
      },   
    ],
  }),
  async created() {
    this.typeUser = localStorage.getItem('typeUser');
    this.userInfo = localStorage.getItem('vendeurName');
    await this.getAllMessage();
    for(var i=0; i<this.message.length; i++){
      this.duration = this.duration + (this.message[i].message.length*0.35);
    }
    if(this.duration<=25){
      this.duration = 25;
    }
  },
  methods: {
    iconFunction(colorName){
      switch (colorName) {
        case "goodNews":
          return "mdi-new-box";
          break;
        case "warningNews":
          return "mdi-alert-outline";
          break;
        case "badNews":
          return "mdi-alert-decagram-outline";
          break;
        
      
        default:
          break;
      }
    },
    colorFunction(colorName){
      switch (colorName) {
        case "goodNews":
          return "green";
          break;
        case "warningNews":
          return "amber";
          break;
        case "badNews":
          return "red";
          break;
        
      
        default:
          break;
      }
    },
    async getAllMessage(){
      await axios({
              url: process.env.VUE_APP_URL_API_CLIENT + 'getListmessageRapide/',
              method: 'POST',
              data: {},
          }).then((res) => {
              this.message = res.data.result
              this.$store.commit('addMessage',this.message)
          }).catch((err) => {
              this.error_enedis = true
          })
    },
    openPdl() {
      window.open(
        'https://selectra.info/energie/guides/compteurs/electrique/point-livraison-pdl',
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    logout() {
      localStorage.removeItem('vendeurName')
      localStorage.removeItem('typeUser')
      localStorage.removeItem('token')
      this.$router.push('/login')
    },
  },

  computed: {
    //renvoie les element du menu en fonction et type d'utilisateur
    filteredByUserTypeItems() {
      return this.items.filter((item) => item.userType.includes(this.typeUser))
    },
    
  }
}
</script>
<style scoped>
::v-deep .v-toolbar__content {
   padding: 0px !important;
   width:100%;
   display:flex;
   flex-direction:row;
   justify-content:space-between;
   
 }

::v-deep .v-list-group__header__append-icon {
   margin-left:0px !important;
   
 }

 .v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item{
  padding-left:35px !important;
}

@media only screen and (max-width:335px){
  .user-info{
    font-size:1.2em !important;
  }
  .role{
    font-size:1.2em !important;
  }
}

.role{
  font-size:1.5em;
  font-weight:500;
}
.user-info{
  font-size:1.5em;
  font-weight:300;
}
</style>
<style lang="scss">
* { box-sizing: border-box; }

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0D47A1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.ticker-wrap {
  position: fixed;
  width: 100%;
  overflow: hidden;
  height: 4rem;
  background-color: rgba(#f1f1f1, 0.9); 
  padding-left: 100%;
  box-sizing: content-box;
  .ticker {
    --duration: 30s;
    display: inline-block;
    height: 4rem;
    line-height: 4rem;  
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite; 
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
   -webkit-animation-name: ticker;
           animation-name: ticker;
    -webkit-animation-duration: var(--duration);
            animation-duration: var(--duration);
    &__item {
      display: inline-block;
      padding: 0 2rem;
      font-size: 1.5rem;
      color: white;   

    }
  }
    
}
.ticker:hover {
  animation-play-state: paused;
}
.goodNews {
background-color:#00C853;
}
.badNews {
background-color: #D50000;
}
.warningNews {
background-color: #FBC02D;
}
</style>
