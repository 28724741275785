<template>
  <div v-if="scoreInfoLegale && scoreCreditSafe">
    <v-subheader class="mt-12">Note de Solvabilité:</v-subheader>
    <v-divider class="mb-12"></v-divider>
    <!-- Infos CredistSafe et InfoLegale -->
    <v-row>
      <v-col xs="12" sm="6" md="3" lg="2">
        <v-card outlined>
          <v-card-title class="justify-start">
            <v-avatar class="mr-2" size="36" rounded>
              <img
                alt="credit safe score"
                src="../../assets/images/logos/logo-credit-safe.png"
              />
            </v-avatar>
            creditsafe
          </v-card-title>

          <v-card-text class=" text-right text-body-1  mt-1 pr-6"
            >Score:
            <span class="ml-2 font-weight-black text-h6">{{
              scoreCreditSafe
            }}</span>
            /100
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xs="12" sm="6" md="3" lg="2">
        <v-card outlined>
          <v-card-title class="justify-start">
            <v-avatar class="mr-2" size="36" rounded>
              <img
                alt="credit safe score"
                src="../../assets/images/logos/logo-info-legale.jpg"
              />
            </v-avatar>
            infolegale
          </v-card-title>

          <v-card-text class="  text-right text-body-1  mt-1 pr-6"
            >Score:
            <span class="ml-2 font-weight-black text-h6">{{
              scoreInfoLegale
            }}</span>
            /20
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['scoreInfoLegale', 'scoreCreditSafe', 'isSiretFound'],
}
</script>
