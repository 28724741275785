<template>
    <div>
        <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
                <dialogeModal
                    :error="error"
                    :dialog="dialog"
                    :loading="loading"
                    @closeDialog="dialog = false"
                    :titre="title"
                    :messageDialogeSucces="this.messageDialogeSucces"
                    :messageDialogeError="this.messageDialogeError" />
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialogAddCompteur==true">
                <v-dialog
                    v-model="dialogAddCompteur"
                    persistent
                    max-width="1000"
                    min-height="250">
                    <v-card>
                        <v-form ref="form"  lazy-validation v-if="ajouterUnAutreCompteur==true">
                            <v-card-title class="text-h5">
                                <p>Edition contrat Ekwateur</p>
                            </v-card-title>
                            <v-card-actions>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <p><b>N° Compteur selectionné:</b> <small>{{numCompteur}}</small></p>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-autocomplete 
                                            v-model="selectedCompteur" 
                                            :items="listCompteur" 
                                            :loading="isLoading" 
                                            hide-no-data 
                                            hide-selected
                                            label="Autre compteur" 
                                            placeholder="Ajouter un compteur" 
                                            prepend-icon="mdi-account-search-outline"
                                            chips
                                            :item-text="item"
                                            :item-value="item"
                                            deletable-chips
                                            multiple
                                            >
                                            <template v-slot:item="data">
                                                <template>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item"></v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                            <template v-slot:prepend-item>
                                                <v-list-item ripple @click="toggle">
                                                    <v-list-item-content>
                                                        <v-list-item-title>Select All</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-form>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="listCompteurResult.length>0">
                                    <b-table :busy="loadingTableHistorique"  :fields="fieldhistoriqueDeCotation" :items="listCompteurResult" responsive bordered>
                                        <template #table-busy>
                                                <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Chargement...</strong>
                                            </div>
                                        </template>
                                        <template #cell(numCompteur)='data'>
                                        <small>{{data.item.numCompteur}}</small>
                                        </template>
                                        <template #cell(kva)='data'>
                                            <small>{{data.item.kva}}</small>
                                        </template>

                                        <template #cell(type)='data'>
                                            <small>{{data.item.type}}</small>
                                        </template>
                                        <template #cell(conso)='data'>
                                            <v-text-field dense outlined label="Consommation (Mwh)" v-model="data.item.conso"></v-text-field>
                                        </template>


                                        <template #cell(dateDebut)="data">
                                            <v-text-field dense type="date" outlined label="Date Debut" v-model="data.item.dateDebut"></v-text-field>
                                        </template>
                                        <template #cell(action)="data">
                                            <v-btn @click="supprimerCompteur(data.item.numCompteur)">Supprimer</v-btn>
                                        </template>
                                    </b-table>       
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12"> 
                                    <v-row class="mb-10 mt-8" align="center" justify="center">
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="ajouterUnAutreCompteur==true">
                                            <v-btn color="primary"  @click="appelleEnedis" >get Donner Enedis</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="ajouterUnAutreCompteur==false">
                                            <v-btn class="text-white" :color="$colors[0]" @click="openAjouterUnAutreCompteur">
                                                +Compteur
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                                            <v-btn color="error" @click="closeModal">Annuler</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                            <v-btn color="teal" @click="createContrat">Générer le Contrat</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-coL>
                            </v-row>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-btn
            @click="openDialog"
            depressed class="ma-auto text-capitalize text-white" :color="$colors[0]">
            Éditer contrat
        </v-btn>
    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText, setFillingRgbColor, setFontAndSize  } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import dialogeModal from "../../components/ui/dialogeModal"
    import axios from 'axios'
    export default{
        name: "EkwateurContrat",
        components:{dialogeModal:dialogeModal},
        props: {
            donner: null,
            numCompteur: null,
            societe: null,
            donnerEnedis: null,
            optionTarifaire: null,
            listCompteur: []
        },
        data() {
            return {
                loadingTableHistorique: false,
                fieldhistoriqueDeCotation:[
                    {key:"numCompteur", label:"Numéro de compteur"},
                    {key: "type", label:"Option tarifaire"},
                    {key:"kva", label:"KVA"},
                    {key:"dateDebut", label:"Début de debut"},
                    {key:"conso",label:"Consommation en (Mwh)"},
                    {key:"action",label:"Action"}
                ],
                dialog: false,
                error: false,
                title: '',
                selectedCompteur: [],
                listCompteurResult:[],
                loading: false,
                messageDialogeError: "",
                messageDialogeSucces:"",
                dialogAddCompteur: false,
                ajouterUnAutreCompteur: false,
                isLoading: false,
                data:{
                    dateAjourdhui: new Date().getDate()+"/"+(parseInt(new Date().getMonth())+1)+"/"+new Date().getFullYear(),
                    dateDebut: "10/10/2020",
                    dateFin: "10/10/2022",
                    pdl: "14552022144555",
                    siret: "11111111111111", 
                    codeNaf: "1212Z", 
                    adresseSociete: "199 RUE, 12554 commune d'atte", 
                    adresseCompteur: "199 RUE, 12554 commune d'atte",
                    codePostaleCompteur: '',
                    voieCompteur: '',
                    communeCompteur: '',
                    codePostaleSociete: '',
                    voieSociete: '',
                    communeSociete: '',
                    
                    codePostale: '',
                    nom: "chrif",
                    prenom: "slimen", 
                    email: "slimenc6@gmail", 
                    numTele: "0755448899", 
                    consommationAnuuelle: "377.20",
                    typeCompteur: "BTINFMUDT",
                    kva: "24",
                    raison: "Gaz elec moins cher Gaz elec moins cher Gaz elec moins cher",
                    nbrMonth: '2',
                    cabase: '',
                    cahp: '',
                    cahc: '',
                },
            }

        },
        watch:{
            selectedCompteur(newVal, oldVal){
                if(newVal.length != oldVal.length){
                    console.log('ok')
                    var index = -1
                    for (var i = 0; this.listCompteurResult.length; i++){
                        index = newVal.findIndex(x => x === this.listCompteurResult[i].numCompteur)
                        if (index == -1) { 
                            this.listCompteurResult.splice(i, 1);
                            break
                        }
                    }
                }
            },
        },
        created() {
            var index = this.listCompteur.findIndex(x => x === this.numCompteur)
            if (index > -1) { 
                this.listCompteur.splice(index, 1);
            }
        },
        methods:{
            toggle(){
                this.selectedCompteur = [...this.listCompteur]
            },
            closeModal(){
                this.dialogAddCompteur=false
                this.ajouterUnAutreCompteur = false
                this.selectedCompteur = []
                this.listCompteurResult = []
            },
            openDialog(){
                this.dialogAddCompteur = true
            },
            supprimerCompteur(numComp){
                var index = this.selectedCompteur.findIndex(x => x === numComp)
                if (index > -1) { 
                    this.selectedCompteur.splice(index, 1);
                }
                index = this.listCompteurResult.findIndex(x => x.numCompteur === numComp)
                if (index > -1) { 
                    this.listCompteurResult.splice(index, 1);
                }
                index = this.listCompteur.findIndex(x => x === numComp)
                if(index == -1){
                    this.listCompteur.push(numComp)
                }
            },
            async openAjouterUnAutreCompteur(){
                this.ajouterUnAutreCompteur = true
            },
            async appelleEnedis(){
                this.loadingTableHistorique = true;
                await this.getConsoAllCompteur()
                
                this.loadingTableHistorique = false;
            },
            async createContrat(){
                
                this.title = "Creation de contrat"
                this.addData = false
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialogeError= ""
                this.messageDialogeSucces=""
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    listCompteur: this.listCompteurResult,
                    societe: this.societe,
                }
                await axios({
                    url: 'https://editioncontrat.volto.fr/EkwateurApp',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'_EKWATEUR_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialogeError= ""
                this.messageDialogeSucces=""
            },
            async getConsoAllCompteur(){
                this.dialog=true
                this.loading=true
                this.title = "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!"
                this.messageDialoge="veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!"
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('listCompteur', this.selectedCompteur)
                formData.append('dateDebut', this.donner.dateDebut)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllCompteurConso/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    this.listCompteurResult = res.data.result
                    console.log(this.listCompteurResult)
                    this.dialog=false
                }).catch((err)=>{
                    this.listCompteurResult=[]
                    this.error = true
                    this.messageDialogeError = "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!"
                })
            },
        }
    
    }
</script>
