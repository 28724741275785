<template>
  <v-form
    ref="formeEntreprise"
    @submit.prevent="submitForm"
    v-model="isFormValid"
    class="mb-16"
  >
    <v-subheader>Recherche de l'Entreprise:</v-subheader>

    <v-divider class="mb-12"></v-divider>
                
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        v-if="showSiret"
        transition="fade-transition"
      >
        <v-text-field
          name="siret"
          counter="14"
          v-model="editedForm.siret"
          :rules="[required('le SIRET d\'entreprise'), verifyLength(14)]"
          type="number"
          label="Le numéro Siret"
          outlined
          width="90%"
          @input="handleSiretInput"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        v-if="showNomEntreprise"
        transition="fade-transition"
      >
        <v-text-field
          name="nomEntreprise"
          v-model.trim="editedForm.nomEntreprise"
          type="text"
          label="Le nom d'Entreprise"
          :rules="[required('le nom d\'entreprise')]"
          outlined
          width="90%"
          @input="handleNomEntrepriseInput"
          validate-on-blur
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="3"
        xl="3"
        class="d-flex justify-lg-end justify-md-end justify-sm-center justify-xs-center"
      >
        <v-btn
          depressed
          elevation="1"
          large
          color="primary"
          style="textTransform: none"
          class="ma-auto"
          width="90%"
          type="submit"
        >
          Rechercher
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="3"
        xl="3"
        class="d-flex justify-lg-end justify-md-end justify-sm-center justify-xs-center"
      >
        <v-btn
          @click="resetForm"
          depressed
          style="textTransform: none"
          large
          elevation="1"
          class="ma-auto"
          width="90%"
          >Réinitialiser</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  emits: ['get-list-compteurs','isEntrepriseFoundChange'],
  props: ['is-entreprise-found'],
  data() {
    return {
      isFormValid: false,

      editedForm: {
        fieldOnScreen: null,
        siret: '',
        nomEntreprise: '',
      },

      defaultForm: {
        fieldOnScreen: null,
        siret: '',
        nomEntreprise: '',
      },

      //règles de validation forme SIRET
      required(message) {
        return (value) => !!value || `Veuillez saisir ${message}.`
      },
      verifyLength(length) {
        return (value) =>
          value.length == length || 'Le siret doit comporter 14 chiffres.'
      },
    }
  },
  methods: {
    submitForm() {
      if (!this.$refs.formeEntreprise.validate()) return
      let data
      if (this.editedForm.fieldOnScreen === 'siret') {
        data = { type: 'siret', value: this.editedForm.siret }
      }
      if (this.editedForm.fieldOnScreen === 'nomEntreprise') {
        data = { type: 'nom', value: this.editedForm.nomEntreprise }
      }

      this.$emit('get-list-compteurs', data)
    },
    resetForm() {
      this.$emit('isEntrepriseFoundChange', false)
      //remplace un objet editedForm par une copie de defaultForm
      this.editedForm = Object.assign({}, this.defaultForm)
      this.$refs.formeEntreprise.resetValidation()
      this.isFormValid = true
    },
    handleSiretInput(value) {
      this.$emit('isEntrepriseFoundChange', false)
      const field = 'siret'
      if (value !== '') {
        if (this.editedForm.fieldOnScreen !== 'siret') {
          this.editedForm.fieldOnScreen = field
        }
        return
      }
      this.editedForm.fieldOnScreen = null
    },
    handleNomEntrepriseInput(value) {
      this.$emit('isEntrepriseFoundChange', false)
      const field = 'nomEntreprise'
      if (value !== '') {
        if (this.editedForm.fieldOnScreen !== 'nomEntreprise') {
          this.editedForm.fieldOnScreen = field
        }
        return
      }
      this.editedForm.fieldOnScreen = null
    },
  },
  computed: {
    showNomEntreprise() {
      let show = false

      if (
        this.editedForm.fieldOnScreen === 'nomEntreprise' ||
        !this.editedForm.fieldOnScreen
      ) {
        show = true
      }
      return show
    },
    showSiret() {
      let show = false
      if (
        this.editedForm.fieldOnScreen === 'siret' ||
        !this.editedForm.fieldOnScreen
      ) {
        show = true
      }
      return show
    },
  },
}
</script>
