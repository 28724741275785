<template>
  <v-card class="ma-4 pa-4" elevation="10">
    <h1 class="ma-4 pt-4 title-container mb-6 d-flex">Nouvelle Vente professionnelle</h1>
    <!-- modale loading-->
    <v-row>
        <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
          <dialogeModal
                    :error="error"
                    :dialog="dialog"
                    :loading="loading"
                    @closeDialog="dialog = false"
                    titre="Demande de cotation"
                    :messageDialogeSucces="this.messageDialogeSucces"
                    :messageDialogeError="this.messageDialogeError" />
        </v-col>
      </v-row>
    <v-row>
      <!-- Champ SIREN et bouton pour lancer la recherche -->

      <v-col cols="12">
        <v-form ref="formSiret" lazy-validation>
          <v-row>
          <v-col>
              <v-radio-group
                v-model="typeEnergie"
                row
                @change="renitialiseData"
              >
                <v-radio label="Electricité" value="ELEC"></v-radio>
                <v-radio label="Gaz" value="GAZ"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="pb-0 mt-4" cols="10" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                required
                :rules="[rules.required, rules.siretLength, rules.isNumber]"
                outlined
                @input="renitialiseData"
                v-model="societe.siret"
                label="Siret"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <v-btn
                @click="rechercheParSiret"
                depressed
                elevation="1"
                large
                class="mt-1 primary ma-auto text-capitalize"
                width="100%"
              >
                Rechercher
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="siretFound==true">
      <!-- info vente -->
      <v-col cols="12">
        <v-form ref="formVente" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('un nom'),rules.isString]"
                v-model="societe.info.nom"
                label="Nom"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('un prenom'),rules.isString]"
                v-model="societe.info.prenom"
                label="Prenom"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('une adresse e-mail valide'),rules.emailRules]"
                v-model="societe.info.email"
                label="Adresse e-mail"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('un numéro de téléphone valide'), rules.isNumber, rules.phoneLength]"
                v-model="societe.info.num_tel"
                label="Numéro de téléphone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                    outlined
                    :rules="[required('une raison sociale valide')]"
                    v-model="societe.raison"
                    label="Raison sociale"
                ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                        outlined
                        :rules="[required('Adresse postale')]"
                        v-model="societe.adressePostale"
                        label="Adresse postale"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-radio-group v-model="autreCompteur" row>
                    <v-radio label="Autre compteur" :value="true"></v-radio>
                    <v-radio label="Compteur Déjà existe" :value="false"></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-autocomplete
                        v-if="autreCompteur==false"
                        ref="compteur"
                        outlined
                        :rules="[required('un N° compteur valide')]"
                        :items="listeCompteurs"
                        v-model="societe.numCompteur"
                        label="Compteur"
                    ></v-autocomplete>
                    <v-text-field
                        v-if="autreCompteur==true && typeEnergie=='GAZ'"
                        outlined
                        :rules="[required('un N° compteur valide'), rules.isGazCompteur]"
                        v-model="societe.numCompteur"
                        label="Compteur"
                    ></v-text-field>
                    <v-text-field
                            v-if="autreCompteur==true && typeEnergie=='ELEC'"
                            outlined
                            :rules="[required('un N° compteur valide'), rules.compteurElecLength, rules.isNumber]"
                            v-model="societe.numCompteur"
                            label="Compteur"
                        ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6">
                    <v-text-field
                        outlined
                        :rules="[required('une durre valide'), rules.value]"
                        type="number"
                        step="1"
                        v-model="societe.duree"
                        label="durée (mois)"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="6">
                <v-text-field
                    outlined
                    :rules="[required('une valeur valide'), rules.value]"
                    type="number"
                    step="0.001"
                    v-model="societe.valeur"
                    label="Valeur"
                    suffix="€"
                ></v-text-field> 
                </v-col>
                <v-col cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6">
                    <v-text-field
                        outlined
                        :rules="[required('une % valide'), rules.value]"
                        type="number"
                        step="0.001"
                        v-model="societe.pourcentage"
                        label="Pourcentage"
                        suffix="%"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6">
                    <v-text-field
                        outlined
                        :rules="[required('une marge valide'), rules.value]"
                        type="number"
                        step="0.001"
                        v-model="societe.marge"
                        label="Marge"
                        suffix="€"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-autocomplete 
                        ref="compteur"
                        outlined
                        :rules="[required('un Fournisseur valide')]"
                        :items="listeFournisseur"
                        v-model="societe.fournisseur"
                        label="Fournisseur"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <!-- button -->
            <v-row align="end" justify="end" class="mb-8">
                <!-- Validation forme -->

                <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <v-btn
                    depressed
                    elevation="1"
                    large
                    class="ma-auto text-capitalize"
                    width="100%"
                    :loading="false"
                    @click="renitialiseData"
                >
                    Réinitialiser
                </v-btn>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <v-btn
                    depressed
                    elevation="1"
                    large
                    class="primary ma-auto text-capitalize"
                    width="100%"
                    :loading="false"
                    @click="submitVente"
                >
                    Envoyer
                </v-btn>
                </v-col>
            </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
    import axios from "axios";
    import dialogeModal from '../../components/ui/dialogeModal'
    export default {
        name: "ajouterUneVenteDansRapport",
        components: {dialogeModal:dialogeModal},
        data() {
            return {
                
                dialog : false,
                loading : false,
                error : false,
                messageDialogeError:"",
                messageDialogeSucces: "L'e-mail a été envoyé avec succès",

                typeEnergie: "GAZ",
                autreCompteur: false,
                societe:{
                    siret:'',
                    adressePostale: '',
                    codeNaf: '',
                    codeInsee: '',
                    raison: '',
                    numCompteur: '',
                    fournisseur: '',
                    valeur: '',
                    pourcentage: 0,
                    marge: 0,
                    duree: 0,
                    info: {
                        nom: '',
                        prenom: '',
                        num_tel: '',
                        email: '',
                    },
                },
                listeCompteurs: [],
                listeFournisseur: [
                    "EDS",
                    "Ekwateur",
                    "ENDESA",
                    "ENGIE PRO",
                    "ENOVOS",
                    "VATTENFALL",
                    "GAZEL_ENERGIE",
                    "GAZPROM",
                    "Iberdrola",
                    "LLUM",
                    "Picoty",
                    "Primeo",
                    "Synelva",
                    "Total",
                    "Wekiwi",
                    "LUCIA",
                    "GEDIA",
                ],
                siretFound:false,
                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                rules: {
                    isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres", 
                    required: (value) => !!value || "Ce champ est obligatoire.",
                    siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
                    isNumber: (v) =>
                    v.match(/^[0-9]+$/) != null || "Ce champ doit être constitué uniquement de chiffres",
                    compteurElecLength: (v) =>
                    v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
                    isGazCompteur: (v) =>
                    v.match(/^(GI)[0-9]{6}$/) ||
                    (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
                    "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
                    phoneLength: (v) =>
                    v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
                    phoneisNumber: (v) =>
                    v.match(/^[0-9]+$/) != null || "Ce champ doit être constitué uniquement de chiffres",
                    margeMaxMin: (v) =>
                    (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

                    emailRules: [
                    (v) => !!v || "E-mail obligatoire",
                    (v) => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
                    ],
                },
            }
        },
        async created(){
            //await this.getAllSociete()
        },
        methods:{
            renitialiseData(){
                let self = this
                self.siretFound = false;
                self.societe.raison = "";
                self.societe.adressePostale = "";
                self.societe.codeInsee = "";
                self.societe.codeNaf = "";
                self.societe.info.nom = "";
                self.societe.info.prenom = "";
                self.societe.info.email = "";
                self.societe.info.num_tel = "";
            },
            async rechercheParSiret(){
                if (this.getToken("token")) {
                    let self = this;
                    self.siretFound = false;
                    this.$refs.formSiret.validate();
                    if (this.$refs.formSiret.validate()) {
                        this.dialog = true;
                        this.loading = true;
                        this.error = false;
                        
                        await axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + "getDonneParSiretRapportVente/",
                            method: "POST",
                            data: { siret: this.societe.siret, type: self.typeEnergie, token: this.getToken("token") },
                        })
                        .then((res) => {
                            if (res.data.result === "permission") {
                                localStorage.removeItem("vendeurName");
                                localStorage.removeItem("typeUser");
                                localStorage.removeItem("token");
                                this.$router.push("/login");
                            }
                            if (res.data.result == true) {
                                self.siretFound = true;
                                self.societe.raison = res.data.raison;
                                self.societe.adressePostale = res.data.adresse;
                                self.societe.codeNaf = res.data.codeNaf;
                                self.societe.codeInsee = res.data.codeInsee;
                                if(res.data.infoPerson==true){
                                    self.societe.info.nom = res.data.nom
                                    self.societe.info.prenom = res.data.prenom
                                    self.societe.info.email = res.data.AdresseEmail
                                    self.societe.info.num_tel = res.data.numTele
                                    self.listeCompteurs = res.data.listeCompteur
                                }
                                self.loading = false;
                                self.dialog = false;
                            } else {
                                self.loading = false;
                                self.dialog = true;
                                self.error = true;
                                self.messageDialogeError = "Le siret est incorrect";
                                self.siretFound = false;
                                self.societe.raison = "";
                                self.societe.siret = "";
                                self.societe.adressePostale = "";
                                self.societe.codeInsee = "";
                                self.societe.codeNaf = "";
                                self.societe.info.nom = "";
                                self.societe.info.prenom = "";
                                self.societe.info.email = "";
                                self.societe.info.num_tel = "";
                            }
                        })
                        .catch((err) => {
                            self.siretFound = false;
                            self.societe.raison = "";
                            self.societe.siret = "";
                            self.societe.adressePostale = "";
                            self.societe.codePostale = "";
                            self.societe.codeInsee = "";
                            self.societe.codeNaf = "";
                            
                            self.societe.info.nom = "";
                            self.societe.info.prenom = "";
                            self.societe.info.email = "";
                            self.societe.info.num_tel = "";

                            self.loading = false;
                            self.dialog = true;
                            self.error = true;
                            this.messageDialogeError = "Une erreur est survenue !";
                        });
                    }
                } else {
                    this.$router.push("/login");
                }
            },
            async submitVente(){

            },
            async getAllSociete(){
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + "getToutLesSocietePossibleRapportVente/",
                    method: "POST",
                    data: {token: this.getToken("token") },
                }).then((res) => {
                })
            }
        }
    }
</script>