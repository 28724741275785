import './installCompositionApi.js'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import vuetify from './plugins/vuetify'
import store from "./store/index"
import dayjs from 'dayjs'


//import tokenControle from './plugins/tokenControle.js'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'

Vue.config.productionTip = false
Vue.use(store);
Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
const MyPlugin = {
  install(Vue, options) {
    Vue.prototype.setToken = (keyName, keyValue, ttl) => {
      const data = {
          value: keyValue,              
          ttl: Date.now() + (ttl * 1000),   
      }
   
      localStorage.setItem(keyName, JSON.stringify(data));
  }
  Vue.prototype.$colors = ["#103A5E", "#1E6CA4", "#FFCB35"];
  Vue.prototype.$dayjs = dayjs;
  Vue.prototype.getToken = (keyName) => {
      const data = localStorage.getItem(keyName);
      if (!data) {
          return null;
      }
      const item = JSON.parse(data);
      if (Date.now() > item.ttl) {
          localStorage.removeItem(keyName);
          return null;
      }
      return item.value;
  }
    Vue.prototype.globalHelper = () => {
      alert("Hello world")
    }
    Vue.prototype.whoIAm = async (token) => {
      var formData = new FormData()
      formData.append('token', token)
      var typeUser = "";
      await axios({
          url:
          process.env.VUE_APP_URL_API_CLIENT +
          'whoIam/',
          method: 'POST',
          data: formData,
      })
      .then((res) => {
        typeUser =  res.data.result
      }).catch((err)=>{
        console.log(err)
        typeUser = "noToken"
      })
      return typeUser
  }
  },
}
Vue.use(MyPlugin)
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
