<template>
    <v-card elevation="5">
        
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cette Autorisaton de collection de connées ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                    >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >Confirmer</v-btn
                >
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- loading -->
        <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
            <v-dialog
                v-model="dialog"
                persistent
                max-width="500"
                >
                <v-card>
                    <v-card-title class="text-h5">
                    Demande de cotation
                    </v-card-title>
                    <v-card-text>
                        <v-alert v-if="error==true && loading==false" type="error">
                            {{messageDialoge}}
                        </v-alert>
                        <v-alert v-if="error==false && loading==false" type="success">
                            {{messageDialoge}}
                        </v-alert>
                        <v-flex  class="text-center">
                            <v-progress-circular v-if="loading==true"
                                :size="150"
                                :width="7"
                                color="primary"
                                indeterminate
                                >
                                Patientez  ...
                            </v-progress-circular>
                        </v-flex>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="loading==false"
                        color="green darken-1"
                        text
                        @click="dialog = false"
                    >
                        FERMER
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
        <!-- first menu -->
        <v-tabs
            v-model="tab"
            background-color="primary"
            dark
            icons-and-text
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            >
            <v-tabs-slider></v-tabs-slider>
    
            <v-tab href="#tab-2">
                Demande De Cotation
                <v-icon>drive_folder_upload</v-icon>
            </v-tab>
            <v-tab href="#tab-1">
                Autorisaton De Collection De Donnees
                <v-icon>task</v-icon>
            </v-tab>
    
        </v-tabs>

        <!-- seconde menu -->
        <v-tabs-items style="border-radius:5px;" v-model="tab">
            <!-- first one -->
            <v-tab-item :value="'tab-1'" >
                <v-card flat>
                    <v-tabs
                        v-model="tab2"
                        background-color="#5d8aba"
                        color="#000000"
                        icons-and-text
                        next-icon="mdi-arrow-right-bold-box-outline"
                        prev-icon="mdi-arrow-left-bold-box-outline"
                        show-arrows
                      >
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#ACD-1" @click="getAllAutorisationDeCollectionDeDonnees">
                            seulement de l'électricité
                            <v-icon>content_paste_search</v-icon>
                        </v-tab>
                        <v-tab href="#ACD-2" @click="getAllAutorisationDeCollectionDeDonnees">
                            seulement du gaz
                            <v-icon>content_paste_search</v-icon>
                        </v-tab>
                        <v-tab href="#ACD-3" @click="getAllAutorisationDeCollectionDeDonnees">
                            les demandes dual
                            <v-icon>content_paste_search</v-icon>
                        </v-tab>
                        
                    </v-tabs>
                    
                </v-card>
                <!-- body for acd -->
                <v-tabs-items v-model="tab2">
                    <v-text-field class="ml-6" color="#000000" width="150" v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                    
                    <v-tab-item :value="'ACD-1'" style="background: #ffffff;">
                        <v-card flat>
                            <v-layout column style="padding-top:0px">

                                <v-data-iterator
                                    :search="search"
                                    :items="acd_ELEC"
                                    item-key="id"
                                    :items-per-page="4"
                                    :single-expand="singleExpand"
                                    
                                  >
                                    <template v-slot:default="{ items, isExpanded, expand }">
                                    
                                    <v-row>
                                        <v-col
                                        v-for="item in items"
                                        :key="item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        >
                                        <v-card>
                                            <v-switch
                                            :input-value="isExpanded(item)"
                                            :label="isExpanded(item) ? '' : ''"
                                            class="pl-4 mt-0"
                                            @change="(v) => expand(item, v)"
                                            ></v-switch>
                                            <v-card-title>
                                            <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                <div>
                                                                <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].siret}}</p>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                <div>
                                                                <p class="text-body-1 grey--text  mb-2">Raison:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].Raison}}</p>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="d-flex flex-column" >
                                                                <div style="max-width: 270px; max-height:90px; overflow: auto;">
                                                                <p class="text-body-1 grey--text  mb-2">Numéro de Compteur ELEC :</p>
                                                                <p class="font-weight-regular " >
                                                                    <span v-for="(compteur,i) in item.numCompteurElec">{{compteur}} | </span>
                                                                </p>
                                                                
                                                                </div>
                                                            </v-col>

                                                            <!-- Bouton Souscrire -->
                                                            <v-col class="d-flex flex-column" cols="12" sm="12" md="2" lg="2" xl="2">
                                                                <v-row class="ml-6 mt-3">
                                                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" >
                                                                        <v-btn color="error" class="mr-4" circle="50%" @click="openDeleteItem(item.id)" >
                                                                            <v-icon>delete_forever</v-icon>Supprimer
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                            </v-card-title>
                                            
                                            <v-divider></v-divider>
                                            <v-list
                                            v-if="isExpanded(item)"
                                            dense
                                            >
                                            <v-list-item>
                                                <v-list-item-content>
                                                     <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3"  justify="start" align="start" align-content="start">
                                                            <div>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">Nom:</span>
                                                                {{societeACD[item.Societe].nom}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">Prenom:</span>
                                                                {{societeACD[item.Societe].prenom}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">AdresseEmail:</span>
                                                                {{societeACD[item.Societe].AdresseEmail}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">numTele:</span>
                                                                {{societeACD[item.Societe].numTele}}
                                                            </p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                            <div>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">AdressePostale:</span>
                                                                    {{societeACD[item.Societe].AdressePostal}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">codeNaf:</span>
                                                                    {{societeACD[item.Societe].codeNaf}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">codeInsee:</span>
                                                                    {{societeACD[item.Societe].codeInsee}}
                                                                </p>
                                                            </div>
                                                        </v-col>
                                                        <!-- Budget -->
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="" justify="start" align="start" align-content="start">
                                                            <div>
                                                                <div style="max-width: 270px; max-height:90px; overflow: auto;">
                                                                    <p class="text-body-1 grey--text  mb-2">Numéro de Compteur ELEC :</p>
                                                                    <p class="font-weight-regular " >
                                                                        <span v-for="(compteur,i) in item.numCompteurElec">{{compteur}} | </span>
                                                                    </p>
                                                                </div>
                                                                <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].siret}}</p>
                                                                <!--<p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Etat:</span>
                                                                    signed
                                                                </p>-->
                                                            </div>
                                                        </v-col>

                                                        <!-- score -->
                                                        <v-col class="d-flex flex-column" cols="12" sm="12" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                            
                                                            <v-card outlined>
                                                                <v-card-title class="justify-start">
                                                                    <v-avatar class="mr-2" size="36" rounded>
                                                                    <img
                                                                        alt="credit safe score"
                                                                        src="../assets/images/logos/logo-credit-safe.png"
                                                                    />
                                                                    </v-avatar>
                                                                    creditsafe
                                                                </v-card-title>

                                                                <v-card-text class=" text-left text-body-1  pr-6"
                                                                    >Score:
                                                                    <span class="ml-2 font-weight-black text-h6">{{
                                                                    societeACD[item.Societe].scoreCreditSafe
                                                                    }}</span>
                                                                    /100
                                                                </v-card-text>
                                                            </v-card>
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/logoElliPro.png"
                                                                        />
                                                                        </v-avatar>
                                                                        Elli Pro
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1 pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">
                                                                        {{societeACD[item.Societe].scoreEllipro}}
                                                                        </span>
                                                                        /10
                                                                    </v-card-text>
                                                                </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                            </v-list>
                                        </v-card>
                                        </v-col>
                                    </v-row>
                                    </template>
                                </v-data-iterator>
                            </v-layout>
                             
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'ACD-2'">
                        <v-card flat>
                            <v-layout column style="padding-top:0px">
                            
                                <v-data-iterator
                                    :search="search"
                                    :items="acd_GAZ"
                                    item-key="id"
                                    :items-per-page="4"
                                    :single-expand="singleExpand"
                                    
                                  >
                                    <template v-slot:default="{ items, isExpanded, expand }">
                                    
                                    <v-row>
                                        <v-col
                                        v-for="item in items"
                                        :key="item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        >
                                        <v-card>
                                            <v-switch
                                            :input-value="isExpanded(item)"
                                            :label="isExpanded(item) ? '' : ''"
                                            class="pl-4 mt-0"
                                            @change="(v) => expand(item, v)"
                                            ></v-switch>
                                            <v-card-title>
                                            <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                <div>
                                                                <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].siret}}</p>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                <div>
                                                                <p class="text-body-1 grey--text  mb-2">Raison:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].Raison}}</p>
                                                                </div>
                                                            </v-col>
                                                            <!-- compteur gaz -->
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="d-flex flex-column" >
                                                                <div style="max-width: 270px; max-height:90px; overflow: auto;">
                                                                <p class="text-body-1 grey--text  mb-2">Numéro de Compteur GAZ :</p>
                                                                <p class="font-weight-regular ">
                                                                    <span v-for="(compteur,i) in item.numCompteurGaz">{{compteur}} | </span>
                                                                </p>
                                                                
                                                                </div>
                                                            </v-col>

                                                            <!-- Bouton Souscrire -->
                                                            <v-col class="d-flex flex-column" cols="12" sm="12" md="2" lg="2" xl="2">
                                                                <v-row class="ml-6 mt-3">
                                                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" >
                                                                        <v-btn color="error" class="mr-4" circle="50%" @click="openDeleteItem(item.id)" >
                                                                            <v-icon>delete_forever</v-icon>Supprimer
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                            </v-card-title>
                                            
                                            <v-divider></v-divider>
                                            <v-list
                                            v-if="isExpanded(item)"
                                            dense
                                            >
                                            <v-list-item>
                                                <v-list-item-content>
                                                    
                                                    <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3"  justify="start" align="start" align-content="start">
                                                            <div>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">Nom:</span>
                                                                {{societeACD[item.Societe].nom}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">Prenom:</span>
                                                                {{societeACD[item.Societe].prenom}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">AdresseEmail:</span>
                                                                {{societeACD[item.Societe].AdresseEmail}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">numTele:</span>
                                                                {{societeACD[item.Societe].numTele}}
                                                            </p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                            <div>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">AdressePostale:</span>
                                                                    {{societeACD[item.Societe].AdressePostal}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">codeNaf:</span>
                                                                    {{societeACD[item.Societe].codeNaf}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">codeInsee:</span>
                                                                    {{societeACD[item.Societe].codeInsee}}
                                                                </p>
                                                            </div>
                                                        </v-col>
                                                        <!-- Budget -->
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="" justify="start" align="start" align-content="start">
                                                            <div>
                                                                <div style="max-width: 270px; max-height:90px; overflow: auto;">
                                                                    <p class="text-body-1 grey--text  mb-2">Numéro de Compteur GAZ :</p>
                                                                    <p class="font-weight-regular " >
                                                                        <span v-for="(compteur,i) in item.numCompteurGaz">{{compteur}} | </span>
                                                                    </p>
                                                                </div>
                                                                <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].siret}}</p>
                                                                <!--<p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Etat:</span>
                                                                    signed
                                                                </p>-->
                                                            </div>
                                                        </v-col>

                                                        <!-- score -->
                                                        <v-col class="d-flex flex-column" cols="12" sm="12" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                            
                                                            <v-card outlined>
                                                                <v-card-title class="justify-start">
                                                                    <v-avatar class="mr-2" size="36" rounded>
                                                                    <img
                                                                        alt="credit safe score"
                                                                        src="../assets/images/logos/logo-credit-safe.png"
                                                                    />
                                                                    </v-avatar>
                                                                    creditsafe
                                                                </v-card-title>

                                                                <v-card-text class=" text-left text-body-1  pr-6"
                                                                    >Score:
                                                                    <span class="ml-2 font-weight-black text-h6">{{
                                                                    societeACD[item.Societe].scoreCreditSafe
                                                                    }}</span>
                                                                    /100
                                                                </v-card-text>
                                                            </v-card>
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/logoElliPro.png"
                                                                        />
                                                                        </v-avatar>
                                                                        Elli Pro
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1 pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">
                                                                        {{societeACD[item.Societe].scoreEllipro}}
                                                                        </span>
                                                                        /10
                                                                    </v-card-text>
                                                                </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                            </v-list>
                                        </v-card>
                                        </v-col>
                                    </v-row>
                                    </template>
                                </v-data-iterator>
                            </v-layout>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'ACD-3'">
                        <v-card flat>
                            <v-layout column style="padding-top:0px">*
                                
                                <v-data-iterator
                                    :search="search"
                                    :items="acd_DUAL"
                                    item-key="id"
                                    :items-per-page="4"
                                    :single-expand="singleExpand"
                                    
                                  >
                                    <template v-slot:default="{ items, isExpanded, expand }">
                                    
                                    <v-row>
                                        <v-col
                                        v-for="item in items"
                                        :key="item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        >
                                        <v-card>
                                            <v-switch
                                            :input-value="isExpanded(item)"
                                            :label="isExpanded(item) ? '' : ''"
                                            class="pl-4 mt-0"
                                            @change="(v) => expand(item, v)"
                                            ></v-switch>
                                            <v-card-title>
                                                <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                            
                                                             <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                <div>
                                                                <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].siret}}</p>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                <div>
                                                                <p class="text-body-1 grey--text  mb-2">Raison:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].Raison}}</p>
                                                                </div>
                                                            </v-col>
                                                            <!-- compteurelec -->
                                                            <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="d-flex flex-column" >
                                                                <div style="max-width: 270px; max-height:90px; overflow: auto;">
                                                                <p class="text-body-1 grey--text  mb-2">Numéro de Compteur ELEC :</p>
                                                                <p class="font-weight-regular " >
                                                                    <span v-for="(compteur,i) in item.numCompteurElec">{{compteur}} | </span>
                                                                </p>
                                                                
                                                                </div>
                                                            </v-col>

                                                            <!-- Bouton Souscrire -->
                                                            <v-col class="d-flex flex-column" cols="12" sm="12" md="2" lg="2" xl="2">
                                                                <v-row class="ml-6 mt-3">
                                                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" >
                                                                        <v-btn color="error" class="mr-4" circle="50%" @click="openDeleteItem(item.id)" >
                                                                            <v-icon>delete_forever</v-icon>Supprimer
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                            </v-card-title>
                                            
                                            <v-divider></v-divider>
                                            <v-list
                                            v-if="isExpanded(item)"
                                            dense
                                            >
                                            <v-list-item>
                                                <v-list-item-content>
                                                     <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3"  justify="start" align="start" align-content="start">
                                                            <div>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">Nom:</span>
                                                                {{societeACD[item.Societe].nom}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">Prenom:</span>
                                                                {{societeACD[item.Societe].prenom}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">AdresseEmail:</span>
                                                                {{societeACD[item.Societe].AdresseEmail}}
                                                            </p>
                                                            <p class="font-weight-regular ">
                                                                <span class="text-body-1 grey--text  mb-2">numTele:</span>
                                                                {{societeACD[item.Societe].numTele}}
                                                            </p>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                            <div>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">AdressePostale:</span>
                                                                    {{societeACD[item.Societe].AdressePostal}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">codeNaf:</span>
                                                                    {{societeACD[item.Societe].codeNaf}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">codeInsee:</span>
                                                                    {{societeACD[item.Societe].codeInsee}}
                                                                </p>
                                                            </div>
                                                        </v-col>
                                                        <!-- Budget -->
                                                        <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="" justify="start" align="start" align-content="start">
                                                            <div>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Activite:</span>
                                                                    {{item.activitePrincipale}}
                                                                </p>
                                                                <div style="max-width: 270px; max-height:90px; overflow: auto;">
                                                                <p class="text-body-1 grey--text  mb-2">Numéro de Compteur GAZ :</p>
                                                                <p class="font-weight-regular " >
                                                                    <span v-for="(compteur,i) in item.numCompteurGaz">{{compteur}} | </span>
                                                                </p>
                                                                </div>
                                                                <div style="max-width: 270px; max-height:90px; overflow: auto;">
                                                                <p class="text-body-1 grey--text  mb-2">Numéro de Compteur ELEC :</p>
                                                                <p class="font-weight-regular " >
                                                                    <span v-for="(compteur,i) in item.numCompteurElec">{{compteur}} | </span>
                                                                </p>
                                                                </div>
                                                                <p class="text-body-1 grey--text  mb-2">Siret:</p>
                                                                <p class="font-weight-regular">{{societeACD[item.Societe].siret}}</p>

                                                                
                                                                <!--<p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Etat:</span>
                                                                    signed
                                                                </p>-->
                                                            </div>
                                                        </v-col>

                                                        <!-- score -->
                                                        <v-col class="d-flex flex-column" cols="12" sm="12" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                            
                                                            <v-card outlined>
                                                                <v-card-title class="justify-start">
                                                                    <v-avatar class="mr-2" size="36" rounded>
                                                                    <img
                                                                        alt="credit safe score"
                                                                        src="../assets/images/logos/logo-credit-safe.png"
                                                                    />
                                                                    </v-avatar>
                                                                    creditsafe
                                                                </v-card-title>

                                                                <v-card-text class=" text-left text-body-1  pr-6"
                                                                    >Score:
                                                                    <span class="ml-2 font-weight-black text-h6">{{
                                                                    societeACD[item.Societe].scoreCreditSafe
                                                                    }}</span>
                                                                    /100
                                                                </v-card-text>
                                                            </v-card>
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/logoElliPro.png"
                                                                        />
                                                                        </v-avatar>
                                                                        Elli Pro
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1 pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">
                                                                        {{societeACD[item.Societe].scoreEllipro}}
                                                                        </span>
                                                                        /10
                                                                    </v-card-text>
                                                                </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                            </v-list>
                                        </v-card>
                                        </v-col>
                                    </v-row>
                                    </template>
                                </v-data-iterator>

                            </v-layout>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-tab-item>
            
            <!-- seconde one -->
            <v-tab-item :value="'tab-2'">
                <v-card flat>
                    <v-tabs
                        v-model="tab3"
                        background-color="#5d8aba"
                        color="#000000"
                        dark
                        icons-and-text
                        next-icon="mdi-arrow-right-bold-box-outline"
                        prev-icon="mdi-arrow-left-bold-box-outline"
                        show-arrows
                      >
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#COTA-1" @click="getAllDemandeDeCotationaTraiter">
                            Cotation à traiter
                            <v-icon>content_paste_search</v-icon>
                        </v-tab>
                
                        <v-tab href="#COTA-2" @click="getAllDemandeDeCotationAccepter">
                            Cotation accepter
                            <v-icon>content_paste_go</v-icon>
                        </v-tab>

                        <v-tab href="#COTA-3" @click="getAllDemandeDeCotationRefuser">
                            Cotation refuser
                            <v-icon>content_paste_off</v-icon>
                        </v-tab>
                    </v-tabs>
                </v-card>
                <!-- body for cotation -->
                <v-tabs-items v-model="tab3">
                    <v-text-field class="ml-6" color="#000000" width="150" v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                    
                    <v-tab-item :value="'COTA-1'" >
                        <!-- body a traiter -->
                        <v-card flat>
                            <v-layout column style="padding-top:0px">

                                <v-data-iterator
                                    :search="search"
                                    :items="demande_de_cotation_traiter"
                                    item-key="id"
                                    :items-per-page="4"
                                    :single-expand="singleExpand"
                                    
                                  >
                                    <template v-slot:default="{ items, isExpanded, expand }">
                                    
                                    <v-row>
                                        <v-col
                                        v-for="item in items"
                                        :key="item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        >
                                        <v-card>
                                            <v-switch
                                            :input-value="isExpanded(item)"
                                            :label="isExpanded(item) ? '' : ''"
                                            class="pl-4 mt-0"
                                            @change="(v) => expand(item, v)"
                                            ></v-switch>
                                            <v-card-title>
                                            <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                                <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                                                                    <div>
                                                                    <p class="text-body-1 grey--text  mb-2">Type:</p>
                                                                    <p class=" font-weight-regular">{{item.typeDemande}}</p>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                                                                    <div>
                                                                    <p class=" font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Siret:</span>
                                                                        <span class="text-body-1 black--text  mb-2">{{societeDemandeDeCotation[item.Societe].siret}}</span>   
                                                                    </p>
                                                                    <p class=" font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Raison:</span>
                                                                        <span class="text-body-1 black--text  mb-2">{{societeDemandeDeCotation[item.Societe].Raison}}</span>   
                                                                    </p>
                                                                    </div>
                                                                </v-col>
                                                                <!-- Budget -->
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="d-flex flex-column" >
                                                                    <div>
                                                                    <p class="text-body-1 grey--text  mb-2">Numéro de Compteur :</p>
                                                                    </div>
                                                                    <div style="max-width: 170px; min-width:170px; max-height:90px; overflow: auto;">
                                                                    <p class=" font-weight-regular ">
                                                                        {{item.numCompteur}}
                                                                    </p>
                                                                    </div>
                                                                </v-col> 
                                                                <!-- Bouton Souscrire -->
                                                                <v-col class="d-flex flex-column" cols="12" sm="4" md="2" lg="2" xl="2">
                                                                    <v-row class="ml-1 mt-3">
                                                                        <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                                                                            <v-btn color="success" class="mr-4" @click="validerDemandeDeCotation(item.id)" circle="50%" >
                                                                                <v-icon>done_outline</v-icon>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" >
                                                                            <v-btn color="error" class="mr-4" @click="refuserDemandeDeCotation(item.id)" circle="50%" >
                                                                                <v-icon>clear</v-icon>
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                        <v-list-item v-if="item.fileUrl1!='' && item.fileUrl1!='undefined' && item.fileUrl1!=null" :href="item.fileUrl1" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 1</span></v-list-item>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                        <v-list-item v-if="item.fileUrl2!=''&& item.fileUrl2!='undefined' && item.fileUrl2!=null" :href="item.fileUrl2" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 2</span></v-list-item>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                       <v-list-item v-if="item.fileUrl3!=''&& item.fileUrl3!='undefined' && item.fileUrl3!=null" :href="item.fileUrl3" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 3</span></v-list-item>
                                                                </v-col>

                                                               
                                                            </v-row>
                                            </v-card-title>
                                            
                                            <v-divider></v-divider>
                                            <v-list
                                            v-if="isExpanded(item)"
                                            dense
                                            >
                                            <v-list-item>
                                                <v-list-item-content>
                                                     <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3"  justify="start" align="start" align-content="start">
                                                                <div>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Envoyeur :</span>
                                                                    {{compteDemandeDeCotation[item.Compte].nom +' '+compteDemandeDeCotation[item.Compte].prenom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Nom client:</span>
                                                                    {{societeDemandeDeCotation[item.Societe].nom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Prénom client:</span>
                                                                    {{societeDemandeDeCotation[item.Societe].prenom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Adresse e-mail :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].AdresseEmail}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Numéro de téléphone :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].numTele}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Poste :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].fonction}}
                                                                </p>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                                <div>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">AdressePostale:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].AdressePostal}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">codeNaf:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].codeNaf}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">codeInsee:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].codeInsee}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Echeance:</span>
                                                                        {{item.Echeance}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">finContrat:</span>
                                                                        {{item.finContrat}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Date de cotation souhaitée:</span>
                                                                        {{item.dateCotationSouhaite}}
                                                                    </p>
                                                                    
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Numéro de compteur:</span>
                                                                        <div style="max-width: 170px; min-width:170px; max-height:90px; overflow: auto;">
                                                                            {{item.numCompteur}}
                                                                        </div>
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Siret:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].siret}}
                                                                    </p>
                                                                </div>
                                                            </v-col>
                                                            <!-- Budget -->
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="" justify="start" align="start" align-content="start">
                                                                <div v-if="item.typeDemande == 'ELEC'">
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Note:</span>
                                                                        {{item.comment}}
                                                                    </p>
                                                                    <!--
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Segment:</span>
                                                                        {{item.Segment}}
                                                                    </p>-->
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HPH:</span>
                                                                        {{item.HPH}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HCH:</span>
                                                                        {{item.HCH}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HPE:</span>
                                                                        {{item.HPE}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HCE:</span>
                                                                        {{item.HCE}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">POINTE:</span>
                                                                        {{item.POINTE}}
                                                                    </p>
                                                                    
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HP:</span>
                                                                        {{item.HP}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HC:</span>
                                                                        {{item.HC}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">BASE:</span>
                                                                        {{item.BASE}}
                                                                    </p>

                                                                </div>
                                                                <div v-if="item.typeDemande == 'GAZ'">
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Note:</span>
                                                                        {{item.comment}}
                                                                    </p>
                                                                    <!--
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">tarif:</span>
                                                                        {{item.tarif}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">profile:</span>
                                                                        {{item.profile}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">consomation:</span>
                                                                        {{item.consomation}}
                                                                    </p>-->
                                                                </div>
                                                            </v-col>

                                                            <!-- Bouton Souscrire -->
                                                            <v-col class="d-flex flex-column" cols="12" sm="12" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                                
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/images/logos/logo-credit-safe.png"
                                                                        />
                                                                        </v-avatar>
                                                                        creditsafe
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1  pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">{{
                                                                        societeDemandeDeCotation[item.Societe].scoreCreditSafe
                                                                        }}</span>
                                                                        /100
                                                                    </v-card-text>
                                                                </v-card>
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/logoElliPro.png"
                                                                        />
                                                                        </v-avatar>
                                                                        Elli Pro
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1 pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">
                                                                        {{societeDemandeDeCotation[item.Societe].scoreEllipro}}
                                                                        </span>
                                                                        /10
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                            </v-list>
                                        </v-card>
                                        </v-col>
                                    </v-row>
                                    </template>
                                </v-data-iterator>
                            </v-layout>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'COTA-2'">
                        <!-- body a accepter -->
                        <v-card flat>
                            <v-layout column style="padding-top:0px">

                                <v-data-iterator
                                    :search="search"
                                    :items="demande_de_cotation_accepter"
                                    item-key="id"
                                    :items-per-page="4"
                                    :single-expand="singleExpand"
                                    
                                  >
                                    <template v-slot:default="{ items, isExpanded, expand }">
                                    
                                    <v-row>
                                        <v-col
                                        v-for="item in items"
                                        :key="item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        >
                                        <v-card>
                                            <v-switch
                                            :input-value="isExpanded(item)"
                                            :label="isExpanded(item) ? '' : ''"
                                            class="pl-4 mt-0"
                                            @change="(v) => expand(item, v)"
                                            ></v-switch>
                                            <v-card-title>
                                            <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                                <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                                                                    <div>
                                                                    <p class="text-body-1 grey--text  mb-2">Type:</p>
                                                                    <p class=" font-weight-regular">{{item.typeDemande}}</p>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                                                                    <div>
                                                                    <p class=" font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Siret:</span>
                                                                        <span class="text-body-1 black--text  mb-2">{{societeDemandeDeCotation[item.Societe].siret}}</span>   
                                                                    </p>
                                                                    <p class=" font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Raison sociale :</span>
                                                                        <span class="text-body-1 black--text  mb-2">{{societeDemandeDeCotation[item.Societe].Raison}}</span>   
                                                                    </p>
                                                                    </div>
                                                                </v-col>
                                                                <!-- Budget -->
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="d-flex flex-column" >
                                                                    <div>
                                                                    <p class="text-body-1 grey--text  mb-2">Numéro de Compteur :</p>
                                                                    </div>
                                                                    <div style="max-width: 170px; min-width:170px; max-height:90px; overflow: auto;">
                                                                    <p class=" font-weight-regular ">
                                                                        {{item.numCompteur}}
                                                                    </p>
                                                                    </div>
                                                                </v-col> 
                                                                <!-- Bouton Souscrire -->
                                                                <v-col class="d-flex flex-column" cols="12" sm="4" md="2" lg="2" xl="2">
                                                                    <v-row class="ml-1 mt-3">
                                                                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" >
                                                                            <v-btn color="error" class="mr-4" @click="refuserDemandeDeCotation(item.id)" circle="50%" >
                                                                                <v-icon>clear</v-icon>
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                        <v-list-item v-if="item.fileUrl1!='' && item.fileUrl1!='undefined' && item.fileUrl1!=null" :href="item.fileUrl1" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 1</span></v-list-item>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                        <v-list-item v-if="item.fileUrl2!=''&& item.fileUrl2!='undefined' && item.fileUrl2!=null" :href="item.fileUrl2" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 2</span></v-list-item>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                       <v-list-item v-if="item.fileUrl3!=''&& item.fileUrl3!='undefined' && item.fileUrl3!=null" :href="item.fileUrl3" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 3</span></v-list-item>
                                                                </v-col>

                                                               
                                                            </v-row>
                                            </v-card-title>
                                            
                                            <v-divider></v-divider>
                                            <v-list
                                            v-if="isExpanded(item)"
                                            dense
                                            >
                                            <v-list-item>
                                                <v-list-item-content>
                                                     <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3"  justify="start" align="start" align-content="start">
                                                                <div>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Envoyeur:</span>
                                                                    {{compteDemandeDeCotation[item.Compte].nom +' '+compteDemandeDeCotation[item.Compte].prenom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Nom client:</span>
                                                                    {{societeDemandeDeCotation[item.Societe].nom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Prénom client:</span>
                                                                    {{societeDemandeDeCotation[item.Societe].prenom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Adresse e-mail :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].AdresseEmail}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Numéro de téléphone :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].numTele}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Poste :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].fonction}}
                                                                </p>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                                <div>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">AdressePostale:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].AdressePostal}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">codeNaf:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].codeNaf}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">codeInsee:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].codeInsee}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Echeance:</span>
                                                                        {{item.Echeance}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">finContrat:</span>
                                                                        {{item.finContrat}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Date de cotation souhaitée:</span>
                                                                        {{item.dateCotationSouhaite}}
                                                                    </p>
                                                                    
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Numéro de compteur:</span>
                                                                        <div style="max-width: 170px; min-width:170px; max-height:90px; overflow: auto;">
                                                                            {{item.numCompteur}}
                                                                        </div>
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Siret:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].siret}}
                                                                    </p>
                                                                </div>
                                                            </v-col>
                                                            <!-- Budget -->
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="" justify="start" align="start" align-content="start">
                                                                <div v-if="item.typeDemande == 'ELEC'">
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Note:</span>
                                                                        {{item.comment}}
                                                                    </p>
                                                                    <!--
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Segment:</span>
                                                                        {{item.Segment}}
                                                                    </p>-->
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HPH:</span>
                                                                        {{item.HPH}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HCH:</span>
                                                                        {{item.HCH}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HPE:</span>
                                                                        {{item.HPE}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HCE:</span>
                                                                        {{item.HCE}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">POINTE:</span>
                                                                        {{item.POINTE}}
                                                                    </p>
                                                                    
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HP:</span>
                                                                        {{item.HP}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HC:</span>
                                                                        {{item.HC}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">BASE:</span>
                                                                        {{item.BASE}}
                                                                    </p>

                                                                </div>
                                                                <div v-if="item.typeDemande == 'GAZ'">
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Note:</span>
                                                                        {{item.comment}}
                                                                    </p>
                                                                    <!--
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">tarif:</span>
                                                                        {{item.tarif}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">profile:</span>
                                                                        {{item.profile}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">consomation:</span>
                                                                        {{item.consomation}}
                                                                    </p>-->
                                                                </div>
                                                            </v-col>

                                                            <!-- Bouton Souscrire -->
                                                            <v-col class="d-flex flex-column" cols="12" sm="12" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                                
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/images/logos/logo-credit-safe.png"
                                                                        />
                                                                        </v-avatar>
                                                                        creditsafe
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1  pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">{{
                                                                        societeDemandeDeCotation[item.Societe].scoreCreditSafe
                                                                        }}</span>
                                                                        /100
                                                                    </v-card-text>
                                                                </v-card>
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/logoElliPro.png"
                                                                        />
                                                                        </v-avatar>
                                                                        Elli Pro
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1 pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">
                                                                        {{societeDemandeDeCotation[item.Societe].scoreEllipro}}
                                                                        </span>
                                                                        /10
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                            </v-list>
                                        </v-card>
                                        </v-col>
                                    </v-row>
                                    </template>
                                </v-data-iterator>
                            </v-layout>
                        </v-card>
                    </v-tab-item>
                    
                    <v-tab-item :value="'COTA-3'">
                        <!-- body a refuser -->
                        <v-card flat>
                            <v-layout column style="padding-top:0px">

                                <v-data-iterator
                                    :search="search"
                                    :items="demande_de_cotation_refuser"
                                    item-key="id"
                                    :items-per-page="4"
                                    :single-expand="singleExpand"
                                    
                                  >
                                    <template v-slot:default="{ items, isExpanded, expand }">
                                    
                                    <v-row>
                                        <v-col
                                        v-for="item in items"
                                        :key="item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        >
                                        <v-card>
                                            <v-switch
                                            :input-value="isExpanded(item)"
                                            :label="isExpanded(item) ? '' : ''"
                                            class="pl-4 mt-0"
                                            @change="(v) => expand(item, v)"
                                            ></v-switch>
                                            <v-card-title>
                                            <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                                <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                                                                    <div>
                                                                    <p class="text-body-1 grey--text  mb-2">Type:</p>
                                                                    <p class=" font-weight-regular">{{item.typeDemande}}</p>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                                                                    <div>
                                                                    <p class=" font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Siret:</span>
                                                                        <span class="text-body-1 black--text  mb-2">{{societeDemandeDeCotation[item.Societe].siret}}</span>   
                                                                    </p>
                                                                    <p class=" font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Raison:</span>
                                                                        <span class="text-body-1 black--text  mb-2">{{societeDemandeDeCotation[item.Societe].Raison}}</span>   
                                                                    </p>
                                                                    </div>
                                                                </v-col>
                                                                <!-- Budget -->
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="d-flex flex-column" >
                                                                    <div>
                                                                    <p class="text-body-1 grey--text  mb-2">Compteur:</p>
                                                                    </div>
                                                                    <div style="max-width: 170px; min-width:170px; max-height:90px; overflow: auto;">
                                                                    <p class=" font-weight-regular ">
                                                                        {{item.numCompteur}}
                                                                    </p>
                                                                    </div>
                                                                </v-col> 
                                                                <!-- Bouton Souscrire -->
                                                                <v-col class="d-flex flex-column" cols="12" sm="4" md="2" lg="2" xl="2">
                                                                    <v-row class="ml-1 mt-3">
                                                                        <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                                                                            <v-btn color="success" class="mr-4" @click="validerDemandeDeCotation(item.id)" circle="50%" >
                                                                                <v-icon>done_outline</v-icon>
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                        <v-list-item v-if="item.fileUrl1!='' && item.fileUrl1!='undefined' && item.fileUrl1!=null" :href="item.fileUrl1" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 1</span></v-list-item>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                        <v-list-item v-if="item.fileUrl2!=''&& item.fileUrl2!='undefined' && item.fileUrl2!=null" :href="item.fileUrl2" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 2</span></v-list-item>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                                                                       <v-list-item v-if="item.fileUrl3!=''&& item.fileUrl3!='undefined' && item.fileUrl3!=null" :href="item.fileUrl3" target="_blank"><span class="text-body-4 blue--text">Pièce jointe 3</span></v-list-item>
                                                                </v-col>

                                                               
                                                            </v-row>
                                            </v-card-title>
                                            
                                            <v-divider></v-divider>
                                            <v-list
                                            v-if="isExpanded(item)"
                                            dense
                                            >
                                            <v-list-item>
                                                <v-list-item-content>
                                                     <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3"  justify="start" align="start" align-content="start">
                                                                <div>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Envoyeur:</span>
                                                                    {{compteDemandeDeCotation[item.Compte].nom +' '+compteDemandeDeCotation[item.Compte].prenom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Nom client:</span>
                                                                    {{societeDemandeDeCotation[item.Societe].nom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Prenom client:</span>
                                                                    {{societeDemandeDeCotation[item.Societe].prenom}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Adresse e-mail :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].AdresseEmail}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Numéro de téléphone :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].numTele}}
                                                                </p>
                                                                <p class="font-weight-regular ">
                                                                    <span class="text-body-1 grey--text  mb-2">Poste :</span>
                                                                    {{societeDemandeDeCotation[item.Societe].fonction}}
                                                                </p>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                                <div>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">AdressePostale:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].AdressePostal}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">codeNaf:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].codeNaf}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">codeInsee:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].codeInsee}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Echeance:</span>
                                                                        {{item.Echeance}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">finContrat:</span>
                                                                        {{item.finContrat}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Date de cotation souhaitée:</span>
                                                                        {{item.dateCotationSouhaite}}
                                                                    </p>
                                                                    
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Numéro de compteur:</span>
                                                                        <div style="max-width: 170px; min-width:170px; max-height:90px; overflow: auto;">
                                                                            {{item.numCompteur}}
                                                                        </div>
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Siret:</span>
                                                                        {{societeDemandeDeCotation[item.Societe].siret}}
                                                                    </p>
                                                                </div>
                                                            </v-col>
                                                            <!-- Budget -->
                                                            <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="" justify="start" align="start" align-content="start">
                                                                <div v-if="item.typeDemande == 'ELEC'">
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Note:</span>
                                                                        {{item.comment}}
                                                                    </p>
                                                                    <!--
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Segment:</span>
                                                                        {{item.Segment}}
                                                                    </p>-->
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HPH:</span>
                                                                        {{item.HPH}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HCH:</span>
                                                                        {{item.HCH}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HPE:</span>
                                                                        {{item.HPE}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2' || item.Segment== 'C4'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HCE:</span>
                                                                        {{item.HCE}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C3' || item.Segment== 'C2'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">POINTE:</span>
                                                                        {{item.POINTE}}
                                                                    </p>
                                                                    
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HP:</span>
                                                                        {{item.HP}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">HC:</span>
                                                                        {{item.HC}}
                                                                    </p>
                                                                    <p v-if="item.Segment== 'C5'" class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">BASE:</span>
                                                                        {{item.BASE}}
                                                                    </p>

                                                                </div>
                                                                <div v-if="item.typeDemande == 'GAZ'">
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">Note:</span>
                                                                        {{item.comment}}
                                                                    </p>
                                                                    <!--
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">tarif:</span>
                                                                        {{item.tarif}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">profile:</span>
                                                                        {{item.profile}}
                                                                    </p>
                                                                    <p class="font-weight-regular ">
                                                                        <span class="text-body-1 grey--text  mb-2">consomation:</span>
                                                                        {{item.consomation}}
                                                                    </p>-->
                                                                </div>
                                                            </v-col>

                                                            <!-- Bouton Souscrire -->
                                                            <v-col class="d-flex flex-column" cols="12" sm="12" md="3" lg="3" xl="3" justify="start" align="start" align-content="start">
                                                                
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/images/logos/logo-credit-safe.png"
                                                                        />
                                                                        </v-avatar>
                                                                        creditsafe
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1  pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">{{
                                                                        societeDemandeDeCotation[item.Societe].scoreCreditSafe
                                                                        }}</span>
                                                                        /100
                                                                    </v-card-text>
                                                                </v-card>
                                                                <v-card outlined>
                                                                    <v-card-title class="justify-start">
                                                                        <v-avatar class="mr-2" size="36" rounded>
                                                                        <img
                                                                            alt="credit safe score"
                                                                            src="../assets/logoElliPro.png"
                                                                        />
                                                                        </v-avatar>
                                                                        Elli Pro
                                                                    </v-card-title>

                                                                    <v-card-text class=" text-left text-body-1 pr-6"
                                                                        >Score:
                                                                        <span class="ml-2 font-weight-black">
                                                                        {{societeDemandeDeCotation[item.Societe].scoreEllipro}}
                                                                        </span>
                                                                        /10
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                            </v-list>
                                        </v-card>
                                        </v-col>
                                    </v-row>
                                    </template>
                                </v-data-iterator>
                            </v-layout>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-tab-item>
        </v-tabs-items>
        
      
      
    </v-card>
</template>
<script>
    import axios from 'axios'
    export default {
    name: 'DemandeDeCotation',
        data() {
            return {
                singleExpand: false,
                panel: [],
                idItemToDelete: null,
                dialogDelete: false,
                //data for dialog
                dialog: false,
                error: false,
                loading: false,
                messageDialoge: "L'e-mail a été envoyé avec succès",
                search: '',
                tab: null,
                tab2: null,
                tab3: null,
                headers: [
                    { text: '', value: 'numCompteur', disabled: true,sortable: false, align: 'center', width: "100%"},
                    { text: '', value: 'Raison', sortable: false, align: 'center', width: "0"},
                    { text: '', value: 'siret', sortable: false, align: 'center', width: "0"},
                ],
                
                headersElec: [
                    { text: '', value: 'numCompteurElec', disabled: true,sortable: false, align: 'center', width: "100%"},
                    { text: '', value: 'Raison', sortable: false, align: 'center', width: "0"},
                    { text: '', value: 'siret', sortable: false, align: 'center', width: "0"},
                ],
                headersGaz: [
                    { text: '', value: 'numCompteurGaz', disabled: true,sortable: false, align: 'center', width: "100%"},
                    { text: '', value: 'Raison', sortable: false, align: 'center', width: "0"},
                    { text: '', value: 'siret', sortable: false, align: 'center', width: "0"},
                ],
                headersDual: [
                    { text: '', value: 'numCompteurGaz', disabled: true,sortable: false, align: 'center', width: "100%"},
                    { text: '', value: 'numCompteurElec', disabled: false,sortable: false, align: 'center', width: "0"},
                    { text: '', value: 'Raison', sortable: false, align: 'center', width: "0"},
                    { text: '', value: 'siret', sortable: false, align: 'center', width: "0"},
                ],
                societeDemandeDeCotation: [],
                compteDemandeDeCotation: [],
                demande_de_cotation_traiter: [],
                demande_de_cotation_accepter: [],
                demande_de_cotation_refuser: [],

                
                societeACD: [],
                compteACD: [],
                acd_GAZ: [],
                acd_ELEC: [],
                acd_DUAL: [],
            }
        },
        created() {
            //this.verifyApplicationDataSecurity()
            this.getAllDemandeDeCotation()
            this.getAllAutorisationDeCollectionDeDonnees()
        },
        methods: {
            getAllDemandeDeCotationaTraiter(){
                this.dialog = true
                this.loading=true
                this.error = false
                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllDemandeDeCotationaTraiter/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if (res.data.result==true){
                        
                        self.societeDemandeDeCotation = res.data.societes
                        self.compteDemandeDeCotation =res.data.comptes
                        try {
                            self.demande_de_cotation_traiter = res.data.demandeEnAttend
                        } catch {
                            self.demande_de_cotation_traiter = []
                        }
                        this.dialog = false
                        this.loading=false
                        this.error = false
                    }else if(res.data.result=='permission'){
                        alert('permission')
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.dialog = false
                    this.loading=false
                    this.error = false
                })
            },
            getAllDemandeDeCotationRefuser(){
                this.dialog = true
                this.loading=true
                this.error = false
                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllDemandeDeCotationRefuser/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if (res.data.result==true){
                        
                        self.societeDemandeDeCotation = res.data.societes
                        self.compteDemandeDeCotation =res.data.comptes
                        try {
                            self.demande_de_cotation_refuser = res.data.demandeRefus
                        } catch {
                            self.demande_de_cotation_refuser = []
                        }
                        this.dialog = false
                        this.loading=false
                        this.error = false
                    }else if(res.data.result=='permission'){
                        alert('permission')
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.dialog = false
                    this.loading=false
                    this.error = false
                })
            },
            getAllDemandeDeCotationAccepter(){
                this.dialog = true
                this.loading=true
                this.error = false
                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllDemandeDeCotationAccepter/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if (res.data.result==true){
                        
                        self.societeDemandeDeCotation = res.data.societes
                        self.compteDemandeDeCotation =res.data.comptes
                        try {
                            self.demande_de_cotation_accepter = res.data.demandeValider
                        } catch {
                            self.demande_de_cotation_accepter = []
                        }
                        this.dialog = false
                        this.loading=false
                        this.error = false
                    }else if(res.data.result=='permission'){
                        alert('permission')
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.dialog = false
                    this.loading=false
                    this.error = false
                })
            },
            getAllDemandeDeCotation() {
                
                this.dialog = true
                this.loading=true
                this.error = false
                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllDemandeDeCotation/',
                    method: 'POST',
                    data: formData,
                })
                    .then((res) => {
                        if (res.data.result==true){
                            
                            self.societeDemandeDeCotation = res.data.societes
                            self.compteDemandeDeCotation =res.data.comptes
                            try {
                                self.demande_de_cotation_traiter = res.data.demandeEnAttend
                            } catch {
                                self.demande_de_cotation_traiter = []
                            }
                            try {
                                self.demande_de_cotation_accepter = res.data.demandeValider
                            } catch {
                                self.demande_de_cotation_accepter = []
                            }
                            try {
                                self.demande_de_cotation_refuser = res.data.demandeRefus
                            } catch {
                                self.demande_de_cotation_refuser = []
                            }
                            
                            this.dialog = false
                            this.loading=false
                            this.error = false
                        }else if(res.data.result=='permission'){
                            alert('permission')
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.dialog = false
                        this.loading=false
                        this.error = false
                    })
            },
            validerDemandeDeCotation(id){
                this.dialog = true
                this.loading=true
                this.error = false
                let self = this
                var formData = new FormData()
                formData.append('id', id)
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'validerDemandeDeCotation/',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                    if (res.data.result === 'permission') {
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    } else if(res.data.result == true){
                        this.getAllDemandeDeCotation()
                    }else if(res.data.result == false){
                        this.getAllDemandeDeCotation()
                        alert("Cette demande de cotation n'existe pas !")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            },
            refuserDemandeDeCotation(id){
                this.dialog = true
                this.loading=true
                this.error = false
                let self = this
                var formData = new FormData()
                formData.append('id', id)
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'refuserDemandeDeCotation/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if (res.data.result === 'permission') {
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    } else if(res.data.result == true){
                        this.getAllDemandeDeCotation()
                    }else if(res.data.result == false){
                        this.getAllDemandeDeCotation()
                        alert("Cette demande de cotation n'existe pas !")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

            },
            getAllAutorisationDeCollectionDeDonnees() {
                this.dialog = true
                this.loading=true
                this.error = false
                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllAutorisationDeCollectionDeDonne/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result == true){
                        self.societeACD = res.data.societes
                        self.compteACD = res.data.comptes
                        self.acd_ELEC = res.data.demandeElec
                        self.acd_GAZ = res.data.demandeGaz
                        self.acd_DUAL = res.data.demandeDual
                    }else if(res.data.result == false){
                        alert("Il semble qu'une erreur soit survenue ! Veuillez réessayer")

                    }else if(res.data.result == 'permission'){
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    this.dialog = false
                    this.loading=false
                    this.error = false
                })
                .catch((err) => {
                    this.dialog = false
                    this.loading=false
                    this.error = false
                    console.log(err)
                })
            },
            deleteItemConfirm(){
                
                var formData = new FormData()
                formData.append('id', this.idItemToDelete)
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'supprimerAutorisationDeCollectionDeDonne/',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                    if (res.data.result === 'permission') {
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    } else if(res.data.result == true){
                        this.getAllAutorisationDeCollectionDeDonnees()
                        this.dialogDelete = false
                    }else if(res.data.result == false){
                        this.getAllAutorisationDeCollectionDeDonnees()
                       alert("Cette demande de cotation n'existe pas !")
                    }
                })
                .catch((err) => {
                    this.dialogDelete = false;
                })
            },
            closeDelete(){
                this.dialogDelete = false
            },
            openDeleteItem(id){
                this.idItemToDelete = id
                this.dialogDelete = true
            },
        }
    }
</script>
<style scoped>
.v-text-field{
      width: 400px;
}
</style>