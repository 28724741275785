<style>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}
.one-line {
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>

<template>
    <div>
        <div class="tableFilters">
            <div class="ml-4 mt-3 mb-3">
                <div class="row">
                    <div class="col-6">
                        <slot name="header"></slot>
                    </div>
                </div>
            </div> 
        </div>
        <table class="table table-striped table-bordered" style="display: block; width:100%; overflow-x:auto;">
            <thead>
                <tr>
                    <th style="text-align: left;" v-for="column in columns" :key="column.name" v-if="column.sortable" 
                        :class="sortKey == column.name ? (sortOrders[column.name]>0 ? 'sorting_asc': 'sorting_desc'):'sorting'"
                        :style="'width:'+column.width+';'+'cursor:pointer;'">
                            <v-card-title   @click="openDropDown(column.name)">
                                <p class="one-line">{{column.label}}<v-icon color="teal" class="a_link" :left="true">mdi-sort-reverse-variant</v-icon></p>
                            </v-card-title>
                            <div class="dropdown">
                                <div class="dropdown-content" :id="'dropDown'+column.name">
                                    <div class="item"><p  @click="$emit('sort', column.name)">Sort</p></div>
                                    <div style="max-width:200px;">
                                        <v-autocomplete 
                                                v-model="filterItem[column.name]" 
                                                :items="items[column.name]"
                                                hide-no-data 
                                                label="" 
                                                multiple
                                                :menu-props="{closeOnContentClick:closeEnClick}"
                                            >
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                <v-checkbox v-model="attrs.inputValue" :indeterminate="item.indeterminate"></v-checkbox>
                                                <v-chip label > {{ item }} </v-chip>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }"  style="width:100px;">
                                                
                                            </template>
                                            <template v-slot:prepend-item >
                                                <v-list-item ripple @click="selectTout(column.name)" >
                                                    <v-list-item-content>
                                                        <v-list-item-title>Select All</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                    <div><v-btn @click="$emit('filter', {'type':'filter', 'items':filterItem})">ok</v-btn></div>
                                </div>
                                
                            </div>
                        
                    </th>
                    <th style="text-align: center;" :key="column.name" v-else
                        :class="sortKey == column.name ? (sortOrders[column.name]>0 ? 'sorting_asc': 'sorting_desc'):'sorting'"
                        :style="'width:'+column.width+';'+'cursor:pointer;'">
                        {{column.label}}
                    </th>
                </tr>
            </thead>
            <slot name="body"></slot>
        </table>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import $ from 'jquery'
    import jQuery from 'jquery'
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    export default{
        props: ['columns', 'sortKey', 'sortOrders', 'items'],
        data(){
            return{
                isOpenMyDropdown: false,
                closeEnClick: false,
                dialog: false,
                value: "",
                search:"",
                filterItem: {
                    nom: [],
                    raison: [],
                    fournisseur: [],
                    type: [],
                    numCompteur: [],
                    Consommation: [],
                    Duree: [],
                    marge: [],
                    valeurBrut: [],
                    Pourcentage: [],
                    valeurAA: [],
                    valeurGEMC: [],
                    status: [],
                },
                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
            }
        },
        watch: {
        },
        created() {
            this.selectToutInit()
        },
        methods:{ 
            openDropDown(name){
                for(var i=0; i<this.columns.length; i++){
                    if(this.columns[i].name != name){
                        $("#dropDown"+this.columns[i].name).css("display", "none");
                    }
                }
                var display = $("#dropDown"+name).css("display")
                if(display == 'none'){
                    $("#dropDown"+name).css("display", "block");
                }else{
                    $("#dropDown"+name).css("display", "none");
                }
            },
            selectToutInit(){
                this.filterItem = {... this.items}
            },
            selectTout(value){
                if(this.filterItem[value].length==this.items[value].length){
                    this.filterItem[value] = []
                    this.closeEnClick = false
                }else{
                    this.closeEnClick = false
                    this.filterItem[value] = []
                    for (var i=0; i<this.items[value].length; i++){
                        this.filterItem[value].push(this.items[value][i]);
                    }
                }
            },
        }
    }
</script>
<style scoped>
@media only screen and (max-width:350px){
    .search-input{
        width:125px !important;
    }
}
</style>