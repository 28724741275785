<template>
    <v-container
        fluid>
        <!-- modal supprimer -->
        <v-dialog v-model="ouvrireDialogeSupprimer" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cette vente ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="fermerDialogeSupprimerVente"
                    >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="supprimerVente"
                    >Confirmer</v-btn
                >
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- modal refuser -->
        <v-dialog v-model="ouvrireDialogeRefuser" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir refuser cette vente ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12" sm="12" >
                            <v-textarea
                                filled
                                :rules="[rules.required('une commentaire.')]"
                                auto-grow
                                label="Commentaire"
                                rows="2"
                                row-height="20"
                                v-model="commentRefuser"
                                >
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" >
                            <v-btn color="blue darken-1" text @click="fermerDialogeRefuserVente"
                                >Annuler</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="refuserVente"
                                >Confirmer</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-form>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- modale loading-->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500"
            >
            <v-card>
                <v-card-title class="text-h5">
                Controle des ventes pro
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="error==true && loading==false" type="error">
                        {{messageDialoge}}
                    </v-alert>
                    <v-alert v-if="error==false && loading==false" type="success">
                        {{messageDialoge}}
                    </v-alert>
                    <v-flex  class="text-center">
                        <v-progress-circular v-if="loading==true"
                            :size="150"
                            :width="7"
                            color="primary"
                            indeterminate
                            >
                            Patientez  ...
                        </v-progress-circular>
                    </v-flex>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="loading==false"
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    FERMER
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- body -->
        <v-card class="mt-3" elevation="12">
            <template>
                <v-toolbar dark color="primary" fixed>
                    <v-toolbar-title class="white--text">Controle Ventes Pro</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                </v-toolbar>
                <v-layout column style="padding-top:0px">

                    <v-data-iterator
                        :search="search"
                        :items="mesVentePro.ventes"
                        item-key="id"
                        :items-per-page="4"
                        :single-expand="singleExpand"
                        
                        >
                        <template v-slot:default="{ items, isExpanded, expand }">
                        
                        <v-row>
                            <v-col
                            v-for="item in items"
                            :key="item.id"
                            cols="12"
                            sm="12"
                            md="12"
                            lg="12"
                            >
                            <v-card>
                                <!--<v-switch
                                :input-value="isExpanded(item)"
                                :label="isExpanded(item) ? 'ferme' : 'ouvre'"
                                class="pl-4 mt-0"
                                @change="(v) => expand(item, v)"
                                ></v-switch>
                                <v-card-title>
                                </v-card-title>
                                -->
                                <v-divider></v-divider>
                                <v-list
                                dense
                                >
                                
                                <v-list-item>
                                    <v-list-item-content>
                                    <v-row>
                                        <v-col cols="12" sm="2" md="1" lg="1" xl='1'>
                                            <div style="max-height:80px; overflow: auto;">
                                                <p>{{dateFormatSlach(item.date)}}</p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="1" lg="1" xl='1'>
                                            <div style="max-height:80px; overflow: auto;">
                                                <p>{{item.type}}</p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3" lg="2" xl='2'>
                                            <div style="max-height:130px; overflow: auto;">
                                                <p><span class="text-body-1 grey--text  mb-2">Raison:</span>{{mesVentePro.societes[item.Societe].Raison}}</p>
                                                <p><span class="text-body-1 grey--text  mb-2">Vendeur:</span>{{mesVentePro.comptes[item.Compte].nom+' '+mesVentePro.comptes[item.Compte].prenom }}</p>
                                                
                                                <p><span class="text-body-1 grey--text  mb-2">Numéro de compteur: </span>{{item.numCompteur}}</p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="2" lg="1" xl='1'>
                                                <span class="text-body-1 grey--text  mb-2">Fournisseur: </span>
                                                <p>{{item.fournisseur}}</p>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="1" lg="1" xl='1'>
                                            <span class="text-body-1 grey--text  mb-2">Valeur: </span>
                                            <p>{{item.valeur+" €"}}</p>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="2" lg="2" xl='2'>
                                            <span class="text-body-1 grey--text  mb-2">Commentaire:</span>
                                            <p>{{item.commentaire}}</p>
                                            <hr/>
                                            <div v-if="item.status=='Refuser' && item.commentaireRefus!= ''" style="max-height:120px; overflow: auto;" class="mt-2">
                                                <span class="text-body-3 orange--text  mb-2">Commentaire refus</span>
                                                <p>{{item.commentaire_refus}}</p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="1" lg="1" xl='1'>
                                            <span class="text-body-1 grey--text  mb-2">Status: </span>
                                            <div>
                                                <p v-if="item.status == 'Refuser'" style="color: red;"><b>{{item.status}}</b></p>
                                                <p v-if="item.status == 'Accepter'" style="color: green;"><b>{{item.status}}</b></p>
                                                <p v-if="item.status == 'en Attend'" style="color: gray;"><b>{{item.status}}</b></p>
                                                <p v-if="item.status == 'en attente de validation'" style="color: blue;"><b>Phase de validation</b></p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="2" lg="1" xl='1'>
                                            <div style="max-height:120px; overflow: auto;">
                                                <div class="mb-2">
                                                    <div v-if="item.Contrat != null">
                                                        <a style="text-decoration:none" :href="item.Contrat" target="_blank">
                                                            <span class="text-body-2 blue--text mb-2">Contrat</span>
                                                        </a>
                                                    </div>
                                                </div>

                                                <div class="mb-2">
                                                    <div v-if="item.RIB != null">
                                                        <a style="text-decoration:none" :href="item.RIB" target="_blank">
                                                            <span class="text-body-2 blue--text mb-2">RIB</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="mb-2">
                                                    <div v-if="item.Factures != null">
                                                        <a style="text-decoration:none" :href="item.Factures" target="_blank">
                                                            <span class="text-body-2 blue--text mb-2">Factures</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                
                                                <div class="mb-2">
                                                    <div v-if="item.SEPA != null">
                                                        <a style="text-decoration:none" :href="item.SEPA" target="_blank">
                                                            <span class="text-body-2 blue--text mb-2">SEPA</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                
                                                <div class="mb-2">
                                                    <div v-if="item.ACD != null">
                                                        <a style="text-decoration:none" :href="item.ACD" target="_blank">
                                                            <span class="text-body-2 blue--text mb-2">ACD</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="2" lg="2" xl='2'>
                                            <div style="max-width: 120px; min-width:120px; min-height:90px; max-height:100px;" class="pt-4">
                                                <v-row
                                                    align="center"
                                                    justify="space-around"
                                                >
                                                    <v-btn
                                                    @click="envoyerPourValider(item)"
                                                    v-if="item.status != 'en attente de validation' && item.status!='Accepter' && item.status!='Refuser'"
                                                    class="mt-1"
                                                    x-small
                                                    color="primary"
                                                    dark>Envoyer pour valider</v-btn>
                                                    <v-btn
                                                    <v-btn
                                                    @click="accepterVente(item)"
                                                    v-if="item.status!='Accepter'"
                                                    class="mt-1"
                                                    x-small
                                                    color="success"
                                                    dark>Valider</v-btn>
                                                    <v-btn
                                                    @click="ouvrireDialogeRefuserVente(item)"
                                                    v-if="item.status!='Refuser'"
                                                    class="mt-1"
                                                    x-small
                                                    color="warning"
                                                    dark>Refuser</v-btn>
                                                    <v-btn
                                                    @click="ouvrireDialogeSupprimerVente(item)"
                                                    class="mt-1"
                                                    x-small
                                                    color="error"
                                                    dark>Supprimer</v-btn>
                                                </v-row>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                </v-list>
                            </v-card>
                            </v-col>
                        </v-row>
                        </template>
                    </v-data-iterator>
                </v-layout>
            </template>
        </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'VentePro',
        components: {
        },
        data: () => ({
            singleExpand: false,
            dialog: false,
            loading: false,
            error: false,
            messageDialoge: '',
            ouvrireDialogeRefuser: false,
            ouvrireDialogeSupprimer: false,
            VenteSupprimer: '',
            VenteRefuser: '',
            commentRefuser:'',
            status : "",
            search: '',
            
            mesVentePro: [],
            toutLesDonner: [],

            //règles de validation forme propositions
            rules: {
                required(finPhrase) {
                return (value) => !!value || `Veuillez saisir ${finPhrase}`
                },
            },

        }),
        created() {
            this.typeUser = localStorage.getItem('typeUser')
            //this.verifyApplicationDataSecurity()
            this.getAllVentePro()
        },
        methods: {
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
            getFileName(fileUrl){
                return fileUrl.split('/')[fileUrl.split('/').length-1].split('_')[0]
            },
            splitedList(str){
                
                if(str !== '' && str != null){
                    return str.split(',')
                }else{
                    return null
                }
            },
            verifyApplicationDataSecurity() {
                if (this.$cookies.get('token')) {
                    var formData = new FormData()
                    formData.append('type', localStorage.getItem('typeUser'))
                    formData.append('vueAfficher', 'admin')
                    formData.append('token', this.$cookies.get('token'))
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'verifyApplicationDataSecurity.php',
                        method: 'POST',
                        data: formData,
                        
                    })
                    .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    }
                    })
                    .catch((err) => {
                    console.log(err)
                    })
                }else{
                    this.$router.push('/login')
                }
            },
            getAllVentePro() {
                let self = this     
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'getAllVentePro/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        self.mesVentePro = res.data
                    }else if(res.data.result == "permission"){

                    }
                })
            },
            envoyerPourValider(item){
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''
                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', item.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'envoyerPourValiderUneVentePro/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    if(res.data.result==true){
                        self.getAllVentePro()
                        
                        self.loading = false
                        self.error = false
                        self.messageDialoge = 'Un email déjà envoyer au vendeur pour notifier'
                    }else if(res.data.result==false){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'vente n\'existe pas'
                    }else if(res.data.result=='permission'){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'permission denied'
                    }
                })
            },
            accepterVente(item){
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''
                let self = this
                var formData = new FormData()
                formData.append('id', item.id)
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +'accepterUneVentePro/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    if(res.data.result==true){
                        self.getAllVentePro()
                        
                        self.loading = false
                        self.error = false
                        self.messageDialoge = 'Vente accepter, et un email déjà envoyer au vendeur pour notifier'
                    }else if(res.data.result==false){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'vente n\'existe plus'
                    }else if(res.data.result=='permission'){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'permission denied'

                    }
                })
            },
            ouvrireDialogeRefuserVente(item){
                this.VenteRefuser = item
                this.ouvrireDialogeRefuser = true
            },
            fermerDialogeRefuserVente(){
                this.VenteRefuser = ''
                this.ouvrireDialogeRefuser = false
            },
            ouvrireDialogeSupprimerVente(item){
                this.VenteSupprimer = item
                this.ouvrireDialogeSupprimer = true
            },
            fermerDialogeSupprimerVente(){
                this.VenteSupprimer = ''
                this.ouvrireDialogeSupprimer = false
            },
            refuserVente(){
                if (!this.$refs.form.validate()) return
                //fermer modal comment
                this.ouvrireDialogeRefuser = false
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''

                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('comment', this.commentRefuser)
                formData.append('id', this.VenteRefuser.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'refuserUneVentePro/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    if(res.data.result==true){
                        
                        self.getAllVentePro()
                        this.loading = false
                        this.error = false
                        self.commentRefuser=''
                        this.messageDialoge = 'Vente refuser, et un email déjà envoyer au vendeur'
                    }else if(res.data.result==false){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'vente n\'existe pas'
                    }else if(res.data.result=='permission'){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'permission denied'
                    }
                })
            },
            supprimerVente(){
                this.ouvrireDialogeSupprimer = false
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''
                let self = this
                var formData = new FormData()
                
                formData.append('token', this.getToken('token'))
                formData.append('id', this.VenteSupprimer.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'supprimerUneVentePro/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    if(res.data.result==true){
                        
                        self.getAllVentePro()
                        self.loading = false
                        self.error = false
                        self.VenteSupprimer=''
                        self.messageDialoge = 'Vente supprimer, et un email déjà envoyer au vendeur'
                    }else if(res.data.result==false){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'vente n\'existe pas'
                    }else if(res.data.result=='permission'){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'permission denied'
                    }
                })
            },
        }
    }
</script>