<template>
    <div>
        <v-row>
          <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
              <loadingComponent @closeDialog='dialog = $event' :dialogProps="dialog" :messageDialogeProps="messageDialogeError" :loadingProps="loading" :errorProps="error" />
          </v-col>
        </v-row>
        <v-btn @click="modifyRecapitulatif" :color="$colors[0]" class="text-right text-white"> Créer une présentation
          <v-icon class="ml-6" right dark>
            mdi-file-document-multiple
          </v-icon>
        </v-btn>

        <table id="table_recapulatif" style="display: none;">
            <tr>
                <td>Fournisseurs</td>
                <td>DUREE</td>
                <td>TYPE D'OFFRE</td>
                <td>HTVA</td>
                <td>TTC</td>
                <td v-if="fournisseurActuelle == true">Economies</td>
                <!--<td>TURP INCLUS</td>-->
            </tr>
            <tr  v-for="(company, index) in lesDonneesFournisseur"
                    :key="company.name">
                    <td v-if="company.logo=='Alpiq_120X35.png'"><img src="../assets/logoParticulier/Alpiq_120X35.png" /></td>
                        <td v-if="company.logo=='Alterna_120X35.png'"><img src="../assets/logoParticulier/Alterna_120X35.png" /></td>
                        <td v-if="company.logo=='LLUM_120X35.png'"><img src="../assets/logoParticulier/LLUM_120X35.png" /></td>
                        <td v-if="company.logo=='Antargaz_120X35.png'"><img src="../assets/logoParticulier/Antargaz_120X35.png" /></td>
                        <td v-if="company.logo=='Axpo_120X35.png'"><img src="../assets/logoParticulier/Axpo_120X35.png" /></td>
                        <td v-if="company.logo=='BARRY_120X35.png'"><img src="../assets/logoParticulier/BARRY_120X35.png" /></td>
                        <td v-if="company.logo=='BULB_120X35.png'"><img src="../assets/logoParticulier/BULB_120X35.png" /></td>
                        <td v-if="company.logo=='BUTAGAZ_120X35.png'"><img src="../assets/logoParticulier/BUTAGAZ_120X35.png" /></td>
                        <td v-if="company.logo=='CDISCOUNT_120X35.png'"><img src="../assets/logoParticulier/CDISCOUNT_120X35.png" /></td>
                        <td v-if="company.logo=='Dyneff_120X35.png'"><img src="../assets/logoParticulier/Dyneff_120X35.png" /></td>
                        <td v-if="company.logo=='Edenkia_120X35.png'"><img src="../assets/logoParticulier/Edenkia_120X35.png" /></td>
                        <td v-if="company.logo=='EDF_120X35.png'"><img src="../assets/logoParticulier/EDF_120X35.png" /></td>
                        <td v-if="company.logo=='EDS_120X35.png'"><img src="../assets/logoParticulier/EDS_120X35.png" /></td>
                        <td v-if="company.logo=='EDSB_120X35.png'"><img src="../assets/logoParticulier/EDSB_120X35.png" /></td>
                        <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_120X35.png" /></td>
                        <td v-if="company.logo=='Elecocite_120X35.png'"><img src="../assets/logoParticulier/Elecocite_120X35.png" /></td>
                        <td v-if="company.logo=='Electricite_de_provence_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_provence_120X35.png" /></td>
                        <td v-if="company.logo=='Electricite_de_savoie_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_savoie_120X35.png" /></td>
                        <td v-if="company.logo=='Electricite_de_Strasbourg_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_Strasbourg_120X35.png" /></td>
                        <td v-if="company.logo=='Enalp_120X35.png'"><img src="../assets/logoParticulier/Enalp_120X35.png" /></td>
                        <td v-if="company.logo=='Endesa_120X35.png'"><img src="../assets/logoParticulier/Endesa_120X35.png" /></td>
                        <td v-if="company.logo=='Enercoop_120X35.png'"><img src="../assets/logoParticulier/Enercoop_120X35.png" /></td>
                        <td v-if="company.logo=='Energem_120X35.png'"><img src="../assets/logoParticulier/Energem_120X35.png" /></td>
                        <td v-if="company.logo=='ENERGIE_DICI_120X35.png'"><img src="../assets/logoParticulier/ENERGIE_DICI_120X35.png" /></td>
                        <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_120X35.png" /></td>
                        <td v-if="company.logo=='ENI_120X35.png'"><img src="../assets/logoParticulier/ENI_120X35.png" /></td>
                        <td v-if="company.logo=='Enovos_120X35.png'"><img src="../assets/logoParticulier/Enovos_120X35.png" /></td>
                        <td v-if="company.logo=='Gaz_de_Bordeaux_120X35.png'"><img src="../assets/logoParticulier/Gaz_de_Bordeaux_120X35.png" /></td>
                        <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_120X35.png" /></td>
                        <td v-if="company.logo=='Gazprom_120X35.png'"><img src="../assets/logoParticulier/Gazprom_120X35.png" /></td>
                        <td v-if="company.logo=='GEG_120X35.png'"><img src="../assets/logoParticulier/GEG_120X35.png" /></td>
                        <td v-if="company.logo=='GREEN_YELLOW_120X35.png'"><img src="../assets/logoParticulier/GREEN_YELLOW_120X35.png" /></td>
                        <td v-if="company.logo=='HAPPE_120X35.png'"><img src="../assets/logoParticulier/HAPPE_120X35.png" /></td>
                        <td v-if="company.logo=='IBERDROLA_120X35.png'"><img src="../assets/logoParticulier/IBERDROLA_120X35.png" /></td>
                        <td v-if="company.logo=='ILEK_120X35.png'"><img src="../assets/logoParticulier/ILEK_120X35.png" /></td>
                        <td v-if="company.logo=='LECLERCQ_120X35.png'"><img src="../assets/logoParticulier/LECLERCQ_120X35.png" /></td>
                        <td v-if="company.logo=='LUCIA_120X35.png'"><img src="../assets/logoParticulier/LUCIA_120X35.png" /></td>
                        <td v-if="company.logo=='MEGA_120X35.png'"><img src="../assets/logoParticulier/MEGA_120X35.png" /></td>
                        <td v-if="company.logo=='mint-energie_120X35.png'"><img src="../assets/logoParticulier/mint-energie_120X35.png" /></td>
                        <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_120X35.png" /></td>
                        <td v-if="company.logo=='OVO_120X35.png'"><img src="../assets/logoParticulier/OVO_120X35.png" /></td>
                        <td v-if="company.logo=='Picoty_120X35.png'"><img src="../assets/logoParticulier/Picoty_120X35.png" /></td>
                        <td v-if="company.logo=='PLANETE_OUI_120X35.png'"><img src="../assets/logoParticulier/PLANETE_OUI_120X35.png" /></td>
                        <td v-if="company.logo=='PLUM_120X35.png'"><img src="../assets/logoParticulier/PLUM_120X35.png" /></td>
                        <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_120X35.png" /></td>
                        <td v-if="company.logo=='Proxelia_120X35.png'"><img src="../assets/logoParticulier/Proxelia_120X35.png" /></td>
                        <td v-if="company.logo=='Redeéo_120X35.png'"><img src="../assets/logoParticulier/Redeéo_120X35.png" /></td>
                        <td v-if="company.logo=='Selia_120X35.png'"><img src="../assets/logoParticulier/Selia_120X35.png" /></td>
                        <td v-if="company.logo=='SOWEE_120X35.png'"><img src="../assets/logoParticulier/SOWEE_120X35.png" /></td>
                        <td v-if="company.logo=='Synelva_120X35.png'"><img src="../assets/logoParticulier/Synelva_120X35.png" /></td>
                        <td v-if="company.logo=='TDE_120X35.png'"><img src="../assets/logoParticulier/TDE_120X35.png" /></td>
                        <td v-if="company.logo=='Urban_Solar_120X35.png'"><img src="../assets/logoParticulier/Urban_Solar_120X35.png" /></td>
                        <td v-if="company.logo=='Vattenfall_120X35.png'"><img src="../assets/logoParticulier/Vattenfall_120X35.png" /></td>
                        <td v-if="company.logo=='Wekiwi_120X35.png'"><img src="../assets/logoParticulier/Wekiwi_120X35.png" /></td>
                    <td v-if="company.logo=='yeli-xs_120X35.png'"><img src="../assets/logoParticulier/yeli-xs_120X35.png" /></td>
                
                <td v-if="company.typeFournisseur!='Actuelle' && company.typeFournisseur!='AuFrn' && company.type != 'Latitude'">{{calculerNombreDesMois(company.dateDebut, company.dateFin)}} MOIS</td>
                <td v-else-if="company.type == 'Latitude'">--</td>
                <td v-else>{{company.nbrMoisForActuelle}} MOIS</td>
                <td>{{ company.type=="" || company.type==null  ? "--" : company.type }}</td>
                
                <!--<td>{{isNaN(company.budget) ? "--": new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR', currencySign: 'accounting'}).format(company.budget)}}</td>-->
                <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(0)) + " €"}}</td>
                
                <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(0)) + " €"}}</td>
                <td v-if="fournisseurActuelle == true">
                    {{  numberWithSpaces((parseFloat(lesDonneesFournisseur[0]['TotaleanuelleTTC']) -  parseFloat(company.TotaleanuelleTTC)).toFixed(0))+ " €" }}
                </td>
                <!--<td v-if="company.TURPE == 'inclus'">OUI</td>
                <td v-else>NON</td>-->
            </tr>
            
        </table>
        <table id="mytableC3" style="display: none;" v-if="typeCompteur=='C3'">
            <tbody>
                <tr>
                    <td></td>
                    <td>Pointe</td>
                    <td>HPH</td>
                    <td>HCH</td>
                    <td>HPE</td>
                    <td>HCE</td>
                    <td>Pointe</td>
                    <td>HPH</td>
                    <td>HCH</td>
                    <td>HPE</td>
                    <td>HCE</td>
                    <td>CAR</td>
                    <td>Fourniture (part Abonnement) en €HTT/an</td>
                    <td>Fourniture (part Energie) en €HTT/an</td>
                    <td>Budget</td>
                    <td>TVA</td>
                    <td>Total annuel </td>
                    <td>Total annuel TTC</td>
                </tr>
            </tbody>
            <tbody v-for="(company, index) in lesDonneesFournisseur"
                    :key="company.name" v-if="(company.typeFournisseur=='AuFrn')||(company.typeFournisseur=='Actuelle')|| (company.fournisseur == 'Gazel Energie')">
                <tr>
                    <td v-if="company.logo=='Alpiq_120X35.png'"><img src="../assets/logoParticulier/Alpiq_80X30.png" /></td>
                        <td v-if="company.logo=='Alterna_120X35.png'"><img src="../assets/logoParticulier/Alterna_80X30.png" /></td>
                        <td v-if="company.logo=='LLUM_120X35.png'"><img src="../assets/logoParticulier/LLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Antargaz_120X35.png'"><img src="../assets/logoParticulier/Antargaz_80X30.png" /></td>
                        <td v-if="company.logo=='Axpo_120X35.png'"><img src="../assets/logoParticulier/Axpo_80X30.png" /></td>
                        <td v-if="company.logo=='BARRY_120X35.png'"><img src="../assets/logoParticulier/BARRY_80X30.png" /></td>
                        <td v-if="company.logo=='BULB_120X35.png'"><img src="../assets/logoParticulier/BULB_80X30.png" /></td>
                        <td v-if="company.logo=='BUTAGAZ_120X35.png'"><img src="../assets/logoParticulier/BUTAGAZ_80X30.png" /></td>
                        <td v-if="company.logo=='CDISCOUNT_120X35.png'"><img src="../assets/logoParticulier/CDISCOUNT_80X30.png" /></td>
                        <td v-if="company.logo=='Dyneff_120X35.png'"><img src="../assets/logoParticulier/Dyneff_80X30.png" /></td>
                        <td v-if="company.logo=='Edenkia_120X35.png'"><img src="../assets/logoParticulier/Edenkia_80X30.png" /></td>
                        <td v-if="company.logo=='EDF_120X35.png'"><img src="../assets/logoParticulier/EDF_80X30.png" /></td>
                        <td v-if="company.logo=='EDS_120X35.png'"><img src="../assets/logoParticulier/EDS_80X30.png" /></td>
                        <td v-if="company.logo=='EDSB_120X35.png'"><img src="../assets/logoParticulier/EDSB_80X30.png" /></td>
                        <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                        <td v-if="company.logo=='Elecocite_120X35.png'"><img src="../assets/logoParticulier/Elecocite_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_provence_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_provence_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_savoie_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_savoie_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_Strasbourg_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_Strasbourg_80X30.png" /></td>
                        <td v-if="company.logo=='Enalp_120X35.png'"><img src="../assets/logoParticulier/Enalp_80X30.png" /></td>
                        <td v-if="company.logo=='Endesa_120X35.png'"><img src="../assets/logoParticulier/Endesa_80X30.png" /></td>
                        <td v-if="company.logo=='Enercoop_120X35.png'"><img src="../assets/logoParticulier/Enercoop_80X30.png" /></td>
                        <td v-if="company.logo=='Energem_120X35.png'"><img src="../assets/logoParticulier/Energem_80X30.png" /></td>
                        <td v-if="company.logo=='ENERGIE_DICI_120X35.png'"><img src="../assets/logoParticulier/ENERGIE_DICI_80X30.png" /></td>
                        <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                        <td v-if="company.logo=='ENI_120X35.png'"><img src="../assets/logoParticulier/ENI_80X30.png" /></td>
                        <td v-if="company.logo=='Enovos_120X35.png'"><img src="../assets/logoParticulier/Enovos_80X30.png" /></td>
                        <td v-if="company.logo=='Gaz_de_Bordeaux_120X35.png'"><img src="../assets/logoParticulier/Gaz_de_Bordeaux_80X30.png" /></td>
                        <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                        <td v-if="company.logo=='Gazprom_120X35.png'"><img src="../assets/logoParticulier/Gazprom_80X30.png" /></td>
                        <td v-if="company.logo=='GEG_120X35.png'"><img src="../assets/logoParticulier/GEG_80X30.png" /></td>
                        <td v-if="company.logo=='GREEN_YELLOW_120X35.png'"><img src="../assets/logoParticulier/GREEN_YELLOW_80X30.png" /></td>
                        <td v-if="company.logo=='HAPPE_120X35.png'"><img src="../assets/logoParticulier/HAPPE_80X30.png" /></td>
                        <td v-if="company.logo=='IBERDROLA_120X35.png'"><img src="../assets/logoParticulier/IBERDROLA_80X30.png" /></td>
                        <td v-if="company.logo=='ILEK_120X35.png'"><img src="../assets/logoParticulier/ILEK_80X30.png" /></td>
                        <td v-if="company.logo=='LECLERCQ_120X35.png'"><img src="../assets/logoParticulier/LECLERCQ_80X30.png" /></td>
                        <td v-if="company.logo=='LUCIA_120X35.png'"><img src="../assets/logoParticulier/LUCIA_80X30.png" /></td>
                        <td v-if="company.logo=='MEGA_120X35.png'"><img src="../assets/logoParticulier/MEGA_80X30.png" /></td>
                        <td v-if="company.logo=='mint-energie_120X35.png'"><img src="../assets/logoParticulier/mint-energie_80X30.png" /></td>
                        <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                        <td v-if="company.logo=='OVO_120X35.png'"><img src="../assets/logoParticulier/OVO_80X30.png" /></td>
                        <td v-if="company.logo=='Picoty_120X35.png'"><img src="../assets/logoParticulier/Picoty_80X30.png" /></td>
                        <td v-if="company.logo=='PLANETE_OUI_120X35.png'"><img src="../assets/logoParticulier/PLANETE_OUI_80X30.png" /></td>
                        <td v-if="company.logo=='PLUM_120X35.png'"><img src="../assets/logoParticulier/PLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_80X30.png" /></td>
                        <td v-if="company.logo=='Proxelia_120X35.png'"><img src="../assets/logoParticulier/Proxelia_80X30.png" /></td>
                        <td v-if="company.logo=='Redeéo_120X35.png'"><img src="../assets/logoParticulier/Redeéo_80X30.png" /></td>
                        <td v-if="company.logo=='Selia_120X35.png'"><img src="../assets/logoParticulier/Selia_80X30.png" /></td>
                        <td v-if="company.logo=='SOWEE_120X35.png'"><img src="../assets/logoParticulier/SOWEE_80X30.png" /></td>
                        <td v-if="company.logo=='Synelva_120X35.png'"><img src="../assets/logoParticulier/Synelva_80X30.png" /></td>
                        <td v-if="company.logo=='TDE_120X35.png'"><img src="../assets/logoParticulier/TDE_80X30.png" /></td>
                        <td v-if="company.logo=='Urban_Solar_120X35.png'"><img src="../assets/logoParticulier/Urban_Solar_80X30.png" /></td>
                        <td v-if="company.logo=='Vattenfall_120X35.png'"><img src="../assets/logoParticulier/Vattenfall_80X30.png" /></td>
                        <td v-if="company.logo=='Wekiwi_120X35.png'"><img src="../assets/logoParticulier/Wekiwi_80X30.png" /></td>
                    <td v-if="company.logo=='yeli-xs_120X35.png'"><img src="../assets/logoParticulier/yeli-xs_80X30.png" /></td>

                    <td>{{isNaN(company.unitairepointe) ? "--": parseFloat(company.unitairepointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_pointe) ? "--": parseFloat(company.capa_pointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'"></td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
            </tbody>
            
            <!--<tbody v-else-if="(company.fournisseur == 'Gazel Energie')">
                <tr v-if="company.case_two=='1'">
                    <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                    <td>{{isNaN(company.pointe_year_one) ? "--": parseFloat(company.pointe_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hph_year_one) ? "--": parseFloat(company.hph_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hch_year_one) ? "--": parseFloat(company.hch_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hpe_year_one) ? "--": parseFloat(company.hpe_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hce_year_one) ? "--": parseFloat(company.hce_year_one).toFixed(2)+"€"}}</td>

                    <td>{{isNaN(company.cout_capa_pointe1) ? "--": parseFloat(company.cout_capa_pointe1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hph1) ? "--": parseFloat(company.cout_capa_hph1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hch1) ? "--": parseFloat(company.cout_capa_hch1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hpe1) ? "--": parseFloat(company.cout_capa_hpe1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hce1) ? "--": parseFloat(company.cout_capa_hce1).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_year_one) ? "--": parseFloat(company.consommationEnergie_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo1)? "--": parseFloat(company.budgetAbo1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.tva1) ? "--": parseFloat(company.tva1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_annelle_1) ? "--": parseFloat(company.budget_annelle_1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.totale_ttc1) ? "--": parseFloat(company.totale_ttc1).toFixed(2)+"€"}}</td>
                    
                </tr>
                <tr  v-if="company.case_two=='1'">
                    <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                    <td>{{isNaN(company.pointe_year_two) ? "--": parseFloat(company.pointe_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hph_year_two) ? "--": parseFloat(company.hph_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hch_year_two) ? "--": parseFloat(company.hch_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hpe_year_two) ? "--": parseFloat(company.hpe_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hce_year_two) ? "--": parseFloat(company.hce_year_two).toFixed(2)+"€"}}</td>

                    <td>{{isNaN(company.cout_capa_pointe2) ? "--": parseFloat(company.cout_capa_pointe2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hph2) ? "--": parseFloat(company.cout_capa_hph2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hch2) ? "--": parseFloat(company.cout_capa_hch2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hpe2) ? "--": parseFloat(company.cout_capa_hpe2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hce2) ? "--": parseFloat(company.cout_capa_hce2).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_year_two) ? "--": parseFloat(company.consommationEnergie_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo2)? "--": parseFloat(company.budgetAbo2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.tva2) ? "--": parseFloat(company.tva2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_annelle_2) ? "--": parseFloat(company.budget_annelle_2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.totale_ttc2) ? "--": parseFloat(company.totale_ttc2).toFixed(2)+"€"}}</td>
                    
                </tr>
                <tr  v-if="company.case_two=='0'">
                    <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>

                    <td>{{isNaN(company.unitairepointe) ? "--": parseFloat(company.unitairepointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>

                    <td>{{isNaN(company.capa_pointe) ? "--": parseFloat(company.capa_pointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'"></td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
            </tbody>-->
            <tbody v-else-if="(company.fournisseur == 'Primeo Energie')">
                <tr>
                    <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_120X35.png" /></td>
                    <td>{{isNaN(company.unitairepointe) ? "--": parseFloat(company.unitairepointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_pointe) ? "--": parseFloat(company.capa_pointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo)? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    
                </tr>
                <tr v-if="'2023' in company">
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitairepointe) ? "--": parseFloat(company['2023'].unitairepointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehph) ? "--": parseFloat(company['2023'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehch) ? "--": parseFloat(company['2023'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehpe) ? "--": parseFloat(company['2023'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehce) ? "--": parseFloat(company['2023'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_pointe) ? "--": parseFloat(company['2023'].capa_pointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hph) ? "--": parseFloat(company['2023'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hch) ? "--": parseFloat(company['2023'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hpe) ? "--": parseFloat(company['2023'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hce) ? "--": parseFloat(company['2023'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr v-if="'2024' in company">
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitairepointe) ? "--": parseFloat(company['2024'].unitairepointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehph) ? "--": parseFloat(company['2024'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehch) ? "--": parseFloat(company['2024'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehpe) ? "--": parseFloat(company['2024'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehce) ? "--": parseFloat(company['2024'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_pointe) ? "--": parseFloat(company['2024'].capa_pointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hph) ? "--": parseFloat(company['2024'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hch) ? "--": parseFloat(company['2024'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hpe) ? "--": parseFloat(company['2024'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hce) ? "--": parseFloat(company['2024'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr v-if="'2025' in company">
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitairepointe) ? "--": parseFloat(company['2025'].unitairepointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehph) ? "--": parseFloat(company['2025'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehch) ? "--": parseFloat(company['2025'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehpe) ? "--": parseFloat(company['2025'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehce) ? "--": parseFloat(company['2025'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_pointe) ? "--": parseFloat(company['2025'].capa_pointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hph) ? "--": parseFloat(company['2025'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hch) ? "--": parseFloat(company['2025'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hpe) ? "--": parseFloat(company['2025'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hce) ? "--": parseFloat(company['2025'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr v-if="'2026' in company">
                    <td>2026</td>
                    <td>{{isNaN(company['2026'].unitairepointe) ? "--": parseFloat(company['2026'].unitairepointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehph) ? "--": parseFloat(company['2026'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehch) ? "--": parseFloat(company['2026'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehpe) ? "--": parseFloat(company['2026'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehce) ? "--": parseFloat(company['2026'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_pointe) ? "--": parseFloat(company['2026'].capa_pointe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hph) ? "--": parseFloat(company['2026'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hch) ? "--": parseFloat(company['2026'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hpe) ? "--": parseFloat(company['2026'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hce) ? "--": parseFloat(company['2026'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2026'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2026'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2026'].aboAns) ? "--": parseFloat(company['2026'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2026'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2026'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TVA) ? "--": numberWithSpaces(parseFloat(company['2026'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budget) ? "--": numberWithSpaces(parseFloat(company['2026'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2026'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
            </tbody>
        </table>

      
         <table id="mytableC4" style="display: none;" v-if="typeCompteur=='C4'">
            <tbody>
                <tr>
                    <td></td>
                    <td>HPH</td>
                    <td>HCH</td>
                    <td>HPE</td>
                    <td>HCE</td>
                    <td>HPH</td>
                    <td>HCH</td>
                    <td>HPE</td>
                    <td>HCE</td>
                    <td>CAR</td>
                    <td>Fourniture (part Abonnement) en €HTT/an</td>
                    <td>Fourniture (part Energie) en €HTT/an</td>
                    <td>Budget</td>
                    <td>TVA</td>
                    <td>Total annuel </td>
                    <td>Total annuel TTC</td>
                </tr>
            </tbody>
            <tbody v-for="(company, index) in lesDonneesFournisseur"
                    :key="company.name" v-if="(company.typeFournisseur=='AuFrn')||(company.typeFournisseur=='Actuelle') || ((company.fournisseur != 'engie') &&(company.fournisseur != 'Ekwateur')
                    && (company.fournisseur != 'Primeo Energie')&& (company.type!='Prix Fixe') && (company.typeFournisseur!='extern'))">
                <tr>
                    <td v-if="company.logo=='Alpiq_120X35.png'"><img src="../assets/logoParticulier/Alpiq_80X30.png" /></td>
                        <td v-if="company.logo=='Alterna_120X35.png'"><img src="../assets/logoParticulier/Alterna_80X30.png" /></td>
                        <td v-if="company.logo=='LLUM_120X35.png'"><img src="../assets/logoParticulier/LLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Antargaz_120X35.png'"><img src="../assets/logoParticulier/Antargaz_80X30.png" /></td>
                        <td v-if="company.logo=='Axpo_120X35.png'"><img src="../assets/logoParticulier/Axpo_80X30.png" /></td>
                        <td v-if="company.logo=='BARRY_120X35.png'"><img src="../assets/logoParticulier/BARRY_80X30.png" /></td>
                        <td v-if="company.logo=='BULB_120X35.png'"><img src="../assets/logoParticulier/BULB_80X30.png" /></td>
                        <td v-if="company.logo=='BUTAGAZ_120X35.png'"><img src="../assets/logoParticulier/BUTAGAZ_80X30.png" /></td>
                        <td v-if="company.logo=='CDISCOUNT_120X35.png'"><img src="../assets/logoParticulier/CDISCOUNT_80X30.png" /></td>
                        <td v-if="company.logo=='Dyneff_120X35.png'"><img src="../assets/logoParticulier/Dyneff_80X30.png" /></td>
                        <td v-if="company.logo=='Edenkia_120X35.png'"><img src="../assets/logoParticulier/Edenkia_80X30.png" /></td>
                        <td v-if="company.logo=='EDF_120X35.png'"><img src="../assets/logoParticulier/EDF_80X30.png" /></td>
                        <td v-if="company.logo=='EDS_120X35.png'"><img src="../assets/logoParticulier/EDS_80X30.png" /></td>
                        <td v-if="company.logo=='EDSB_120X35.png'"><img src="../assets/logoParticulier/EDSB_80X30.png" /></td>
                        <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                        <td v-if="company.logo=='Elecocite_120X35.png'"><img src="../assets/logoParticulier/Elecocite_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_provence_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_provence_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_savoie_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_savoie_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_Strasbourg_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_Strasbourg_80X30.png" /></td>
                        <td v-if="company.logo=='Enalp_120X35.png'"><img src="../assets/logoParticulier/Enalp_80X30.png" /></td>
                        <td v-if="company.logo=='Endesa_120X35.png'"><img src="../assets/logoParticulier/Endesa_80X30.png" /></td>
                        <td v-if="company.logo=='Enercoop_120X35.png'"><img src="../assets/logoParticulier/Enercoop_80X30.png" /></td>
                        <td v-if="company.logo=='Energem_120X35.png'"><img src="../assets/logoParticulier/Energem_80X30.png" /></td>
                        <td v-if="company.logo=='ENERGIE_DICI_120X35.png'"><img src="../assets/logoParticulier/ENERGIE_DICI_80X30.png" /></td>
                        <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                        <td v-if="company.logo=='ENI_120X35.png'"><img src="../assets/logoParticulier/ENI_80X30.png" /></td>
                        <td v-if="company.logo=='Enovos_120X35.png'"><img src="../assets/logoParticulier/Enovos_80X30.png" /></td>
                        <td v-if="company.logo=='Gaz_de_Bordeaux_120X35.png'"><img src="../assets/logoParticulier/Gaz_de_Bordeaux_80X30.png" /></td>
                        <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                        <td v-if="company.logo=='Gazprom_120X35.png'"><img src="../assets/logoParticulier/Gazprom_80X30.png" /></td>
                        <td v-if="company.logo=='GEG_120X35.png'"><img src="../assets/logoParticulier/GEG_80X30.png" /></td>
                        <td v-if="company.logo=='GREEN_YELLOW_120X35.png'"><img src="../assets/logoParticulier/GREEN_YELLOW_80X30.png" /></td>
                        <td v-if="company.logo=='HAPPE_120X35.png'"><img src="../assets/logoParticulier/HAPPE_80X30.png" /></td>
                        <td v-if="company.logo=='IBERDROLA_120X35.png'"><img src="../assets/logoParticulier/IBERDROLA_80X30.png" /></td>
                        <td v-if="company.logo=='ILEK_120X35.png'"><img src="../assets/logoParticulier/ILEK_80X30.png" /></td>
                        <td v-if="company.logo=='LECLERCQ_120X35.png'"><img src="../assets/logoParticulier/LECLERCQ_80X30.png" /></td>
                        <td v-if="company.logo=='LUCIA_120X35.png'"><img src="../assets/logoParticulier/LUCIA_80X30.png" /></td>
                        <td v-if="company.logo=='MEGA_120X35.png'"><img src="../assets/logoParticulier/MEGA_80X30.png" /></td>
                        <td v-if="company.logo=='mint-energie_120X35.png'"><img src="../assets/logoParticulier/mint-energie_80X30.png" /></td>
                        <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                        <td v-if="company.logo=='OVO_120X35.png'"><img src="../assets/logoParticulier/OVO_80X30.png" /></td>
                        <td v-if="company.logo=='Picoty_120X35.png'"><img src="../assets/logoParticulier/Picoty_80X30.png" /></td>
                        <td v-if="company.logo=='PLANETE_OUI_120X35.png'"><img src="../assets/logoParticulier/PLANETE_OUI_80X30.png" /></td>
                        <td v-if="company.logo=='PLUM_120X35.png'"><img src="../assets/logoParticulier/PLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_80X30.png" /></td>
                        <td v-if="company.logo=='Proxelia_120X35.png'"><img src="../assets/logoParticulier/Proxelia_80X30.png" /></td>
                        <td v-if="company.logo=='Redeéo_120X35.png'"><img src="../assets/logoParticulier/Redeéo_80X30.png" /></td>
                        <td v-if="company.logo=='Selia_120X35.png'"><img src="../assets/logoParticulier/Selia_80X30.png" /></td>
                        <td v-if="company.logo=='SOWEE_120X35.png'"><img src="../assets/logoParticulier/SOWEE_80X30.png" /></td>
                        <td v-if="company.logo=='Synelva_120X35.png'"><img src="../assets/logoParticulier/Synelva_80X30.png" /></td>
                        <td v-if="company.logo=='TDE_120X35.png'"><img src="../assets/logoParticulier/TDE_80X30.png" /></td>
                        <td v-if="company.logo=='Urban_Solar_120X35.png'"><img src="../assets/logoParticulier/Urban_Solar_80X30.png" /></td>
                        <td v-if="company.logo=='Vattenfall_120X35.png'"><img src="../assets/logoParticulier/Vattenfall_80X30.png" /></td>
                        <td v-if="company.logo=='Wekiwi_120X35.png'"><img src="../assets/logoParticulier/Wekiwi_80X30.png" /></td>
                    <td v-if="company.logo=='yeli-xs_120X35.png'"><img src="../assets/logoParticulier/yeli-xs_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    <td v-if="company.cee == 'NON SOUMIS'"></td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo)? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    
                </tr>
            </tbody>

            <tbody v-else-if="(company.typeFournisseur=='extern')">
                <tr>
                    <td v-if="company.logo=='Alpiq_120X35.png'"><img src="../assets/logoParticulier/Alpiq_80X30.png" /></td>
                        <td v-if="company.logo=='Alterna_120X35.png'"><img src="../assets/logoParticulier/Alterna_80X30.png" /></td>
                        <td v-if="company.logo=='LLUM_120X35.png'"><img src="../assets/logoParticulier/LLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Antargaz_120X35.png'"><img src="../assets/logoParticulier/Antargaz_80X30.png" /></td>
                        <td v-if="company.logo=='Axpo_120X35.png'"><img src="../assets/logoParticulier/Axpo_80X30.png" /></td>
                        <td v-if="company.logo=='BARRY_120X35.png'"><img src="../assets/logoParticulier/BARRY_80X30.png" /></td>
                        <td v-if="company.logo=='BULB_120X35.png'"><img src="../assets/logoParticulier/BULB_80X30.png" /></td>
                        <td v-if="company.logo=='BUTAGAZ_120X35.png'"><img src="../assets/logoParticulier/BUTAGAZ_80X30.png" /></td>
                        <td v-if="company.logo=='CDISCOUNT_120X35.png'"><img src="../assets/logoParticulier/CDISCOUNT_80X30.png" /></td>
                        <td v-if="company.logo=='Dyneff_120X35.png'"><img src="../assets/logoParticulier/Dyneff_80X30.png" /></td>
                        <td v-if="company.logo=='Edenkia_120X35.png'"><img src="../assets/logoParticulier/Edenkia_80X30.png" /></td>
                        <td v-if="company.logo=='EDF_120X35.png'"><img src="../assets/logoParticulier/EDF_80X30.png" /></td>
                        <td v-if="company.logo=='EDS_120X35.png'"><img src="../assets/logoParticulier/EDS_80X30.png" /></td>
                        <td v-if="company.logo=='EDSB_120X35.png'"><img src="../assets/logoParticulier/EDSB_80X30.png" /></td>
                        <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                        <td v-if="company.logo=='Elecocite_120X35.png'"><img src="../assets/logoParticulier/Elecocite_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_provence_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_provence_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_savoie_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_savoie_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_Strasbourg_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_Strasbourg_80X30.png" /></td>
                        <td v-if="company.logo=='Enalp_120X35.png'"><img src="../assets/logoParticulier/Enalp_80X30.png" /></td>
                        <td v-if="company.logo=='Endesa_120X35.png'"><img src="../assets/logoParticulier/Endesa_80X30.png" /></td>
                        <td v-if="company.logo=='Enercoop_120X35.png'"><img src="../assets/logoParticulier/Enercoop_80X30.png" /></td>
                        <td v-if="company.logo=='Energem_120X35.png'"><img src="../assets/logoParticulier/Energem_80X30.png" /></td>
                        <td v-if="company.logo=='ENERGIE_DICI_120X35.png'"><img src="../assets/logoParticulier/ENERGIE_DICI_80X30.png" /></td>
                        <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                        <td v-if="company.logo=='ENI_120X35.png'"><img src="../assets/logoParticulier/ENI_80X30.png" /></td>
                        <td v-if="company.logo=='Enovos_120X35.png'"><img src="../assets/logoParticulier/Enovos_80X30.png" /></td>
                        <td v-if="company.logo=='Gaz_de_Bordeaux_120X35.png'"><img src="../assets/logoParticulier/Gaz_de_Bordeaux_80X30.png" /></td>
                        <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                        <td v-if="company.logo=='Gazprom_120X35.png'"><img src="../assets/logoParticulier/Gazprom_80X30.png" /></td>
                        <td v-if="company.logo=='GEG_120X35.png'"><img src="../assets/logoParticulier/GEG_80X30.png" /></td>
                        <td v-if="company.logo=='GREEN_YELLOW_120X35.png'"><img src="../assets/logoParticulier/GREEN_YELLOW_80X30.png" /></td>
                        <td v-if="company.logo=='HAPPE_120X35.png'"><img src="../assets/logoParticulier/HAPPE_80X30.png" /></td>
                        <td v-if="company.logo=='IBERDROLA_120X35.png'"><img src="../assets/logoParticulier/IBERDROLA_80X30.png" /></td>
                        <td v-if="company.logo=='ILEK_120X35.png'"><img src="../assets/logoParticulier/ILEK_80X30.png" /></td>
                        <td v-if="company.logo=='LECLERCQ_120X35.png'"><img src="../assets/logoParticulier/LECLERCQ_80X30.png" /></td>
                        <td v-if="company.logo=='LUCIA_120X35.png'"><img src="../assets/logoParticulier/LUCIA_80X30.png" /></td>
                        <td v-if="company.logo=='MEGA_120X35.png'"><img src="../assets/logoParticulier/MEGA_80X30.png" /></td>
                        <td v-if="company.logo=='mint-energie_120X35.png'"><img src="../assets/logoParticulier/mint-energie_80X30.png" /></td>
                        <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                        <td v-if="company.logo=='OVO_120X35.png'"><img src="../assets/logoParticulier/OVO_80X30.png" /></td>
                        <td v-if="company.logo=='Picoty_120X35.png'"><img src="../assets/logoParticulier/Picoty_80X30.png" /></td>
                        <td v-if="company.logo=='PLANETE_OUI_120X35.png'"><img src="../assets/logoParticulier/PLANETE_OUI_80X30.png" /></td>
                        <td v-if="company.logo=='PLUM_120X35.png'"><img src="../assets/logoParticulier/PLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_80X30.png" /></td>
                        <td v-if="company.logo=='Proxelia_120X35.png'"><img src="../assets/logoParticulier/Proxelia_80X30.png" /></td>
                        <td v-if="company.logo=='Redeéo_120X35.png'"><img src="../assets/logoParticulier/Redeéo_80X30.png" /></td>
                        <td v-if="company.logo=='Selia_120X35.png'"><img src="../assets/logoParticulier/Selia_80X30.png" /></td>
                        <td v-if="company.logo=='SOWEE_120X35.png'"><img src="../assets/logoParticulier/SOWEE_80X30.png" /></td>
                        <td v-if="company.logo=='Synelva_120X35.png'"><img src="../assets/logoParticulier/Synelva_80X30.png" /></td>
                        <td v-if="company.logo=='TDE_120X35.png'"><img src="../assets/logoParticulier/TDE_80X30.png" /></td>
                        <td v-if="company.logo=='Urban_Solar_120X35.png'"><img src="../assets/logoParticulier/Urban_Solar_80X30.png" /></td>
                        <td v-if="company.logo=='Vattenfall_120X35.png'"><img src="../assets/logoParticulier/Vattenfall_80X30.png" /></td>
                        <td v-if="company.logo=='Wekiwi_120X35.png'"><img src="../assets/logoParticulier/Wekiwi_80X30.png" /></td>
                    <td v-if="company.logo=='yeli-xs_120X35.png'"><img src="../assets/logoParticulier/yeli-xs_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo)? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    
                </tr>
                <tr v-if="'2023' in company">
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitairehph) ? "--": parseFloat(company['2023'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehch) ? "--": parseFloat(company['2023'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehpe) ? "--": parseFloat(company['2023'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehce) ? "--": parseFloat(company['2023'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hph) ? "--": parseFloat(company['2023'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hch) ? "--": parseFloat(company['2023'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hpe) ? "--": parseFloat(company['2023'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hce) ? "--": parseFloat(company['2023'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr  v-if="'2024' in company">
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitairehph) ? "--": parseFloat(company['2024'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehch) ? "--": parseFloat(company['2024'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehpe) ? "--": parseFloat(company['2024'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehce) ? "--": parseFloat(company['2024'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hph) ? "--": parseFloat(company['2024'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hch) ? "--": parseFloat(company['2024'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hpe) ? "--": parseFloat(company['2024'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hce) ? "--": parseFloat(company['2024'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr  v-if="'2025' in company">
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitairehph) ? "--": parseFloat(company['2025'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehch) ? "--": parseFloat(company['2025'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehpe) ? "--": parseFloat(company['2025'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehce) ? "--": parseFloat(company['2025'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hph) ? "--": parseFloat(company['2025'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hch) ? "--": parseFloat(company['2025'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hpe) ? "--": parseFloat(company['2025'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hce) ? "--": parseFloat(company['2025'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr  v-if="'2026' in company">
                    <td>2026</td>
                    <td>{{isNaN(company['2026'].unitairehph) ? "--": parseFloat(company['2026'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehch) ? "--": parseFloat(company['2026'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehpe) ? "--": parseFloat(company['2026'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehce) ? "--": parseFloat(company['2026'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hph) ? "--": parseFloat(company['2026'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hch) ? "--": parseFloat(company['2026'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hpe) ? "--": parseFloat(company['2026'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hce) ? "--": parseFloat(company['2026'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2026'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2026'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2026'].aboAns) ? "--": parseFloat(company['2026'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2026'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2026'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TVA) ? "--": numberWithSpaces(parseFloat(company['2026'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budget) ? "--": numberWithSpaces(parseFloat(company['2026'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2026'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
            </tbody>
            <!--<tbody v-else-if="(company.fournisseur == 'Gazel Energie')">
                <tr v-if="company.case_two=='1'">
                    <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                    <td>{{isNaN(company.hph_year_one) ? "--": parseFloat(company.hph_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hch_year_one) ? "--": parseFloat(company.hch_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hpe_year_one) ? "--": parseFloat(company.hpe_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hce_year_one) ? "--": parseFloat(company.hce_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hph1) ? "--": parseFloat(company.cout_capa_hph1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hch1) ? "--": parseFloat(company.cout_capa_hch1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hpe1) ? "--": parseFloat(company.cout_capa_hpe1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hce1) ? "--": parseFloat(company.cout_capa_hce1).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_year_one) ? "--": parseFloat(company.consommationEnergie_year_one).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo1)? "--": parseFloat(company.budgetAbo1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.tva1) ? "--": parseFloat(company.tva1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_annelle_1) ? "--": parseFloat(company.budget_annelle_1).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.totale_ttc1) ? "--": parseFloat(company.totale_ttc1).toFixed(2)+"€"}}</td>
                    
                </tr>
                <tr  v-if="company.case_two=='1'">
                    <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                    <td>{{isNaN(company.hph_year_two) ? "--": parseFloat(company.hph_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hch_year_two) ? "--": parseFloat(company.hch_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hpe_year_two) ? "--": parseFloat(company.hpe_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.hce_year_two) ? "--": parseFloat(company.hce_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hph2) ? "--": parseFloat(company.cout_capa_hph2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hch2) ? "--": parseFloat(company.cout_capa_hch2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hpe2) ? "--": parseFloat(company.cout_capa_hpe2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.cout_capa_hce2) ? "--": parseFloat(company.cout_capa_hce2).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_year_two) ? "--": parseFloat(company.consommationEnergie_year_two).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo2)? "--": parseFloat(company.budgetAbo2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.tva2) ? "--": parseFloat(company.tva2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_annelle_2) ? "--": parseFloat(company.budget_annelle_2).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.totale_ttc2) ? "--": parseFloat(company.totale_ttc2).toFixed(2)+"€"}}</td>
                    
                </tr>
                <tr  v-if="company.case_two=='0'">
                    <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'"></td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
            </tbody>-->
            <tbody v-else-if="(company.fournisseur == 'Ekwateur')">
                <tr>
                    <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo)? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    
                </tr>
                <tr>
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitairehph) ? "--": parseFloat(company['2023'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehch) ? "--": parseFloat(company['2023'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehpe) ? "--": parseFloat(company['2023'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehce) ? "--": parseFloat(company['2023'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hph) ? "--": parseFloat(company['2023'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hch) ? "--": parseFloat(company['2023'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hpe) ? "--": parseFloat(company['2023'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hce) ? "--": parseFloat(company['2023'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr>
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitairehph) ? "--": parseFloat(company['2024'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehch) ? "--": parseFloat(company['2024'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehpe) ? "--": parseFloat(company['2024'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehce) ? "--": parseFloat(company['2024'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hph) ? "--": parseFloat(company['2024'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hch) ? "--": parseFloat(company['2024'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hpe) ? "--": parseFloat(company['2024'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hce) ? "--": parseFloat(company['2024'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr>
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitairehph) ? "--": parseFloat(company['2025'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehch) ? "--": parseFloat(company['2025'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehpe) ? "--": parseFloat(company['2025'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehce) ? "--": parseFloat(company['2025'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hph) ? "--": parseFloat(company['2025'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hch) ? "--": parseFloat(company['2025'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hpe) ? "--": parseFloat(company['2025'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hce) ? "--": parseFloat(company['2025'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.fournisseur == 'OHM' && company.type=='Prix Fixe')">
                <tr>
                    <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo)? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    
                </tr>
                <tr v-if="'2023' in company">
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitairehph) ? "--": parseFloat(company['2023'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehch) ? "--": parseFloat(company['2023'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehpe) ? "--": parseFloat(company['2023'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehce) ? "--": parseFloat(company['2023'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hph) ? "--": parseFloat(company['2023'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hch) ? "--": parseFloat(company['2023'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hpe) ? "--": parseFloat(company['2023'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hce) ? "--": parseFloat(company['2023'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr v-if="'2024' in company">
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitairehph) ? "--": parseFloat(company['2024'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehch) ? "--": parseFloat(company['2024'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehpe) ? "--": parseFloat(company['2024'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehce) ? "--": parseFloat(company['2024'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hph) ? "--": parseFloat(company['2024'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hch) ? "--": parseFloat(company['2024'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hpe) ? "--": parseFloat(company['2024'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hce) ? "--": parseFloat(company['2024'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr v-if="'2025' in company">
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitairehph) ? "--": parseFloat(company['2025'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehch) ? "--": parseFloat(company['2025'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehpe) ? "--": parseFloat(company['2025'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehce) ? "--": parseFloat(company['2025'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hph) ? "--": parseFloat(company['2025'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hch) ? "--": parseFloat(company['2025'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hpe) ? "--": parseFloat(company['2025'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hce) ? "--": parseFloat(company['2025'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.fournisseur == 'Primeo Energie')">
                <tr>
                    <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_120X35.png" /></td>
                    <td>{{isNaN(company.unitairehph) ? "--": parseFloat(company.unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch) ? "--": parseFloat(company.unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe) ? "--": parseFloat(company.unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce) ? "--": parseFloat(company.unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo)? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    
                </tr>
                <tr v-if="'2023' in company">
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitairehph) ? "--": parseFloat(company['2023'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehch) ? "--": parseFloat(company['2023'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehpe) ? "--": parseFloat(company['2023'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehce) ? "--": parseFloat(company['2023'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hph) ? "--": parseFloat(company['2023'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hch) ? "--": parseFloat(company['2023'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hpe) ? "--": parseFloat(company['2023'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hce) ? "--": parseFloat(company['2023'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr v-if="'2024' in company">
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitairehph) ? "--": parseFloat(company['2024'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehch) ? "--": parseFloat(company['2024'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehpe) ? "--": parseFloat(company['2024'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehce) ? "--": parseFloat(company['2024'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hph) ? "--": parseFloat(company['2024'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hch) ? "--": parseFloat(company['2024'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hpe) ? "--": parseFloat(company['2024'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hce) ? "--": parseFloat(company['2024'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr v-if="'2025' in company">
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitairehph) ? "--": parseFloat(company['2025'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehch) ? "--": parseFloat(company['2025'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehpe) ? "--": parseFloat(company['2025'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehce) ? "--": parseFloat(company['2025'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hph) ? "--": parseFloat(company['2025'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hch) ? "--": parseFloat(company['2025'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hpe) ? "--": parseFloat(company['2025'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hce) ? "--": parseFloat(company['2025'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
                <tr v-if="'2026' in company">
                    <td>2026</td>
                    <td>{{isNaN(company['2026'].unitairehph) ? "--": parseFloat(company['2026'].unitairehph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehch) ? "--": parseFloat(company['2026'].unitairehch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehpe) ? "--": parseFloat(company['2026'].unitairehpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehce) ? "--": parseFloat(company['2026'].unitairehce).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hph) ? "--": parseFloat(company['2026'].capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hch) ? "--": parseFloat(company['2026'].capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hpe) ? "--": parseFloat(company['2026'].capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hce) ? "--": parseFloat(company['2026'].capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2026'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2026'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2026'].aboAns) ? "--": parseFloat(company['2026'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2026'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budgetAbo)? "--": numberWithSpaces(parseFloat(company['2026'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TVA) ? "--": numberWithSpaces(parseFloat(company['2026'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budget) ? "--": numberWithSpaces(parseFloat(company['2026'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2026'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehph_First_Years) ? "--": parseFloat(company.unitairehph_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch_First_Years) ? "--": parseFloat(company.unitairehch_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe_First_Years) ? "--": parseFloat(company.unitairehpe_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce_First_Years) ? "--": parseFloat(company.unitairehce_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_First_Years) ? "--": parseFloat(company.consommationEnergie_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo_First_Years)? "--": parseFloat(company.budgetAbo_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TVA_First_Years) ? "--": parseFloat(company.TVA_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_First_Years) ? "--": parseFloat(company.budget_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC_First_Years) ? "--": parseFloat(company.TotaleanuelleTTC_First_Years).toFixed(2)+"€"}}</td>
                    
                </tr>
                <tr v-if="company.nbrYears=='3'">
                    <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehph_Seconde_Years) ? "--": parseFloat(company.unitairehph_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehch_Seconde_Years) ? "--": parseFloat(company.unitairehch_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehpe_Seconde_Years) ? "--": parseFloat(company.unitairehpe_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehce_Seconde_Years) ? "--": parseFloat(company.unitairehce_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hph) ? "--": parseFloat(company.capa_hph).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hch) ? "--": parseFloat(company.capa_hch).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hpe) ? "--": parseFloat(company.capa_hpe).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hce) ? "--": parseFloat(company.capa_hce).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_Seconde_Years) ? "--": parseFloat(company.consommationEnergie_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo_Seconde_Years)? "--": parseFloat(company.budgetAbo_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TVA_Seconde_Years) ? "--": parseFloat(company.TVA_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_Seconde_Years) ? "--": parseFloat(company.budget_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC_Seconde_Years) ? "--": parseFloat(company.TotaleanuelleTTC_Seconde_Years).toFixed(2)+"€"}}</td>
                    
                </tr>
            </tbody>
        </table>

        <table id="mytableC5Hp" style="display: none;" v-if="typeCompteur=='C5HP'">
            <tbody>
                <tr>
                    <td></td>
                    <td>HP</td>
                    <td>HC</td>
                    <td>HP</td>
                    <td>HC</td>
                    <td>CAR</td>
                    <td>Fourniture (part Abonnement) en €HTT/an</td>
                    <td>Fourniture (part Energie) en €HTT/an</td>
                    <td>Budget</td>
                    <td>TVA</td>
                    <td>Total annuel </td>
                    <td>Total annuel TTC</td>
                </tr>
            </tbody>
            <tbody v-for="(company, index) in lesDonneesFournisseur"
                    :key="company.name" v-if="(company.typeFournisseur=='AuFrn')||(company.typeFournisseur=='Actuelle') || ((company.fournisseur != 'engie') && 
                    (company.fournisseur != 'Ekwateur')&& (company.type!='Prix Fixe') && (company.typeFournisseur!='extern'))">
                <tr>
                    
                    <td v-if="company.logo=='Alpiq_120X35.png'"><img src="../assets/logoParticulier/Alpiq_80X30.png" /></td>
                        <td v-if="company.logo=='Alterna_120X35.png'"><img src="../assets/logoParticulier/Alterna_80X30.png" /></td>
                        <td v-if="company.logo=='LLUM_120X35.png'"><img src="../assets/logoParticulier/LLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Antargaz_120X35.png'"><img src="../assets/logoParticulier/Antargaz_80X30.png" /></td>
                        <td v-if="company.logo=='Axpo_120X35.png'"><img src="../assets/logoParticulier/Axpo_80X30.png" /></td>
                        <td v-if="company.logo=='BARRY_120X35.png'"><img src="../assets/logoParticulier/BARRY_80X30.png" /></td>
                        <td v-if="company.logo=='BULB_120X35.png'"><img src="../assets/logoParticulier/BULB_80X30.png" /></td>
                        <td v-if="company.logo=='BUTAGAZ_120X35.png'"><img src="../assets/logoParticulier/BUTAGAZ_80X30.png" /></td>
                        <td v-if="company.logo=='CDISCOUNT_120X35.png'"><img src="../assets/logoParticulier/CDISCOUNT_80X30.png" /></td>
                        <td v-if="company.logo=='Dyneff_120X35.png'"><img src="../assets/logoParticulier/Dyneff_80X30.png" /></td>
                        <td v-if="company.logo=='Edenkia_120X35.png'"><img src="../assets/logoParticulier/Edenkia_80X30.png" /></td>
                        <td v-if="company.logo=='EDF_120X35.png'"><img src="../assets/logoParticulier/EDF_80X30.png" /></td>
                        <td v-if="company.logo=='EDS_120X35.png'"><img src="../assets/logoParticulier/EDS_80X30.png" /></td>
                        <td v-if="company.logo=='EDSB_120X35.png'"><img src="../assets/logoParticulier/EDSB_80X30.png" /></td>
                        <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                        <td v-if="company.logo=='Elecocite_120X35.png'"><img src="../assets/logoParticulier/Elecocite_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_provence_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_provence_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_savoie_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_savoie_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_Strasbourg_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_Strasbourg_80X30.png" /></td>
                        <td v-if="company.logo=='Enalp_120X35.png'"><img src="../assets/logoParticulier/Enalp_80X30.png" /></td>
                        <td v-if="company.logo=='Endesa_120X35.png'"><img src="../assets/logoParticulier/Endesa_80X30.png" /></td>
                        <td v-if="company.logo=='Enercoop_120X35.png'"><img src="../assets/logoParticulier/Enercoop_80X30.png" /></td>
                        <td v-if="company.logo=='Energem_120X35.png'"><img src="../assets/logoParticulier/Energem_80X30.png" /></td>
                        <td v-if="company.logo=='ENERGIE_DICI_120X35.png'"><img src="../assets/logoParticulier/ENERGIE_DICI_80X30.png" /></td>
                        <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                        <td v-if="company.logo=='ENI_120X35.png'"><img src="../assets/logoParticulier/ENI_80X30.png" /></td>
                        <td v-if="company.logo=='Enovos_120X35.png'"><img src="../assets/logoParticulier/Enovos_80X30.png" /></td>
                        <td v-if="company.logo=='Gaz_de_Bordeaux_120X35.png'"><img src="../assets/logoParticulier/Gaz_de_Bordeaux_80X30.png" /></td>
                        <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                        <td v-if="company.logo=='Gazprom_120X35.png'"><img src="../assets/logoParticulier/Gazprom_80X30.png" /></td>
                        <td v-if="company.logo=='GEG_120X35.png'"><img src="../assets/logoParticulier/GEG_80X30.png" /></td>
                        <td v-if="company.logo=='GREEN_YELLOW_120X35.png'"><img src="../assets/logoParticulier/GREEN_YELLOW_80X30.png" /></td>
                        <td v-if="company.logo=='HAPPE_120X35.png'"><img src="../assets/logoParticulier/HAPPE_80X30.png" /></td>
                        <td v-if="company.logo=='IBERDROLA_120X35.png'"><img src="../assets/logoParticulier/IBERDROLA_80X30.png" /></td>
                        <td v-if="company.logo=='ILEK_120X35.png'"><img src="../assets/logoParticulier/ILEK_80X30.png" /></td>
                        <td v-if="company.logo=='LECLERCQ_120X35.png'"><img src="../assets/logoParticulier/LECLERCQ_80X30.png" /></td>
                        <td v-if="company.logo=='LUCIA_120X35.png'"><img src="../assets/logoParticulier/LUCIA_80X30.png" /></td>
                        <td v-if="company.logo=='MEGA_120X35.png'"><img src="../assets/logoParticulier/MEGA_80X30.png" /></td>
                        <td v-if="company.logo=='mint-energie_120X35.png'"><img src="../assets/logoParticulier/mint-energie_80X30.png" /></td>
                        <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                        <td v-if="company.logo=='OVO_120X35.png'"><img src="../assets/logoParticulier/OVO_80X30.png" /></td>
                        <td v-if="company.logo=='Picoty_120X35.png'"><img src="../assets/logoParticulier/Picoty_80X30.png" /></td>
                        <td v-if="company.logo=='PLANETE_OUI_120X35.png'"><img src="../assets/logoParticulier/PLANETE_OUI_80X30.png" /></td>
                        <td v-if="company.logo=='PLUM_120X35.png'"><img src="../assets/logoParticulier/PLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_80X30.png" /></td>
                        <td v-if="company.logo=='Proxelia_120X35.png'"><img src="../assets/logoParticulier/Proxelia_80X30.png" /></td>
                        <td v-if="company.logo=='Redeéo_120X35.png'"><img src="../assets/logoParticulier/Redeéo_80X30.png" /></td>
                        <td v-if="company.logo=='Selia_120X35.png'"><img src="../assets/logoParticulier/Selia_80X30.png" /></td>
                        <td v-if="company.logo=='SOWEE_120X35.png'"><img src="../assets/logoParticulier/SOWEE_80X30.png" /></td>
                        <td v-if="company.logo=='Synelva_120X35.png'"><img src="../assets/logoParticulier/Synelva_80X30.png" /></td>
                        <td v-if="company.logo=='TDE_120X35.png'"><img src="../assets/logoParticulier/TDE_80X30.png" /></td>
                        <td v-if="company.logo=='Urban_Solar_120X35.png'"><img src="../assets/logoParticulier/Urban_Solar_80X30.png" /></td>
                        <td v-if="company.logo=='Vattenfall_120X35.png'"><img src="../assets/logoParticulier/Vattenfall_80X30.png" /></td>
                        <td v-if="company.logo=='Wekiwi_120X35.png'"><img src="../assets/logoParticulier/Wekiwi_80X30.png" /></td>
                    <td v-if="company.logo=='yeli-xs_120X35.png'"><img src="../assets/logoParticulier/yeli-xs_80X30.png" /></td>
                    
                    <td>{{isNaN(company.unitairehp) ? "--": parseFloat(company.unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehc) ? "--": parseFloat(company.unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hp) ? "--": parseFloat(company.capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hc) ? "--": parseFloat(company.capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.typeFournisseur == 'extern')">
                <tr>
                    <td v-if="company.logo=='Alpiq_120X35.png'"><img src="../assets/logoParticulier/Alpiq_80X30.png" /></td>
                        <td v-if="company.logo=='Alterna_120X35.png'"><img src="../assets/logoParticulier/Alterna_80X30.png" /></td>
                        <td v-if="company.logo=='LLUM_120X35.png'"><img src="../assets/logoParticulier/LLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Antargaz_120X35.png'"><img src="../assets/logoParticulier/Antargaz_80X30.png" /></td>
                        <td v-if="company.logo=='Axpo_120X35.png'"><img src="../assets/logoParticulier/Axpo_80X30.png" /></td>
                        <td v-if="company.logo=='BARRY_120X35.png'"><img src="../assets/logoParticulier/BARRY_80X30.png" /></td>
                        <td v-if="company.logo=='BULB_120X35.png'"><img src="../assets/logoParticulier/BULB_80X30.png" /></td>
                        <td v-if="company.logo=='BUTAGAZ_120X35.png'"><img src="../assets/logoParticulier/BUTAGAZ_80X30.png" /></td>
                        <td v-if="company.logo=='CDISCOUNT_120X35.png'"><img src="../assets/logoParticulier/CDISCOUNT_80X30.png" /></td>
                        <td v-if="company.logo=='Dyneff_120X35.png'"><img src="../assets/logoParticulier/Dyneff_80X30.png" /></td>
                        <td v-if="company.logo=='Edenkia_120X35.png'"><img src="../assets/logoParticulier/Edenkia_80X30.png" /></td>
                        <td v-if="company.logo=='EDF_120X35.png'"><img src="../assets/logoParticulier/EDF_80X30.png" /></td>
                        <td v-if="company.logo=='EDS_120X35.png'"><img src="../assets/logoParticulier/EDS_80X30.png" /></td>
                        <td v-if="company.logo=='EDSB_120X35.png'"><img src="../assets/logoParticulier/EDSB_80X30.png" /></td>
                        <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                        <td v-if="company.logo=='Elecocite_120X35.png'"><img src="../assets/logoParticulier/Elecocite_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_provence_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_provence_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_savoie_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_savoie_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_Strasbourg_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_Strasbourg_80X30.png" /></td>
                        <td v-if="company.logo=='Enalp_120X35.png'"><img src="../assets/logoParticulier/Enalp_80X30.png" /></td>
                        <td v-if="company.logo=='Endesa_120X35.png'"><img src="../assets/logoParticulier/Endesa_80X30.png" /></td>
                        <td v-if="company.logo=='Enercoop_120X35.png'"><img src="../assets/logoParticulier/Enercoop_80X30.png" /></td>
                        <td v-if="company.logo=='Energem_120X35.png'"><img src="../assets/logoParticulier/Energem_80X30.png" /></td>
                        <td v-if="company.logo=='ENERGIE_DICI_120X35.png'"><img src="../assets/logoParticulier/ENERGIE_DICI_80X30.png" /></td>
                        <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                        <td v-if="company.logo=='ENI_120X35.png'"><img src="../assets/logoParticulier/ENI_80X30.png" /></td>
                        <td v-if="company.logo=='Enovos_120X35.png'"><img src="../assets/logoParticulier/Enovos_80X30.png" /></td>
                        <td v-if="company.logo=='Gaz_de_Bordeaux_120X35.png'"><img src="../assets/logoParticulier/Gaz_de_Bordeaux_80X30.png" /></td>
                        <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                        <td v-if="company.logo=='Gazprom_120X35.png'"><img src="../assets/logoParticulier/Gazprom_80X30.png" /></td>
                        <td v-if="company.logo=='GEG_120X35.png'"><img src="../assets/logoParticulier/GEG_80X30.png" /></td>
                        <td v-if="company.logo=='GREEN_YELLOW_120X35.png'"><img src="../assets/logoParticulier/GREEN_YELLOW_80X30.png" /></td>
                        <td v-if="company.logo=='HAPPE_120X35.png'"><img src="../assets/logoParticulier/HAPPE_80X30.png" /></td>
                        <td v-if="company.logo=='IBERDROLA_120X35.png'"><img src="../assets/logoParticulier/IBERDROLA_80X30.png" /></td>
                        <td v-if="company.logo=='ILEK_120X35.png'"><img src="../assets/logoParticulier/ILEK_80X30.png" /></td>
                        <td v-if="company.logo=='LECLERCQ_120X35.png'"><img src="../assets/logoParticulier/LECLERCQ_80X30.png" /></td>
                        <td v-if="company.logo=='LUCIA_120X35.png'"><img src="../assets/logoParticulier/LUCIA_80X30.png" /></td>
                        <td v-if="company.logo=='MEGA_120X35.png'"><img src="../assets/logoParticulier/MEGA_80X30.png" /></td>
                        <td v-if="company.logo=='mint-energie_120X35.png'"><img src="../assets/logoParticulier/mint-energie_80X30.png" /></td>
                        <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                        <td v-if="company.logo=='OVO_120X35.png'"><img src="../assets/logoParticulier/OVO_80X30.png" /></td>
                        <td v-if="company.logo=='Picoty_120X35.png'"><img src="../assets/logoParticulier/Picoty_80X30.png" /></td>
                        <td v-if="company.logo=='PLANETE_OUI_120X35.png'"><img src="../assets/logoParticulier/PLANETE_OUI_80X30.png" /></td>
                        <td v-if="company.logo=='PLUM_120X35.png'"><img src="../assets/logoParticulier/PLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_80X30.png" /></td>
                        <td v-if="company.logo=='Proxelia_120X35.png'"><img src="../assets/logoParticulier/Proxelia_80X30.png" /></td>
                        <td v-if="company.logo=='Redeéo_120X35.png'"><img src="../assets/logoParticulier/Redeéo_80X30.png" /></td>
                        <td v-if="company.logo=='Selia_120X35.png'"><img src="../assets/logoParticulier/Selia_80X30.png" /></td>
                        <td v-if="company.logo=='SOWEE_120X35.png'"><img src="../assets/logoParticulier/SOWEE_80X30.png" /></td>
                        <td v-if="company.logo=='Synelva_120X35.png'"><img src="../assets/logoParticulier/Synelva_80X30.png" /></td>
                        <td v-if="company.logo=='TDE_120X35.png'"><img src="../assets/logoParticulier/TDE_80X30.png" /></td>
                        <td v-if="company.logo=='Urban_Solar_120X35.png'"><img src="../assets/logoParticulier/Urban_Solar_80X30.png" /></td>
                        <td v-if="company.logo=='Vattenfall_120X35.png'"><img src="../assets/logoParticulier/Vattenfall_80X30.png" /></td>
                        <td v-if="company.logo=='Wekiwi_120X35.png'"><img src="../assets/logoParticulier/Wekiwi_80X30.png" /></td>
                    <td v-if="company.logo=='yeli-xs_120X35.png'"><img src="../assets/logoParticulier/yeli-xs_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehp) ? "--": parseFloat(company.unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehc) ? "--": parseFloat(company.unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hp) ? "--": parseFloat(company.capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hc) ? "--": parseFloat(company.capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2023' in company">
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitairehp) ? "--": parseFloat(company['2023'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehc) ? "--": parseFloat(company['2023'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hp) ? "--": parseFloat(company['2023'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hc) ? "--": parseFloat(company['2023'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2024' in company">
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitairehp) ? "--": parseFloat(company['2024'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehc) ? "--": parseFloat(company['2024'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hp) ? "--": parseFloat(company['2024'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hc) ? "--": parseFloat(company['2024'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2025' in company">
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitairehp) ? "--": parseFloat(company['2025'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehc) ? "--": parseFloat(company['2025'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hp) ? "--": parseFloat(company['2025'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hc) ? "--": parseFloat(company['2025'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2026' in company">
                    <td>2026</td>
                    <td>{{isNaN(company['2026'].unitairehp) ? "--": parseFloat(company['2026'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].unitairehc) ? "--": parseFloat(company['2026'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hp) ? "--": parseFloat(company['2026'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_hc) ? "--": parseFloat(company['2026'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2026'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2026'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2026'].aboAns) ? "--": parseFloat(company['2026'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2026'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2026'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TVA) ? "--": numberWithSpaces(parseFloat(company['2026'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budget) ? "--": numberWithSpaces(parseFloat(company['2026'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2026'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.fournisseur == 'Ekwateur')">
                <tr>
                    <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehp) ? "--": parseFloat(company.unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehc) ? "--": parseFloat(company.unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hp) ? "--": parseFloat(company.capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hc) ? "--": parseFloat(company.capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr>
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitairehp) ? "--": parseFloat(company['2023'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehc) ? "--": parseFloat(company['2023'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hp) ? "--": parseFloat(company['2023'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hc) ? "--": parseFloat(company['2023'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr>
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitairehp) ? "--": parseFloat(company['2024'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehc) ? "--": parseFloat(company['2024'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hp) ? "--": parseFloat(company['2024'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hc) ? "--": parseFloat(company['2024'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr>
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitairehp) ? "--": parseFloat(company['2025'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehc) ? "--": parseFloat(company['2025'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hp) ? "--": parseFloat(company['2025'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hc) ? "--": parseFloat(company['2025'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.fournisseur == 'engie')">
                <tr v-if="company.nbrYears=='3' && company.type != 'Latitude'">
                    <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                    
                    <td>{{isNaN(company.unitairehp_First_Years) ? "--": parseFloat(company.unitairehp_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehc_First_Years) ? "--": parseFloat(company.unitairehc_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hp) ? "--": parseFloat(company.capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hc) ? "--": parseFloat(company.capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_First_Years) ? "--": parseFloat(company.consommationEnergie_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo_First_Years) ? "--": parseFloat(company.budgetAbo_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TVA_First_Years) ? "--": parseFloat(company.TVA_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_First_Years) ? "--": parseFloat(company.budget_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC_First_Years) ? "--": parseFloat(company.TotaleanuelleTTC_First_Years).toFixed(2)+"€"}}</td>
                    </td>
                </tr>
                <tr>
                    <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                    
                    <td>{{isNaN(company.unitairehp_Seconde_Years) ? "--": parseFloat(company.unitairehp_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehc_Seconde_Years) ? "--": parseFloat(company.unitairehc_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hp) ? "--": parseFloat(company.capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hc) ? "--": parseFloat(company.capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_Seconde_Years) ? "--": parseFloat(company.consommationEnergie_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo_Seconde_Years) ? "--": parseFloat(company.budgetAbo_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TVA_Seconde_Years) ? "--": parseFloat(company.TVA_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_Seconde_Years) ? "--": parseFloat(company.budget_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC_Seconde_Years) ? "--": parseFloat(company.TotaleanuelleTTC_Seconde_Years).toFixed(2)+"€"}}</td>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.fournisseur == 'OHM' && company.type=='Prix Fixe')">
                <tr>
                    <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                    <td>{{isNaN(company.unitairehp) ? "--": parseFloat(company.unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.unitairehc) ? "--": parseFloat(company.unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hp) ? "--": parseFloat(company.capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_hc) ? "--": parseFloat(company.capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2023' in company">
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitairehp) ? "--": parseFloat(company['2023'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].unitairehc) ? "--": parseFloat(company['2023'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hp) ? "--": parseFloat(company['2023'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_hc) ? "--": parseFloat(company['2023'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2024' in company">
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitairehp) ? "--": parseFloat(company['2024'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].unitairehc) ? "--": parseFloat(company['2024'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hp) ? "--": parseFloat(company['2024'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_hc) ? "--": parseFloat(company['2024'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2025' in company">
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitairehp) ? "--": parseFloat(company['2025'].unitairehp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].unitairehc) ? "--": parseFloat(company['2025'].unitairehc).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hp) ? "--": parseFloat(company['2025'].capa_hp).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_hc) ? "--": parseFloat(company['2025'].capa_hc).toFixed(2)+"€"}}</td>
                    
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
            </tbody>
        </table>

        <table id="mytableC5Base" style="display: none;"  v-if="typeCompteur=='C5BASE'">
            <tbody>
                <tr>
                    <td></td>
                  <td>Base</td>
                  <td>Base</td>
                  <td>CAR</td>
                  <td>Fourniture (part Abonnement) en €HTT/an</td>
                  <td>Fourniture (part Energie) en €HTT/an</td>
                  <td>Budget</td>
                  <td>TVA</td>
                  <td>Total annuel </td>
                  <td>Total annuel TTC</td>
                </tr>
            </tbody>
            <tbody v-for="(company, index) in lesDonneesFournisseur"
                    :key="company.name" v-if="(company.typeFournisseur=='AuFrn')||(company.typeFournisseur=='Actuelle') ||((company.fournisseur != 'engie') && 
                    (company.fournisseur != 'Ekwateur')&& (company.type!='Prix Fixe')&& (company.typeFournisseur!='extern'))">
                <tr>
                    
                    <td v-if="company.logo=='Alpiq_120X35.png'"><img src="../assets/logoParticulier/Alpiq_80X30.png" /></td>
                        <td v-if="company.logo=='Alterna_120X35.png'"><img src="../assets/logoParticulier/Alterna_80X30.png" /></td>
                        <td v-if="company.logo=='LLUM_120X35.png'"><img src="../assets/logoParticulier/LLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Antargaz_120X35.png'"><img src="../assets/logoParticulier/Antargaz_80X30.png" /></td>
                        <td v-if="company.logo=='Axpo_120X35.png'"><img src="../assets/logoParticulier/Axpo_80X30.png" /></td>
                        <td v-if="company.logo=='BARRY_120X35.png'"><img src="../assets/logoParticulier/BARRY_80X30.png" /></td>
                        <td v-if="company.logo=='BULB_120X35.png'"><img src="../assets/logoParticulier/BULB_80X30.png" /></td>
                        <td v-if="company.logo=='BUTAGAZ_120X35.png'"><img src="../assets/logoParticulier/BUTAGAZ_80X30.png" /></td>
                        <td v-if="company.logo=='CDISCOUNT_120X35.png'"><img src="../assets/logoParticulier/CDISCOUNT_80X30.png" /></td>
                        <td v-if="company.logo=='Dyneff_120X35.png'"><img src="../assets/logoParticulier/Dyneff_80X30.png" /></td>
                        <td v-if="company.logo=='Edenkia_120X35.png'"><img src="../assets/logoParticulier/Edenkia_80X30.png" /></td>
                        <td v-if="company.logo=='EDF_120X35.png'"><img src="../assets/logoParticulier/EDF_80X30.png" /></td>
                        <td v-if="company.logo=='EDS_120X35.png'"><img src="../assets/logoParticulier/EDS_80X30.png" /></td>
                        <td v-if="company.logo=='EDSB_120X35.png'"><img src="../assets/logoParticulier/EDSB_80X30.png" /></td>
                        <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                        <td v-if="company.logo=='Elecocite_120X35.png'"><img src="../assets/logoParticulier/Elecocite_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_provence_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_provence_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_savoie_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_savoie_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_Strasbourg_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_Strasbourg_80X30.png" /></td>
                        <td v-if="company.logo=='Enalp_120X35.png'"><img src="../assets/logoParticulier/Enalp_80X30.png" /></td>
                        <td v-if="company.logo=='Endesa_120X35.png'"><img src="../assets/logoParticulier/Endesa_80X30.png" /></td>
                        <td v-if="company.logo=='Enercoop_120X35.png'"><img src="../assets/logoParticulier/Enercoop_80X30.png" /></td>
                        <td v-if="company.logo=='Energem_120X35.png'"><img src="../assets/logoParticulier/Energem_80X30.png" /></td>
                        <td v-if="company.logo=='ENERGIE_DICI_120X35.png'"><img src="../assets/logoParticulier/ENERGIE_DICI_80X30.png" /></td>
                        <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                        <td v-if="company.logo=='ENI_120X35.png'"><img src="../assets/logoParticulier/ENI_80X30.png" /></td>
                        <td v-if="company.logo=='Enovos_120X35.png'"><img src="../assets/logoParticulier/Enovos_80X30.png" /></td>
                        <td v-if="company.logo=='Gaz_de_Bordeaux_120X35.png'"><img src="../assets/logoParticulier/Gaz_de_Bordeaux_80X30.png" /></td>
                        <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                        <td v-if="company.logo=='Gazprom_120X35.png'"><img src="../assets/logoParticulier/Gazprom_80X30.png" /></td>
                        <td v-if="company.logo=='GEG_120X35.png'"><img src="../assets/logoParticulier/GEG_80X30.png" /></td>
                        <td v-if="company.logo=='GREEN_YELLOW_120X35.png'"><img src="../assets/logoParticulier/GREEN_YELLOW_80X30.png" /></td>
                        <td v-if="company.logo=='HAPPE_120X35.png'"><img src="../assets/logoParticulier/HAPPE_80X30.png" /></td>
                        <td v-if="company.logo=='IBERDROLA_120X35.png'"><img src="../assets/logoParticulier/IBERDROLA_80X30.png" /></td>
                        <td v-if="company.logo=='ILEK_120X35.png'"><img src="../assets/logoParticulier/ILEK_80X30.png" /></td>
                        <td v-if="company.logo=='LECLERCQ_120X35.png'"><img src="../assets/logoParticulier/LECLERCQ_80X30.png" /></td>
                        <td v-if="company.logo=='LUCIA_120X35.png'"><img src="../assets/logoParticulier/LUCIA_80X30.png" /></td>
                        <td v-if="company.logo=='MEGA_120X35.png'"><img src="../assets/logoParticulier/MEGA_80X30.png" /></td>
                        <td v-if="company.logo=='mint-energie_120X35.png'"><img src="../assets/logoParticulier/mint-energie_80X30.png" /></td>
                        <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                        <td v-if="company.logo=='OVO_120X35.png'"><img src="../assets/logoParticulier/OVO_80X30.png" /></td>
                        <td v-if="company.logo=='Picoty_120X35.png'"><img src="../assets/logoParticulier/Picoty_80X30.png" /></td>
                        <td v-if="company.logo=='PLANETE_OUI_120X35.png'"><img src="../assets/logoParticulier/PLANETE_OUI_80X30.png" /></td>
                        <td v-if="company.logo=='PLUM_120X35.png'"><img src="../assets/logoParticulier/PLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_80X30.png" /></td>
                        <td v-if="company.logo=='Proxelia_120X35.png'"><img src="../assets/logoParticulier/Proxelia_80X30.png" /></td>
                        <td v-if="company.logo=='Redeéo_120X35.png'"><img src="../assets/logoParticulier/Redeéo_80X30.png" /></td>
                        <td v-if="company.logo=='Selia_120X35.png'"><img src="../assets/logoParticulier/Selia_80X30.png" /></td>
                        <td v-if="company.logo=='SOWEE_120X35.png'"><img src="../assets/logoParticulier/SOWEE_80X30.png" /></td>
                        <td v-if="company.logo=='Synelva_120X35.png'"><img src="../assets/logoParticulier/Synelva_80X30.png" /></td>
                        <td v-if="company.logo=='TDE_120X35.png'"><img src="../assets/logoParticulier/TDE_80X30.png" /></td>
                        <td v-if="company.logo=='Urban_Solar_120X35.png'"><img src="../assets/logoParticulier/Urban_Solar_80X30.png" /></td>
                        <td v-if="company.logo=='Vattenfall_120X35.png'"><img src="../assets/logoParticulier/Vattenfall_80X30.png" /></td>
                        <td v-if="company.logo=='Wekiwi_120X35.png'"><img src="../assets/logoParticulier/Wekiwi_80X30.png" /></td>
                    <td v-if="company.logo=='yeli-xs_120X35.png'"><img src="../assets/logoParticulier/yeli-xs_80X30.png" /></td>

                    <td>{{isNaN(company.unitaire) ? "--": parseFloat(company.unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_base) ? "--": parseFloat(company.capa_base).toFixed(2)+"€"}}</td>

                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.typeFournisseur == 'extern')">
                <tr>
                    <td v-if="company.logo=='Alpiq_120X35.png'"><img src="../assets/logoParticulier/Alpiq_80X30.png" /></td>
                        <td v-if="company.logo=='Alterna_120X35.png'"><img src="../assets/logoParticulier/Alterna_80X30.png" /></td>
                        <td v-if="company.logo=='LLUM_120X35.png'"><img src="../assets/logoParticulier/LLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Antargaz_120X35.png'"><img src="../assets/logoParticulier/Antargaz_80X30.png" /></td>
                        <td v-if="company.logo=='Axpo_120X35.png'"><img src="../assets/logoParticulier/Axpo_80X30.png" /></td>
                        <td v-if="company.logo=='BARRY_120X35.png'"><img src="../assets/logoParticulier/BARRY_80X30.png" /></td>
                        <td v-if="company.logo=='BULB_120X35.png'"><img src="../assets/logoParticulier/BULB_80X30.png" /></td>
                        <td v-if="company.logo=='BUTAGAZ_120X35.png'"><img src="../assets/logoParticulier/BUTAGAZ_80X30.png" /></td>
                        <td v-if="company.logo=='CDISCOUNT_120X35.png'"><img src="../assets/logoParticulier/CDISCOUNT_80X30.png" /></td>
                        <td v-if="company.logo=='Dyneff_120X35.png'"><img src="../assets/logoParticulier/Dyneff_80X30.png" /></td>
                        <td v-if="company.logo=='Edenkia_120X35.png'"><img src="../assets/logoParticulier/Edenkia_80X30.png" /></td>
                        <td v-if="company.logo=='EDF_120X35.png'"><img src="../assets/logoParticulier/EDF_80X30.png" /></td>
                        <td v-if="company.logo=='EDS_120X35.png'"><img src="../assets/logoParticulier/EDS_80X30.png" /></td>
                        <td v-if="company.logo=='EDSB_120X35.png'"><img src="../assets/logoParticulier/EDSB_80X30.png" /></td>
                        <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                        <td v-if="company.logo=='Elecocite_120X35.png'"><img src="../assets/logoParticulier/Elecocite_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_provence_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_provence_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_savoie_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_savoie_80X30.png" /></td>
                        <td v-if="company.logo=='Electricite_de_Strasbourg_120X35.png'"><img src="../assets/logoParticulier/Electricite_de_Strasbourg_80X30.png" /></td>
                        <td v-if="company.logo=='Enalp_120X35.png'"><img src="../assets/logoParticulier/Enalp_80X30.png" /></td>
                        <td v-if="company.logo=='Endesa_120X35.png'"><img src="../assets/logoParticulier/Endesa_80X30.png" /></td>
                        <td v-if="company.logo=='Enercoop_120X35.png'"><img src="../assets/logoParticulier/Enercoop_80X30.png" /></td>
                        <td v-if="company.logo=='Energem_120X35.png'"><img src="../assets/logoParticulier/Energem_80X30.png" /></td>
                        <td v-if="company.logo=='ENERGIE_DICI_120X35.png'"><img src="../assets/logoParticulier/ENERGIE_DICI_80X30.png" /></td>
                        <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>
                        <td v-if="company.logo=='ENI_120X35.png'"><img src="../assets/logoParticulier/ENI_80X30.png" /></td>
                        <td v-if="company.logo=='Enovos_120X35.png'"><img src="../assets/logoParticulier/Enovos_80X30.png" /></td>
                        <td v-if="company.logo=='Gaz_de_Bordeaux_120X35.png'"><img src="../assets/logoParticulier/Gaz_de_Bordeaux_80X30.png" /></td>
                        <td v-if="company.logo=='GazelEnergie_120X35.png'"><img src="../assets/logoParticulier/GazelEnergie_80X30.png" /></td>
                        <td v-if="company.logo=='Gazprom_120X35.png'"><img src="../assets/logoParticulier/Gazprom_80X30.png" /></td>
                        <td v-if="company.logo=='GEG_120X35.png'"><img src="../assets/logoParticulier/GEG_80X30.png" /></td>
                        <td v-if="company.logo=='GREEN_YELLOW_120X35.png'"><img src="../assets/logoParticulier/GREEN_YELLOW_80X30.png" /></td>
                        <td v-if="company.logo=='HAPPE_120X35.png'"><img src="../assets/logoParticulier/HAPPE_80X30.png" /></td>
                        <td v-if="company.logo=='IBERDROLA_120X35.png'"><img src="../assets/logoParticulier/IBERDROLA_80X30.png" /></td>
                        <td v-if="company.logo=='ILEK_120X35.png'"><img src="../assets/logoParticulier/ILEK_80X30.png" /></td>
                        <td v-if="company.logo=='LECLERCQ_120X35.png'"><img src="../assets/logoParticulier/LECLERCQ_80X30.png" /></td>
                        <td v-if="company.logo=='LUCIA_120X35.png'"><img src="../assets/logoParticulier/LUCIA_80X30.png" /></td>
                        <td v-if="company.logo=='MEGA_120X35.png'"><img src="../assets/logoParticulier/MEGA_80X30.png" /></td>
                        <td v-if="company.logo=='mint-energie_120X35.png'"><img src="../assets/logoParticulier/mint-energie_80X30.png" /></td>
                        <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                        <td v-if="company.logo=='OVO_120X35.png'"><img src="../assets/logoParticulier/OVO_80X30.png" /></td>
                        <td v-if="company.logo=='Picoty_120X35.png'"><img src="../assets/logoParticulier/Picoty_80X30.png" /></td>
                        <td v-if="company.logo=='PLANETE_OUI_120X35.png'"><img src="../assets/logoParticulier/PLANETE_OUI_80X30.png" /></td>
                        <td v-if="company.logo=='PLUM_120X35.png'"><img src="../assets/logoParticulier/PLUM_80X30.png" /></td>
                        <td v-if="company.logo=='Primeo_120X35.png'"><img src="../assets/logoParticulier/Primeo_80X30.png" /></td>
                        <td v-if="company.logo=='Proxelia_120X35.png'"><img src="../assets/logoParticulier/Proxelia_80X30.png" /></td>
                        <td v-if="company.logo=='Redeéo_120X35.png'"><img src="../assets/logoParticulier/Redeéo_80X30.png" /></td>
                        <td v-if="company.logo=='Selia_120X35.png'"><img src="../assets/logoParticulier/Selia_80X30.png" /></td>
                        <td v-if="company.logo=='SOWEE_120X35.png'"><img src="../assets/logoParticulier/SOWEE_80X30.png" /></td>
                        <td v-if="company.logo=='Synelva_120X35.png'"><img src="../assets/logoParticulier/Synelva_80X30.png" /></td>
                        <td v-if="company.logo=='TDE_120X35.png'"><img src="../assets/logoParticulier/TDE_80X30.png" /></td>
                        <td v-if="company.logo=='Urban_Solar_120X35.png'"><img src="../assets/logoParticulier/Urban_Solar_80X30.png" /></td>
                        <td v-if="company.logo=='Vattenfall_120X35.png'"><img src="../assets/logoParticulier/Vattenfall_80X30.png" /></td>
                        <td v-if="company.logo=='Wekiwi_120X35.png'"><img src="../assets/logoParticulier/Wekiwi_80X30.png" /></td>
                    <td v-if="company.logo=='yeli-xs_120X35.png'"><img src="../assets/logoParticulier/yeli-xs_80X30.png" /></td>
                    <td>{{isNaN(company.unitaire) ? "--": parseFloat(company.unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_base) ? "--": parseFloat(company.capa_base).toFixed(2)+"€"}}</td>

                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2023' in company">
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitaire) ? "--": parseFloat(company['2023'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_base) ? "--": parseFloat(company['2023'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr  v-if="'2024' in company">
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitaire) ? "--": parseFloat(company['2024'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_base) ? "--": parseFloat(company['2024'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr  v-if="'2025' in company">
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitaire) ? "--": parseFloat(company['2025'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_base) ? "--": parseFloat(company['2025'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr  v-if="'2026' in company">
                    <td>2026</td>
                    <td>{{isNaN(company['2026'].unitaire) ? "--": parseFloat(company['2026'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_base) ? "--": parseFloat(company['2026'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2026'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2026'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2026'].aboAns) ? "--": parseFloat(company['2026'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2026'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2026'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TVA) ? "--": numberWithSpaces(parseFloat(company['2026'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budget) ? "--": numberWithSpaces(parseFloat(company['2026'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2026'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.fournisseur == 'Ekwateur')">
                <tr>
                    <td v-if="company.logo=='Ekwateur_120X35.png'"><img src="../assets/logoParticulier/Ekwateur_80X30.png" /></td>
                    <td>{{isNaN(company.unitaire) ? "--": parseFloat(company.unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_base) ? "--": parseFloat(company.capa_base).toFixed(2)+"€"}}</td>

                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr>
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitaire) ? "--": parseFloat(company['2023'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_base) ? "--": parseFloat(company['2023'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr>
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitaire) ? "--": parseFloat(company['2024'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_base) ? "--": parseFloat(company['2024'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr>
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitaire) ? "--": parseFloat(company['2025'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_base) ? "--": parseFloat(company['2025'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.fournisseur == 'OHM' && company.type=='Prix Fixe')">
                <tr>
                    <td v-if="company.logo=='OHM_120X35.png'"><img src="../assets/logoParticulier/OHM_80X30.png" /></td>
                    <td>{{isNaN(company.unitaire) ? "--": parseFloat(company.unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_base) ? "--": parseFloat(company.capa_base).toFixed(2)+"€"}}</td>

                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie) ? "--": numberWithSpaces(parseFloat(company.consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo) ? "--": numberWithSpaces(parseFloat(company.budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TVA) ? "--": numberWithSpaces(parseFloat(company.TVA).toFixed(2))+"€---"}}</td>
                    <td>{{isNaN(company.budget) ? "--": numberWithSpaces(parseFloat(company.budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company.TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2023' in company">
                    <td>2023</td>
                    <td>{{isNaN(company['2023'].unitaire) ? "--": parseFloat(company['2023'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].capa_base) ? "--": parseFloat(company['2023'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2023'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2023'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2023'].aboAns) ? "--": parseFloat(company['2023'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2023'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2023'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2023'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TVA) ? "--": numberWithSpaces(parseFloat(company['2023'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].budget) ? "--": numberWithSpaces(parseFloat(company['2023'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2023'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2023'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2024' in company">
                    <td>2024</td>
                    <td>{{isNaN(company['2024'].unitaire) ? "--": parseFloat(company['2024'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].capa_base) ? "--": parseFloat(company['2024'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2024'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2024'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2024'].aboAns) ? "--": parseFloat(company['2024'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2024'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2024'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2024'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TVA) ? "--": numberWithSpaces(parseFloat(company['2024'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].budget) ? "--": numberWithSpaces(parseFloat(company['2024'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2024'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2024'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2025' in company">
                    <td>2025</td>
                    <td>{{isNaN(company['2025'].unitaire) ? "--": parseFloat(company['2025'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].capa_base) ? "--": parseFloat(company['2025'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2025'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2025'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2025'].aboAns) ? "--": parseFloat(company['2025'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2025'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2025'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2025'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TVA) ? "--": numberWithSpaces(parseFloat(company['2025'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].budget) ? "--": numberWithSpaces(parseFloat(company['2025'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2025'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2025'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
                <tr v-if="'2026' in company">
                    <td>2026</td>
                    <td>{{isNaN(company['2026'].unitaire) ? "--": parseFloat(company['2026'].unitaire).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].capa_base) ? "--": parseFloat(company['2026'].capa_base).toFixed(2)+"€"}}</td>
                    <td v-if="company['2026'].cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company['2026'].cee).toFixed(2)}} €</td>
                    <td>{{isNaN(company['2026'].aboAns) ? "--": parseFloat(company['2026'].aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company['2026'].consommationEnergie) ? "--": numberWithSpaces(parseFloat(company['2026'].consommationEnergie).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budgetAbo) ? "--": numberWithSpaces(parseFloat(company['2026'].budgetAbo).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TVA) ? "--": numberWithSpaces(parseFloat(company['2026'].TVA).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].budget) ? "--": numberWithSpaces(parseFloat(company['2026'].budget).toFixed(2))+"€"}}</td>
                    <td>{{isNaN(company['2026'].TotaleanuelleTTC) ? "--": numberWithSpaces(parseFloat(company['2026'].TotaleanuelleTTC).toFixed(2))+"€"}}</td>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="(company.fournisseur == 'engie')">
                <tr v-if="company.nbrYears=='3' && company.type != 'Latitude'">
                    <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>

                    <td>{{isNaN(company.unitaire_First_Years) ? "--": parseFloat(company.unitaire_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_base) ? "--": parseFloat(company.capa_base).toFixed(2)+"€"}}</td>

                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_First_Years) ? "--": parseFloat(company.consommationEnergie_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo_First_Years) ? "--": parseFloat(company.budgetAbo_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TVA_First_Years) ? "--": parseFloat(company.TVA_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_First_Years) ? "--": parseFloat(company.budget_First_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC_First_Years) ? "--": parseFloat(company.TotaleanuelleTTC_First_Years).toFixed(2)+"€"}}</td>
                    </td>
                </tr>
                <tr>
                    <td v-if="company.logo=='Engie_120X35.png'"><img src="../assets/logoParticulier/Engie_80X30.png" /></td>

                    <td>{{isNaN(company.unitaire_Seconde_Years) ? "--": parseFloat(company.unitaire_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.capa_base) ? "--": parseFloat(company.capa_base).toFixed(2)+"€"}}</td>

                    <td v-if="company.cee == 'NON SOUMIS'">--</td>
                    <td v-else>{{parseFloat(company.cee).toFixed(2)}} €</td>

                    <td>{{isNaN(company.aboAns) ? "--": parseFloat(company.aboAns).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.consommationEnergie_Seconde_Years) ? "--": parseFloat(company.consommationEnergie_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budgetAbo_Seconde_Years) ? "--": parseFloat(company.budgetAbo_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TVA_Seconde_Years) ? "--": parseFloat(company.TVA_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.budget_Seconde_Years) ? "--": parseFloat(company.budget_Seconde_Years).toFixed(2)+"€"}}</td>
                    <td>{{isNaN(company.TotaleanuelleTTC_Seconde_Years) ? "--": parseFloat(company.TotaleanuelleTTC_Seconde_Years).toFixed(2)+"€"}}</td>
                    </td>
                </tr>
            </tbody>
        </table>
        
    </div>
</template>

<script>
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import loadingComponent from "../components/ui/loading-component.vue"
import download from 'downloadjs'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import axios from 'axios'
export default {
    name: 'TextView',
    components:{loadingComponent},
    props: {
        parametreCompteur: null,
        donnerDesFournisseur: [],
        typeCompteur: null,
        fournisseurActuelle: null,
        indexes: [],
        numCompteur: null,
        dataEnedis: [],
        nbrMonthsEnedis: 0,
        turpeData: [],
        donneDeCotation: [],
    },
  data: () => ({
    dialog: false,
    loading: false,
    img : null,
    kva: null,
    horaire: [],
    bt: null,
    temps: [],
    labels: [],
    messageDialogeError: "",
    generatedPdf: null,
    lesDonneesFournisseur: [],
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  created() {
  },
  methods: {

    numberWithSpaces(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    },
    async offreDetailler(){
        
        const doc = new jsPDF('l', 'pt', 'a4')
        var bestOffre = null
        if(this.fournisseurActuelle == true){
                bestOffre = this.lesDonneesFournisseur[1]
        }else{
                bestOffre = this.lesDonneesFournisseur[0]
        }
        console.log(bestOffre)
        let result = null
        await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + 'offreDetailles/',
            method: 'POST',
            data: {'dataCotation': this.donneDeCotation,
                    'parametreCompteur': this.parametreCompteur,
                    'bestOffre': bestOffre,
                },
        }).then((res) => {
            console.log(res.data.result)
            if(res.data.result == true){
                result = res.data.data
            }else{
                result = false
            }
        })
        if(result!=false){
            var tableColumn = ['Année','Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre', 'Total'] 

            var tableRows = []
            for(var annee in result) {
                var somme = 0
                var result1 = result[annee];
                var line = [annee, '', '', '', '','','','','','','','','', '']
                for(var month in result1) {
                    var value = result1[month];
                    var index = tableColumn.indexOf(month)
                    if(index != -1){
                        line[index]=this.numberWithSpaces(value)+" €"
                    }
                }
                tableRows.push(line)
            }
            var columnStyle = {
                0: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 50, minCellHeight: 35 ,fontStyle: 'bold'},
                1: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 55, minCellHeight: 35 ,fontStyle: 'normal'},
                2: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 55, minCellHeight: 35 ,fontStyle: 'normal'},
                3: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 53, minCellHeight: 35 ,fontStyle: 'normal'},
                4: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 55, minCellHeight: 35 ,fontStyle: 'normal'},
                5: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 53, minCellHeight: 35 ,fontStyle: 'normal'},
                6: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 55, minCellHeight: 35 ,fontStyle: 'normal'},
                7: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 60, minCellHeight: 35 ,fontStyle: 'normal'},
                8: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 58, minCellHeight: 35 ,fontStyle: 'normal'},
                9: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 62, minCellHeight: 35 ,fontStyle: 'normal'},
                10: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 60, minCellHeight: 35 ,fontStyle: 'normal'},
                11: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 60, minCellHeight: 35 ,fontStyle: 'normal'},
                12: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 60, minCellHeight: 35 ,fontStyle: 'normal'},
                13: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 55, minCellHeight: 35 ,fontStyle: 'bold'},
            }
            doc.autoTable(tableColumn, tableRows, 
            { 
                startX: 5,
                startY: 190,
                columnStyles: columnStyle,
                headStyles :{fillColor : [210, 210, 210], textColor: 0, halign: 'center',valign: 'middle'}
            })
            let blob = doc.output("arraybuffer");
            return blob;
        }else{
            return false
        }
    },
    getImageLogo(logo) {
      return '../assets/logoParticulier/' + logo
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return ` €{month}/ €{day}/ €{year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return ` €{year}- €{month.padStart(2, '0')}- €{day.padStart(2, '0')}`
    },
    async generateTableRecapulatif(){
        var columnStyle = null
        if(this.fournisseurActuelle == true){
            columnStyle = {
                0: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 120, minCellHeight: 35 ,fontStyle: 'normal'},
                1: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 140, minCellHeight: 35 ,fontStyle: 'normal'},
                2: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
                3: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
                4: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
                5: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
                6: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
            }
        }else{
            columnStyle = {
                0: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 120, minCellHeight: 35 ,fontStyle: 'normal'},
                1: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 140, minCellHeight: 35 ,fontStyle: 'normal'},
                2: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
                3: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
                4: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
                5: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 255, fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 35 ,fontStyle: 'normal'},
            }
        }
        let startLeft = 150;
        const doc = new jsPDF('l', 'pt', 'a4')
        doc.setFontType("bold");
        doc.setFontSize(24);
        doc.setTextColor('#042A5F');
        doc.text(75, 120, "Récapitulatif")
         doc.autoTable({
            html: '#table_recapulatif',
            columnStyles: columnStyle,
            theme: 'grid',
            margin: { left: startLeft, top:140 },
            bodyStyles: {
                fontSize: 5.5,
                halign: 'center',
                valign: 'middle',
                textColor: 0,
            },
            didParseCell: function (data) {
                if (data.row.index === 0) {
                    data.cell.styles.fillColor = [240, 240, 240];
                }
                if(data.row.index % 2 === 0){
                    data.cell.styles.fillColor = [240, 240, 240];
                }
            },
            didDrawCell: function(data) {
                if (data.row.index >= 1 && data.column.index === 0 && data.cell.section === 'body') {
                    try{
                        var td = data.cell.raw;
                        var img = td.getElementsByTagName('img')[0];
                        var dim1 = data.cell.height;
                        var dim2 = data.cell.width;
                        doc.addImage(img, data.cell.x,  data.cell.y, dim2, dim1);
                    }catch(erro){

                    }
                }
            }
        });
        doc.setFontType("bold");
        doc.setFontSize(24);
        doc.setTextColor('#042A5F');
        doc.text(75, 420, "Répartition des coûts")
        
        doc.setFontSize(10);
        doc.setFontType("bold");
        doc.setTextColor('#1f1e1d');
        doc.text(73, 450, "Savez-vous ce qui compose votre facture d’électricité ? ")
        doc.setFontSize(8);
        doc.setFontType("normal");
        doc.setTextColor('#1f1e1d');
        doc.text(73, 475, "Il faut retenir que seule la partie Energies est négociable. ")
        doc.text(73, 492, "Les taxes et contributions sont appliquées sur l’abonnement")
        doc.text(73, 505, "et sur la consommation.")
        doc.text(73, 526, "Le TURPE est déterminé par la CRE et finance la maintenance")
        doc.text(73, 538, "du réseau d'électricité.")
        
        var tr =  parseFloat(parseFloat(this.turpeData['energieTurp']) + parseFloat(this.turpeData['aboTurpe'])).toFixed(2)
        var cta = parseFloat(this.turpeData['ctaFinal'])
        var tcfe =  parseFloat(this.turpeData['tcfeFinal'])
        var cspe = parseFloat(this.turpeData['cspeFinal'])
        var tx = 0
        tx = (cta + tcfe + cspe).toFixed(2)
        var en = 0
        var tv = 0
        if(this.fournisseurActuelle == true){
            en = parseFloat(this.lesDonneesFournisseur[1]['consommationEnergie']).toFixed(2)
            tv = parseFloat(this.lesDonneesFournisseur[1]['TVA']).toFixed(2)
        }else{
            en = parseFloat(this.lesDonneesFournisseur[0]['consommationEnergie']).toFixed(2)
            tv = parseFloat(this.lesDonneesFournisseur[0]['TVA']).toFixed(2)
        }

       
        let payload1 = {
            energie: en,
            turpe: tr,
            tva: tv,
            taxe: tx,
        }
        await axios.get(process.env.VUE_APP_URL_API_CLIENT + 'getEnergieGraph/',{
                params: payload1,
        })
        .then((res) => {
            this.img  = res.data.some;
            doc.addImage(this.img, 'PNG', 380, 370, 400, 200);
        })
    

        let blob = doc.output("arraybuffer");
        return blob;
    },
    async getsmallGraphe(enr, tup, tv, tax){
        let blob = null
          const doc = new jsPDF('l', 'pt', 'a4')

          let payload1 = {
              energie: enr,
              turpe: tup,
              tva: tv,
              taxe: tax,
          }
            if(this.nbrMonthsEnedis>=8){
                await axios.get('https://flaskapi.gaz-elec-moinscher.fr/getSmallGraph/',{
                    
                        params: payload1,
                }).then((res) => {
                    this.img  = res.data.some;
                    doc.addImage(this.img, 'JPEG', 15, 150, 800, 400);
                })
            }
            blob = doc.output("arraybuffer")
        return blob;
        
    },
    async getBarGraph(){
        let blob = null
        var doc = null
        var result_Enedis = false
        
        
        let payload = {
            //numCompteur: 30001460437381,
            //numCompteur: 25957452924301,
            numCompteur: this.numCompteur,
        }
        var jsonData = null
        var apiData = null
        result_Enedis = true
        //temprelle
        /*var apiData = [{"result":"Found","typeCompteur":"HTACU5","kva":"270","CAHPH":{"12":20.102,"11":27.961,"10":0,"09":0,"08":0,"07":0,"06":0,"05":0,"04":0,"03":10.97,"02":7.85,"01":13.192},"CAHCH":{"12":14.689,"11":14.156,"10":0,"09":0,"08":0,"07":0,"06":0,"05":0,"04":0,"03":6.553,"02":6.828,"01":11.946},"CAHPE":{"12":0,"11":0,"10":23.653,"09":12.989,"08":14.059,"07":15.202,"06":12.273,"05":11.036,"04":7.026,"03":0,"02":0,"01":0},"CAHCE":{"12":0,"11":0,"10":12.487,"09":5.969,"08":6.77,"07":6.662,"06":5.553,"05":6.224,"04":4.441,"03":0,"02":0,"01":0},"Pointe":{"12":7.237,"11":0,"10":0,"09":0,"08":0,"07":0,"06":0,"05":0,"04":0,"03":0,"02":2.679,"01":4.569},"kvaHPH":"270","kvaHCH":"270","kvaHPE":"270","kvaHCE":"270","kvaPOINTE":"270","resultMesurs":"Found"}]
        jsonData = JSON.stringify(apiData)
        result_Enedis = true*/
        /*await axios.get(process.env.VUE_APP_URL_API_CLIENT +'/enedisApi/getDonnerCompteurParMonth.php',{
                    params: payload,
          }).then((res) => {
              if(res.data[0].result === 'Found' && res.data[0].resultMesurs==='Found'){
                    result_Enedis = true
                    jsonData = JSON.stringify(res.data)
                    apiData = res.data
              }else{
                    result_Enedis = false
              }
          })*/
            
        var doc = null
        if(result_Enedis == true){
            if(this.typeCompteur=="C5BASE"){
                doc = new jsPDF('l', 'pt', 'a4')
            }else{
                doc = await this.getConsomationTotaleGraphe(jsonData)
            }
            //les table kva(puissance souscrite)
            doc.setFontType("bold");
            doc.setFontSize(10);
            doc.setTextColor(38, 41, 39);
            doc.text(480, 110, "PUISSANCE SOUSCRITE")
            var bodyTable = null
            if(this.typeCompteur=="C5BASE"){
                bodyTable = [
                        [
                            { content: 'BASE', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ],
                        [
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['BASE']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ]
                ]
                doc.autoTable({
                    body:  bodyTable,
                    startY: 120, 
                    margin: { left: 480},
                });
            }else if(this.typeCompteur=="C5HP"){
                bodyTable = [
                        [
                            { content: 'HP', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: 'HC', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ],
                        [
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['BASE']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['BASE']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ]
                ]
                doc.autoTable({
                    body:  bodyTable,
                    startY: 120, 
                    margin: { left: 480},
                });
            }else if(this.typeCompteur=="C4"){
                bodyTable = [
                        [
                            { content: 'HPH', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: 'HCH', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: 'HPE', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: 'HCE', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ],
                        [
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['HPH']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['HCH']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['HPE']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['HCE']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ]
                ]
                doc.autoTable({
                    body:  bodyTable,
                    startY: 120, 
                    margin: { left: 400},
                });
            }else if(this.typeCompteur=="C3"){

                bodyTable = [
                        [
                            { content: 'HPH', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: 'HCH', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: 'HPE', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: 'HCE', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: 'POINTE', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ],
                        [
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['HPH']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['HCH']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['HPE']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['HCE']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            { content: this.dataEnedis['donneTechniqueInfo'][0]['data']['PTE']+" kVA", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                    ]
                ]
                doc.autoTable({
                    body:  bodyTable,
                    startY: 120, 
                    margin: { left: 400},
                });
            }
            //consommation annuelle
            if(this.typeCompteur=="C5BASE"){
                if ('donne' in this.dataEnedis['mesuresInfo'][0]){
                    doc.setFontType("bold");
                    doc.setFontSize(10);
                    doc.setTextColor(38, 41, 39);
                    doc.text(480, 180, "CONSOMMATION ANNUELLE")
                    var consoAnnuelle = 0
                    /*for (var k in apiData[0]['CABASE']){
                        consoAnnuelle = consoAnnuelle+apiData[0]['CABASE'][k]['value']
                    }
                    */
                    consoAnnuelle = this.dataEnedis['mesuresInfo'][0]['donne']['BASE']['somme']
                    bodyTable = [
                            [
                                { content: parseFloat(consoAnnuelle).toFixed(2) +" MMh", colSpan: 1, styles: {halign: 'center',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineColor: [255, 255, 255], lineWidth:1, cellWidth: 70, minCellHeight: 25 ,fontStyle: 'normal'} },
                            ]
                    ]
                    doc.autoTable({
                        body:  bodyTable,
                        startY: 190, 
                        margin: { left: 480},
                    });
                }
            }
            
            //information sur la societe
            doc.autoTable({
                body:  [
                        [
                            { content: this.lesDonneesFournisseur[0]['nomSociete'], colSpan: 1, styles: { halign: 'left',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineWidth:0, cellWidth: 300, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ],
                        [
                            { content: this.lesDonneesFournisseur[0]['adressePostale'], colSpan: 1, styles: {halign: 'left',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineWidth:0, cellWidth: 300, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ],
                        [
                            { content: "RAE : "+this.numCompteur, colSpan: 1, styles: {halign: 'left',valign: 'middle', fillColor: [208,247,238], textColor: 0,  fontSize:9, lineWidth:0, cellWidth: 300, minCellHeight: 25 ,fontStyle: 'normal'} },
                        ]
                ],
                startY: 150, 
                margin: { left: 60},
            });
            doc.setFontType("bold");
            doc.setFontSize(24);
            doc.setTextColor('#042A5F');
            doc.text(60, 120, "VOS INFORMATIONS")

        }
        if(this.nbrMonthsEnedis>=8){
          if(result_Enedis == true){
              let payload1 = {
                    //jsonData est la reponse de enedis qui contient la consommation par mois
                    data: this.dataEnedis,
                    parametreCompteur: this.parametreCompteur,
              }
              //appele de api flask qui convert les data en graph du bar
            doc.setFontType("bold");
            doc.setFontSize(24);
            doc.setTextColor('#042A5F');
            doc.text(60, 270, "VOTRE HISTORIQUE DE CONSOMMATION (MWh)")
            await axios.get(process.env.VUE_APP_URL_API_CLIENT + 'getBarGraphNewVersionDjango/',{
                params: payload1,
            })
            .then((res) => {
                var Tmpimg  = res.data.some;
                
                if(this.typeCompteur=="C5BASE"){
                    doc.addImage(Tmpimg, 'JPEG', 140, 280, 490, 240);
                }else{
                    doc.addImage(Tmpimg, 'JPEG', 300, 280, 490, 240);
                }
            })
                blob = doc.output("arraybuffer")
                return blob
          }else{
              return 0
          }
        }else{
            return 0
        }
    },
    //la somme du consommation pour les dernier 12 mois calculer et convertie en graph de bar horizontal
    async getConsomationTotaleGraphe(jsonData){
        var result_Enedis_Horaire = false
        const doc = new jsPDF('l', 'pt', 'a4')
        if(this.nbrMonthsEnedis>=8){
            let payload1 = {
                //jsonData est la reponse de enedis qui contient la consommation par mois
                data: this.dataEnedis,
                parametreCompteur: this.parametreCompteur,
            }
            await axios.get(process.env.VUE_APP_URL_API_CLIENT + 'getSommeConsoGraphNewVersionDjango/',{
                
                    params: payload1,
            })
            .then((res) => {
                this.img  = res.data.some;
                doc.addImage(this.img, 'PNG',  30, 240, 240, 250);
            })
        }
        return doc;
    },
    generatePdf(){
        // 20 pour c3 | 30 pour c4 | 50 pour c5 hphc | 60 c5 base
        let startLeft = 0;
        const doc = new jsPDF('l', 'pt', 'a4')
        doc.setFontType("bold");
        doc.setFontSize(24);
        doc.setTextColor('#042A5F');
        doc.text(75, 100, "LES OFFRES EN DÉTAILS")
        // le grand table qui contient la consommation deviser par compteur 
        console.log(this.typeCompteur)
        console.log(this.typeCompteur)
        console.log(this.typeCompteur)
        if(this.typeCompteur == 'C5BASE'){
            // fournisseur Table c5 base
                startLeft = 60;
                doc.autoTable({
                    body: [
                            [
                                { 
                                    content: '', 
                                    colSpan: 1, styles: { halign: 'center' , lineWidth:1} 
                                },
                                { 
                                    content: 'Côut énergie', 
                                    colSpan: 3, styles: { halign: 'center',valign: 'middle' , lineWidth:1, textColor:[255,255,255], fillColor: [65, 184, 213] } 
                                },
                                { 
                                    content: 'Fournisseur (part Energie)',
                                    colSpan: 3, styles: { halign: 'center',valign: 'middle' , lineWidth:1, fillColor: [255, 219, 68]} 
                                },
                                { 
                                    content: 'Facture Annuelle', 
                                    colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, textColor:[255,255,255], fillColor: [251, 175, 59]} 
                                }
                            ],
                            [
                                { content: 'Fournisseur (part Energie)', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 80, fontSize:8} },
                                { content: 'PRIX FOURNITURE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 60, fontSize:8} },
                                { content: 'CAPACITE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 60, fontSize:8} },
                                { content: 'CEE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 50, fontSize:8} },
                                { content: 'Abonnement fournisseur', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 65, fontSize:8} },
                                { content: 'Part Energie', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 65, fontSize:8} },
                                { content: 'Consommation Abo', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 65, fontSize:8} },
                                { content: '', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 200, fontSize:8} },
                            ]
                    ],
                    margin: { left: startLeft, top:120},
                });

                doc.autoTable({
                    html: '#mytableC5Base',
                    columnStyles: {
                        0: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 80, minCellHeight: 30 ,fontStyle: 'normal'},
                        1: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 60, minCellHeight: 30 ,fontStyle: 'normal'},
                        2: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 60, minCellHeight: 30 ,fontStyle: 'normal'},
                        3: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                        4: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 65, minCellHeight: 30 ,fontStyle: 'normal'},
                        5: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 65, minCellHeight: 30 ,fontStyle: 'normal'},
                        6: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 65, minCellHeight: 30 ,fontStyle: 'normal'},
                        7: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 60, minCellHeight: 30 ,fontStyle: 'normal'},
                        8: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 30 ,fontStyle: 'normal'},
                        9: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 70, minCellHeight: 30 ,fontStyle: 'normal'},
                    },
                    theme: 'grid',
                    margin: { left: startLeft },
                    bodyStyles: {
                        fontSize: 5.5,
                        halign: 'center',
                        valign: 'middle',
                        textColor: 0,
                    },
                    didParseCell: function (data) {
                        if (data.row.index === 0 && data.column.index >= 1 && data.column.index <=3  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [65, 184, 213];
                            data.cell.styles.textColor = 255
                        }
                        if (data.row.index === 0 && data.column.index > 3 && data.column.index <=6  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [255, 219, 68];
                        }
                        if (data.row.index === 0 && data.column.index > 6 && data.column.index <=10  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [251, 175, 59];
                            data.cell.styles.textColor = 255
                        }
                    },
                    
                    didDrawCell: function(data) {
                        if (data.row.index >= 1 && data.column.index === 0 && data.cell.section === 'body') {
                            try{
                                var td = data.cell.raw;
                                var img = td.getElementsByTagName('img')[0];
                                var dim1 = data.cell.height;
                                var dim2 = data.cell.width;
                                doc.addImage(img, data.cell.x,  data.cell.y, dim2, dim1);
                            }catch(erro){

                            }
                        }
                    }
                });
            
        } else if(this.typeCompteur == 'C5HP'){
            startLeft = 50;
            // fournisseur Table c5 hp hc
            
                doc.autoTable({
                    body: [
                            [
                                { 
                                    content: '', 
                                    colSpan: 1, styles: { halign: 'center' , lineWidth:1} 
                                },
                                { 
                                    content: 'Côut énergie', 
                                    colSpan: 3, styles: { halign: 'center',valign: 'middle' , lineWidth:1, textColor:[255,255,255], fillColor: [65, 184, 213] } 
                                },
                                { 
                                    content: 'Fournisseur (part Energie)',
                                    colSpan: 3, styles: { halign: 'center',valign: 'middle' , lineWidth:1, fillColor: [255, 219, 68]} 
                                },
                                { 
                                    content: 'Facture Annuelle', 
                                    colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, textColor:[255,255,255], fillColor: [251, 175, 59]} 
                                }
                            ],
                            [
                                { content: 'Fournisseur (part Energie)', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 80, fontSize:8} },
                                { content: 'PRIX FOURNITURE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 100, fontSize:8} },
                                { content: 'CAPACITE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 100, fontSize:8} },
                                { content: 'CEE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 50, fontSize:8} },
                                { content: 'Abonnement fournisseur', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 65, fontSize:8} },
                                { content: 'Part Energie', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 65, fontSize:8} },
                                { content: 'Consommation Abo', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 65, fontSize:8} },
                                { content: '', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 175, fontSize:8} },
                            ]
                    ],
                    margin: { left: startLeft, top:120},
                });

                doc.autoTable({
                    html: '#mytableC5Hp',
                    columnStyles: {
                        0: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 80, minCellHeight: 30 ,fontStyle: 'normal'},
                        1: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                        2: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                        3: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                        4: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                        5: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                        6: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 65, minCellHeight: 30 ,fontStyle: 'normal'},
                        7: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 65, minCellHeight: 30 ,fontStyle: 'normal'},
                        8: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 65, minCellHeight: 30 ,fontStyle: 'normal'},
                        9: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 55, minCellHeight: 30 ,fontStyle: 'normal'},
                        10: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 60, minCellHeight: 30 ,fontStyle: 'normal'},
                        11: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:7, lineWidth:1, cellWidth: 60, minCellHeight: 30 ,fontStyle: 'normal'},
                    },
                    theme: 'grid',
                    margin: { left: startLeft },
                    bodyStyles: {
                        fontSize: 5.5,
                        halign: 'center',
                        valign: 'middle',
                        textColor: 0,
                    },
                    didParseCell: function (data) {
                        if (data.row.index === 0 && data.column.index >= 1 && data.column.index <=5  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [65, 184, 213];
                            data.cell.styles.textColor = 255
                        }
                        if (data.row.index === 0 && data.column.index > 5 && data.column.index <=8  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [255, 219, 68];
                        }
                        if (data.row.index === 0 && data.column.index > 8 && data.column.index <=12  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [251, 175, 59];
                            data.cell.styles.textColor = 255
                        }
                    },
                    didDrawCell: function(data) {
                        if (data.row.index >= 1 && data.column.index === 0 && data.cell.section === 'body') {
                            try{
                                var td = data.cell.raw;
                                var img = td.getElementsByTagName('img')[0];
                                var dim1 = data.cell.height;
                                var dim2 = data.cell.width;
                                doc.addImage(img, data.cell.x,  data.cell.y, dim2, dim1);
                            }catch(erro){

                            }
                        }
                    }
                });
            

        }else if(this.typeCompteur == 'C3'){
            // fournisseur Table c3
            startLeft = 15;
            doc.autoTable({
                body: [
                        [
                            { 
                                content: '', 
                                colSpan: 1, styles: { halign: 'center' , lineWidth:1} 
                            },
                            { 
                                content: 'Côut énergie', 
                                colSpan: 3, styles: { halign: 'center',valign: 'middle' , lineWidth:1, textColor:[255,255,255], fillColor: [65, 184, 213] } 
                            },
                            { 
                                content: 'Fournisseur (part Energie)',
                                colSpan: 3, styles: { halign: 'center',valign: 'middle' , lineWidth:1, fillColor: [255, 219, 68]} 
                            },
                            { 
                                content: 'Facture Annuelle', 
                                colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, textColor:[255,255,255], fillColor: [251, 175, 59]} 
                            }
                        ],
                        [
                            { content: 'Fournisseur (part Energie)', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 75, fontSize:8} },
                            { content: 'PRIX FOURNITURE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 185, fontSize:8} },
                            { content: 'CAPACITE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 185, fontSize:8} },
                            { content: 'CEE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 37, fontSize:8} },
                            { content: 'Abonnement fournisseur', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 55, fontSize:8} },
                            { content: 'Part Energie', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 55, fontSize:8} },
                            { content: 'Consommation Abo', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 55, fontSize:8} },
                            { content: '', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 140, fontSize:8} },
                        ]
                ],
                //15
                margin: { left: startLeft, top:120},
            });

            doc.autoTable({
                html: '#mytableC3',
                columnStyles: {
                    0: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 75, minCellHeight: 30 ,fontStyle: 'normal'},
                    1: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    2: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    3: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    4: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    5: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},//pointe
                    6: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    7: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    8: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    9: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    10: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},//pointe
                    11: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 37, minCellHeight: 30 ,fontStyle: 'normal'},
                    12: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 55, minCellHeight: 30 ,fontStyle: 'normal'},
                    13: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 55, minCellHeight: 30 ,fontStyle: 'normal'},
                    14: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 55, minCellHeight: 30 ,fontStyle: 'normal'},
                    15: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                    16: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                    17: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                },
                theme: 'grid',
                margin: { left: startLeft },
                bodyStyles: {
                    fontSize: 5.5,
                    halign: 'center',
                    valign: 'middle',
                    textColor: 0,
                },
                didParseCell: function (data) {
                    if (data.row.index === 0 && data.column.index >= 1 && data.column.index <=11  && data.cell.section === 'body') {
                        data.cell.styles.fillColor = [65, 184, 213];
                        data.cell.styles.textColor = 255
                    }
                    if (data.row.index === 0 && data.column.index > 11 && data.column.index <=14  && data.cell.section === 'body') {

                        data.cell.styles.fillColor = [255, 219, 68];
                    }
                    if (data.row.index === 0 && data.column.index > 14 && data.column.index <=19  && data.cell.section === 'body') {
                        data.cell.styles.fillColor = [251, 175, 59];
                        data.cell.styles.textColor = 255
                    }
                },
                didDrawCell: function(data) {
                    if (data.row.index >= 1 && data.column.index === 0 && data.cell.section === 'body') {
                        try{
                            var td = data.cell.raw;
                            var img = td.getElementsByTagName('img')[0];
                            var dim1 = data.cell.height;
                            var dim2 = data.cell.width;
                            doc.addImage(img, data.cell.x,  data.cell.y, dim2, dim1);
                        }catch(erro){

                        }
                    }
                }
            });
        }else if(this.typeCompteur == 'C4'){
            // fournisseur Table c4
                startLeft = 30;
                doc.autoTable({
                    body: [
                            [
                                { 
                                    content: '', 
                                    colSpan: 1, styles: { halign: 'center' , lineWidth:1} 
                                },
                                { 
                                    content: 'Côut énergie', 
                                    colSpan: 3, styles: { halign: 'center',valign: 'middle' , lineWidth:1, textColor:[255,255,255], fillColor: [65, 184, 213] } 
                                },
                                { 
                                    content: 'Fournisseur (part Energie)',
                                    colSpan: 3, styles: { halign: 'center',valign: 'middle' , lineWidth:1, fillColor: [255, 219, 68]} 
                                },
                                { 
                                    content: 'Facture Annuelle', 
                                    colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, textColor:[255,255,255], fillColor: [251, 175, 59]} 
                                }
                            ],
                            [
                                { content: 'Fournisseur (part Energie)', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 80, fontSize:8} },
                                { content: 'PRIX FOURNITURE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 160, fontSize:8} },
                                { content: 'CAPACITE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 160, fontSize:8} },
                                { content: 'CEE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 40, fontSize:8} },
                                { content: 'Abonnement fournisseur', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 60, fontSize:8} },
                                { content: 'Part Energie', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 60, fontSize:8} },
                                { content: 'Consommation Abo', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 64, fontSize:8} },
                                { content: '', colSpan: 1, styles: { halign: 'center',valign: 'middle' , lineWidth:1, cellWidth: 140, fontSize:8} },
                            ]
                    ],
                    //30
                    margin: { left: startLeft, top:120},
                });

                doc.autoTable({
                    html: '#mytableC4',
                    columnStyles: {
                        0: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 80, minCellHeight: 30 ,fontStyle: 'normal'},
                        1: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        2: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        3: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        4: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        5: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        6: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        7: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        8: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        9: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        10: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 60, minCellHeight: 30 ,fontStyle: 'normal'},
                        11: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 60, minCellHeight: 30 ,fontStyle: 'normal'},
                        12: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 64, minCellHeight: 30 ,fontStyle: 'normal'},
                        13: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 40, minCellHeight: 30 ,fontStyle: 'normal'},
                        14: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                        15: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0,  fontSize:6, lineWidth:1, cellWidth: 50, minCellHeight: 30 ,fontStyle: 'normal'},
                    },
                    theme: 'grid',
                    margin: { left: startLeft},
                    bodyStyles: {
                        fontSize: 5.5,
                        halign: 'center',
                        valign: 'middle',
                        textColor: 0,
                    },
                    didParseCell: function (data) {
                        
                        if (data.row.index === 0 && data.column.index >= 1 && data.column.index <=9  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [65, 184, 213];
                            data.cell.styles.textColor = 255
                        }
                        if (data.row.index === 0 && data.column.index > 9 && data.column.index <=12  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [255, 219, 68];
                        }
                        if (data.row.index === 0 && data.column.index > 12 && data.column.index <=18  && data.cell.section === 'body') {
                            data.cell.styles.fillColor = [251, 175, 59];
                            data.cell.styles.textColor = 255
                        }
                    },
                    didDrawCell: function(data) {
                        if (data.row.index >= 1 && data.column.index === 0 && data.cell.section === 'body') {
                            try{
                                var td = data.cell.raw;
                                var img = td.getElementsByTagName('img')[0];
                                var dim1 = data.cell.height;
                                var dim2 = data.cell.width;
                                doc.addImage(img, data.cell.x,  data.cell.y, dim2, dim1);
                            }catch(erro){

                            }
                        }
                    }
                });
            
        }

        
        

        //second Table est lma table de turpe
        doc.autoTable({
            body: [
                    [
                        
                        { 
                            content: 'TURPE', 
                            colSpan: 2, styles: { halign: 'center',valign: 'middle' , textColor: 255, lineWidth:1, fillColor: [4, 42, 95] } 
                        },
                        { 
                            content: 'Taxes',
                            colSpan: 3, styles: { halign: 'center',valign: 'middle', textColor: 255, lineWidth:1, fillColor: [51, 148, 231]} 
                        }
                    ],
                    [
                        { content: 'ABONNEMENT', colSpan: 1, styles: { halign: 'center',valign: 'middle', fillColor: [234, 234, 234] , lineWidth:1, cellWidth: 160, fontSize:8} },
                        { content: 'PART CONSOMMATION', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor: [234, 234, 234], lineWidth:1, cellWidth: 160, fontSize:8} },
                        { content: 'CTA', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor: [234, 234, 234], lineWidth:1, cellWidth: 80, fontSize:8} },
                        { content: 'TCFE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor: [234, 234, 234], lineWidth:1, cellWidth: 80, fontSize:8} },
                        { content: 'CSPE', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor: [234, 234, 234], lineWidth:1, cellWidth: 80, fontSize:8} },
                    ],
                    [
                        { content: parseFloat(this.turpeData['aboTurpe']).toFixed(2)+' €', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor:255, lineWidth:1, cellWidth: 160, fontSize:8} },
                        { content: parseFloat(this.turpeData['energieTurp']).toFixed(2) +' €', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor:255, lineWidth:1, cellWidth: 160, fontSize:8} },
                        { content: parseFloat(this.turpeData['ctaFinal']).toFixed(2) +' €', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor:255, lineWidth:1, cellWidth: 80, fontSize:8} },
                        { content: parseFloat(this.turpeData['tcfeFinal']).toFixed(2) +' €', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor:255, lineWidth:1, cellWidth: 80, fontSize:8} },
                        { content: parseFloat(this.turpeData['cspeFinal']).toFixed(2) +' €', colSpan: 1, styles: { halign: 'center',valign: 'middle' , fillColor:255, lineWidth:1, cellWidth: 80, fontSize:8} },
                    ]
            ],
            margin: { left: 120, top:120},
        });
        
        //doc.save('table.pdf')
        let blob = doc.output("arraybuffer");
        return blob;
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
        const copiedPages = await mergedPdf.copyPages(
            donorPdf,
            donorPdf.getPageIndices()
        )
        copiedPages.forEach((page) => {
            mergedPdf.addPage(page)
        })
        return mergedPdf
    },
    async modifyRecapitulatif() {
        if(this.indexes.length>0){
            this.dialog=true
            this.loading=true
           
            //sort the indexex c'est a dire trie des table fournisseur selon le côut
            this.indexes.sort(function(a, b) {
                return a - b;
            });
            
            //creation de liste qui contient seullment les donnees de fournisseur selectionne
            this.lesDonneesFournisseur = []
            if(this.fournisseurActuelle == true){
                this.lesDonneesFournisseur.push(this.donnerDesFournisseur[0])
            }
            for (var i=0; i<this.indexes.length; i++){
                    this.lesDonneesFournisseur.push(this.donnerDesFournisseur[this.indexes[i]])
            }
            //la colonne zero tousjour contient les donner partager entre tout les fournisseur comme le TURPE
            if(this.indexes.indexOf(0) == -1){
                this.lesDonneesFournisseur[0]['EnergieTurpe'] = this.turpeData['energieTurp']
                this.lesDonneesFournisseur[0]['PuissanceSousTurpe'] = this.turpeData['PSTurpe']
                this.lesDonneesFournisseur[0]['tcfeFinal'] = this.turpeData['tcfeFinal']
                this.lesDonneesFournisseur[0]['cspeFinal'] = this.turpeData['cspeFinal']
                this.lesDonneesFournisseur[0]['aboTurpe'] = this.turpeData['aboTurpe']
                this.lesDonneesFournisseur[0]['ctaFinal'] = this.turpeData['ctaFinal']
            }else{
                this.lesDonneesFournisseur[0]['EnergieTurpe'] = this.turpeData['energieTurp']
                this.lesDonneesFournisseur[0]['PuissanceSousTurpe'] = this.turpeData['PSTurpe']
                this.lesDonneesFournisseur[0]['tcfeFinal'] = this.turpeData['tcfeFinal']
                this.lesDonneesFournisseur[0]['cspeFinal'] = this.turpeData['cspeFinal']
                this.lesDonneesFournisseur[0]['aboTurpe'] = this.turpeData['aboTurpe']
                this.lesDonneesFournisseur[0]['ctaFinal'] = this.turpeData['ctaFinal']
            }
            const url1 = '/pdf/presentationFrederick/MART-ELEC-Presentation-offre-1.pdf'
            const firstDonorPdfBytes = await fetch(url1).then((res) =>
                res.arrayBuffer()
            )

            const url4 = '/pdf/presentationFrederick/MART-ELEC-Presentation-offre-5.pdf'
            const fourthDonorPdfBytes = await fetch(url4).then((res) =>
                res.arrayBuffer()
            )

            const pngUrl1 = '/logos/vattenfall-logo.png'
            const pngImageBytes1 = await fetch(pngUrl1).then((res) =>
                res.arrayBuffer()
            )

            const pngUrl2 = '/logos/elecocite-logo.png'
            const pngImageBytes2 = await fetch(pngUrl2).then((res) =>
                res.arrayBuffer()
            )

            // Load a PDFDocument from each of the existing PDFs
            const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes)
            
            // service
            const fourthDonorPdfDoc = await PDFDocument.load(fourthDonorPdfBytes)

            // Create a new PDFDocument
            let mergedPdf = await PDFDocument.create()
            //table recapulatif
            let tablePageRecap = await PDFDocument.load( await this.generateTableRecapulatif())
            const backGroundRecapUrl = '/logos/logoDune.png'
            const backGroundRecapImage = await fetch(backGroundRecapUrl).then((res) =>
                res.arrayBuffer()
            )
            let tableRecapPageDoc = tablePageRecap;
            const helveticaFont = await tableRecapPageDoc.embedFont( StandardFonts.Helvetica )
            const pagesRcap = tableRecapPageDoc.getPages()
            const firstPageRcap = pagesRcap[0]
            const { width, height } = firstPageRcap.getSize()
            const pngImageRecap = await tableRecapPageDoc.embedPng(backGroundRecapImage)
            firstPageRcap.drawImage(pngImageRecap, {
                x: 630,
                y: 500,
                width: 165,
                height: 40,
            })

            //fin table recapulatif
            //table detaille
            let tablePage = await PDFDocument.load(this.generatePdf())
            const backGround = '/logos/logoDune.png'
            const backGroundImage = await fetch(backGround).then((res) =>
                res.arrayBuffer()
            )
            let tablePageDoc = tablePage;
            const pages = tablePageDoc.getPages()
            const firstPage = pages[0]
            const pngImage = await tablePageDoc.embedPng(backGroundImage)
            const pngDims = pngImage.scale(0)
            firstPage.drawImage(pngImage, {
                x: 630,
                y: 500,
                width: 165,
                height: 40,
            })
            //fin table detailes


            //create the graphe big
            var bigGraphtable = null
            var byteDocBigGraph = null

            
                 byteDocBigGraph = await this.getBarGraph()
                if(byteDocBigGraph != 0){
                    bigGraphtable = await PDFDocument.load(byteDocBigGraph)
                    let bigGraphPageDoc = bigGraphtable;
                    const pagesBigGraphe = bigGraphPageDoc.getPages()
                    const firstBigGraphe = pagesBigGraphe[0]
                    const pngImageBigGraph = await bigGraphPageDoc.embedPng(backGroundRecapImage)
                    firstBigGraphe.drawImage(pngImageBigGraph, {
                        x: 630,
                        y: 500,
                        width: 155,
                        height: 45,
                    })
                }
            
            //end create graphe big

            mergedPdf = await this.addPagesToPdf(mergedPdf, firstDonorPdfDoc)
            mergedPdf = await this.addPagesToPdf(mergedPdf, tablePageRecap)
             if(byteDocBigGraph != 0){
                mergedPdf = await this.addPagesToPdf(mergedPdf, bigGraphtable)
             }
            mergedPdf = await this.addPagesToPdf(mergedPdf, tablePage)
            //table detaille plus plus
            
            //mergedPdf = await this.addPagesToPdf(mergedPdf, smallGraphtable)
            //fin add page of fournisseur 
            //begin services
            //fin services

            //begin conatact
            const urlContact = '/pdf/presentationFrederick/MART-ELEC-Presentation-offre-5.pdf' 
            const contactPdfBytes = await fetch(urlContact).then((res) =>
                res.arrayBuffer()
            )
            const contactPdfDoc = await PDFDocument.load(contactPdfBytes)
            
            const pagesContact = contactPdfDoc.getPages()
            const firstPageContact = pagesContact[0]
            
            
            
            var username = localStorage.getItem('email')
            var nomPrenom = localStorage.getItem('vendeurName')
            var Telephone = localStorage.getItem('Telephone')
            const timesRomanFont = await contactPdfDoc.embedFont(StandardFonts.HelveticaBold)
            const Helvetica = await contactPdfDoc.embedFont(StandardFonts.Helvetica)
            
            firstPageContact.drawText(nomPrenom, {
                x: 330,
                y: 260,
                size: 27,
                font: timesRomanFont,
                color: rgb(1, 1, 1),
            })
            if(Telephone != null && Telephone != "null"){
                firstPageContact.drawText(Telephone, {
                    x: 330,
                    y: 230,
                    size: 23,
                    font: Helvetica,
                    color: rgb(1, 1, 1),
                })

            }
            firstPageContact.drawText(username, {
                x: 300,
                y: 190,
                size: 23,
                font: Helvetica,
                color: rgb(1, 1, 1),
            })
            
            mergedPdf = await this.addPagesToPdf(mergedPdf, contactPdfDoc)
            //fin contact
            const pdfBytes = await mergedPdf.save()

            this.generatedPdf = pdfBytes
            
            this.loading=true
            this.dialog=false
            download(pdfBytes, 'presentation-'+this.lesDonneesFournisseur[0]['nomSociete']+'-'+new Date().toLocaleDateString("fr")+'.pdf', 'application/pdf')
        }
    },

    async addPagesToPdf(mergedPdf, donorPdf) {
        const copiedPages = await mergedPdf.copyPages(
            donorPdf,
            donorPdf.getPageIndices()
        )
        copiedPages.forEach((page) => {
            mergedPdf.addPage(page)
        })
        return mergedPdf
    },
    filterArray(inputArr){
        var found ={};
        var out = inputArr.filter(function(element){
            return found.hasOwnProperty(element)? false : (found[element]=true);
        });
        return out;
    },
    calculerNombreDesMois(date1, date2){
        var dateMomentObject = moment(date1, "DD/MM/YYYY")
        date1 = dateMomentObject.toDate()
        var dateMomentObject = moment(date2, "DD/MM/YYYY")
        date2 = dateMomentObject.toDate()
        var lesMois = ((date2.getMonth()+1) - (date1.getMonth()+1))
        var lesJour = (date2.getDate() - date1.getDate())
        if(lesJour>15){
            lesMois = lesMois+1
        }
        return lesMois + (12 * (date2.getFullYear() - date1.getFullYear()));
    },
    verifyApplicationDataSecurity() {
         if (this.$cookies.get('token')) {
                var formData = new FormData()
                formData.append('type', localStorage.getItem('typeUser'))
                formData.append('vueAfficher', 'pro')
                formData.append('token', this.$cookies.get('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'verifyApplicationDataSecurity.php',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                var data = JSON.parse(JSON.stringify(res.data))
                if (data[0].result === 'notLogin') {
                    localStorage.setItem('vueAfficher', 'particulier')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser')
                    this.$cookies.remove('token')
                    this.$router.push('/login')
                } else if (data[0].result === 'accessDenied') {
                    localStorage.setItem('vueAfficher', 'particulier')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser')
                    this.$cookies.remove('token')
                    this.$router.push('/login')
                }
                })
                .catch((err) => {
                console.log(err)
                })
            }else{
                this.$router.push('/login')
            }
    },
  },
}
</script>
