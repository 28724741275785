<template>
  <div class="ma-4 pa-4">
    <h5 class="pt-4 title-container mb-6 d-flex">Nouvelle Vente professionnelle</h5>
    <!-- modale loading-->
    <v-row>
    <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
      <dialogeModal
        :error="error"
        :dialog="dialog"
        :loading="loading"
        @closeDialog="dialog = false"
        titre="Ajouter une vente professionnelle"
        :messageDialogeSucces="this.messageDialogeSucces"
        :messageDialogeError="this.messageDialogeError" />
      </v-col>
    </v-row>
    <v-row>
      <!-- Champ SIREN et bouton pour lancer la recherche -->

      <v-col cols="12">
        <v-form ref="formSiren" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('un siren valide'), rules.isSiren]"
                v-model="siretInfo.numSiren"
                label="Siren"
                @input="reinitialiseVente"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <v-btn
                @click="submitRechercheSiret"
                color="blue darken-4"
                large
                class="mt-1 ma-auto text-white"
                width="100%"
                :loading="statusSiren.loading"
              >
                Rechercher
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <!-- v-if="siretFound == 1"  -->
    <!-- Champ SIRET et bouton pour lancer la recherche -->
    <!-- :rules="[
                  required(),
                  verifyLength(),
                  siretAcceptNumber(),
                  siretInList(),
                ]" -->
    <v-alert
        dense
        text
        type="error"
        v-if="!statusSiren.success && statusSiren.error"
        >
      {{ this.statusSiren.error }}
    </v-alert>

    <v-row v-if="statusSiren.success">
      <v-col cols="12">
        <v-form ref="formSiret" lazy-validation>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="siretInfo.typeEnergie"
                @change="modiferTypeEnergie"
                row
              >
                <v-radio label="Electricité" value="ELEC"></v-radio>
                <v-radio label="Gaz" value="GAZ"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="6" md="6" lg="6" xl="6">
              <v-autocomplete
                ref="siret"
                :items="listeSiret"
                v-model="siretInfo.numSiret"
                @change="listeCompteurs=[]"
                outlined
                :rules="[required('un siret valide')]"
                label="Siret"
              ></v-autocomplete>
            </v-col>

            <!-- v-if="siretFound == 1 || siretFound == 2" -->
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <v-btn
                depressed
                elevation="1"
                large
                @click="submitContinue"
                class="mt-1 ma-auto text-white"
                color="blue darken-4"
                width="100%"
                :loading="loadingValidationButton"
              >
                Valider
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    
    <v-alert dense text type="error" v-if="!statusCompteur.success">
      {{ this.statusCompteur.error }}
    </v-alert>
    <v-alert class="mt-1 mb-4"  dense text type="warning" v-if="!error_enedis && !statusCompteur.success">
      Attention, ce numéro de siret n'est pas enregistré dans notre base de données ou votre demande de cotation a été refusée
    </v-alert>
    <v-row v-if="(listeCompteurs.length > 0 && statusSiren.success==true)">
      <!-- info vente -->
      <v-col cols="12">
        <v-form ref="formVente" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('un nom'),rules.isString]"
                v-model="siretInfo.info.nom"
                label="Nom"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('un prenom'),rules.isString]"
                v-model="siretInfo.info.prenom"
                label="Prenom"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('une adresse e-mail valide'),rules.emailRules]"
                v-model="siretInfo.info.email"
                label="Adresse e-mail"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('un numéro de téléphone valide'), rules.isNumber, rules.phoneLength]"
                v-model="siretInfo.info.num_tel"
                label="Numéro de téléphone"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                outlined
                :rules="[required('une raison sociale valide')]"
                v-model="siretInfo.raison"
                label="Raison sociale"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-autocomplete
                v-if="listeCompteurs.length>0"
                ref="compteur"
                outlined
                :rules="[required('un N° compteur valide')]"
                :items="listeCompteurs"
                v-model="siretInfo.numCompteur"
                label="Compteur"
              ></v-autocomplete>
               <v-text-field
                v-if="listeCompteurs.length==0 && typeUser=='admin' && siretInfo.typeEnergie=='GAZ'"
                outlined
                :rules="[required('un N° compteur valide'), rules.isGazCompteur]"
                v-model="siretInfo.numCompteur"
                label="Compteur"
              ></v-text-field>
               <v-text-field
                v-if="listeCompteurs.length==0 && typeUser=='admin' && siretInfo.typeEnergie=='ELEC'"
                outlined
                :rules="[required('un N° compteur valide'), rules.compteurElecLength, rules.isNumber]"
                v-model="siretInfo.numCompteur"
                label="Compteur"
              ></v-text-field>
               </v-col>
              <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              v-if="siretInfo.typeEnergie === 'ELEC'"
            >
              <v-autocomplete
                ref="compteur"
                outlined
                :rules="[required('un Fournisseur valide')]"
                :items="listeFournisseursElec"
                v-model="siretInfo.fournisseur"
                @change="choisieFournisseur"
                label="Fournisseur"
              ></v-autocomplete>
            </v-col>
            <v-col  v-if="siretInfo.typeEnergie === 'ELEC'" cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6">
              <v-text-field
                outlined
                :rules="[required('une valeur valide'), rules.value]"
                type="number"
                step="0.001"
                v-model="siretInfo.valeur"
                label="Valeur"
                suffix="€"
              ></v-text-field> 
            </v-col>
          </v-row>

          <v-row>
            

            <v-col cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6">
              <v-text-field
                outlined
                v-if="siretInfo.typeEnergie === 'GAZ'"
                :rules="[required('une valeur valide'), rules.value]"
                type="number"
                step="0.001"
                v-model="siretInfo.valeur"
                label="Valeur"
                suffix="€"
              ></v-text-field> 
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6"
              v-if="siretInfo.typeEnergie === 'GAZ'"
              >
              <v-autocomplete
                ref="compteur"
                outlined
                :rules="[required('un Fournisseur valide')]"
                :items="listeFournisseursGaz"
                v-model="siretInfo.fournisseur"
                @change="choisieFournisseur"
                label="Fournisseur"
              ></v-autocomplete>
            </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" lg="12" xl="12"  v-if="siretInfo.fournisseur=='Ekwateur'">
                <v-card>
                <v-card-title>
                  Informations d'Annexe 5
                </v-card-title>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                      <v-text-field
                        outlined
                        :rules="[required('un IBAN valide')]"
                        v-model="infoAnnexe.IBAN"
                        label="IBAN">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                      <v-btn @click="getBankName">
                        obtenir le nom de la banque
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                      <v-text-field
                        outlined
                        :rules="[required('un nom de banque valide')]"
                        v-model="infoAnnexe.bank_name"
                        label="Nom de la banque">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                      <v-text-field
                        outlined
                        :rules="[required('un BIC valide')]"
                        v-model="infoAnnexe.bic"
                        label="BIC">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                      <v-text-field
                        outlined
                        type="date"
                        :rules="[required('une date de bascule')]"
                        v-model="infoAnnexe.dateDeBasculeSouhaite"
                        label="Date de bascule souhaitée">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            
            <!-- input file -->
            <inputFilePDF class='mt-12' :numberOfDuplicate="fileTypes.length" v-if="siretInfo.fournisseur!=null && siretInfo.fournisseur!=''" 
            @clearFileList="clearFileList"  @removeSplitPDF="removeSplitPDF" @sendPDFSplit="receivePDFSplit" :filePossible="fileTypes"/>
            <v-row >
                <v-col class="" cols="10" sm="5" md="4" lg="3" v-for="index in fileTypes" :key="index">
                     <div class="flex w-full h-screen items-center justify-center text-center">
                        <div class="p-1 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                            <h4>{{index}}</h4>
                            <input type="file" style="display: none;" :name="index" :id="index" 
                            class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange(index, null)" :ref="index" accept=".pdf,.eml,.msg" />

                            <label :for="index" class="block cursor-pointer">
                                <div :class="fileRequired?'requireddropzone':'dropzone'">
                                <v-icon x-large>cloud_upload </v-icon>
                                    <p>
                                        Déposez votre fichier ici <span><br>(ou)</span>
                                    </p>
                                    <p style="background-color: #e2e2e2;">Séléctionnez le fichier à importer</p>
                                    <p>Types de fichier supportées: pdf, eml</p>
                                </div>
                            </label>
                            
                            <ul class="mt-4" v-if="filelist[index]" v-cloak>
                                <li class="text-sm p-1" v-if="filelist[index][0] && filelist[index]!=='undefined'" >
                                    <a :href="filelist[index][0].url" target="_blank">{{filelist[index][0].name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </v-col>
            </v-row>

          <!-- commentaire -->
          <v-row class="mt-16" align="center" justify="center">
            <v-col cols="12" md="12" lg="12">
              <v-textarea
                solo
                auto-grow0
                name="comment"
                label="Commentaire"
                v-model="siretInfo.comment"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- button -->
          <v-row align="end" justify="end" class="mb-8">
            <!-- Validation forme -->

            <div>
              <v-btn
                depressed
                large
                text
                color="error"
                class="ma-auto"
                width="100%"
                :loading="false"
                @click="reinitialiseVente"
              >
                Réinitialiser
              </v-btn>
            </div>

            <div>
              <v-btn
                depressed
                elevation="1"
                large
                color="blue darken-4"
                class="ma-auto text-white"
                width="100%"
                :loading="false"
                @click="submitVente"
              >
                Envoyer
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import dialogeModal from '../../components/ui/dialogeModal.vue'
import inputFilePDF from './inputFilePDF'
export default {
  name: 'AjouterVentePro',
  components:{dialogeModal,inputFilePDF},

  data() {
    return {
      loadingValidationButton:false,
      error_enedis: true,
      dialog: false,
      loading: false,
      error: false,
      messageDialogeError: '',
      messageDialogeSucces:'',
      typeUser: '',
      continueAdmin: false,
      typeEnergieLocked: false,

      statusCompteur: {
        success: true,
        error: '',
        loading: false,
      },
      statusSiren: {
        success: false,
        error: '',
        loading: false,
      },

      listeCompteurs: [],
      lesFilesPossible: [],
      listeFournisseursElec: ['Engie', 'EDF', 'Dyneff'],
      listeFournisseursGaz: ['Butagaz', 'Eni'],
      listeSiret: [],

      fileTypes: [],
      typeFile: '',
      filesAttached: { file1: null, file2: null, file3: null },
      infoAnnexe:{
        nomDeSiteSurFacture: "",
        dateDeBasculeSouhaite:"",
        puissance:"",
        Nvoie:"",
        libelleVoie: "",
        codePostal: "",
        commune: "",
        bank_name: "",
        bic: "",
        IBAN: "",
      },
      siretInfo: {
        fileRequired: false,
        typeEnergie: 'ELEC',
        numSiren: '',
        numSiret: '',
        raison: '',
        numCompteur: '',
        compteur: [],
        comment: '',
        fournisseur: '',
        valeur: '',
        info: {
          nom: '',
          prenom: '',
          num_tel: '',
          email: '',
        },
      },
      fileRequired: false,
      filelist: {
          'CGV': [],
          'IBAN': [],
          'contrat': [],
          'RIB': [],
          'facture': [],
          'SEPA': [],
      },
      chargerToutFichier: true,
      //les rules du formulaire
      required(fieldName) {
          return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
      },
      rules: {
        isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
        noWhiteSpace: value => !(/\s/.test(value)) || "Ce champ ne doit pas être constitué d'espace",
        
        isSiren: (v) =>
          v.length == 9 && v.match(/^[0-9]+$/) != null|| 'Le siren doit être un nombre de 9 chiffres',        
        required: (value) => !!value || 'Ce champ est obligatoire.',
        value: (val) => val>=0 || "Ce champ doit être supérieur ou égal à zéro",

        sirenLength: (v) =>
          v.length == 9 || 'Le siren doit comporter 9 chiffres',
        isNumber: (v) => v.match(/^[0-9]+$/) != null || 'Ce champ doit être constitué uniquement de chiffres',
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 ||
          'Le numéro de téléphone est une suite de 10 chiffres',
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || 'Le numéro de téléphone est invalide',
        // margeMaxMin: (v) =>
        //   (v <= 10 && v >= 2) || 'La marge doit être comprise entre 2 et 10',

        emailRules: (v) => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || "l'adresse mail est invalide",
      },      
      verifyLength() {
        return (
          this.numSiret.length == 14 || 'Le siret doit comporter 14 chiffres.'
        )
      },
      siretAcceptNumber() {
        return this.numSiret.match(/^[0-9]+$/) != null || 'Ce champ doit être constitué uniquement de nombres'
      },
      siretInList() {
        return (
          this.toutLesSiret.indexOf(this.numSiret) != -1 ||
          'Choisissez un siret à partir de la liste'
        )
      },
    }
  },

  methods: {
    clearFileList(){
      for(const [key,value] of Object.entries(this.filelist)){
        this.filelist[key] = []
      }
    },
    removeSplitPDF(filePDF){
      for(const [key,value] of Object.entries(this.filelist)){
        if(value[0] != null && value[0].name == filePDF.name){
          this.remove(key)
        }
      }
    },
    receivePDFSplit({listFilePDF, listeIndex}){ 
      for(var j =0; j< this.fileTypes.length; j++){
        var index = listeIndex.findIndex(x => x === j)
        if (index == -1){
          this.onChange(this.fileTypes[j],null)
        }
      }
      for(var i =0; i< listeIndex.length; i++){
          this.onChange(this.fileTypes[listeIndex[i]],[listFilePDF[i]])
      }
      
    },
    getBankName(){
      if (this.infoAnnexe.IBAN != "" || this.infoAnnexe.IBAN != null){
        var formDonne = new FormData()
          formDonne.append('iban', this.infoAnnexe.IBAN)
        axios({
              url: process.env.VUE_APP_URL_API_CLIENT + 'getBankName/',
              method: 'POST',
              data: formDonne,
              
          }).then((res) => {
            if(res.data.result==true){
              this.infoAnnexe.bank_name = res.data.bank_name
              this.infoAnnexe.bic = res.data.bic
            }else{
              this.infoAnnexe.bank_name = ""
              this.infoAnnexe.bic = ""
              alert("Impossible de trouver le nom de la banque à partir de vos informations, essayez de la saisir manuellement s'il vous plaît")
            }
          }).catch((err)=>{
              this.infoAnnexe.bank_name = ""
              this.infoAnnexe.bic = ""
              alert("Impossible de trouver le nom de la banque à partir de vos informations, essayez de la saisir manuellement s'il vous plaît")
              console.log(err)
          })
      }else{
        return false;
      }
    },
    modiferTypeEnergie(){
      this.siretInfo.fournisseur=''
      this.siretInfo.numCompteur=''
      this.siretInfo.raison=''
      this.listeCompteurs=[]
      this.continueAdmin = false
      this.typeEnergieLocked = false
       this.fileRequired = false
      this.filelist['contrat'] = []
      this.filelist['facture'] = []
      this.filelist['CGV'] = []
      this.filelist['RIB'] = []
      this.filelist['IBAN'] = []
      this.filelist['SEPA'] = []
      this.chargerToutFichier = true
      this.fileTypes = []
    },
    verifierQueToutLesFichierCharger(){
        for(var i =0; i< this.fileTypes.length; i++){
            if(this.filelist[this.fileTypes[i]].length==0){
                return false
            }
        }
        return true
    },
    reinitialiseVente() {
      this.listeCompteurs = []
      this.listeSiret = []
      
      this.continueAdmin = false
      // this.listeFournisseursElec = []
      // this.listeFournisseursElec = []
      this.typeEnergieLocked = false
      this.statusCompteur.success = true
      this.statusCompteur.error = ''
      this.statusCompteur.loading = false

      this.statusSiren.success = false
      this.statusSiren.error = ''
      this.statusSiren.loading = false
       this.fileRequired = false
       
      this.siretInfo.raison=''
      this.filelist['contrat'] = []
      this.filelist['facture'] = []
      this.filelist['CGV'] = []
      this.filelist['RIB'] = []
      this.filelist['IBAN'] = []
      this.filelist['SEPA'] = []
      this.chargerToutFichier = true
    },
    async submitVente() {
        this.chargerToutFichier = this.verifierQueToutLesFichierCharger()
        if(this.chargerToutFichier == false) return
        
        if(this.$refs.formVente.validate() && this.siretInfo.typeEnergie == 'ELEC'){
          var formDonne = new FormData()
          formDonne.append('token', this.getToken('token'))
          formDonne.append('numCompteur', this.siretInfo.numCompteur)
          formDonne.append('autorisation', "false")
          
          await axios({
              url: process.env.VUE_APP_URL_API_CLIENT + 'verifCompteurExist/',
              method: 'POST',
              data: formDonne,
              
          }).then((res) => {
              if(res.data[0].result==='valide'){
                this.error_enedis = false
              }else if(res.data[0].result==='notValide'){
                  this.error_enedis = true
              }else if(res.data[0].result==='dataErreur'){
                  this.error_enedis = true
              }
          }).catch((err) => {
              this.error_enedis = true
          })
        }
        if(this.error_enedis == false || this.siretInfo.typeEnergie == 'GAZ'){
          let self = this
          if (!this.$refs.formVente.validate()) return
          this.chargerToutFichier = this.verifierQueToutLesFichierCharger()
          if(this.chargerToutFichier == false) return
          self.dialog = true
          self.loading = true
          self.error = false
          self.messageDialogeError = ''
          self.messageDialogeSucces = ''
          var formData = new FormData()
          formData.append('infoAnnexe', JSON.stringify(this.infoAnnexe))
          formData.append('siret', this.siretInfo.numSiret)
          formData.append('type', this.siretInfo.typeEnergie)
          formData.append('nom', this.siretInfo.info.nom)
          formData.append('prenom', this.siretInfo.info.prenom)
          formData.append('email', this.siretInfo.info.email)
          formData.append('numTele', this.siretInfo.info.num_tel)
          formData.append('numCompteur', this.siretInfo.numCompteur)
          formData.append('raison', this.siretInfo.raison)
          formData.append('fournisseur', this.siretInfo.fournisseur)
          formData.append('valeur', this.siretInfo.valeur)
          formData.append('status', 'en Attend')
          formData.append('commentaire', this.siretInfo.comment)
          formData.append('token', this.getToken('token'))
          for(var i =0; i< this.fileTypes.length; i++){
              formData.append(this.fileTypes[i], this.filelist[this.fileTypes[i]][0])
          }
          axios({
              url: process.env.VUE_APP_URL_API_CLIENT + 'ajouterVenetePro/',
              method: 'POST',
              data: formData,
              
          }).then((res) => {
              if(res.data.result == true){
                  self.loading = false
                  self.error = false
                  self.messageDialogeSucces = 'Vente ajoutée avec succès !'
              }else if (res.data.result == false){
                  self.loading = false
                  self.error = true
                  self.messageDialogeError = "Il semble qu'il y ait un problème. Veuillez contacter un administrateur"
              }else if (res.data.result == 'IBAN'){
                  self.loading = false
                  self.error = true
                  self.messageDialogeError = "Vérifier le numéro IBAN!"
              }else if (res.data.result == 'Error'){
                  self.loading = false
                  self.error = true
                  self.messageDialogeError = "la clé de L'Api qui vérifie l'IBAN a expiré!"
              }else if(res.data.result == "permission"){
                self.loading = false
                self.error = true
                self.messageDialogeError = 'Permission refusée'
              }
          }).catch((err)=>{
            self.dialog = false
            self.loading = false
            self.error = false
            self.messageDialogeSucces = ''
            self.messageDialogeError = ''
          })
        }
        
    },

    submitRechercheSiret() {
      this.$refs.formSiren.validate();
      //vide la liste des SIRET avant chaque recherche
      this.listeSiret = []
      //si le SIREN est saisi
      if (this.$refs.formSiren.validate()) {
        this.getAllSiret(this.siretInfo.numSiren)
      }
    },

    async getAllSiret(siren) {
        this.statusSiren.success = false
        this.statusSiren.loading = true
        this.statusSiren.error = ''
        var formData = new FormData()
        formData.append('siren', siren)
        formData.append('token', this.getToken('token'))
        axios({
                url: process.env.VUE_APP_URL_API_CLIENT + 'getListeSiretVentePro/',
                method: 'POST',
                data: formData,
                
        }).then((res) => {
            var data = JSON.parse(JSON.stringify(res.data))
            if (data.result === 1) {
                this.typeUser = data.type
                const listeSiret = []
              for (var key in data.siret) {
                  listeSiret.push(data.siret[key])
              }

              if (listeSiret.length === 0) {
                  this.statusSiren.success = false
                  this.statusSiren.loading = false
                  this.statusSiren.error = 'Impossible de trouver le SIRET correspondant ! Veuillez vérifier que votre SIREN soit valide.'
                  return
              }
              this.listeSiret = listeSiret
              this.statusSiren.loading = false
              this.statusSiren.success = true
            }else if(data.result === 0){
                  this.statusSiren.success = false
                  this.statusSiren.loading = false
                  this.statusSiren.error = "Ce numéro de sirène n'existe pas dans la base de données, assurez-vous que vous avez une demande de cotation associée à cette société"
                  this.listeSiret = []
                  return
            }else{
                this.statusSiren.success = false
                this.statusSiren.loading = false
                this.statusSiren.error = 'Impossible de trouver le SIRET correspondant ! Veuillez vérifier que votre SIREN soit valide.'
                return
            }
        }).catch((err) => {
            console.log(err)
            this.statusSiren.success = false
            this.statusSiren.loading = false
            this.statusSiren.error = 'Une erreur est survenue ! Veuillez réessayer plus tard'
        })
    },

    async submitContinue() {
      this.loadingValidationButton = true;
      if (this.$refs.formSiret.validate()){
        this.typeEnergieLocked = true
        await this.getInfoEtCompteur(
          this.siretInfo.numSiret,
          this.siretInfo.typeEnergie
        )

      }
        this.loadingValidationButton = false;
    },
    choisieFournisseur(){
        this.fileTypes = this.lesFilesPossible[this.siretInfo.fournisseur]
        this.typeFile = this.fileTypes[0]
        for(var i = 0; i< this.fileTypes.length; i++){
            this.filelist[this.fileTypes[i]]=[]
        }
    },

    async getInfoEtCompteur(siret, typeEnergie) {  
        let self = this
        this.statusCompteur.success = true
        var formData = new FormData()
        formData.append('siret', siret)
        formData.append('token', this.getToken('token'))
        formData.append('type', typeEnergie)
        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT + 'getInfoEtCompteurVentePro/',
          method: 'POST',
          data: formData,
        }).then((res)=>{
            var data = JSON.parse(JSON.stringify(res.data))
            if(data.result == false){
              this.statusSiren.success = true
              this.statusSiren.loading = false
              self.statusCompteur.success = false
              this.statusCompteur.error = "Il n'y a pas de numéro de compteur associé à ce siret, merci de vérifier que votre Demande de Cotation a bien été accepté"
              
            }else if(data.result == true){
                self.listeCompteurs = data.compteur
                self.siretInfo.info = data.info
                self.siretInfo.raison = data.info.raison
                var formData1 = new FormData()
                formData1.append('type', typeEnergie)
                  axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getFournisseurAndFiles/',
                    method: 'POST',
                    data: formData1,
                    
                }).then((res)=>{
                    if(typeEnergie == "ELEC"){
                        this.listeFournisseursElec = res.data.fournisseur
                        this.lesFilesPossible = res.data.files
                    }else{
                        this.listeFournisseursGaz = res.data.fournisseur
                        this.lesFilesPossible = res.data.files
                    }
                    
                }).catch((err)=>{

                })
                
            }else if(data.result == 'permissionAdmin'){
              this.statusSiren.success = false
              this.statusSiren.loading = false
              this.statusSiren.error = "Il n'y a pas de numéro de compteur associé à ce siret, merci de vérifier que votre Demande de Cotation a bien été accepté"
            
            }else if(data.result == 'permission'){
              localStorage.removeItem('vendeurName')
              localStorage.removeItem('typeUser')
              localStorage.removeItem('token')
              this.$router.push('/login')
            }
            
        }).catch ((err) => {
            self.statusCompteur.success = false
            self.statusCompteur.error = err.message
        })
        
    },
    onChange(file, data) {
        if(data==null){
            this.filelist[file] = this.$refs[file][0].files
        }else{
            let blobPDF = new Blob([data[0]], { type: 'application/pdf' });
            data[0].url = window.URL.createObjectURL(blobPDF)
            this.filelist[file] = data;
        }
    },

    //files
    remove(file) {
          this.filelist[file]= []
    },
    dragover(event) {
        event.preventDefault();
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('bg-green-300')) {
            event.currentTarget.classList.remove('bg-gray-100');
            event.currentTarget.classList.add('bg-green-300');
        }
    },
    dragleave(event) {
        // Clean up
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
        let self = this
        event.preventDefault();
        var nameInput = event.currentTarget.querySelector('input').name
        if(event.dataTransfer.files.length>1){
            self.$refs.file.files=null;
        }else{
            var posExtension = event.dataTransfer.files[0].name.split('.').length-1;
            var extension = event.dataTransfer.files[0].name.split('.')[posExtension];
            if(extension != 'pdf' && extension != 'PDF' && extension != 'EML' && extension != 'eml'  && extension != 'MSG' && extension != 'msg'){
              self.$refs[nameInput].files=null;
            }else{
              
              self.$refs[nameInput].files = event.dataTransfer.files;
                self.onChange(nameInput, event.dataTransfer.files); // Trigger the onChange event manually
                // Clean up
                event.currentTarget.classList.add('bg-gray-100');
                event.currentTarget.classList.remove('bg-green-300');
            }
            
        }
    },
  },
     created() {
      this.clearFileList();
    },
}
</script>
<style scoped>
::v-deep .v-label{
  margin:0px; 
}

@media only screen and (max-width:400px){
    .title-container{
      font-size:large;
      margin-left:0px !important;
    }
  }
</style>
<style scoped lang="scss">
    .dropzone {
    border: 2px solid #90a4ae56;
    border-radius: 8px;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    p {
        margin-bottom: 0;
        &:first-of-type {
        font-weight: 500;
        font-size: 1rem;
        color: #263238;
        span {
            color: gray;
        }
        }
        &:last-of-type {
        font-size: 0.8rem;
        }
    }
    }
    .requireddropzone {
    border: 2px dashed red;
    border-radius: 8px;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    p {
        margin-bottom: 0;
        &:first-of-type {
        font-weight: 500;
        font-size: 1rem;
        color: #263238;
        span {
            color: gray;
        }
        }
        &:last-of-type {
        font-size: 0.8rem;
        }
    }
    }
