<template>
    <div>
        <loadingComponent @closeDialog="dialog = $event" v-if="dialog" :dialogProps="dialog" :loadingProps="loading" :errorProps="error"/>
        <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialogAddCompteur==true">
                <v-dialog
                    v-model="dialogAddCompteur"
                    persistent
                    min-height="250">
                    <v-card>
                        <v-toolbar
                            :color="$colors[0]"
                            dark>
                            <v-card-title class="text-h6">
                                Edition contrat Ekwateur
                            </v-card-title>
                        </v-toolbar>
                    
                        <v-form ref="form"  lazy-validation v-if="ajouterUnAutreCompteur==true">
                            <v-card-actions class="d-flex flex-row">
                                <div class="d-flex flex-column">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <p><b>N° Compteur selectionné:</b> <small>{{numCompteur}}</small></p>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-autocomplete 
                                            v-model="selectedCompteur" 
                                            :items="listCompteurResult" 
                                            :loading="isLoading" 
                                            hide-no-data 
                                            hide-selected
                                            label="Autre compteur" 
                                            placeholder="Ajouter un compteur" 
                                            prepend-icon="mdi-account-search-outline"
                                            chips
                                            item-text="numCompteur"
                                            item-value="numCompteur"
                                            deletable-chips
                                            multiple
                                            return-object
                                            >
                                            <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index === 0">
                                                    <span>{{ item.numCompteur }}</span>
                                                    </v-chip>
                                                    <span
                                                    v-if="index === 1"
                                                    class="grey--text text-caption"
                                                    >
                                                    (+{{ selectedCompteur.length -1 }} {{selectedCompteur.length -1 > 1 ? 'éléments' : "élément"}})
                                                    </span>
                                                </template>
                                            <template v-slot:item="data">
                                                <template>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.numCompteur"></v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                            <template v-slot:prepend-item>
                                                <v-list-item ripple @click="toggle">
                                                    <v-list-item-content>
                                                        <v-list-item-title>Select All</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </div>
                            </v-card-actions>
                        </v-form>
                            <v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="selectedCompteur.length>0">
                                    <b-table
                                        class="text-center" 
                                        id="tableau-edition-ekwateur" 
                                        :busy="loadingTableHistorique" 
                                        fixed :fields="fieldhistoriqueDeCotation" 
                                        :items="selectedCompteur" 
                                        responsive 
                                        bordered
                                        :per-page="perPage"
                                        :current-page="currentPage">
                                        <template #table-busy>
                                                <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Chargement...</strong>
                                            </div>
                                        </template>
                                        <template #cell(numCompteur)='data'>
                                        <small>{{data.item.numCompteur}}</small>
                                        </template>
                                        <template #cell(tarif)='data'>
                                            <small>{{data.item.tarif}}</small>
                                        </template>

                                        <template #cell(profil)='data'>
                                            <small>{{data.item.profil}}</small>
                                        </template>
                                        <template #cell(conso)='data'>
                                            <v-text-field :rules="[rules.required]" dense outlined v-model="data.item.conso"></v-text-field>
                                        </template>


                                        <template #cell(dateDebut)="data">
                                            <v-text-field dense :rules="[rules.required,rules.superiorToday]" type="date" outlined  v-model="data.item.dateDebut"></v-text-field>
                                        </template>
                                        <template #cell(action)="data">
                                            <v-btn icon plain @click="supprimerCompteur(data.item.numCompteur)"><v-icon color="error">mdi-delete</v-icon></v-btn>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"></b-pagination>       
                                </v-col>
                                <div class="d-flex flex-column ma-4"> 
                                    <div class="d-flex flex-row justify-end">
                                        <v-btn color="error" text @click="closeModal">Fermer</v-btn>
                                        <v-btn :color="$colors[0]" class="white--text" @click="createContrat"> Generer contrat
                                                <v-icon dense class="ml-2">mdi-file-upload</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <div style="position:absolute;top:0px;right:0px">   
            <v-btn v-if="listCompteur.length == 0" icon  class="white--text" @click="createContrat">
                <v-icon :color="$colors[0]">mdi-file-upload</v-icon>
            </v-btn>
        
            <v-btn
                v-if="listCompteur.length > 0"
                @click="openDialog"
                icon
                depressed class="text-capitalize white--text">
                <v-icon :color="$colors[0]">mdi-bookmark-plus</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
    import loadingComponent from "../../components/ui/loading-component.vue"
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText, setFillingRgbColor, setFontAndSize  } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import axios from 'axios'
    import dayjs from "dayjs"
    export default{
        name: "EkwateurContratGAZ",
        components:{loadingComponent},
        props: {
            donner: null,
            numCompteur: null,
            listCompteur: [],
            societe: null,
        },
        data() {
            return {
                perPage: 3,
                currentPage: 1,
                rules:{
                        required: value => !!value || 'Ce champ est obligatoire.',
                        superiorToday: value => value > dayjs()|| `La date doit etre supérieure ou égale au ${dayjs().format('DD/MM/YYYY')}`
                    },
                loadingTableHistorique: false,
                fieldhistoriqueDeCotation:[
                    {key:"numCompteur", label:"Numéro de compteur"},
                    {key: "tarif", label:"Tarif"},
                    {key:"profil", label:"Profil"},
                    {key:"conso",label:"Consommation (Mwh)"},
                    {key:"dateDebut", label:"Début de contrat"},
                    {key:"action",label:"Action"}
                ],
                title: '',
                selectedCompteur: [],
                listCompteurResult: [],
                messageDialogeError: "",
                messageDialogeSucces:"",
                dialogAddCompteur: false,
                ajouterUnAutreCompteur: false,
                dialog: false,
                error: false,
                loading: false,
                isLoading: false,
                messageDialoge: "",
                data:{
                    dateAjourdhui: new Date().getDate()+"/"+(parseInt(new Date().getMonth())+1)+"/"+new Date().getFullYear(),
                    dateDebut: "10/10/2020",
                    dateFin: "10/10/2022",
                    pdl: "14552022144555",
                    siret: "11111111111111", 
                    codeNaf: "1212Z", 
                    adresseSociete: "199 RUE, 12554 commune d'atte", 
                    adresseCompteur: "199 RUE, 12554 commune d'atte",
                    codePostaleCompteur: '',
                    voieCompteur: '',
                    communeCompteur: '',
                    codePostaleSociete: '',
                    voieSociete: '',
                    communeSociete: '',
                    
                    codePostale: '',
                    nom: "chrif",
                    prenom: "slimen", 
                    email: "slimenc6@gmail", 
                    numTele: "0755448899", 
                    consommationAnuuelle: "377.20",
                    typeCompteur: "BTINFMUDT",
                    kva: "24",
                    raison: "Gaz elec moins cher Gaz elec moins cher Gaz elec moins cher",
                    nbrMonth: '2',
                    cabase: '',
                    cahp: '',
                    cahc: '',
                },
            }

        },
        created() {
            var index = this.listCompteur.findIndex(x => x === this.numCompteur)
            if (index > -1) { 
                this.listCompteur.splice(index, 1);
            }
            for(var i=0; i<this.listCompteur.length; i++){
                this.listCompteurResult[i] = {
                    "numCompteur": this.listCompteur[i],
                    "tarif": "T1",
                    "profil": "P011",
                    "conso": 0,
                    "dateDebut": this.donner.dateDebut.split('/').reverse().join('-'),
                }
            }
        },
        methods:{
            toggle(){
                this.selectedCompteur = [...this.listCompteurResult]
            },
            closeModal(){
                this.dialogAddCompteur=false
                this.ajouterUnAutreCompteur = false
                this.selectedCompteur = []
                for(var i=0; i<this.listCompteur.length; i++){
                    this.listCompteurResult[i] = {
                        "numCompteur": this.listCompteur[i],
                        "tarif": "T1",
                        "profil": "P011",
                        "conso": 0,
                        "dateDebut": this.donner.dateDebut.split('/').reverse().join('-'),
                    }
                }
            },
            openDialog(){
                this.dialogAddCompteur = true
                this.ajouterUnAutreCompteur = true
            },
            supprimerCompteur(numComp){
                var index = this.selectedCompteur.findIndex(x => x.numCompteur === numComp)
                if (index > -1) { 
                    this.selectedCompteur.splice(index, 1);
                }
                index = this.listCompteurResult.findIndex(x => x.numCompteur === numComp)
                if (index == -1) { 
                    this.listCompteurResult.push(x);
                }
            },
            async openAjouterUnAutreCompteur(){
                this.ajouterUnAutreCompteur = true
            },
            async createContrat(){
                this.addData = false
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialoge= ""
                var dataToSend = {
                    donner: this.donner,
                    listCompteur: this.selectedCompteur,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                }
                await axios({
                    url: 'https://editioncontrat.volto.fr/ekwateurContratGaz',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'_EKWATEUR_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
                
            },
            async getCodePostale(adresse){
                var myRe = new RegExp('\\d\\d\\d\\d\\d', '');
                var myArray = myRe.exec(adresse);
                var voie = ""
                var codePostale = ""
                var commune = ""
                if(myArray!=null){
                    codePostale =  myArray[0]
                    if(adresse.split(codePostale).length>=2){
                        voie = adresse.split(codePostale)[0]
                        commune = adresse.split(codePostale)[1]
                    }else{
                        commune = ""
                        voie = adresse
                    }
                }else{
                     voie = adresse
                }
                return [codePostale, voie, commune]
            },
            async getAdresseCompteur(){
                this.addData = false
                this.data.dateAjourdhui= new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear()
                if(this.donnerEnedis.donneTechniqueInfo[0].result == 'valide'){
                    this.data.adresseCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie+' '+
                            this.donnerEnedis.donneTechniqueInfo[0].data.codePostal+' '+this.donnerEnedis.donneTechniqueInfo[0].data.commune
                    
                    this.data.codePostaleCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.codePostal
                    this.data.voieCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie
                    this.data.communeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.commune

                }else{
                    
                    this.data.codePostaleCompteur = ""
                    this.data.voieCompteur = ""
                    this.data.communeCompteur = ""
                    this.data.adresseCompteu = ""
                }
                this.data.dateDebut = this.donner.dateDebut+""
                this.data.dateFin = this.donner.dateFin+""
                this.data.pdl = this.numCompteur+""
                this.data.siret = this.societe.siret+''
                this.data.adresseSociete = this.societe.AdressePostal+""
                this.data.nom = this.societe.nom+""
                this.data.prenom = this.societe.prenom+""
                this.data.email = this.societe.AdresseEmail+""
                this.data.numTele = this.societe.numTele+""
                this.data.fonction = this.societe.fonction+""
                this.data.consommationAnuuelle = this.donner.consomationGlobale+""
                this.data.kva = this.donner.kva+""
                this.data.raison = this.societe.Raison+""
                this.data.nbrMonth = this.donner.nbrMonth+""
                this.data.typeCompteur = this.optionTarifaire
                this.data.codeNaf = this.societe.codeNaf               
                this.data.codePostaleSociete = this.societe.codePostal
                this.data.voieSociete = this.societe.Voie
                this.data.communeSociete = this.societe.commune
                this.data.duree = this.donner.duree
                this.data.type = this.donner.type
                this.data.parametreCompteur = this.donner.parametreCompteur
                this.data.scoreCreditSafe = this.donner.scoreCreditSafe
            },
            async generatePdfForm(){
                
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialoge= ""
                 await this.getAdresseCompteur();
                /////////////// page 1 ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var form = pageZeroDoc.getForm()
                var fields = form.getFields()
                const dateDebut = form.getTextField('dateDebut')
                dateDebut.setText(this.data.dateDebut)
                dateDebut.setFontSize(11)
                var parag = this.data.raison+". dont le siège est situé "+
                    this.data.adresseSociete+". immatriculée sous le numéro SIRET "+
                    this.data.siret+"., représentée par "+
                    this.data.nom+" "+this.data.prenom+". en sa qualité de "+
                    this.data.fonction+" dûment habilité aux fins des présentes."
                const para = form.getTextField('para')
                para.setText(parag)
                para.setFontSize(9.5)
                const designe = form.getTextField('designe')
                designe.setText("Ci-après désigné « le Client » ou « "+this.data.raison+" ».")
                designe.setFontSize(9.5)
                fields.forEach(field => {
                    field.enableReadOnly()
                })
                form.updateFieldAppearances()

                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur2.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageOneDoc = await PDFDocument.load(pageOne)
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur3.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageTwoDoc = await PDFDocument.load(pageOne)
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur4.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageThreeDoc = await PDFDocument.load(pageOne)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                 firstPage.drawText(this.data.dateAjourdhui, {
                    x: 215,
                    y: 292,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur5.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFourDoc = await PDFDocument.load(pageOne)
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur6.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFiveDoc = await PDFDocument.load(pageOne)
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur7.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageSixDoc = await PDFDocument.load(pageOne)
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur8.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageSevenDoc = await PDFDocument.load(pageOne)
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur9.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const page8Doc = await PDFDocument.load(pageOne)
                var form = page8Doc.getForm()
                var fields = form.getFields()
                const faitle = form.getTextField('faitle')
                faitle.setText("Fait le "+this.data.dateAjourdhui)
                faitle.setFontSize(9.5)
                
                const nomSign = form.getTextField('nomSign')
                nomSign.setText(this.data.nom+" "+this.data.prenom)
                nomSign.setFontSize(8.5)
                
                const fonction = form.getTextField('fonction')
                fonction.setText(this.data.fonction)
                fonction.setFontSize(9.5)

                fields.forEach(field => {
                    field.enableReadOnly()
                })
                form.updateFieldAppearances()
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur10.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const page9Doc = await PDFDocument.load(pageOne)
                var form = page9Doc.getForm()
                var fields = form.getFields()
                fields.forEach(field => {
                    const type = field.constructor.name
                    const name = field.getName()
                })
                
                const PDL = form.getTextField('PDL')
                PDL.setText(this.data.pdl)
                PDL.setFontSize(8)
                
                const dateDebutFrn = form.getTextField('dateDebutFrn')
                dateDebutFrn.setText(this.data.dateDebut)
                dateDebutFrn.setFontSize(8)
                
                const conso = form.getTextField('conso')
                conso.setText(this.data.consommationAnuuelle+" MWh")
                conso.setFontSize(8)

                const kva = form.getTextField('kva')
                kva.setText(this.data.kva)
                kva.setFontSize(8)
                const nomClient = form.getTextField('nomClient')
                nomClient.setText(this.data.nom)
                nomClient.setFontSize(8)
                const prenomClient = form.getTextField('prenomClient')
                prenomClient.setText(this.data.prenom)
                prenomClient.setFontSize(8)

                var lesAdresse = await this.getCodePostale(this.data.adresseSociete)
                if (lesAdresse[0]!=""){
                    const adrClient = form.getTextField('adrClient')
                    adrClient.setText(lesAdresse[1])
                    adrClient.setFontSize(8)
                    const codePostale = form.getTextField('codePostale')
                    codePostale.setText(lesAdresse[0])
                    codePostale.setFontSize(8)
                    const commun = form.getTextField('commun')
                    commun.setText(lesAdresse[2])
                    commun.setFontSize(8)

                }else{
                    const adrClient = form.getTextField('adrClient')
                    adrClient.setText(lesAdresse[1])
                    adrClient.setFontSize(8)

                }
                const pays = form.getTextField('pays')
                pays.setText("France")
                pays.setFontSize(8)
                const siren = form.getTextField("siren")
                siren.setText(this.data.siret.substring(0, 9))
                siren.setFontSize(8)
                

                fields.forEach(field => {
                    field.enableReadOnly()
                })
                form.updateFieldAppearances()
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Ekwateur11.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const page10Doc = await PDFDocument.load(pageOne)
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Annexe-1.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const Annexe1 = await PDFDocument.load(pageOne)
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Annexe-2.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const Annexe2 = await PDFDocument.load(pageOne)
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Annexe-3.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const Annexe3 = await PDFDocument.load(pageOne)
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Annexe-4.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const Annexe4 = await PDFDocument.load(pageOne)
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Annexe-5.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const Annexe5 = await PDFDocument.load(pageOne)
                
                /////////////// page ///////////////////////////////////////
                var url0 = '/pdf/Ekwateur/Annexe-6.pdf'
                var pageOne = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const Annexe6 = await PDFDocument.load(pageOne)
                
                

                //fin contact
                 // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSixDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSevenDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page8Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page9Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page10Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, Annexe1)
                mergedPdf = await this.addPagesToPdf(mergedPdf, Annexe2)
                mergedPdf = await this.addPagesToPdf(mergedPdf, Annexe3)
                mergedPdf = await this.addPagesToPdf(mergedPdf, Annexe4)
                mergedPdf = await this.addPagesToPdf(mergedPdf, Annexe5)
                mergedPdf = await this.addPagesToPdf(mergedPdf, Annexe6)
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_Ekwateur_'+this.data.raison+'_.pdf', 'application/pdf')
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
            },
            async addPagesToPdf(mergedPdf, donorPdf) {
                const copiedPages = await mergedPdf.copyPages(
                    donorPdf,
                    donorPdf.getPageIndices()
                )
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page)
                })
                return mergedPdf
            },
        },
        computed: {
            rows() {
              return this.selectedCompteur.length
            }
        }
    
    }
</script>
<style>
#tableau-edition-ekwateur.table-bordered thead th {
    background-color:#103A5E;
    color:white;
    font-weight:bold;
}
</style>
