<style>
    .td-valeur{
        align-items:center;
        flex-direction:column
    }

    .td-statut{
        align-items:center;
        flex-direction:column;
    }
    .td-table, .td-valeur, .td-statut{
        display:flex;
        justify-content:center;
    }
    .d1{
        display: flex;
        align-items:center;
        width:100%;
        justify-content:flex-end;
    }
    .dropdown {
    position: relative;
    display: inline-block;
    }


    .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    }
    .one-line {
        text-align:center;
        font-size:15px;
        overflow: hidden; 
        white-space: nowrap;
    }
    .header th {
       height: 2px;
       line-height: 2px;
    }
    .radioButton{
        display:  inline-block;
    }

    .color-paye{
        color:#1B5E20
    }

    .color-validation{
        color:#137AE4
    }

    .color-valide{
        color:#13E464
    }

    .color-annuler{
        color:#FF5733
    }
    .hide{
        display: none;
    }

</style>
<template>
    <div>
        <v-card class="body">
        <div class="tableFilters d-flex flex-row align-items-center ml-lg-6 ml-2">
                        <input class="form-control search-input mt-4" type="text" v-model="search" placeholder="Rechercher..."
                            @input="filterResult({'type':'search', 'key':search})"  style="width:300px;">
                        <v-btn :color="$colors[0]"  class="ml-lg-4 mt-4 text-white" @click="resetFilter">réinitialiser les filtres</v-btn>
                        <v-btn :color="$colors[0]" class="ml-lg-6 mt-4 text-white" @click="extraireCsv('file')">Rapport de vente<v-icon class="ml-2">mdi-download</v-icon></v-btn>
        </div>
        <!--<dataTableAvancer class="ma-8" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy" 
            @search="rechercheData" @filter="filterResult" :items="items">-->
            <table id="table" class="ma-lg-5 ma-2 table table-striped" style="display: block; width:98%; min-height:300px; overflow-x:auto;">
                <thead >
                    <tr class="header">
                        <th style="text-align: left;" v-for="column in columns" :key="column.name" v-if="column.sortable" 
                            :class="sortKey == column.name ? (sortOrders[column.name]>0 ? 'sorting_asc': 'sorting_desc'):'sorting'"
                            :style="'width:'+column.width+';'">
                                <div v-if="column.name!='date'">
                                    <v-card-title  class="pa-0">
                                        <p class="one-line ma-0" style="width:280px">{{column.label}}</p>
                                                <v-select
                                                @input="filterResult({'type':'filter', 'items':filterItem})" 
                                                v-model="filterItem[column.name]" 
                                                :items="items[column.name]"
                                                hide-no-data 
                                                multiple>
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index === 0">
                                                    <span>{{ item }}</span>
                                                    </v-chip>
                                                    <span
                                                    v-if="index === 1"
                                                    class="grey--text text-caption"
                                                    >
                                                    (+{{ items[column.name].length -1 }} {{items[column.name].length -1 > 1 ? 'éléments' : "élément"}})
                                                    </span>
                                                </template>
                                                <template v-slot:prepend-item >
                                                        <v-list-item ripple class="pa-0">
                                                            <v-list-item-content>
                                                                    <div class="d-flex justify-space-between">
                                                                        <v-btn class="mx-2" @click="sortBy(column.name)">Triez</v-btn>
                                                                        <v-btn class="mx-2" style="cursor:pointer" v-if="filterItem[column.name]?.length < items[column.name]?.length || filterItem[column.name]?.length == 0" @click="selectTout(column.name,filterItem)">séléctionner</v-btn>
                                                                    </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider class="mt-2"></v-divider>
                                                    </template>
                                            </v-select>
                                    </v-card-title>
                                </div>
                                <div v-else>
                                    <v-card-title style="cursor:pointer" @click="sortBy(column.name)">
                                        <p class="one-line">{{column.label}}</p>
                                    </v-card-title>
                                </div>
                        </th>
                        <th style="text-align: left;" :key="column.name" v-else
                            :class="sortKey == column.name ? (sortOrders[column.name]>0 ? 'sorting_asc': 'sorting_desc'):'sorting'"
                            :style="'width:'+column.width+';'+'cursor:pointer;'">
                                <v-card-title class="pa-0"  @click="openDropDown(column.name)">
                                    <p class="one-line ma-0" style="width:220px">{{column.label}}</p>
                                    <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date"
                                        label="Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date"
                                    no-title
                                    range
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="datePicker()"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                                </v-card-title>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="projects.length > 0 && loadingTable == false">
                    <tr class="text-center" v-for="( project, index) in projects" 
                        :key="project.id">
                        <td class="td">
                            <div class="td-table">
                                {{project.date}}
                            </div>
                        </td>
                        <td class="td">
                            <div class="td-table">
                                {{project.Commercial}}
                            </div>

                        </td>
                        <td class="td">
                            <small class="td-table">
                                    <router-link :to="{path: '/les-profils-societes', query: { id: project.idSociete }}">{{project.Raison}}</router-link> 
                            </small>
                        </td>
                        <td class="td">
                            <small class="td-table">
                                {{project.fournisseur}}
                            </small>
                        </td>
                        <td>
                            <div v-if="project.type == 'ELEC'" class="td-table">
                                <v-icon  color="orange darken-1" class="mr-2">mdi-lightning-bolt-circle</v-icon>{{project.type}}
                            </div>
                            <div v-else class="td-table">
                                <v-icon  color="blue darken-4" class="mr-2">mdi-fire-circle</v-icon>{{project.type}}
                            </div>
                        </td>
                        <td class="td">
                            <div class="td-table">
                                {{project.numCompteur}}
                            </div>
                        </td>
                        <td class="td">
                            <div class='td-valeur'>
                                <div :id="'consommation'+project.id" style="width:100%"> 
                                    <div class="d1">
                                        <span>
                                            {{project.consommation}} Mwh  
                                        </span>
                                        <v-icon   @click="tryUpdateValue('consommation', project.id)" :id="'buttonTryEditConsommation'+project.id" class="pull-right ml-6" style="display: inline-block;"
                                            color='grey darken-4'>
                                            mdi-playlist-edit
                                        </v-icon>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <!--<v-radio-group
                                        style="display: none; margin:10px"
                                        :class="'radiovalueConsommation'+project.id" 
                                        v-model="RelierAuCalcule"
                                        row
                                        label="Influence sur les valeurs:"
                                        >
                                        <v-radio
                                            label="Oui"
                                            value="oui"
                                        ></v-radio>
                                        <v-radio
                                            label="Non"
                                            value="non"
                                        ></v-radio>
                                    </v-radio-group>-->
                                    <b-form-input type="text" :id="'inputConsommation'+project.id" :value="project.consommation" style="display: none; width:80%;" placeholder="CAR"></b-form-input>
                                    <div class="d-flex justify-content-center">
                                        <v-icon @click="annulerUpdateValue('consommation', project.id)" :id="'buttonAnnulerEditConsommation'+project.id" class="left-icon-pos pull-left" style="display: none;"
                                            color='red darken-4'>
                                            mdi-marker-cancel
                                        </v-icon>
                                        <v-icon @click="modifierValue('consommation', project.id)" :id="'buttonValideEditConsommation'+project.id" class="left-icon-pos pull-left ml-8" style="display: none;"
                                            color='green darken-4'>
                                            mdi-cloud-check
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="td">
                            <!--<div class="td-table">
                                {{project.duree}} mois
                            </div>-->
                            <div class='td-table'>
                                <div :id="'valueduree'+project.id" style="width:100%"> 
                                    <div class="d1">
                                        <span>
                                            {{project.duree}} €/Mwh
                                        </span>
                                        <v-icon   @click="tryUpdateValue('duree', project.id)" :id="'buttonTryEditduree'+project.id" class="pull-right ml-6" style="display: inline-block;"
                                            color='grey darken-4'>
                                            mdi-playlist-edit
                                        </v-icon>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <!--<v-radio-group
                                        style="display: none; margin:10px"
                                        :class="'radioduree'+project.id" 
                                        v-model="RelierAuCalcule"
                                        row
                                        label="Influence sur les valeurs:"
                                        >
                                        <v-radio
                                            label="Oui"
                                            value="oui"
                                        ></v-radio>
                                        <v-radio
                                            label="Non"
                                            value="non"
                                        ></v-radio>
                                    </v-radio-group>-->
                                    <b-form-input type="text" :id="'inputduree'+project.id" :value="project.duree" style="display: none; width:80%;" placeholder="Duree"></b-form-input>
                                    <div class="d-flex justify-content-center">
                                        <v-icon @click="annulerUpdateValue('duree', project.id)" :id="'buttonAnnulerEditduree'+project.id" class="left-icon-pos pull-left" style="display: none;"
                                            color='red darken-4'>
                                            mdi-marker-cancel
                                        </v-icon>
                                        <v-icon @click="modifierValue('duree', project.id)" :id="'buttonValideEditduree'+project.id" class="left-icon-pos pull-left ml-8" style="display: none;"
                                            color='green darken-4'>
                                            mdi-cloud-check
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="td">
                            <div class="td-table">
                                {{project.dateDF}}
                            </div>
                        </td>
                        <td class="td">
                            <div class='td-table'>
                                <div :id="'valuemarge'+project.id" style="width:100%"> 
                                    <div class="d1">
                                        <span>
                                            {{project.marge}} €/Mwh
                                        </span>
                                        <v-icon   @click="tryUpdateValue('valuemarge', project.id)" :id="'buttonTryEditvaluemarge'+project.id" class="pull-right ml-6" style="display: inline-block;"
                                            color='grey darken-4'>
                                            mdi-playlist-edit
                                        </v-icon>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <!--<v-radio-group
                                        style="display: none; margin:10px"
                                        :class="'radiovalueMarge'+project.id" 
                                        v-model="RelierAuCalcule"
                                        row
                                        label="Influence sur les valeurs:"
                                        >
                                        <v-radio
                                            label="Oui"
                                            value="oui"
                                        ></v-radio>
                                        <v-radio
                                            label="Non"
                                            value="non"
                                        ></v-radio>
                                    </v-radio-group>-->
                                    <b-form-input type="text" :id="'inputvaluemarge'+project.id" :value="project.marge" style="display: none; width:80%;" placeholder="Marge"></b-form-input>
                                    <div class="d-flex justify-content-center">
                                        <v-icon @click="annulerUpdateValue('valuemarge', project.id)" :id="'buttonAnnulerEditvaluemarge'+project.id" class="left-icon-pos pull-left" style="display: none;"
                                            color='red darken-4'>
                                            mdi-marker-cancel
                                        </v-icon>
                                        <v-icon @click="modifierValue('valuemarge', project.id)" :id="'buttonValideEditvaluemarge'+project.id" class="left-icon-pos pull-left ml-8" style="display: none;"
                                            color='green darken-4'>
                                            mdi-cloud-check
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="td">
                            <div class='td-valeur'>
                                <div :id="'valuevaleurBrut'+project.id" style="width:100%"> 
                                    <div class="d1">
                                        <span>
                                            {{project.valeurBrut}} € 
                                        </span>
                                        <v-icon   @click="tryUpdateValue('valeurBrut', project.id)" :id="'buttonTryEditvaleurBrut'+project.id" class="pull-right ml-6" style="display: inline-block;"
                                            color='grey darken-4'>
                                            mdi-playlist-edit
                                        </v-icon>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <v-radio-group
                                        style="display: none; margin:10px"
                                        :class="'radiovaluevaleurBrut'+project.id" 
                                        v-model="RelierAuCalcule"
                                        row
                                        label="Influence sur le valeur AA:"
                                        >
                                        <v-radio
                                            label="Oui"
                                            value="oui"
                                        ></v-radio>
                                        <v-radio
                                            label="Non"
                                            value="non"
                                        ></v-radio>
                                    </v-radio-group>
                                    <b-form-input type="text" :id="'inputvaluevaleurBrut'+project.id" :value="project.valeurBrut" style="display: none; width:80%;" placeholder="Valeur Brut"></b-form-input>
                                    <div class="d-flex justify-content-center">
                                        <v-icon @click="annulerUpdateValue('valeurBrut', project.id)" :id="'buttonAnnulerEditvaleurBrut'+project.id" class="left-icon-pos pull-left" style="display: none;"
                                            color='red darken-4'>
                                            mdi-marker-cancel
                                        </v-icon>
                                        <v-icon @click="modifierValue('valeurBrut', project.id)" :id="'buttonValideEditvaleurBrut'+project.id" class="left-icon-pos pull-left ml-8" style="display: none;"
                                            color='green darken-4'>
                                            mdi-cloud-check
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </td>                       
                        <td class="td">
                            <!--<div class='td-valeur'>
                                <div :id="'valuepourcentage'+project.id" style="width:100%"> 
                                    <div class="d1">
                                        <span>
                                            {{project.pourcentage}} % 
                                        </span>
                                        <v-icon   @click="tryUpdateValue('pourcentage', project.id)" :id="'buttonTryEditpourcentage'+project.id" class="pull-right ml-6" style="display: inline-block;"
                                            color='grey darken-4'>
                                            mdi-playlist-edit
                                        </v-icon>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <fieldset :class="'radiovaluepourcentage'+project.id"  style="display: none;">
                                        <input  type="number" :id="'inputvaluepourcentage'+project.id" :value="project.pourcentage" style="display: none;"
                                            class="form-control form-control-sm" max="100" min="0"/>
                                            <small>Influence sur les valeurs:</small>
                                            <v-divider/>
                                            <div class="d-flex justify-content-center">
                                                <div class="radioButton">
                                                    <input  type="radio" :id="'radiovaluepourcentageOui'+project.id" value="oui" v-model="RelierAuCalcule"/>
                                                    <label :for="'radiovaluepourcentageOui'+project.id">Oui</label>
                                                </div>
                                                <div class="radioButton ml-1">
                                                    <input  type="radio"  :id="'radiovaluepourcentageNon'+project.id" value="non" v-model="RelierAuCalcule"/>
                                                    <label :for="'radiovaluepourcentageNon'+project.id">Non</label>
                                                </div>
                                            </div>
                                            <v-divider/>
                                        
                                    </fieldset>
                                    <div class="d-flex justify-content-center">
                                        <v-icon @click="annulerUpdateValue('pourcentage', project.id)" :id="'buttonAnnulerEditpourcentage'+project.id" class="left-icon-pos pull-left" style="display: none;"
                                            color='red darken-4'>
                                            mdi-marker-cancel
                                        </v-icon>
                                        <v-icon @click="modifierValue('pourcentage', project.id)" :id="'buttonValideEditpourcentage'+project.id" class="left-icon-pos pull-left ml-8" style="display: none;"
                                            color='green darken-4'>
                                            mdi-cloud-check
                                        </v-icon>
                                    </div>
                                </div>
                            </div>-->
                            <div class="td-table">
                                {{project.pourcentage}} %
                            </div>
                        </td>
                        <td class="td">
                            <div class='td-valeur'>
                                <div :id="'Value'+project.id" style="width:100%"> 
                                    <div class="d1">
                                        <span>
                                            {{project.valeur}} €  
                                        </span>
                                        <v-icon   @click="tryUpdateValue('value', project.id)" :id="'buttonTryEdit'+project.id" class="pull-right ml-6" style="display: inline-block;"
                                            color='grey darken-4'>
                                            mdi-playlist-edit
                                        </v-icon>
                                    </div>
                                </div>
                                <div>
                                    <input  type="text " :id="'inputValue'+project.id" :value="project.valeur" style="display: none;"
                                            class="form-control form-control-sm"
                                    />
                                
                                    <v-icon @click="annulerUpdateValue('value', project.id)" :id="'buttonAnnulerEdit'+project.id" class="left-icon-pos pull-left" style="display: none;"
                                        color='red darken-4'>
                                        mdi-marker-cancel
                                    </v-icon>
                                    <v-icon @click="modifierValue('value', project.id)" :id="'buttonValideEdit'+project.id" class="left-icon-pos pull-left ml-8" style="display: none;"
                                        color='green darken-4'>
                                        mdi-cloud-check
                                    </v-icon>
                                </div>
                            </div>
                        </td>
                        <td class="td">
                            <div class='td-valeur'>
                                <div :id="'valueGEMC'+project.id" style="width:100%"> 
                                    <div class="d1">
                                        <span>
                                            {{project.valeurGemc}} €  
                                        </span>
                                        <v-icon   @click="tryUpdateValue('valueGEMC', project.id)" :id="'buttonTryEditvalueGEMC'+project.id" class="pull-right ml-6" style="display: inline-block;"
                                            color='grey darken-4'>
                                            mdi-playlist-edit
                                        </v-icon>
                                    </div>
                                </div>
                                <div>
                                    <input  type="text " :id="'inputvalueGEMC'+project.id" :value="project.valeurGemc" style="display: none;"
                                            class="form-control form-control-sm"
                                    />
                                
                                    <v-icon @click="annulerUpdateValue('valueGEMC', project.id)" :id="'buttonAnnulerEditvalueGEMC'+project.id" class="left-icon-pos pull-left" style="display: none;"
                                        color='red darken-4'>
                                        mdi-marker-cancel
                                    </v-icon>
                                    <v-icon @click="modifierValue('valueGEMC', project.id)" :id="'buttonValideEditvalueGEMC'+project.id" class="left-icon-pos pull-left ml-8" style="display: none;"
                                        color='green darken-4'>
                                        mdi-cloud-check
                                    </v-icon>
                                </div>
                            </div>
                        </td>
                        <td class="d-flex flex-column justify-content-center align-items-center">
                            <div class="d-flex flex-row justify-content-center align-items-start">
                                <p v-if="project.status == 'paye'"> 
                                    <v-icon color='#1B5E20' class="mr-2"> mdi-cash-check </v-icon><b>payée</b>
                                </p>
                                <p v-if="project.status == 'en validation'">
                                    <v-icon color='#137AE4' class="mr-2">mdi-send-check </v-icon><b>en cours de validation</b>
                                </p>
                                <p v-if="project.status == 'valide'" >
                                    <v-icon color='green accent-3' class="mr-2"> mdi-check-circle </v-icon><b>validée</b>
                                </p>
                                <p v-if="project.status == 'annuler'">
                                    <v-icon color='red' class="mr-2"> mdi-close-circle </v-icon><b>annulée</b>
                                </p>
                                <p v-if="project.status == 'en Attend'">
                                    <v-icon color='gray' class="mr-2"> mdi-timer-refresh-outline </v-icon><b>En attente</b>
                                </p>
                                <v-icon
                                    size="21"
                                    class="dropdown-toggle mt-1 ml-2" data-toggle="collapse" :data-target="'#collapseStatut'+index" aria-expanded="false" :aria-controls="'collapseStatut'+index" 
                                    left
                                    style="cursor:pointer"
                                    >
                                    mdi-chevron-down
                                </v-icon>
                            </div>
                                    <div  class="collapse" :id="'collapseStatut'+index" style="border:0px solid white">
                                            <v-btn
                                                @click="changeStatut(project,'paye')"
                                                v-if="project.status != 'paye'"
                                                class="mr-2"
                                                icon
                                                plain
                                                small>
                                                <v-icon
                                                color='#1B5E20'>
                                                    mdi-cash-check
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                @click="changeStatut(project,'valide')"
                                                v-if="project.status!='valide'"
                                                class="mr-2"
                                                icon
                                                small
                                                plain>
                                                <v-icon
                                                color='green accent-3'>
                                                    mdi-check-circle
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                @click="changeStatut(project,'en validation')"
                                                v-if="project.status!='en validation'"
                                                class="mr-2"
                                                icon
                                                small
                                                plain>
                                                <v-icon
                                                color='blue darken-4'>
                                                    mdi-send-check
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                @click="changeStatut(project,'annuler')"
                                                v-if="project.status != 'annuler'"
                                                class="mr-2"
                                                icon
                                                small
                                                plain>
                                                <v-icon
                                                    color='red'>
                                                    mdi-close-circle
                                                </v-icon>
                                            </v-btn>
                                </div>
                        </td>
                    </tr>
                </tbody>
                <noDataTable class="ma-4" v-else-if="projects?.length == 0 && loadingTable == false"/>
                <progressCircular class="ma-8" v-else-if='loadingTable == true'/>
            </table>
            <div class="d-flex">
                <v-col class='pa-0 pb-md-2'>
                    <pagination v-if="projects.length > 0" :pagination="pagination"
                        @selectNumberOfRecords="filterResult"
                        @first="filterResult({type:'next', numberOfPage:pagination.firstPage})"
                        @last="filterResult({type:'next', numberOfPage:pagination.lastPage})"
                        @prev="filterResult({type:'next', numberOfPage:pagination.prevPageUrl})"
                        @current="filterResult({type:'next', numberOfPage:pagination.currentPage})"
                        @next="filterResult({type:'next', numberOfPage:pagination.nextPageUrl})">
                    </pagination>
                </v-col>
            </div>
        
        </v-card>
    </div>
    
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    import $ from 'jquery'
    import jQuery from 'jquery'
    import moment from "moment"
    import dataTableAvancer from '../../components/dataTable/dataTableAvancer.vue'
    import noDataTable from "../../components/no-data-table.vue"
    import progressCircular from "../../components/progress-circular.vue"
    import Pagination from '../../components/dataTable/Pagination.vue'
    import axios from 'axios'
    export default{
        name: 'RapportDeVente',
        components: {dataTableAvancer:dataTableAvancer, pagination:Pagination,noDataTable, progressCircular},
        data(){
            let sortOrders={};
            let columns = [
                {width:'6vw',label:'Date', name:'date', sortable:true},
                {width:'6vw',label:'Vendeur', name:'nom', sortable:true},
                {width:'6vw',label:'Raison Sociale', name:'raison', sortable:true},
                {width:'6vw',label:'Fournisseur', name:'fournisseur', sortable:true},
                {width:'6vw',label:'Energie', name:'type', sortable:true},
                {width:'6vw',label:'PDL', name:'numCompteur', sortable:true},
                {width:'6vw',label:'Consommation', name:'Consommation', sortable:true},
                {width:'6vw',label:'Durée du contrat', name:'Duree', sortable:true},
                {width:'6vw',label:'DDF', name:'dateDF', sortable:false},
                {width:'6vw',label:'marge', name:'marge', sortable:true},
                {width:'6vw',label:'Valeur Brut', name:'valeurBrut', sortable:true},
                {width:'6vw',label:'Pourcentage', name:'Pourcentage', sortable:true},
                {width:'6vw',label:'Valeur AA', name:'valeurAA', sortable:true},
                {width:'6vw',label:'Valeur GEMC', name:'valeurGEMC', sortable:true},
                {width:'6vw',label:'Statut', name:'status', sortable:true},
            ];
            columns.forEach((column)=>{
                sortOrders[column.name] = -1;
            });
            return{
                loadingTable:false,
                date:[],
                menu: false,
                //data for header
                isOpenMyDropdown: false,
                closeEnClick: false,
                dialog: false,
                value: "",
                RelierAuCalcule: 'non',
                search:"",
                filterItem: {
                    date:[],
                    nom: [],
                    raison: [],
                    fournisseur: [],
                    type: [],
                    numCompteur: [],
                    Consommation: [],
                    Duree: [],
                    marge: [],
                    valeurBrut: [],
                    Pourcentage: [],
                    valeurAA: [],
                    valeurGEMC: [],
                    status: [],
                    dateDebutFMIN: null,
                    dateDebutFMAX: null,
                },
                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                //restdata
                dialog: false,
                loading: false,
                error: false,
                commentRefuser:'',
                messageDialoge: '',
                ouvrireDialogeRefuser: false,
                ouvrireDialogeSupprimer: false,
                projects: [],
                columns: columns,
                sortKey: 'date',
                sortOrders: sortOrders,
                perPage: ['15', '15', '30'],
                items:{
                    date:[],
                    nom: [],
                    raison: [],
                    fournisseur: [],
                    type: [],
                    numCompteur: [],
                    Consommation: [],
                    Duree: [],
                    marge: [],
                    valeurBrut: [],
                    Pourcentage: [],
                    valeurAA: [],
                    valeurGEMC: [],
                    status: [],
                    dateDebutFMIN: null,
                    dateDebutFMAX: null,
                },
                tableData: {
                    draw: 0,
                    length: 15,
                    search: '',
                    column: 0,
                    dir: 'desc',
                    filterItems: null,
                    filterType: 'all',
                },
                pagination: {
                    firstPage: 0,
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },

                 rules: {
                    required(finPhrase) {
                    return (value) => !!value || `Veuillez saisir ${finPhrase}`
                    },
                },
            }
        },
        async created() {
            this.loadingTable = true;
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            await this.getData();
            this.loadingTable = false;
        },
        methods: {
            downloadCSV(csv, filename) {
                var csvFile;
                var downloadLink;
                csvFile = new Blob([csv], {type: "text/csv"});
                downloadLink = document.createElement("a");
                downloadLink.download = filename;
                downloadLink.href = window.URL.createObjectURL(csvFile);
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);
                downloadLink.click();
            },
            extraireCsv(filename){
                var csv = [];
                var rows = document.querySelectorAll("#table thead tr, #table tbody tr");
                for (var i = 0; i < rows.length; i++) {
                    var row = [], cols = rows[i].querySelectorAll("td, th p");
                    for (var j = 0; j < cols.length; j++) 
                        row.push(cols[j].innerText);
                    
                    csv.push(row.join(","));        
                }
                this.downloadCSV(csv.join("\n"), filename);
            },
            datePicker(){
                this.filterItem.dateDebutFMIN = this.date[0]
                this.filterItem.dateDebutFMAX = this.date[1]
                this.menu = false

                this.$refs.menu.save(this.date)
                this.filterResult({'type':'filter', 'items':this.filterItem})
            },
            resetFilter(){
                this.search = "";
                
                this.date = []
                this.filterItem.dateDebutFMIN= null
                this.filterItem.dateDebutFMAX= null

                this.filterItem = {...this.items};
                this.filterResult({type:'filter', items:this.filterItem, numberOfPage:0})

            },
            openDropDown(name){
                for(var i=0; i<this.columns.length; i++){
                    if(this.columns[i].name != name){
                        $("#dropDown"+this.columns[i].name).css("display", "none");
                    }
                }
                var display = $("#dropDown"+name).css("display")
                if(display == 'none'){
                    $("#dropDown"+name).css("display", "block");
                }else{
                    $("#dropDown"+name).css("display", "none");
                }
            },
            selectTout(value,filterItem){
                if(this.filterItem[value].length==this.items[value].length){
                    this.filterItem[value] = []
                    this.closeEnClick = false
                }else{
                    this.closeEnClick = false
                    this.filterItem[value] = []
                    for (var i=0; i<this.items[value].length; i++){
                        this.filterItem[value].push(this.items[value][i]);
                    }
                    this.filterResult({'type':'filter', 'items':filterItem})
                }
            },
            tryUpdateValue(who, id){
                if (who === "value"){
                    $("#buttonTryEdit"+id).css("display", "none");
                    $("#buttonAnnulerEdit"+id).css("display", "inline-block");
                    $("#buttonValideEdit"+id).css("display", "inline-block");
                    
                    $("#Value"+id).css("display", "none");
                    $("#inputValue"+id).css("display", "inline-block");
                }
                if (who === "consommation"){
                    $("#buttonTryEditConsommation"+id).css("display", "none");
                    $("#buttonAnnulerEditConsommation"+id).css("display", "inline-block");
                    $("#buttonValideEditConsommation"+id).css("display", "inline-block");
                    
                    $("#consommation"+id).css("display", "none");
                    $("#inputConsommation"+id).css("display", "inline-block");
                    $(".radiovalueConsommation"+id).css("display", "inline-block");
                }
                if (who === "valueGEMC"){
                    $("#buttonTryEditvalueGEMC"+id).css("display", "none");
                    $("#buttonAnnulerEditvalueGEMC"+id).css("display", "inline-block");
                    $("#buttonValideEditvalueGEMC"+id).css("display", "inline-block");
                    $("#valueGEMC"+id).css("display", "none");
                    $("#inputvalueGEMC"+id).css("display", "inline-block");
                }
                if (who === "valuemarge"){
                    $("#buttonTryEditvaluemarge"+id).css("display", "none");
                    $("#buttonAnnulerEditvaluemarge"+id).css("display", "inline-block");
                    $("#buttonValideEditvaluemarge"+id).css("display", "inline-block");
                    
                    $("#valuemarge"+id).css("display", "none");
                    $("#inputvaluemarge"+id).css("display", "inline-block");
                    $(".radiovalueMarge"+id).css("display", "inline-block");
                }
                if (who === "valeurBrut"){
                    $("#buttonTryEditvaleurBrut"+id).css("display", "none");
                    $("#buttonAnnulerEditvaleurBrut"+id).css("display", "inline-block");
                    $("#buttonValideEditvaleurBrut"+id).css("display", "inline-block");
                    
                    $("#valuevaleurBrut"+id).css("display", "none");
                    $("#inputvaluevaleurBrut"+id).css("display", "inline-block");
                    $(".radiovaluevaleurBrut"+id).css("display", "inline-block");
                } 
                if (who === "duree"){
                    $("#buttonTryEditduree"+id).css("display", "none");
                    $("#buttonAnnulerEditduree"+id).css("display", "inline-block");
                    $("#buttonValideEditduree"+id).css("display", "inline-block");
                    
                    $("#valueduree"+id).css("display", "none");
                    $("#inputduree"+id).css("display", "inline-block");
                    $(".radioduree"+id).css("display", "inline-block");
                }
                /*if (who === "pourcentage"){
                    $("#buttonTryEditpourcentage"+id).css("display", "none");
                    $("#buttonAnnulerEditpourcentage"+id).css("display", "inline-block");
                    $("#buttonValideEditpourcentage"+id).css("display", "inline-block");
                    
                    $("#valuepourcentage"+id).css("display", "none");
                    $("#inputvaluepourcentage"+id).css("display", "inline-block");
                    $(".radiovaluepourcentage"+id).css("display", "inline-block");
                }*/
            },
            annulerUpdateValue(who,id){
                if (who === "value"){
                    $("#buttonTryEdit"+id).css("display", "inline-block");
                    $("#buttonAnnulerEdit"+id).css("display", "none");
                    $("#buttonValideEdit"+id).css("display", "none");

                    $("#Value"+id).css("display", "inline-block");
                    $("#inputValue"+id).css("display", "none");
                }
                if (who === "consommation"){
                    $("#buttonTryEditConsommation"+id).css("display", "inline-block");
                    $("#buttonAnnulerEditConsommation"+id).css("display", "none");
                    $("#buttonValideEditConsommation"+id).css("display", "none");

                    $("#consommation"+id).css("display", "inline-block");
                    $("#inputConsommation"+id).css("display", "none");
                    $(".radiovalueConsommation"+id).css("display", "none");
                    this.RelierAuCalcule='non'
                }
                 if (who === "valueGEMC"){
                    $("#buttonTryEditvalueGEMC"+id).css("display", "inline-block");
                    $("#buttonAnnulerEditvalueGEMC"+id).css("display", "none");
                    $("#buttonValideEditvalueGEMC"+id).css("display", "none");
                    $("#valueGEMC"+id).css("display", "inline-block");
                    $("#inputvalueGEMC"+id).css("display", "none");
                }
                if (who === "valuemarge"){
                    $("#buttonTryEditvaluemarge"+id).css("display", "inline-block");
                    $("#buttonAnnulerEditvaluemarge"+id).css("display", "none");
                    $("#buttonValideEditvaluemarge"+id).css("display", "none");
                    $("#valuemarge"+id).css("display", "inline-block");
                    $("#inputvaluemarge"+id).css("display", "none");
                    $(".radiovalueMarge"+id).css("display", "none");
                    this.RelierAuCalcule='non'
                }
                if (who === "valeurBrut"){
                    $("#buttonTryEditvaleurBrut"+id).css("display", "inline-block");
                    $("#buttonAnnulerEditvaleurBrut"+id).css("display", "none");
                    $("#buttonValideEditvaleurBrut"+id).css("display", "none");
                    $("#valuevaleurBrut"+id).css("display", "inline-block");
                    $("#inputvaluevaleurBrut"+id).css("display", "none");
                    $(".radiovaluevaleurBrut"+id).css("display", "none");
                    this.RelierAuCalcule='non'
                }
                if (who === "duree"){
                    $("#buttonTryEditduree"+id).css("display", "inline-block");
                    $("#buttonAnnulerEditduree"+id).css("display", "none");
                    $("#buttonValideEditduree"+id).css("display", "none");
                    $("#valueduree"+id).css("display", "inline-block");
                    $("#inputduree"+id).css("display", "none");
                    $(".radioduree"+id).css("display", "none");
                    this.RelierAuCalcule='non'
                }     
                /*if (who === "pourcentage"){
                    $("#buttonTryEditpourcentage"+id).css("display", "inline-block");
                    $("#buttonAnnulerEditpourcentage"+id).css("display", "none");
                    $("#buttonValideEditpourcentage"+id).css("display", "none");
                    $("#valuepourcentage"+id).css("display", "inline-block");
                    $("#inputvaluepourcentage"+id).css("display", "none");
                    $(".radiovaluepourcentage"+id).css("display", "none");
                    this.RelierAuCalcule='oui'
                }*/

            },
            modifierValue(who,id){
                if (who === "value"){   
                    var val = $('#inputValue'+id).val();
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('val', val)
                    formData.append('idVente', id)
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT + 'updateValueAAVentePro/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        if(res.data.result == true){
                            alert('la valeur de cette vente a été mise à jour')
                            var valeur = res.data.valeurAA
                            if(this.items['valeurAA'].indexOf(valeur)==-1){
                                this.items['valeurAA'].push(valeur)
                                this.filterItem['valeurAA'].push(valeur)
                            }else if(this.filterItem['valeurAA'].indexOf(valeur)==-1){
                                this.filterItem['valeurAA'].push(valeur)
                            }
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                            this.annulerUpdateValue("value", id)
                        }else if(res.data.result == false){
                            alert('vérifiez que votre valeur soit valide')
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                        }else if(res.data.result == "permission"){
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                            alert('permission refusée')
                    })
                }
                if (who === "consommation"){   
                    var val = $('#inputConsommation'+id).val();
                    
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('val', val)
                    formData.append('RelierAuCalcule', 'oui')
                    formData.append('idVente', id)
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT + 'updateConsommationVentePro/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        if(res.data.result == true){
                            alert('la valeur de cette vente a été mise à jour')
                            var pourcentage = res.data.pourcentage
                            var valeurBrut = res.data.valeurBrut
                            var valeur = res.data.valeur
                            var valeurGemc = res.data.valeurGemc
                            var marge = res.data.marge
                            var Consommation = res.data.Consommation
                            if(this.items['Consommation'].indexOf(Consommation)==-1){
                                this.items['Consommation'].push(Consommation)
                                this.filterItem['Consommation'].push(Consommation)
                            }else if(this.filterItem['Consommation'].indexOf(Consommation)==-1){
                                this.filterItem['Consommation'].push(Consommation)
                            }
                            if(this.items['Pourcentage'].indexOf(pourcentage)==-1){
                                this.items['Pourcentage'].push(pourcentage)
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }else if(this.filterItem['Pourcentage'].indexOf(pourcentage)==-1){
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }
                            if(this.items['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.items['valeurBrut'].push(valeurBrut)
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }else if(this.filterItem['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }
                            if(this.items['valeurAA'].indexOf(valeur)==-1){
                                this.items['valeurAA'].push(valeur)
                                this.filterItem['valeurAA'].push(valeur)
                            }else if(this.filterItem['valeurAA'].indexOf(valeur)==-1){
                                this.filterItem['valeurAA'].push(valeur)
                            }
                            
                            if(this.items['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.items['valeurGEMC'].push(valeurGemc)
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }else if(this.filterItem['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }
                            if(this.items['marge'].indexOf(marge)==-1){
                                this.items['marge'].push(marge)
                                this.filterItem['marge'].push(marge)
                            }else if(this.filterItem['marge'].indexOf(marge)==-1){
                                this.filterItem['marge'].push(marge)
                            }
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                            this.annulerUpdateValue("consommation", id)
                        }else if(res.data.result == false){
                            alert('vérifiez que votre valeur soit valide')
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                        }else if(res.data.result == "permission"){
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                            alert('permission refusée')
                    })
                }
                if (who === "valueGEMC"){   
                    var val = $('#inputvalueGEMC'+id).val();
                    
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('val', val)
                    formData.append('idVente', id)
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT + 'updateAutreValueVentePro/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        if(res.data.result == true){
                            alert('la valeur de cette vente a été mise à jour')
                            var valeurGemc = res.data.valeurGemc
                            if(this.items['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.items['valeurGEMC'].push(valeurGemc)
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }else if(this.filterItem['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                            this.annulerUpdateValue('valueGEMC', id)
                        }else if(res.data.result == false){
                            alert('vérifiez que votre valeur soit valide')
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                        }else if(res.data.result == "permission"){
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                            alert('permission refusée')
                    })
                }
                if (who === "valuemarge"){   
                    var val = $('#inputvaluemarge'+id).val();
                    
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('val', val)
                    formData.append('RelierAuCalcule', 'oui')
                    formData.append('idVente', id)
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT + 'updateMargeVentePro/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        if(res.data.result == true){
                            alert('la valeur de cette vente a été mise à jour')
                            var pourcentage = res.data.pourcentage
                            var valeurBrut = res.data.valeurBrut
                            var valeur = res.data.valeur
                            var valeurGemc = res.data.valeurGemc
                            var marge = res.data.marge
                            var Consommation = res.data.Consommation
                            if(this.items['Consommation'].indexOf(Consommation)==-1){
                                this.items['Consommation'].push(Consommation)
                                this.filterItem['Consommation'].push(Consommation)
                            }else if(this.filterItem['Consommation'].indexOf(Consommation)==-1){
                                this.filterItem['Consommation'].push(Consommation)
                            }
                            if(this.items['Pourcentage'].indexOf(pourcentage)==-1){
                                this.items['Pourcentage'].push(pourcentage)
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }else if(this.filterItem['Pourcentage'].indexOf(pourcentage)==-1){
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }
                            if(this.items['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.items['valeurBrut'].push(valeurBrut)
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }else if(this.filterItem['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }
                            if(this.items['valeurAA'].indexOf(valeur)==-1){
                                this.items['valeurAA'].push(valeur)
                                this.filterItem['valeurAA'].push(valeur)
                            }else if(this.filterItem['valeurAA'].indexOf(valeur)==-1){
                                this.filterItem['valeurAA'].push(valeur)
                            }
                            if(this.items['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.items['valeurGEMC'].push(valeurGemc)
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }else if(this.filterItem['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }
                            if(this.items['marge'].indexOf(marge)==-1){
                                this.items['marge'].push(marge)
                                this.filterItem['marge'].push(marge)
                            }else if(this.filterItem['marge'].indexOf(marge)==-1){
                                this.filterItem['marge'].push(marge)
                            }
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                            this.annulerUpdateValue('valuemarge',id)
                        }else if(res.data.result == false){
                            alert('vérifiez que votre valeur soit valide')
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                        }else if(res.data.result == "permission"){
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                            alert('permission refusée')
                    })
                }
                if (who === "valeurBrut"){   
                    var val = $('#inputvaluevaleurBrut'+id).val();
                    
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('val', val)
                    formData.append('RelierAuCalcule', this.RelierAuCalcule)
                    formData.append('idVente', id)
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT + 'updateValeurBrut/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        if(res.data.result == true){
                            alert('la valeur de cette vente a été mise à jour')
                            var pourcentage = res.data.pourcentage
                            var valeurBrut = res.data.valeurBrut
                            var valeur = res.data.valeur
                            var valeurGemc = res.data.valeurGemc
                            var marge = res.data.marge
                            var Consommation = res.data.Consommation
                            if(this.items['Consommation'].indexOf(Consommation)==-1){
                                this.items['Consommation'].push(Consommation)
                                this.filterItem['Consommation'].push(Consommation)
                            }else if(this.filterItem['Consommation'].indexOf(Consommation)==-1){
                                this.filterItem['Consommation'].push(Consommation)
                            }
                            if(this.items['Pourcentage'].indexOf(pourcentage)==-1){
                                this.items['Pourcentage'].push(pourcentage)
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }else if(this.filterItem['Pourcentage'].indexOf(pourcentage)==-1){
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }
                            if(this.items['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.items['valeurBrut'].push(valeurBrut)
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }else if(this.filterItem['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }
                            if(this.items['valeurAA'].indexOf(valeur)==-1){
                                this.items['valeurAA'].push(valeur)
                                this.filterItem['valeurAA'].push(valeur)
                            }else if(this.filterItem['valeurAA'].indexOf(valeur)==-1){
                                this.filterItem['valeurAA'].push(valeur)
                            }
                            if(this.items['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.items['valeurGEMC'].push(valeurGemc)
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }else if(this.filterItem['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }
                            if(this.items['marge'].indexOf(marge)==-1){
                                this.items['marge'].push(marge)
                                this.filterItem['marge'].push(marge)
                            }else if(this.filterItem['marge'].indexOf(marge)==-1){
                                this.filterItem['marge'].push(marge)
                            }
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                            this.annulerUpdateValue("valeurBrut",id)
                        }else if(res.data.result == false){
                            alert('vérifiez que votre valeur soit valide')
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                        }else if(res.data.result == "permission"){
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                            alert('permission refusée')
                    })
                }
                if (who === "duree"){   
                    var val = $('#inputduree'+id).val();
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('val', val)
                    formData.append('RelierAuCalcule', this.RelierAuCalcule)
                    formData.append('idVente', id)
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT + 'updateDuree/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        if(res.data.result == true){
                            alert('la valeur de cette vente a été mise à jour')
                            var pourcentage = res.data.pourcentage
                            var valeurBrut = res.data.valeurBrut
                            var valeur = res.data.valeur
                            var valeurGemc = res.data.valeurGemc
                            var marge = res.data.marge
                            var Consommation = res.data.Consommation
                            if(this.items['Consommation'].indexOf(Consommation)==-1){
                                this.items['Consommation'].push(Consommation)
                                this.filterItem['Consommation'].push(Consommation)
                            }else if(this.filterItem['Consommation'].indexOf(Consommation)==-1){
                                this.filterItem['Consommation'].push(Consommation)
                            }
                            if(this.items['Pourcentage'].indexOf(pourcentage)==-1){
                                this.items['Pourcentage'].push(pourcentage)
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }else if(this.filterItem['Pourcentage'].indexOf(pourcentage)==-1){
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }
                            if(this.items['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.items['valeurBrut'].push(valeurBrut)
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }else if(this.filterItem['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }
                            if(this.items['valeurAA'].indexOf(valeur)==-1){
                                this.items['valeurAA'].push(valeur)
                                this.filterItem['valeurAA'].push(valeur)
                            }else if(this.filterItem['valeurAA'].indexOf(valeur)==-1){
                                this.filterItem['valeurAA'].push(valeur)
                            }
                            if(this.items['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.items['valeurGEMC'].push(valeurGemc)
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }else if(this.filterItem['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }
                            if(this.items['marge'].indexOf(marge)==-1){
                                this.items['marge'].push(marge)
                                this.filterItem['marge'].push(marge)
                            }else if(this.filterItem['marge'].indexOf(marge)==-1){
                                this.filterItem['marge'].push(marge)
                            }
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                            this.annulerUpdateValue("duree",id)
                        }else if(res.data.result == false){
                            alert('vérifiez que votre valeur soit valide')
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                        }else if(res.data.result == "permission"){
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                            alert('permission refusée')
                    })
                }
                /*if (who === "pourcentage"){   
                    var val = $('#inputvaluepourcentage'+id).val();
                    
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('val', val)
                    formData.append('RelierAuCalcule', 'oui')
                    formData.append('idVente', id)
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT + 'updatePourcentageVentePro/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        if(res.data.result == true){
                            alert('la valeur de cette vente a été mise à jour')
                            var pourcentage = res.data.pourcentage
                            var valeurBrut = res.data.valeurBrut
                            var valeur = res.data.valeur
                            var valeurGemc = res.data.valeurGemc
                            var marge = res.data.marge
                            var Consommation = res.data.Consommation
                            if(this.items['Consommation'].indexOf(Consommation)==-1){
                                this.items['Consommation'].push(Consommation)
                                this.filterItem['Consommation'].push(Consommation)
                            }else if(this.filterItem['Consommation'].indexOf(Consommation)==-1){
                                this.filterItem['Consommation'].push(Consommation)
                            }
                            if(this.items['Pourcentage'].indexOf(pourcentage)==-1){
                                this.items['Pourcentage'].push(pourcentage)
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }else if(this.filterItem['Pourcentage'].indexOf(pourcentage)==-1){
                                this.filterItem['Pourcentage'].push(pourcentage)
                            }
                            if(this.items['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.items['valeurBrut'].push(valeurBrut)
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }else if(this.filterItem['valeurBrut'].indexOf(valeurBrut)==-1){
                                this.filterItem['valeurBrut'].push(valeurBrut)
                            }
                            if(this.items['valeurAA'].indexOf(valeur)==-1){
                                this.items['valeurAA'].push(valeur)
                                this.filterItem['valeurAA'].push(valeur)
                            }else if(this.filterItem['valeurAA'].indexOf(valeur)==-1){
                                this.filterItem['valeurAA'].push(valeur)
                            }
                            if(this.items['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.items['valeurGEMC'].push(valeurGemc)
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }else if(this.filterItem['valeurGEMC'].indexOf(valeurGemc)==-1){
                                this.filterItem['valeurGEMC'].push(valeurGemc)
                            }
                            if(this.items['marge'].indexOf(marge)==-1){
                                this.items['marge'].push(marge)
                                this.filterItem['marge'].push(marge)
                            }else if(this.filterItem['marge'].indexOf(marge)==-1){
                                this.filterItem['marge'].push(marge)
                            }
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                            this.annulerUpdateValue("pourcentage",id)
                        }else if(res.data.result == false){
                            alert('vérifiez que votre valeur soit valide')
                            this.filterResult({numberOfPage:this.pagination.currentPage})
                        }else if(res.data.result == "permission"){
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                            alert('permission refusée')
                    })
                }*/
            },
            async filterResult({type, key="", items='None', numberOfPage=0}){
                if(type=='search' || type=='nbrRecords' || type=='filter' || type=='sort' || type=='next'){
                    this.tableData.filterType = type
                }
                var pageNumber = 0
                if(type=='search'){
                    var pageNumber = 0
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = key
                }else if(type=="filter"){
                    this.tableData.filterItems = items;
                    this.tableData.filterItems.dateDebutFMIN= this.filterItem.dateDebutFMIN;
                    this.tableData.filterItems.dateDebutFMAX= this.filterItem.dateDebutFMAX;
                }else if(type=="sort"){
                    
                }else if(type=="next"){
                    pageNumber = numberOfPage
                }
                this.tableData.search = key
                this.tableData.draw++;
                await axios({url:process.env.VUE_APP_URL_API_CLIENT + 'filterRapportVentePro/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        "type": this.tableData.filterType,
                        "itemsFilter": this.tableData.filterItems,
                        'token': this.getToken('token')},
                }).then(response => {
                       if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.projects = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });

            },
            rechercheData({type, key, searchItem='None'}) {
                if(type=='search'){
                    var pageNumber = 0
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = key
                }
                var pageNumber = 0
                this.tableData.search = key
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getVenteProServerSideRapportDeVente/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        "searchItem": searchItem,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                       if(response.data.result == true){
                        let data = response.data.data;
                        this.items['nom']  = response.data.nom
                        this.items['raison']  = response.data.raison
                        this.items['fournisseur']  = response.data.fournisseur
                        this.items['type']  = response.data.type
                        this.items['numCompteur']  = response.data.numCompteur
                        this.items['Consommation']  = response.data.Consommation
                        this.items['Duree']  = response.data.listeDuree
                        this.items['marge']  = response.data.listeMarge
                        this.items['valeurBrut']  = response.data.listevaleurBrut
                        this.items['Pourcentage']  = response.data.listepourcentage
                        this.items['valeurAA']  = response.data.listevaleur
                        this.items['valeurGEMC']  = response.data.listevaleurGemc
                        this.items['status']  = response.data.listevaleurstatus
                        if (this.tableData.draw == data.draw) {
                            this.projects = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            /*ouvrireDialogeRefuserVente(item){
                this.VenteRefuser = item
                this.ouvrireDialogeRefuser = true
            },*/
            /*fermerDialogeRefuserVente(){
                this.VenteRefuser = ''
                this.ouvrireDialogeRefuser = false
            },*/
            /*ouvrireDialogeSupprimerVente(item){
                this.VenteSupprimer = item
                this.ouvrireDialogeSupprimer = true
            },*/
            /*fermerDialogeSupprimerVente(){
                this.VenteSupprimer = ''
                this.ouvrireDialogeSupprimer = false
            },*/
            async getData(pageNumber = 0) {
                this.tableData.draw++;
               await axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getVenteProServerSideRapportDeVente/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        "searchItem": "None",
                        'token': this.getToken('token')},
                })
                .then(response => {
                    if(response.data.result == true){
                        let data = response.data.data;
                        this.items['nom']  = response.data.nom
                        this.items['raison']  = response.data.raison
                        this.items['fournisseur']  = response.data.fournisseur
                        this.items['type']  = response.data.type
                        this.items['numCompteur']  = response.data.numCompteur
                        this.items['Consommation']  = response.data.Consommation
                        this.items['Duree']  = response.data.listeDuree
                        this.items['marge']  = response.data.listeMarge
                        this.items['valeurBrut']  = response.data.listevaleurBrut
                        this.items['Pourcentage']  = response.data.listepourcentage
                        this.items['valeurAA']  = response.data.listevaleur
                        this.items['valeurGEMC']  = response.data.listevaleurGemc
                        this.items['status']  = response.data.listevaleurstatus
                        this.filterItem = {...this.items}
                        if (this.tableData.draw == data.draw) {
                            this.projects = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            configPagination(data) {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.filterResult({type: 'sort'});
            },
            getIndex(array, key, value) {
                return array.findIndex(i => i[key] == value)
            },
           async changeStatut(item,newStatus){
                var formData = new FormData()
                    formData.append('newStatus', newStatus)
                    formData.append('id', item.id)
                    formData.append('token', this.getToken('token'))
                    await axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +'changeStatut/',
                        method: 'POST',
                        data: formData,
                        
                    }).then(async (res) => {
                        if(res.data.result == true){
                            await this.getData(this.pagination.currentPage)
                        }else{
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                        console.log(err)
                    })
            },
            deleteRow(deletedItem){

            }
            
        },
    }
    window.onclick = function(event) {
       if (event.target.className == "td" || event.target.className == "v-btn__content" ) {
          $(".dropdown-content").css('display', 'none')
       }
    }
</script>
<style scoped>
.td-table, .d1{
    display:flex;
    justify-content:center;
}
@media only screen and (max-width:350px){
    .search-input{
        width:125px !important;
    }
}
</style>