<template>
    <div>
            <!-- v-for="(item,i) in 5" :key="i" -->
            <v-card class='my-8 pa-4'
                v-for="(company, index) in Propositions"
                :key="company.name" >
                <v-row outlined no-gutters class="header" style="background: #ffffff;" >
                    <!--<v-col cols="12" sm="1" md="1" lg="1" xl="1">
                        <v-checkbox v-if="fournisseurActuelle == true && index == 0"
                            class="mr-2"
                            input-value="true"
                            :disabled="true"
                            ></v-checkbox>
                        <v-checkbox v-else
                            class="ma-0 pa-0"
                            v-model="selected"
                            @click.capture.stop
                            :value="index"
                            :disabled="(selected.length > 4 && selected.indexOf(index) === -1) || (selected.length > 3 && selected.indexOf(index) === -1 && fournisseurActuelle == true)"
                        ></v-checkbox>
                    </v-col>-->
                    <v-col  cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-row class="d-flex flex-column flex-lg-row">
                                    <v-checkbox style="position:absolute;z-index:3" v-if="fournisseurActuelle == true && index == 0"
                                        class="mr-2"
                                        input-value="true"
                                        :disabled="true"
                                        ></v-checkbox>
                                    <v-checkbox v-else
                                        style="position:absolute;z-index:3"
                                        class="ma-0 pa-0"
                                        v-model="selected"
                                        @click.capture.stop
                                        :value="index"
                                        :disabled="(selected.length > 4 && selected.indexOf(index) === -1) || (selected.length > 3 && selected.indexOf(index) === -1 && fournisseurActuelle == true)"
                                    ></v-checkbox>
                                    <v-img v-if="company.fournisseur == 'OHM'"
                                    style="border-radius:5px"
                                    overflow="hiden"
                                    max-height="80px"
                                    width="200px"
                                    class="ml-10"
                                    contain
                                    :src="getImageLogo(company.logo)"
                                    >
                                    </v-img>
                                    <v-img
                                         style="border-radius:5px"
                                        overflow="hiden"
                                        class="align-self-center"
                                        max-width="238px"
                                        max-height="80px"
                                        contain
                                        :src="getImageLogo(company.logo)"
                                    >
                                    </v-img>
                                    <div class="d-flex flex-column flex-lg-row justify-start align-start align-lg-center justify-md-center align-items-center">
                                        <div class="d-flex flex-column flex-sm-row" v-if="company.typeFournisseur != 'AuFrn' && company.typeFournisseur != 'Actuelle'">
                                            <p class="text-body-1 d-flex flex-md-column flex-lg-row grey--text font-weight-light mb-0 ma-2 mx-xs-0">
                                                Début de contrat : 
                                                <span style="color:#103A5E" class="font-weight-bold ml-2">{{ company.dateDebut }}</span>
                                            </p>
                                            <p class="text-body-1 d-flex flex-md-column flex-lg-row grey--text font-weight-light mb-0 ma-2 mx-xs-0">Fin de contrat : <span style="color:#103A5E" class="font-weight-bold ml-2">{{ company.dateFin }}</span></p>
                                        </div>
                                        <div  class="d-flex flex-column flex-sm-row">
                                            <p v-if="!(company.typeFournisseur != 'AuFrn' && company.typeFournisseur != 'Actuelle')" class="text-body-1 d-flex flex-md-column flex-lg-row grey--text font-weight-light   mb-0 ma-2 mx-xs-0">Durée de contrat : <span style="color:#103A5E" class="font-weight-bold ml-2">{{ company.DUREE}} mois</span></p>
                                            <p class="text-body-1 d-flex flex-md-column flex-lg-row grey--text font-weight-light   mb-0 ma-2 mx-xs-0">Budget annuel :<span style="color:#103A5E" class="font-weight-bold ml-2">{{ ""+company.budget.toLocaleString()}} €</span> </p>
                                            <p class="text-body-1 d-flex flex-md-column flex-lg-row grey--text font-weight-light   mb-0 ma-2 ">
                                                Offre : <span style="color:#103A5E" class="font-weight-bold ml-2">{{company.Offre}}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div v-if="company.fournisseur=='Ekwateur'" class="mt-8 mr-2">
                                        <EkwateurContratGAZ :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :listCompteur="listCompteur"/>
                                    </div>
                                    
                                </v-row>
                            
                            <!--<v-col cols="12" sm="6" md="4" lg="3" xl="3" align="center">
                                <div class="align-items-center" v-if="company.typeFournisseur != 'AuFrn' && company.typeFournisseur != 'Actuelle'">
                                        <p class="text-body-1 grey--text font-weight-light mb-0 ma-2 mx-xs-0">
                                            Début de contrat : 
                                            <span class="black--text font-weight-regular">{{ company.dateDebut }}</span>
                                        </p>
                                        <p class="text-body-1 grey--text font-weight-light mb-0 ma-2 mx-xs-0">Fin de contrat : <span class="black--text font-weight-regular">{{ company.dateFin }}</span></p>
                                </div>
                                <div class="align-items-center" v-else>
                                    <p class="text-body-1 grey--text font-weight-light   mb-0 ma-2 mx-xs-0">Durée de contrat : <span class="black--text font-weight-regular">{{ company.DUREE }} mois</span></p>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" xl="3" align="center">
                                <div class="align-items-center">
                                    <p class="text-body-1 grey--text font-weight-light   mb-0 ma-2 mx-xs-0">Budget annuel :<span class="black--text  font-weight-regular ">{{ ""+company.budget.toLocaleString()}} €</span> </p>
                                </div>
                            </v-col>-->
                             <!--
                            <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-if="company.fournisseur=='Engie' || company.typeFournisseur=='extern' || company.fournisseur=='Endesa'" align="center">
                                    <div v-if="company.Offre">
                                        <p class="text-body-1 grey--text font-weight-light   mb-1">
                                            Offre : <span class="black--text  font-weight-regular">{{company.Offre}}</span>
                                        </p>
                                    </div>
                            </v-col>-->
                    </v-col>
                        <v-col style="position:absolute;top:0;right:0;" cols="12" sm="12" md="12" lg="12" xl="12" v-if="company.fournisseur=='Engie'" class=" mt-1 mb-1" align="right">
                            <engieProGaz :donner="company" :numCompteur="numCompteur" :societe="SocieteData"/>
                        </v-col>
                        <v-col style="position:absolute;top:0px;right:0px" cols="12" sm="12" md="12" lg="12" xl="12" v-if="company.fournisseur=='Endesa'" class="mr-8 mt-1 mb-1" align="right">
                            <endesaGaz  :offreId="company.offreId"/>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-divider class="mb-3"></v-divider>
                        </v-col>
                        <!-- infos -->
                        <v-col cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                            <p class="d-flex flex-column text-body-1  ma-0" >
                                <span class="text-body-1 grey--text font-weight-light  ">Prix de la molécule</span>
                                <span style="color:#103A5E" class=" text-body-1 font-weight-bold mr-1">{{ ""+company.prixMolucule.toLocaleString() }}€/MWh</span>
                            </p>

                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                            <p class="d-flex flex-column text-body-1  ma-0 ">
                                <span class="text-body-1 grey--text font-weight-light  ">ARTD</span>
                                <span style="color:#103A5E" class=" text-body-1 font-weight-bold mr-1">{{ ""+company.ARTD.toLocaleString()}}€/MWh</span>
                            </p>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                            <p class="d-flex flex-column text-body-1  ma-0  ">
                                <span class="text-body-1 grey--text font-weight-light  ">Abonnement annuel</span>
                                <span style="color:#103A5E" class=" text-body-1 font-weight-bold mr-1">{{ ""+company.ABO.toLocaleString() }}€</span>
                            </p>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                            <p class="d-flex flex-column text-body-1  ma-0  ">
                                <span class="text-body-1 grey--text font-weight-light  "
                                >CTA</span
                                >
                                <span style="color:#103A5E" class=" text-body-1 font-weight-bold mr-1">{{ ""+company.CTA.toLocaleString()}}€/an</span>
                            </p>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                            <p class="d-flex flex-column text-body-1  ma-0">
                                <span class="text-body-1 grey--text font-weight-light  ">TICGN</span>
                                <span style="color:#103A5E" class=" text-body-1 font-weight-bold mr-1">{{ ""+company.TICGN.toLocaleString()+"€/MWh"}}</span>
                            </p>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                            <p class="d-flex flex-column text-body-1  ma-0  ">
                                <span class="text-body-1 grey--text font-weight-light ">CEE</span>
                                <span style="color:#103A5E" class=" text-body-1 font-weight-bold mr-1" v-if="company.cee == 'NON SOUMIS'">NON SOUMIS</span>
                                <span v-else style="color:#103A5E" class=" text-body-1 font-weight-bold mr-1">{{ ""+company.cee.toLocaleString()}}€/MWh</span>
                            </p>
                        </v-col>
                    
                </v-row>
            </v-card>
    </div>
</template>
<script>
    import EkwateurContratGAZ from '../../views/edition-contrat/EkwateurGaz.vue'
    import engieProGaz from '../../views/edition-contrat/engieProGaz.vue'
    import endesaGaz from "../../views/edition-contrat/endesa.vue"
    import axios from 'axios'
    import download from 'downloadjs'
    import moment from 'moment'
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    export default {
        name: 'ListePropositionsGaz',
        props: {
            Propositions: [],
            SocieteData:[],
            numCompteur: null,
            typeCompteur: null,
            panel: [],
            fournisseurActuelle: null,
            selected: [],
            listCompteur: []
        },
        components: {
            endesaGaz,
            EkwateurContratGAZ,
            engieProGaz,
        },
        data() {
            return {
                dialog: false,
                loading: false,
                error: false,
                codePremio: '',
            }
        },
        methods: {
            calculerNombreDesMois(date1, date2){
                var dateMomentObject = moment(date1, "DD/MM/YYYY")
                date1 = dateMomentObject.toDate()
                var dateMomentObject = moment(date2, "DD/MM/YYYY")
                date2 = dateMomentObject.toDate()
                var lesMois = ((date2.getMonth()+1) - (date1.getMonth()+1))
                var lesJour = (date2.getDate() - date1.getDate())
                if(lesJour>15){
                    lesMois = lesMois+1
                }
                return lesMois + (12 * (date2.getFullYear() - date1.getFullYear()));
            },
            getImageLogo(logo) {
                return require('@/assets/logoParticulier/' + logo)
            },
        }
    }
</script>
