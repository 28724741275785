<template>
  <div>
    <v-card class="mt-6 pa-4 px-16" elevation="10">
      <h1 class="ma-4 mb-6">Electricité Professionnels</h1>
        <v-row>
          <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
              <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="500"
                  >
                  <v-card>
                      <v-card-title class="text-h5">
                      Recherche de l'Entreprise
                      </v-card-title>
                      <v-card-text>
                          <v-alert v-if="error==true && loading==false" type="error">
                              {{messageDialoge}}
                          </v-alert>
                          <v-alert v-if="error==false && loading==false" type="success">
                              {{messageDialoge}}
                          </v-alert>
                          <v-flex  class="text-center">
                              <v-progress-circular v-if="loading==true"
                                  :size="150"
                                  :width="7"
                                  color="primary"
                                  indeterminate
                                  >
                                  Patientez  ...
                              </v-progress-circular>
                          </v-flex>
                      </v-card-text>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn v-if="loading==false"
                          color="green darken-1"
                          text
                          @click="dialog = false"
                      >
                          FERMER
                      </v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
          </v-col>
        </v-row>
      <FormeRechercheEntreprise
        :is-entreprise-found="isEntrepriseFound"
        @get-list-compteurs="getListeCompteurs"
        @isEntrepriseFoundChange ="changeIsEntrepriseFound"
      />

      <InfoSolvabilite
        v-if="isEntrepriseFound"
        :score-info-legale="scoreInfoLegale"
        :score-credit-safe="scoreCreditSafe"
      />

      <FormeDetaillesCompteur
        @reset-forms="resetForms"
        :siret="siret"
        :list-compteurs="listCompteurs"
        v-if="isEntrepriseFound"
        :isLoading="isLoadingPropositions"
        @get-propositions="getPropositions"
        @ischangeCompteurValue="changeCompteurValue"
      />
    </v-card>

    <v-card class="mt-6 pa-4" elevation="10" v-if="isEntrepriseFound && propositions.length > 0">
      <ListPropostions
        :numCompteur="numCompteur"
        :Propositions="propositions"
        :fournisseurActuelle="fournisseurActuelle"
        :typeCompteur="getCompteurType(optionTarifaire)"
        :selected="selected"
      />
    </v-card>
  </div>
</template>

<script>
import FormeRechercheEntreprise from '../components/proposition-professionnels-elec/FormeRechercheEntreprise.vue'
import InfoSolvabilite from '../components/proposition-professionnels-elec/InfoSolvabilite.vue'
import FormeDetaillesCompteur from '../components/proposition-professionnels-elec/FormeDetaillesCompteur.vue'
import ListPropostions from '../components/proposition-professionnels-elec/ListPropostions.vue'

import axios from 'axios'
import dialogeModal from '../components/ui/dialogeModal.vue'
export default {
  components: {
    FormeRechercheEntreprise,
    InfoSolvabilite,
    FormeDetaillesCompteur,
    ListPropostions,
    dialogeModal,
  },

  data() {
    return {
      numCompteur: null,
      selected: [],
      dialog: false,
      error: false,
      loading: false,
      messageDialoge: "L'e-mail a été envoyé avec succès",
      //donne pour le form recherche compteur
      showModal: null,
      isLoading: false,

      isFormValid: false,

      //donne generale

      siret: null,
      listCompteurs: [],
      propositions: [],
      optionTarifaire: null,

      errorType: null,

      isEntrepriseFound: null,
      isCompteursLoading: false,
      isLoadingPropositions: false,
      scoreInfoLegale: null,
      scoreCreditSafe: null,

      //test si fornisseur actuelle existe
      fournisseurActuelle: false,
    }
  },
  
  created() {
    //this.verifyApplicationDataSecurity()
  },
  methods: {
    resetForms() {
      this.isEntrepriseFound = null
      this.scoreInfoLegale = null
      this.scoreCreditSafe = null
      this.propositions = []
      this.selected= []
    },
    changeIsEntrepriseFound(value){
      this.isEntrepriseFound = value
      this.listCompteurs= []
      this.scoreInfoLegale = null
      this.scoreCreditSafe = null
      this.propositions = []
      this.selected= []
    },
    changeCompteurValue(numCompt){
      this.numCompteur = numCompt
      this.propositions = []
      this.selected= []
    },
    getListeCompteurs(data) {
      this.selected= []
      this.showModal = true
      this.loading = true
      this.error = false
      this.dialog = true
      var formData = new FormData()
      formData.append('type', data.type)
      formData.append('value', data.value)
      formData.append('token', this.getToken('token'))
      this.isCompteursLoading = true
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + 'getListCompteurElecParSiret/',
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          const responseData = res.data
          //authentification //cas non authentifié
          if (responseData.result === 'permission') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
            
            this.dialog = false
            this.loading = false
            this.error = false
            //authentification //mauvaise autorisation
          }else {
            //cas SIRET trouvé
            if (responseData.result == true) {
              //SET scores solvabilité
              this.scoreInfoLegale = responseData.scoreInfoLegale
              this.scoreCreditSafe = responseData.scoreCreditSafe
              //SET liste des Compteurs
              this.listCompteurs = responseData.compteur
              this.siret = responseData.siret

              this.isEntrepriseFound = true
              
              this.dialog = false
              this.loading = false
              this.error = false
              //cas SIRET non trouvé
            } else {
              this.isEntrepriseFound = false
              this.dialog = false
              this.loading = false
              this.error = false
              this.dialog = true
              this.error = true
              this.messageDialoge="L'entreprise n'est pas enregistré dans notre base de donnée."
            }
          }
          this.isCompteursLoading = false
          this.showModal = false
        })
        .catch((error) => {
          this.showModal = false
          this.dialog = false
          this.loading = false
          this.error = false
          this.dialog = true
          this.error = true
          this.messageDialoge="L'entreprise n'est pas enregistré dans notre base de donnée."
          this.isEntrepriseFound = false
          this.isCompteursLoading = false
          console.log(error)
        })
    },

    getPropositions(compteurData) {
      this.selected= []
      this.fournisseurActuelle = compteurData.isInfoFurnisseur;
      this.isLoadingPropositions = true

      this.optionTarifaire = compteurData.detaillesContrat.optionTarifaire

      var formData = new FormData()
      formData.append('siretPro', this.siret)
      formData.append(
        'kvaPro',
        compteurData.detaillesContrat.puissanceSouscrite
      )
      formData.append(
        'forniseur',
        compteurData.detaillesContrat.optionTarifaire
      )
      
      formData.append('CAP', compteurData.detaillesContrat.consoP)
      formData.append('CAHP', compteurData.detaillesContrat.consoAHP)
      formData.append('CAHC', compteurData.detaillesContrat.consoAHC)
      formData.append('CAHPH', compteurData.detaillesContrat.consoAHPH)
      formData.append('CAHCH', compteurData.detaillesContrat.consoAHCH)
      formData.append('CAHPB', compteurData.detaillesContrat.consoAHPB)
      formData.append('CAHCB', compteurData.detaillesContrat.consoAHCB)
      formData.append('CA', compteurData.detaillesContrat.consoA)
      formData.append('compteurProAcd', compteurData.compteur)
            
      formData.append('kvaBase', compteurData.detaillesContrat.kvaBase)
      formData.append('kvaHp', compteurData.detaillesContrat.kvaHp)
      formData.append('kvaHc', compteurData.detaillesContrat.kvaHc)
      formData.append('kvaPte', compteurData.detaillesContrat.kvaPte)
      formData.append('kvaHce', compteurData.detaillesContrat.kvaHce)
      formData.append('kvaHpe', compteurData.detaillesContrat.kvaHpe)
      formData.append('kvaHch', compteurData.detaillesContrat.kvaHch)
      formData.append('kvaHph', compteurData.detaillesContrat.kvaHph)

      formData.append(
        'dateDebutPro',
        compteurData.detaillesContrat.dateDebutContrat
      )
      formData.append(
        'dateFinPro',
        compteurData.detaillesContrat.dateFinContrat
      )
      formData.append('marge', compteurData.detaillesContrat.marge)
      //renitialiser cout

      formData.append('showfournisseurActuelle', compteurData.isInfoFurnisseur)
      formData.append('coutP', compteurData.detaillesFournisseur.coutP)
      formData.append('coutHP', compteurData.detaillesFournisseur.coutHP)
      formData.append('coutHC', compteurData.detaillesFournisseur.coutHC)
      formData.append('coutHPH', compteurData.detaillesFournisseur.coutHPH)
      formData.append('coutHCH', compteurData.detaillesFournisseur.coutHCH)
      formData.append('coutHPE', compteurData.detaillesFournisseur.coutHPB)
      formData.append('coutHCE', compteurData.detaillesFournisseur.coutHCB)
      formData.append('coutBase', compteurData.detaillesFournisseur.coutBase)
      // coef capa
      formData.append('coefP', compteurData.detaillesFournisseur.coefP)
      formData.append('coefHP', compteurData.detaillesFournisseur.coefHP)
      formData.append('coefHC', compteurData.detaillesFournisseur.coefHC)
      formData.append('coefHPH', compteurData.detaillesFournisseur.coefHPH)
      formData.append('coefHCH', compteurData.detaillesFournisseur.coefHCH)
      formData.append('coefHPE', compteurData.detaillesFournisseur.coefHPB)
      formData.append('coefHCE', compteurData.detaillesFournisseur.coefHCB)
      formData.append('coefBase', compteurData.detaillesFournisseur.coefBase)
      // cout capa

      formData.append('cout_c_P', compteurData.detaillesFournisseur.cout_c_P)
      formData.append('cout_c_HP', compteurData.detaillesFournisseur.cout_c_HP)
      formData.append('cout_c_HC', compteurData.detaillesFournisseur.cout_c_HC)
      formData.append(
        'cout_c_HPH',
        compteurData.detaillesFournisseur.cout_c_HPH
      )
      formData.append(
        'cout_c_HCH',
        compteurData.detaillesFournisseur.cout_c_HCH
      )
      formData.append('cout_c_HPB',compteurData.detaillesFournisseur.cout_c_HPB)
      formData.append('cout_c_HCB',compteurData.detaillesFournisseur.cout_c_HCB)
      formData.append('cout_c_Base',compteurData.detaillesFournisseur.cout_c_Base)
      formData.append('typeCapa',compteurData.detaillesFournisseur.optionCapacite)
      //autre data

      formData.append('aboForAct', compteurData.detaillesFournisseur.ABO)
      formData.append('forActuelle', compteurData.detaillesFournisseur.fournisseur)
      formData.append('ceeForAct', compteurData.detaillesFournisseur.CEE)
      formData.append('nbrMois', compteurData.detaillesFournisseur.nbrMois)
      //upload pdf file to php server
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + 'calcule_cout_pro.php',
        method: 'POST',
        data: formData,
        
      })
        .then((res) => {
          var data = JSON.parse(JSON.stringify(res.data))
          if (data[0].result === 'notLogin') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else if (data[0].result === 'accessDenied') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else {
            if (data[0].result != '0') {
              if (data[0].result != 2) {
                // si errorType = string c.a.d que l'erreur dans le choix de  Options Tarifaire,
                // errorType == 2 Error de connection try again, ou bien valeur incompatible
                this.errorType = data[0].result
              } else {
                this.errorType = data[0].result
              }
            } else {
              this.errorType = 1
              // save le donnée du fornisseur dans une liste companiesPro
              this.propositions = data
            }
          }
          this.isLoadingPropositions = false
        })
        .catch((err) => {
          console.log(err)
          this.errorType = 2
          this.isLoadingPropositions = false
        })
    },
    getCompteurType(optionTarifaire) {
      if (
        optionTarifaire == 'HTALUPM5' ||
        optionTarifaire == 'HTALU5' ||
        optionTarifaire == 'HTACUPM5' ||
        optionTarifaire == 'HTACU5'
      ) {
        return 'c3'
      } else if (
        optionTarifaire == 'BTSUPCU4' ||
        optionTarifaire == 'BTSUPLU4'
      ) {
        return 'c4'
      } else if (
        optionTarifaire == 'BTINFCUST' ||
        optionTarifaire == 'BTINFLU' ||
        optionTarifaire == 'BTINFCU4'
      ) {
        return 'c5base'
      } else if (
        optionTarifaire == 'BTINFMUDT' ||
        optionTarifaire == 'BTINFMU4'
      ) {
        return 'c5hp'
      }
    },
    verifyApplicationDataSecurity() {
        let self = this
        var formData = new FormData()
        formData.append('type', localStorage.getItem('typeUser'))
        formData.append('vueAfficher', 'pro')
        axios({
            url:
            process.env.VUE_APP_URL_API_CLIENT +
            'verifyApplicationDataSecurity.php',
            method: 'POST',
            data: formData,
            
        })
        .then((res) => {
            var data = JSON.parse(JSON.stringify(res.data))
            if (data[0].result === 'notLogin') {
                localStorage.setItem('vueAfficher', 'particulier')
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser')
                localStorage.removeItem('token')
                this.$router.push('/login')
            } else if (data[0].result === 'accessDenied') {
                localStorage.setItem('vueAfficher', 'particulier')
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser')
                localStorage.removeItem('token')
                this.$router.push('/login')
            }
        })
        .catch((err) => {
            console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
/* The Modal (background) */
.modall {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modall-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
